var render = function render(_c, _vm) {
  return _c("div", { staticClass: "winbox-global-progress" }, [
    _c("progress", {
      staticClass: "progress",
      class: _vm.props.progressClasses,
      attrs: { max: _vm.props.max },
      domProps: { value: _vm.props.value }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }