import { SpecialityUrl } from "@/constants/SpecialityUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllSpecialities(orgType) {
  const url = `${SpecialityUrl.base}?type=${orgType}`;
  return getGenericFullResponse(url);
}

export function createNewSpeciality(data) {
  const url = `${SpecialityUrl.base}`;
  return postGenericFullResponse(url, data);
}
