export const WinboxRoutePaths = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  RESET_PASSWORD: "/reset*",

  SETTINGS: "/settings",
  SETTINGS_PROFILE: "profile",
  SETTINGS_REPORTS: "reports",
  SETTINGS_USERS: "users",
  SETTINGS_TASKS: "tasks",

  FRONT_DASHBOARD: "/",
  DATA_IMPORT: "/data-import",
  COMPANIES_DATA_MAPPING: "/companies-data-mapping",

  GENERIC_PROFILE: "profile",

  COMPANIES: "/companies",
  COMPANY_PROFILE: "profile",
  COMPANY_LOCATIONS: "locations",
  COMPANY_CONTACTS: "contacts",
  COMPANY_AGENCIES: "agencies",
  COMPANY_BRANDS: "brands",
  COMPANY_HIERARCHY: "hierarchy",
  COMPANY_NOTES: "notes",
  COMPANY_TAGS: "tags",
  COMPANY_INTERNAL_NOTES: "internal-notes",
  COMPANY_AUDIT_LOG: "audit-log",
  COMPANY_AGENCY_CONTACTS: "agency-contacts",

  AGENCIES: "/agencies",
  AGENCY_PROFILE: "profile",
  AGENCY_LOCATIONS: "locations",
  AGENCY_CONTACTS: "contacts",
  AGENCY_BRANDS: "brands",
  AGENCY_HIERARCHY: "hierarchy",
  AGENCY_DISCIPLINES: "disciplines",
  AGENCY_NOTES: "notes",
  AGENCY_TAGS: "tags",
  AGENCY_INTERNAL_NOTES: "internal-notes",
  AGENCY_AUDIT_LOG: "audit-log",

  BRANDS: "/brands",
  BRAND_PROFILE: "profile",
  BRAND_CONTACTS: "contacts",
  BRAND_AGENCY_CONTACTS: "agency-contacts",
  BRAND_INDUSTRIES: "industries",
  BRAND_AGENCIES: "agencies",
  BRAND_SPONSORSHIPS: "sponsorships",
  BRAND_HIERARCHY: "hierarchy",
  BRAND_NOTES: "notes",
  BRAND_TAGS: "tags",
  BRAND_INTERNAL_NOTES: "internal-notes",
  BRAND_AUDIT_LOG: "audit-log",

  CONTACTS: "/contacts",
  CONTACT_PROFILE: "profile",
  CONTACT_PROFILE_TITLE_FUNCTIONS: "title-functions",
  CONTACT_PROFILE_BRANDS: "brands",
  CONTACT_PROFILE_SOCIAL_MEDIA: "social-media",
  CONTACT_PROFILE_WORK_HISTORY: "work-history",
  CONTACT_PROFILE_NOTES: "notes",
  CONTACT_PROFILE_SPECIALITY_LISTS: "speciality-lists",
  // Not in profile tabs
  CONTACT_PROFILE_TAGS: "tags",
  CONTACT_PROFILE_INTERNAL_NOTES: "internal-notes",
  CONTACT_PROFILE_AUDIT_LOG: "audit-log",
};
