<template>
  <div class="agency-profile-wrap" v-if="isLoaded">
    <div class="columns is-desktop">
      <div class="column">
        <winbox-card>
          <template v-slot:header> General </template>
          <template v-slot:headerIcon>
            <button
              class="button save-button"
              @click="save(true)"
              v-if="isEditMode"
              :disabled="!isChanged"
            >
              Save
            </button>
            <button
              class="button cancel-button"
              @click="cancel(true)"
              v-if="isEditMode"
            >
              Cancel
            </button>

            <button
              class="button edit-button"
              @click="edit(true)"
              v-if="!isEditMode"
            >
              Edit
            </button>
          </template>
          <div class="content">
            <winbox-input
              label="Agency Name"
              v-model.trim="$v.entity.name.$model"
              :class="status($v.entity.name)"
              @input="addToDataUpdate('name', entity.name)"
              :disabled="!isEditMode"
              field="name"
            />
            <div class="columns">
              <div class="column is-12">
                <winbox-select
                  form-label="Agency Holding Company"
                  v-model="entity.holdingCompany"
                  @input="addToDataUpdate('holdingCompany', entity && entity.holdingCompany)"
                  :options="possibleHoldingCompanies"
                  :disabled="!isEditMode"
                  :clearable="isEditMode"
                />
              </div>
            </div>
            <div class="columns general-columns">
              <div class="column is-3">
                <winbox-select
                  v-model="entity.org_type"
                  :class="status($v.entity.org_type)"
                  :options="entityTypes"
                  form-label="Entity Type"
                  @input="addToDataUpdate('org_type', entity && entity.org_type && entity.org_type.value)"
                  :disabled="!isEditMode"
                  :clearable="isEditMode"
                />
              </div>
              <div class="column is-2">
                <winbox-select
                  form-label="Employee Number"
                  v-model="entity.employeeRange"
                  :options="possibleEmployeeRanges"
                  :disabled="!isEditMode"
                  :clearable="isEditMode"
                  @input="addToDataUpdate('employee_range_id', entity && entity.employeeRange && entity.employeeRange.value)"
                />
              </div>
              <div class="column is-2">
                <winbox-input
                  label="Founded"
                  maxlength="4"
                  v-model.trim="$v.entity.year_founded.$model"
                  :class="status($v.entity.year_founded)"
                  @input="addToDataUpdate('year_founded', entity.year_founded)"
                  :disabled="!isEditMode"
                />
              </div>
              <div class="column is-3">
                <winbox-select
                  v-model="entity.fiscal_close"
                  :options="fiscalCloses"
                  form-label="Fiscal Close"
                  @input="addToDataUpdate('fiscal_close', entity.fiscal_close)"
                  :disabled="!isEditMode"
                  :clearable="isEditMode"
                />
              </div>
            <div class="column is-2">
                <winbox-input
                  label="Stock Symbol"
                  v-model="entity.stock_symbol"
                  @input="addToDataUpdate('stock_symbol', entity.stock_symbol)"
                  :disabled="!isEditMode"
                />
              </div>
            </div>
            <winbox-input
              label="Short Description"
              v-model.trim="$v.entity.short_description.$model"
              :class="status($v.entity.short_description)"
              @input="
                addToDataUpdate('short_description', entity.short_description)
              "
              :disabled="!isEditMode"
            />
            <winbox-input
              v-if="isEditMode"
              label="Web Address"
              icon-left="globe-americas"
              v-model.trim="$v.entity.website.$model"
              :class="status($v.entity.website)"
              @input="addToDataUpdate('website', entity.website)"
              field="website"
            />
            <fieldset
                v-else
                class="border"
              >
                <legend class="float-none w-auto">Web Address</legend>
                <div class="website-container">
                  <a
                    class="website-link"
                    :href="`http://${entity.website}`" target="_blank"
                  >
                  {{ decodeURIComponent(entity.website) }}
                  </a>
                </div>
              </fieldset>
            <winbox-textarea
              v-model.trim="$v.entity.long_description.$model"
              :class="status($v.entity.long_description)"
              form-label="Long Description"
              @input="addToDataUpdate('long_description', entity.long_description)"
              :disabled="!isEditMode"
            />
            <winbox-input
              label="Nickname"
              v-model.trim="$v.entity.nickname.$model"
              :class="status($v.entity.nickname)"
              @input="addToDataUpdate('nickname', entity.nickname)"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <winbox-card :expandable="true">
          <template v-slot:header> Disciplines </template>
          <div class="content">
            <winbox-disciplines
              @changed="onTagsChanged($event, 'name')"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column">
        <winbox-card>
          <template v-slot:header> Social Media </template>
          <template v-slot:headerIcon>
            <winbox-icon
              icon="plus-circle"
              :color="Colors.system.primaryLight"
              @click.native="$refs.winboxSocialMediaRef.addNewSocialEntity"
              v-if="isEditMode"
            />
          </template>
          <div class="content">
            <winbox-social-media
              ref="winboxSocialMediaRef"
              :items="socialEntities"
              @changed="onSocialChanged"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <winbox-card :expandable="true">
          <template v-slot:header> Speciality Lists </template>
          <div class="content">
            <winbox-speciality-tags-list
              :possible-tags="possibleTags"
              :entity-tags="selectedTags"
              org-type="agency"
              @changed="onTagsChanged($event, 'name')"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { BusinessEntityProfileMixin } from "@/components/Pages/Generic/BusinessEntityProfileMixin";
import { NicknamesAndSocialsMixin } from "@/components/Pages/Generic/NicknamesAndSocialsMixin";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

import * as Utils from "@/utils";

export default {
  name: "AgencyProfile",
  mixins: [
    BusinessEntityProfileMixin,
    NicknamesAndSocialsMixin,
    WinboxSpecialityTagsHelperMixin,
  ],
  created() {
    this.getData();
  },
  computed: {
    ...mapState([
      'holdingCompanies',
      'currentAgency',
      'businessSocials',
      'businessNicknames',
      'isInvalid',
    ]),
    id() {
      return +this.$route.params.id;
    },
    possibleHoldingCompanies() {
      return this.holdingCompanies.map((holdingCompany) => {
        return {
          label: holdingCompany.name,
          value: holdingCompany.id,
        }
      });
    },
  },
  methods: {
    ...mapActions([
      'updateAgency',
      'getHoldingCompanies',
      'getEmployeeRanges',
      'getSocials',
      'getDisciplines',
      'getSpecialities',
      'getBusinessSpecialities',
      'getBusinessSocials',
      'getBusinessDisciplines',
      'getBusinessNicknames',
      'edit',
      'reset',
      'save',
    ]),
    async getData() {
      this.isLoaded = false;
      this.entity = this.currentAgency;

      this.loadBusinessSocials();
      this.getDisciplines();
      this.getSpecialities("agency");
      this.getBusinessSpecialities(this.id);
      this.updateBreadcrumbsTitles(this.entity, "Agency");
      this.getBusinessDisciplines(this.id);
      this.loadAgencyHoldingCompany();
      this.loadOrgType();
      await Promise.all([
        this.loadEmployeeRangeData(),
        this.loadBusinessNicknames(),
      ]);
      this.isLoaded = true;
    },
    async loadAgencyHoldingCompany() {
      await this.getHoldingCompanies();
      if (this.entity?.holding_company?.id) {
        this.entity.holdingCompany = this.possibleHoldingCompanies.find(
          item => item.value === this.entity.holding_company.id
        );
      };
    },
    async loadEmployeeRangeData() {
      await this.getEmployeeRanges('Agency');
      if (this.entity?.employee_range_id) {
        this.entity.employeeRange = this.possibleEmployeeRanges.find(
          item => item.value === this.entity.employee_range_id
        );
      };
    },
    async loadBusinessSocials() {
      this.getSocials();
      await this.getBusinessSocials(this.id);
      return this.socialEntities = this.businessSocials;
    },
    async loadBusinessNicknames() {
      await this.getBusinessNicknames(this.id);
      return this.nicknames = this.businessNicknames;
    },
    loadOrgType() {
      if (this.entity?.org_type?.value) {
        this.entity.org_type = this.entity?.org_type?.value;
      };
      if (this.entity?.org_type) {
        this.entity.org_type = this.entityTypes.find(
          item => item.value === this.entity.org_type
        );
      };
    },
    saveEntity() {
      if (this.$v.entity.$error || this.$v.entity.$invalid || this.isInvalid) {
        this.save(false);
        return;
      }

      if (Object.keys(this.dataUpdate).length > 0) {
        this.dataUpdate['holding_company_id'] = this.dataUpdate.holdingCompany ? this.dataUpdate.holdingCompany?.value : null;
        delete this.dataUpdate.holdingCompany;
        const payload = { ...this.dataUpdate };
        this.updateAgency({ id: this.id, payload });
      }

      setTimeout(() => {
        this.edit(false);
      });

      this.afterSaveEntity();
    },
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.agency-profile-wrap {
  ::v-deep {
    .card-content {
      @include profile-card-content-base-styles;
    }
  }
  .save-button,
  .edit-button {
    background: green;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;
    margin-right: 16px;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .cancel-button {
    background: red;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .website-container {
    background-color: whitesmoke;
    border-radius: 4px;
    height: 40px;
    position: relative;
    top: -7px;
  }
  .website-link {
    position: relative;
    left: 8.5px;
    top: 9px;
  }
  fieldset {
    position: relative;
    top: -8.5px;
    min-width: 0;
    margin: 0;
    border: 0;
    height: 48px;
  }
  .w-auto {
    width: auto!important;
  }
  .float-none {
    float: none!important;
  }
  .pb-0 {
    padding-bottom: 0;
  }
  legend {
    background-color: white;
    color: $winbox-body-grey-bg;
    font-size: 12px;
    height: 15px;
    line-height: inherit;
    padding: 0 2px;
    position: relative;
    left: 7px;
    top: 2px;
    z-index: 2;
  }
}
</style>
