var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap-disciplines-block" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c("div", { staticClass: "disciplines" }, [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-disciplines-list", {
                    attrs: {
                      "possible-tags": _vm.possibleDisciplineTags,
                      "entity-tags": _vm.selectedDisciplineTags,
                      "placeholder-possible": _vm.placeholderPossible,
                      "placeholder-selected": _vm.placeholderSelected,
                      "prop-selected-parent-id": "discipline_id",
                      disabled: _vm.disabled
                    },
                    on: {
                      changed: function($event) {
                        return _vm.onTagsChanged($event, "name")
                      }
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }