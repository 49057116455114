import businessGetters from "./business";
import brandGetters from "./brand";
import personGetters from "./person";
import helperGetters from "./helper";
import userGetters from "./user";
import filterGetters from "./filter_options";
import reportGetters from "./report"

const getters = {
  ...businessGetters,
  ...brandGetters,
  ...personGetters,
  ...helperGetters,
  ...userGetters,
  ...filterGetters,
  ...reportGetters
};

export default getters;
