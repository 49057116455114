var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap-user-avatar" }, [
    _c(
      "div",
      { staticClass: "no-user-avatar" },
      [_c("winbox-icon", { attrs: { icon: "user-tie" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }