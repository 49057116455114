<template>
  <div class="wrap-speciality-widget">
    <div class="columns is-desktop">
      <div class="column">
        <winbox-card v-click-outside="hideSearchModal">
          <template v-if="addSearchSupport" v-slot:headerLeftIcon>
            <winbox-icon
              :class="{ active: filterValue || isSearchVisible }"
              class="search-main-icon"
              icon="search"
              color="rgba(255, 255, 255, 0.75)"
              @click.native="showSearchModal"
            />
          </template>
          <template v-slot:header>
            {{ placeholderPossible }}
          </template>
          <template v-slot:headerIcon>
            <winbox-icon v-if="!disabled"
              icon="plus-circle"
              :color="Colors.system.white"
              @click.native="addNewTag"
            />
          </template>
          <div class="left-search-wrap" :class="{ visible: isSearchVisible }">
            <div class="left-search-box">
              <winbox-input
                type="search"
                placeholder="Search..."
                v-model="filterValue"
              />
            </div>
          </div>
          <div class="content">
            <p class="no-data" v-if="!unselectedPossibleTags.length">No items</p>
            <ul v-else class="tags-list">
              <template v-for="(item) in unselectedPossibleTags">
                <li :key="item.id">
                  <div class="columns is-gapless is-vcentered">
                    <div class="column">
                      <div v-if="item[propId]">
                        {{ item[propDesc] }}
                      </div>
                      <div class="new-tag-input-wrap" v-if="!item[propId]">
                        <winbox-input v-model="item[propDesc]" />
                      </div>
                    </div>
                    <div class="column is-narrow action-right">
                      <button
                        v-if="item[propId]"
                        :disabled="item.isSelected || disabled"
                        class="button"
                        @click="addTagToSelected(item)"
                      >
                        <winbox-icon
                          icon="arrow-right"
                          :color="Colors.system.primaryLight"
                        />
                      </button>
                      <button
                        v-if="!item[propId]"
                        class="button"
                        @click="createNewTag(item)"
                      >
                        <winbox-icon
                          icon="check"
                          :color="Colors.system.primaryLight"
                        />
                      </button>
                      <button
                        v-if="!item[propId]"
                        class="button"
                        @click="removeNewlyTag(item)"
                      >
                        <winbox-icon icon="trash" :color="Colors.system.error" />
                      </button>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </winbox-card>
      </div>
      <div class="column">
        <winbox-card>
          <template v-slot:header>
            {{ placeholderSelected }}
          </template>
          <div class="content">
            <p class="no-data" v-if="!selected.length || !possibleTags.length">No items</p>
            <ul v-else class="tags-list">
              <li v-for="(item, key) in selected" :key="key">
                <div class="columns is-gapless is-vcentered">
                  <div class="column is-7 is-10">
                    {{ item[propDesc] }}
                  </div>
                  <div class="column is-2 action-right">
                    <button
                      class="button button-remove"
                      @click="removeTag(item)"
                      v-if="!disabled"
                    >
                      <winbox-icon icon="trash" :color="Colors.system.error" />
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </winbox-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxInput from "@/components/Atoms/WinboxInput";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import lodashGet from "lodash/get";
import { clickOutside } from "@/directives/clickOutside";

export default {
  name: "WinboxDisciplinesList",
  components: {
    WinboxIcon,
    WinboxCard,
    WinboxInput,
  },
  directives: { clickOutside },
  props: {
    orgType: {
      type: String,
      required: false,
      default: "",
    },
    possibleTags: {
      type: Array,
      required: true,
    },
    entityTags: {
      type: Array,
      required: true,
    },
    addSearchSupport: {
      required: false,
      default: true,
    },
    placeholderPossible: {
      required: false,
      default: "Select one or more options",
    },
    placeholderSelected: {
      required: false,
      default: "Selected Lists",
    },
    propId: {
      required: false,
      default: "id",
    },
    propDesc: {
      required: false,
      default: "name",
    },
    propSelectedParentId: {
      required: false,
      default: "speciality_list_id",
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      Colors: Colors,
      allTags: [...this.possibleTags],
      selected: Utils.sortAlphabetic([...this.entityTags], this.propDesc),
      isSearchVisible: false,
      filterValue: null,
    };
  },
  watch: {
    entityTags() {
      this.selected = Utils.sortAlphabetic([...this.entityTags], this.propDesc);
    },
    filterValue() {
      let items = [...this.possibleTags];
      if (this.filterValue) {
        items = this.filterByProp(items, this.filterValue, this.propDesc);
      }

      this.allTags = items;
    },
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
    unselectedPossibleTags() {
      let results = this.possibleTags.map((possibleTag) => {
        return {
          ...possibleTag,
          isSelected: this.selected.some((item) => item[this.propSelectedParentId] === possibleTag.id),
        }
      });

      return results;
    },
  },
  methods: {
    ...mapActions([
      'change',
    ]),
    removeNewlyTag(item) {
      this.allTags.splice(this.allTags.indexOf(item), 1);
    },
    addNewTag() {
      this.allTags.unshift({
        [this.propId]: null,
        [this.propDesc]: "",
      });
    },
    createNewTag(item) {
      item["org_type"] = this.orgType;

      const validName = item[this.propDesc]
        ? !!item[this.propDesc].trim()
        : false;
      if (validName) {
        this.emitState("newTags", [item]);
      }
    },
    addTagToSelected(item) {
      this.selected = [...this.selected, {...item, [this.propSelectedParentId]: item.id}];
      this.change(true);
    },
    removeTag(item) {
      let shouldBeConfirmed = item.id ? confirm("Are you sure?") : true;
      if (shouldBeConfirmed) {
        this.selected = this.selected.filter((selected) => selected.id != item.id)
        this.change(true);
      }
    },
    emitState(key, data) {
      this.$emit("changed", {
        [key]: [...data],
      });
    },
    showSearchModal() {
      this.isSearchVisible = !this.isSearchVisible;
    },
    hideSearchModal() {
      this.isSearchVisible = false;
    },
    filterByProp(data, searchVal, propName) {
      return Utils.filterByProp(data, searchVal, propName);
    },
    editCancel() {
      this.selected = Utils.sortAlphabetic([...this.entityTags], this.propDesc);
    },
    editAccept() {
      this.emitState("selectedTags", this.selected);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.wrap-speciality-widget {
  ::v-deep {
    .winbox-card {
      .card-header {
        background: $winbox-primary-active-bg-color;
      }
      .card-header-title {
        justify-content: center;
      }
      .card-content {
        height: 240px;
        overflow: auto;
        padding: 0.5rem 0;

        .content {
          transition: padding-top 0.5s;
        }

        .left-search-wrap {
          &.visible {
            max-height: 50px;
            transition: max-height 0.5s ease-in;

            + .content {
              padding-top: 3rem;
            }
          }
        }
      }
    }
    .vs__dropdown-menu {
      min-width: auto;
    }
    .vs__dropdown-toggle {
      border: transparent;
    }
  }

  .child-industry {
    margin-left: 50px;
  }
}
.new-tag-input-wrap {
  padding-right: 4px;
}
.tags-list {
  li {
    border-bottom: 1px solid #dbdbdb;
    padding: 0 1rem 5px;
    margin: 0 0 5px;
    @include no-user-select;
    @include button-delete-universal-hover;

    &:last-child {
      border-bottom: 0;
    }
  }
}
.action-right {
  text-align: right;
}
.button-remove {
  background: $winbox-icon-negative-background;
  border: transparent;
  border-radius: 50%;
  font-size: 14px;

  @include button-delete-universal;
}
.left-search-wrap {
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  background: #fff;
  color: $winbox-primary-text-color;
  margin-top: -0.5rem;
  z-index: 1;
  position: absolute;
  box-shadow: 0 8px 10px -3px $winbox-box-shadow-color;
  width: 100%;
  font-size: 13px;

  .left-search-box {
    padding: 8px 15px;
  }
}
.search-main-icon {
  ::v-deep {
    .winbox-icon {
      transition: color 0.3s;
    }
  }

  &.active {
    ::v-deep {
      .winbox-icon {
        color: #fff !important;
      }
    }
  }
}
.no-data {
  padding: 0.5rem 1rem;
}
</style>
