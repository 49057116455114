var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "admin-tasks" },
    [
      _c(
        "winbox-card",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v("\n      Reassign Tasks\n    ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-input", {
                    attrs: {
                      label: "Number of Tasks",
                      type: "number",
                      min: 1,
                      required: ""
                    },
                    model: {
                      value: _vm.numberOfTasks,
                      callback: function($$v) {
                        _vm.numberOfTasks = _vm._n($$v)
                      },
                      expression: "numberOfTasks"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-select", {
                    attrs: {
                      "form-label": "From",
                      options: _vm.userOptions,
                      required: ""
                    },
                    model: {
                      value: _vm.fromUser,
                      callback: function($$v) {
                        _vm.fromUser = $$v
                      },
                      expression: "fromUser"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-select", {
                    attrs: {
                      "form-label": "To",
                      options: _vm.userOptions,
                      required: ""
                    },
                    model: {
                      value: _vm.toUser,
                      callback: function($$v) {
                        _vm.toUser = $$v
                      },
                      expression: "toUser"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    attrs: { disabled: !_vm.canSubmit },
                    on: { click: _vm.reassignTasks }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.submitButtonText) +
                        "\n          "
                    )
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.successMessage
            ? _c("div", { staticClass: "response-box success" }, [
                _vm._v("\n    " + _vm._s(_vm.successMessage) + "\n  ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.infoMessage
            ? _c("div", { staticClass: "response-box error" }, [
                _vm._v("\n    " + _vm._s(_vm.infoMessage) + "\n  ")
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }