<template>
  <div class="create-location">
    <div class="content">
      <winbox-input
        placeholder="Name"
        v-model.trim="$v.entity.name.$model"
        :class="status($v.entity.name)"
        field="locationName"
      />
      <winbox-select
        placeholder="Type"
        v-model.trim="entity.location_type"
        :options="possibleTypes"
        :class="status($v.entity.location_type)"
        :clearable="true"
      />
      <winbox-input
        placeholder="Address 1"
        v-model.trim="$v.entity.address_1.$model"
        :class="status($v.entity.address_1)"
      />
      <winbox-input
        placeholder="Address 2"
        v-model.trim="entity.address_2"
      />
      <div class="columns">
        <div class="column is-7">
          <winbox-input
            placeholder="City"
            v-model.trim="$v.entity.city.$model"
            :class="status($v.entity.city)"
          />
        </div>
        <div class="column is-3">
          <winbox-select
            :placeholder="statePlaceholder"
            v-model.trim="entity.state"
            :options="states"
            :class="status($v.entity.state)"
          />
        </div>
        <div class="column is-2">
          <winbox-input
            placeholder="Zip"
            v-model.trim="$v.entity.zip.$model"
            :class="status($v.entity.zip)"
          />
        </div>
      </div>
      <div class="columns" v-if="isUk">
        <div class="column is-6">
          <winbox-select
            placeholder="TV Region"
            :options="possibleTvRegions"
            v-model.trim="entity.tv_region"
            :clearable="true"
            :class="status($v.entity.tv_region)"
          />
        </div>
        <div class="column is-6">
          <winbox-input
            placeholder="Country"
            v-model.trim="entity.country"
            :class="status($v.entity.country)"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <winbox-input
            v-if="!isUk"
            placeholder="Main Phone"
            v-model.trim="entity.phone"
            icon-left="phone-alt"
            mask="(###)-###-####"
          />
          <winbox-input
            v-else
            placeholder="Main Phone"
            v-model.trim="entity.phone"
            icon-left="phone-alt"
            :class="status($v.entity.phone)"
          />
        </div>
        <div class="column is-6">
          <winbox-input
            v-if="!isUk"
            placeholder="Toll Free"
            v-model.trim="entity.toll_free"
            icon-left="phone-alt"
            mask="(###)-###-####"
          />
          <winbox-input
            v-else
            placeholder="Toll Free"
            v-model.trim="entity.toll_free"
            icon-left="phone-alt"
            :class="status($v.entity.toll_free)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { Colors } from "@/Colors";
import { LocationTypes, UkLocationTypes } from "@/constants/LocationTypes";
import cloneDeep from "clone-deep";
import { required, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import * as Utils from "@/utils";

import { SaveAnyEntitySupportMixin } from "@/components/Pages/Generic/SaveAnyEntitySupportMixin";

const zipLengthValidation = (value, vm) => {
  const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
  const isUk = isUKMetaTag && isUKMetaTag.getAttribute('content') === 'true';
  if (!isUk) {
    return (vm.country === 'USA' && value.length === 5) ||
           (vm.country === 'Canada' && value.length === 7) ||
           (vm.country === undefined && (value.length === 5 || value.length === 7));
  } else {
    return true;
  }
};
const phoneLengthValidation = (value, vm) => {
  const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
  const isUk = isUKMetaTag && isUKMetaTag.getAttribute('content') === 'true';
  if (isUk && value != null) {
    return value.length <= 20;
  } else {
    return true;
  }
};

export default {
  name: "WinboxCreateLocation",
  mixins: [SaveAnyEntitySupportMixin],
  components: {
    WinboxInput,
    WinboxSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    updateEntity: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      Colors: Colors,
      entity: {},
      submitStatus: false,
    };
  },
  validations: {
    entity: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      location_type: {
        required
      },
      address_1: {
        required
      },
      city: {
        required
      },
      state: {
        required
      },
      tv_region: {
        required: requiredIf(function () {
          return this.isUk;
        }),
      },
      country: {
        required: requiredIf(function () {
          return this.isUk;
        }),
      },
      zip: {
        required,
        zipLengthValidation,
      },
      phone: {
        phoneLengthValidation,
      },
      toll_free: {
        phoneLengthValidation,
      },
    }
  },
  computed: {
    ...mapState([
      'tvRegions',
    ]),
    isUk() {
      const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
      return isUKMetaTag && isUKMetaTag.getAttribute('content') == 'true';
    },
    statePlaceholder() {
      return this.isUk ? 'Region' : 'State';
    },
    possibleTypes() {
      if (this.isUk) {
        return Object.entries(UkLocationTypes).map(item => {
          return {
            value: item[0],
            label: item[1]
          };
        });
      } else {
          return Object.entries(LocationTypes).map(item => {
            return {
              value: item[0],
              label: item[1]
            };
        });
      };
    },
    states() {
      return this.$store.getters.STATES.map(item => item.state_abbr);
    },
    possibleTvRegions() {
      return this.tvRegions.map(item => {
        return {
          value: item.id,
          label: item.tv_region
        };
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("getStates");
    this.getData();
    this.entity = cloneDeep(this.data);
    if (this.entity.location_type) {
      this.entity.location_type = this.possibleTypes.find(
        item => item.value === this.entity.location_type
      );
    }
  },
  methods: {
    ...mapActions([
      'getTvRegions',
    ]),
    getData() {
      this.isLoaded = false;

      this.getTvRegions();
    },
    status(validation) {
      if(validation.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    },
    beforeSaveEntity() {
      clearTimeout(this.saveTimeout);

      this.saveTimeout = setTimeout(() => {
        if (this.$v) {
          this.$v.$touch();

          if (this.$v.$anyError) {
            return;
          }
        }

        if (typeof this.saveEntity === "function") {
          this.saveEntity();
        }
      }, this.saveAfterTime);
    },
    validateForm() {
      return this.updateEntity || (!this.updateEntity && this.submitStatus)
    },
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-location {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
</style>
