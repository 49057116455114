import { MutationTypes } from "@/constants/MutationTypes";

export default {
  /* Contacts */
  [MutationTypes.SET_PROFILE_CONTACTS]: (state, payload) => {
    state.profileContacts = payload;
  },

  [MutationTypes.ADD_UPDATE_PROFILE_CONTACT]: (state, payload) => {
    state.currentContact = payload;
  },

  [MutationTypes.SET_PERSON_SOCIALS]: (state, payload) => {
    state.personSocials = payload;
  },

  [MutationTypes.SET_PERSON_TAGS]: (state, payload) => {
    state.personTags = {
      ...state.personTags,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_PERSON_NOTES]: (state, payload) => {
    state.personNotes = payload;
  },

  [MutationTypes.SET_PERSON_SPECIALITIES]: (state, payload) => {
    state.personSpecialities = payload;
  },

  [MutationTypes.SET_PERSON_LOGS]: (state, payload) => {
    state.personLogs = {
      ...state.personLogs,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_PERSON_TITLE_FUNCTIONS]: (state, payload) => {
    state.personTitleFunctions = payload;
  },

  [MutationTypes.SET_PERSON_WORK_HISTORIES]: (state, payload) => {
    state.personWorkHistories = payload;
  },

  [MutationTypes.SET_PERSON_BRANDS]: (state, payload) => {
    state.personBrands = payload;
  },

  [MutationTypes.SET_PERSON_ORGANIZATION]: (state, payload) => {
    state.personOrganization = payload;
  },

  [MutationTypes.SET_PERSON_LOCATION]: (state, payload) => {
    state.personLocation = {
      ...state.personLocation,
      [payload.id]: payload.data
    };
  }
};
