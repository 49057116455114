var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap-demographics-block" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "demographics" },
            [
              _c(
                "div",
                { staticClass: "dropdown-row" },
                _vm._l(_vm.entities, function(entity, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "dropdown-item" },
                    [
                      entity.label !== "Profile Description"
                        ? _c("winbox-select", {
                            attrs: {
                              "form-label": entity.label,
                              options: entity.options,
                              label: "label",
                              disabled: !_vm.isEditMode,
                              multiple: true
                            },
                            on: {
                              input: function($event) {
                                return _vm.onDemographicChanged(entity)
                              }
                            },
                            model: {
                              value: entity.value,
                              callback: function($$v) {
                                _vm.$set(
                                  entity,
                                  "value",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "entity.value"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("winbox-textarea", {
                attrs: {
                  "form-label": "Profile Description",
                  disabled: !_vm.isEditMode
                },
                on: {
                  input: function($event) {
                    return _vm.onDemographicChanged(
                      _vm.entities.profile_description
                    )
                  }
                },
                model: {
                  value: _vm.entities.profile_description.value,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.entities.profile_description,
                      "value",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "entities.profile_description.value"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }