<template>
  <div class="contact-brands">
    <winbox-card>
      <div class="columns">
        <div class="column">
          <winbox-data-table
            class="main-data-table"
            :data="dataset"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
          >
            <tr class="visible-thead-row" slot="prependHead">
              <th>Brand</th>
              <th>Company</th>
              <th>
                <div class="add-new-item">
                  <button class="button create-button" @click="openModal" :disabled="isOrganizationEmpty">
                    <winbox-icon
                      icon="plus-circle"
                      :color="Colors.system.white"
                      v-if="isEditMode"
                    />
                  </button>
                </div>
              </th>
            </tr>
            <template :slot="tableProps.brand" slot-scope="data">
              <router-link
                :data-tooltip="data.row.id"
                :to="{ path: constructBrandLink(data.row.id) }"
              >
                {{ data.row[tableProps.brand] }}
              </router-link>
            </template>
            <template
              v-if="data.row[tableProps.company]"
              :slot="tableProps.company"
              slot-scope="data"
            >
              <router-link
                :data-tooltip="data.row[tableProps.company].id"
                :to="{
                  path: constructCompanyLink(data.row[tableProps.company].id),
                }"
              >
                {{ data.row[tableProps.company].name }}
              </router-link>
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <button
                class="button remove-button"
                v-if="isEditMode"
              >
                <winbox-icon
                  icon="trash"
                  :color="Colors.system.error"
                  @click.native="removeEntity(data.row.id)"
                />
              </button>
            </template>
          </winbox-data-table>
        </div>
      </div>
    </winbox-card>
    <winbox-modal
      :ref="linkBrandsModal"
      :name="linkBrandsModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      width="800px"
      :height="getBrands().length >= 10 ? '670px' : '400px'"
      title="Link Brands"
      save-text="Add Selected"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <winbox-link-entities-remote
        ref="linkEntities"
        :url="getBrands()"
        :responseFunc="enhanceData"
        :config="linkEntitiesConfig"
      />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxLinkEntitiesRemote from "@/components/Molecules/WinboxLinkEntitiesRemote";

import { Colors } from "@/Colors";
import * as BrandsApi from "@/api/brands/brands";
import * as PeopleApi from "@/api/people/people";
import * as Utils from "@/utils";

const tableProps = {
  brand: "name",
  company: "business",
  status: "companybrand_status",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "ContactBrands",
  components: {
    WinboxCard,
    WinboxIcon,
    WinboxModal,
    WinboxDataTable,
    WinboxLinkEntitiesRemote,
  },
  data() {
    return {
      Colors: Colors,
      linkedProp: "_linked",
      dataset: [],
      // organization: '',
      organizationEdited: false,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.brand]: "Brand",
          [tableProps.company]: "Company",
          [tableProps.status]: "",
        },
        sortable: [],
        filterable: [...mainTableColumns],
      },
      specificSlots: [tableProps.brand, tableProps.company, tableProps.status],
      linkBrandsModal: "linkBrandsModal",
      linkEntitiesConfig: {
        tableProps: {
          name: "name",
          linked: "_linked",
        },
        tableOptions: {
          perPage: 10,
          headings: {
            name: "Brand",
            _linked: "Select All",
          },
          sortable: [],
          filterable: ["name"],
        },
      },
    };
  },
  created() {
    this.$root.$on("changeContactOrganization", (data) => {
      this.organizationEdited = true;
    });

    this.$root.$on("changeContactOrganizationCompleted", (data) => {
      this.organizationEdited = false;
    });
  },
  async beforeMount() {
    if(this.organization?.type === 'Agency') {
      await this.getPersonAgencyBrands(this.id);
    }
    else if(this.organization?.type === 'Company') {
      await this.getPersonBrands(this.id);
    }
    this.getData();
  },
  watch: {
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'personOrganization',
      'brandAgencyContacts',
      'personBrands',
      'currentContact',
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
    id() {
      return +this.$route.params.id;
    },
    isOrganizationEmpty() {
      return Object.keys(this.organization).length === 0;
    },
    organization() {
      return this.personOrganization;
    },
    brands() {
      return this.personBrands;
    }
  },
  methods: {
    ...mapActions([
      'getPersonAgencyBrands',
      'getBrandAgenciesContacts',
      'getPersonBrands',
      'change',
    ]),
    getData() {
      if(this.organization?.type === 'Agency') {
        this.dataset = this.modifyData(this.personBrands.data);
      }
      else if(this.organization?.type === 'Company') {
        this.dataset = this.modifyData(this.personBrands);
      }
    },
    isBrandLinked(brand_id) {
      let person_brands = this.dataset;
      let person_brand = person_brands.find((item) => item.id === brand_id);
      return Boolean(person_brand);
    },
    modifyData(data) {
      if (data == undefined) {
        return [];
      }
      else {
        return data.map((item) => {
          return {
            [this.linkedProp]: this.isBrandLinked(item.id), // Custom UI prop (for now)
            ...item,
          };
        });
      }
    },
    async removeEntity(id) {
      let shouldBeConfirmed = confirm("Are you sure?");
      if (shouldBeConfirmed) {
        this.dataset = this.dataset.filter(brand => brand.id !== id);
        this.change(true);
      }
    },
    openModal() {
      this.$refs[this.linkBrandsModal].show();
    },
    onCancel() {
      this.$refs[this.linkBrandsModal].hide();
    },
    async onSave() {
      const oldLinkedItems = this.modifyData(this.dataset);
      let linkedItems = this.$refs.linkEntities.getDeepCloneDataset();
      const newLinkedItems = linkedItems
        .filter((item) => item[this.linkedProp]);

      this.dataset = oldLinkedItems.concat(newLinkedItems);
      this.change(true);

      this.onCancel();
    },
    constructBrandLink(brandId) {
      return Utils.getBrandProfileUrl(brandId);
    },
    constructCompanyLink(companyId) {
      return Utils.getCompanyProfileUrl(companyId);
    },
    getBrands() {
      if(this.organization?.type === 'Agency') {
        return Utils.getBrandsPageUrl() + `?isAgency=1&business_id=${this.organization.id}&ex_person_id=${this.id}`;
      }
      else if(this.organization?.type === 'Company') {
        return Utils.getBrandsPageUrl() + `?business_id=${this.organization.id}&ex_person_id=${this.id}`;
      }
    },
    enhanceData(data) {
      let comparison = (a, b) => a.id === b.id;

      data = data.filter(b => this.dataset.every(a => !comparison(a, b)));

      return data.map((item) => {
        return {
          ...item,
          _linked: this.isBrandLinked(item.id),
        };
      });
    },
    getContactBrandsUrl() {
      if(this.organization?.type === 'Agency') {
        return Utils.getPersonAgencyBrandsUrl(this.id);
      }
      else if(this.organization?.type === 'Company') {
        return Utils.getPersonBrandsUrl(this.id);
      }
    },
    enhanceBrandsData(data, count) {
      return data;
    },
    async updatePossibleBrands(id) {
      this.dataset = [];
      await this.getPersonBrands(id);
    },
    editCancel() {
      this.dataset = this.modifyData(this.personBrands.data);
    },
    async editAccept() {
      const ids = this.dataset.map((item) => item.id);
      if(this.organization.type === 'Agency') {

        const payload = {
          data: this.dataset.map((item) => {
            return {
              person_id: this.id,
              agency_id: this.organization.id,
              brand_id: item.id,
            };
          })
        };

        PeopleApi.removeAllPersonCompanyBrands(this.id);
        PeopleApi.removeAllPersonBusinessAgencyContacts(this.id);
        BrandsApi.bulkUpdateBrandAgencyContacts(this.id, payload);
      }
      else {
        const payload = {
          person: {
            brand_ids: ids,
          },
        };

        PeopleApi.removeAllPersonAgencyBrands(this.id);
        PeopleApi.removeAllPersonBusinessAgencyContacts(this.id);
        PeopleApi.updatePerson(this.id, payload);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-brands {
  ::v-deep {
    .card-content {
      padding: 1rem;
    }
    .create-button {
      @include button-create-table;
    }
    .main-data-table {
      font-size: 12px;
      thead {
        th {
          &:last-child {
            width: 60px;
          }
        }
        tr:not(.visible-thead-row) {
          display: none;
        }
      }
      tbody {
        td {
          &:last-child {
            text-align: center;
          }
        }
        tr {
          &:hover {
            .remove-button {
              opacity: 1 !important;
            }
          }
        }
      }
      .remove-button {
        @include icon-wrapper-base-style;
        @include button-delete-universal;
      }
    }
  }
}
</style>
