export const DemographicAllowedValues = {
  brand_focus:      ['B2B (Business to Business)', 'B2C (Business to Consumer)', 'DTC (Direct to Consumer)',
                     'HCP (Health Care Professional)', 'DTP (Direct to Patient)', 'B2G (Business to Government)',
                     'B2E (Business to Employee)'],
  gender:           ['Male', 'Female', 'All Genders'],
  region:           ['Northeast', 'Midwest', 'South', 'West', 'Southeast', 'Southwest', 'Northwest', 'N/A'],
  region_type:      ['City', 'Urban', 'Suburban', 'Rural', 'Town'],
  age:              ['0 - 12', '13 - 27', '28 - 43', '44 - 59', '60+'],
  generation:       ['Baby Boomers', 'Generation X', 'Millennials (Y)', 'Generation Z',
                     'Generation Alpha'],
  ethnicity:        ['White / Caucasian', 'Black / African American', 'Hispanic / Latino', 'Asian',
                     'Indigenous / Native', 'Middle Eastern / North African', 'Mixed Ethnicity / Multiracial'],
  household_income: ['$25,000 - $50,000', '$50,000 - $100,000', '$100,000 - $150,000', '$150,000 - $200,000', '$200,000+'],
  marital_status:   ['Single', 'Married / Partner'],
  life_stage:       ['Single', 'Young Couple (No Children)', 'Young Family (Children under 6)',
                     'Established Family (Children 6-17)', 'Empty Nesters (Children Grown)', 'Single Parent',
                     'Retired / Senior', 'Student', 'Career-Oriented Individuals (No Children)',
                     'Senior Citizen']
};
