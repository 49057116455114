<template>
  <nav class="breadcrumb is-small" v-if="items.length">
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'is-active': item.isCurrent }"
      >
        <a :href="breadcrumbLink(item)" @click.prevent="navigateTo(item)">
          <winbox-icon
            class="is-small"
            v-if="item.icon"
            :icon="item.icon"
            :color="
              item.isCurrent ? Colors.system.white : Colors.system.primaryLight
            "
          />
          <span>{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";

import { Colors } from "@/Colors";
import { MainIcons } from "@/constants/MainIcons";
import * as Utils from "@/utils";

export default {
  name: "WinboxBreadcrumbs",
  components: {
    WinboxIcon,
  },
  data() {
    return {
      Colors: Colors,
      items: [],
      profileTitles: {
        companies: "Company Profile",
        agencies: "Agency Profile",
        brands: "Brand Profile",
        contacts: "Contact Profile",
      },
      contactProfileSubitems: ["tags", "internal-notes", "audit-log"],
    };
  },
  watch: {
    $route: function () {
      this.items = this.getItems();
    },
  },
  created() {
    this.items = this.getItems();
    Utils.EventBus.$on("updateBreadcrumbs", () => {
      this.items = this.getItems();
    });
  },
  methods: {
    getItems() {
      let items = window.breadcrumbs.map((item) => {
        return {
          ...item,
          title: this.getItemTitle(item),
          icon: this.iconFor(item),
        };
      });

      if (items.length > 0) {
        items[items.length - 1].isCurrent = true;
      }

      return items;
    },
    getItemTitle(item) {
      return item.to.meta.breadcrumb;
    },
    navigateTo(item) {
      this.$router.push(this.breadcrumbLink(item));
    },
    iconFor(item) {
      if (/profile(\/title-functions)?$/.test(item.to.path)) {
        return MainIcons[item.to.path.split("/")[1]];
      }
    },
    breadcrumbLink(item) {
      return item.to.path + "?breadcrumb=true";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.breadcrumb {
  margin: 0 0 0.5rem;
}
</style>
