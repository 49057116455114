<template>
  <div class="error">Page not found.</div>
</template>
<script>
export default {
  name: "PageNotFound"
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.error {
  color: $winbox-error;
}
</style>
