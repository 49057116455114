var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-add-brand-sponsorship" }, [
    !_vm.showNextStep
      ? _c(
          "div",
          { staticClass: "step-one" },
          [
            _c("winbox-server-data-table", {
              ref: "winboxDataTable",
              staticClass: "winbox-add-relationships-table",
              attrs: {
                url: _vm.url,
                columns: _vm.columns,
                options: _vm.tableOptions,
                slots: _vm.specificSlots,
                perPage: 10,
                responseFunction: _vm.enhanceData,
                theme: "with-borders-theme"
              },
              on: { "row-click": _vm.onRowClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: _vm.tableProps.linked,
                    fn: function(data) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "button select-button",
                            class: { active: data.row[_vm.tableProps.linked] }
                          },
                          [
                            _c("winbox-icon", {
                              attrs: {
                                icon: "plus",
                                color: data.row[_vm.tableProps.linked]
                                  ? _vm.Colors.system.lightGray
                                  : _vm.Colors.system.primaryLight
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showNextStep && _vm.entity
      ? _c("div", { staticClass: "step-two" }, [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-4" },
              [
                _c("winbox-select", {
                  class: _vm.status(_vm.$v.entity.category),
                  attrs: {
                    "form-label": "Category",
                    options: _vm.possibleCategories,
                    label: "label"
                  },
                  model: {
                    value: _vm.entity.category,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "category", $$v)
                    },
                    expression: "entity.category"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-4" },
              [
                _c("winbox-select", {
                  class: _vm.status(_vm.$v.entity.affiliation),
                  attrs: {
                    "form-label": "Affiliation",
                    options: _vm.possibleAffiliations,
                    label: "label"
                  },
                  model: {
                    value: _vm.entity.affiliation,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "affiliation", $$v)
                    },
                    expression: "entity.affiliation"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-4" },
              [
                _c("winbox-select", {
                  class: _vm.status(_vm.$v.entity.level),
                  attrs: {
                    "form-label": "Level",
                    options: _vm.possibleLevels,
                    label: "label"
                  },
                  model: {
                    value: _vm.entity.level,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "level", $$v)
                    },
                    expression: "entity.level"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-4" },
              [
                _c("winbox-input", {
                  class: _vm.status(_vm.$v.entity.deal_amount),
                  attrs: { label: "Deal Amount", type: "number" },
                  model: {
                    value: _vm.entity.deal_amount,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "deal_amount", $$v)
                    },
                    expression: "entity.deal_amount"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-4" },
              [
                _c("winbox-input", {
                  class: _vm.status(_vm.$v.entity.season_start_year),
                  attrs: { label: "Season Start Year" },
                  model: {
                    value: _vm.entity.season_start_year,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "season_start_year", $$v)
                    },
                    expression: "entity.season_start_year"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-4" },
              [
                _c("winbox-input", {
                  class: _vm.status(_vm.$v.entity.season_end_year),
                  attrs: { label: "Season End Year" },
                  model: {
                    value: _vm.entity.season_end_year,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "season_end_year", $$v)
                    },
                    expression: "entity.season_end_year"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-6" },
              [
                _c("winbox-textarea", {
                  class: _vm.status(_vm.$v.entity.placement),
                  attrs: { "form-label": "Placement" },
                  model: {
                    value: _vm.entity.placement,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "placement", $$v)
                    },
                    expression: "entity.placement"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-6" },
              [
                _c("winbox-textarea", {
                  class: _vm.status(_vm.$v.entity.activation),
                  attrs: { "form-label": "Description & Activation Details" },
                  model: {
                    value: _vm.entity.activation,
                    callback: function($$v) {
                      _vm.$set(_vm.entity, "activation", $$v)
                    },
                    expression: "entity.activation"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              {
                staticClass: "column is-4",
                staticStyle: {
                  "margin-left": "auto",
                  display: "flex",
                  "justify-content": "flex-end"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { "min-width": "150px", "flex-shrink": "0" } },
                  [
                    _c("winbox-select", {
                      class: _vm.status(_vm.$v.entity.status),
                      attrs: {
                        "form-label": "Status",
                        options: _vm.possibleStatuses,
                        label: "label"
                      },
                      model: {
                        value: _vm.entity.status,
                        callback: function($$v) {
                          _vm.$set(_vm.entity, "status", $$v)
                        },
                        expression: "entity.status"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }