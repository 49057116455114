<template>
  <div ref="toggleMenu" :class="{ visible: isVisible }" class="toggle-menu">
    <button class="button action-button" @click="toggleVisibility">
      <winbox-icon
        icon="ellipsis-v"
        :color="isVisible ? Colors.system.primaryLight : mainIconColor"
      />
    </button>
    <div v-if="isVisible" class="toggle-menu-modal">
      <ul @click="isVisible = false">
        <li
          v-for="option in options"
          :key="option.id"
          :style="[option.style]"
          :title="option.title"
          :data-tooltip="option.dataToltipTitle"
          @click="option.action.call(null, data)"
        >
          <div class="menu-item-icon">
            <winbox-icon
              :icon="option.icon"
              :color="
                option.iconColor ? option.iconColor : Colors.system.primaryLight
              "
            />
          </div>
          <span :style="{ color: option.iconColor }" class="item-text">{{
            option.title
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import { Colors } from "@/Colors";

export default {
  name: "WinboxToggleMenu",
  components: { WinboxIcon },
  props: {
    options: {
      type: Array,
      required: true,
      note: "Array of menu items"
    },
    data: {
      required: false,
      default: null
    },
    mainIconColor: {
      required: false,
      default: Colors.draggableIcon
    }
  },
  data() {
    return {
      Colors: Colors,
      isVisible: false
    };
  },
  mounted() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    documentClick(e) {
      let el = this.$refs.toggleMenu;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.isVisible = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.toggle-menu {
  position: relative;

  .toggle-menu-modal {
    position: absolute;
    padding: 5px 8px;
    background: #fff;
    right: 0;
    border-radius: $winbox-border-radius;
    width: 220px;
    border: 1px solid $winbox-icon-bg;
    box-shadow: 0 2px 5px -2px $winbox-box-shadow-color;
    z-index: 20;

    ul {
      li {
        color: $winbox-link-color;
        cursor: pointer;
        margin: 6px 0;
        font-weight: 400;

        text-align: left;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        @include no-user-select;
      }
      .item-text {
        @include text-overflow-ellipsis;
      }
    }
  }
  .menu-item-icon {
    margin-right: 3px;
  }
}
</style>
