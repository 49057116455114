var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.outsidePopupClick,
          expression: "outsidePopupClick"
        }
      ],
      ref: _vm.type,
      staticClass: "winbox-popover",
      style: _vm.popoverStyle
    },
    [
      _c("div", { staticClass: "arrow location-arrow" }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "close", on: { click: _vm.closePopupFilter } },
        [_vm._v("×")]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { style: _vm.ulStyle },
        _vm._l(_vm.filterOptionItems, function(filterOptionItem) {
          return _c(
            "li",
            {
              key: filterOptionItem.name,
              staticClass: "item",
              on: {
                click: function($event) {
                  return _vm.toggleFilter(filterOptionItem.code)
                }
              }
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasSelectedFilters(filterOptionItem),
                      expression: "hasSelectedFilters(filterOptionItem)"
                    }
                  ]
                },
                [
                  _c("winbox-icon", {
                    attrs: {
                      icon: "check",
                      color: _vm.Colors.system.primaryLight
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "selectedFilter" }, [
                    _vm._v(_vm._s(filterOptionItem.name))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hasSelectedFilters(filterOptionItem),
                      expression: "!hasSelectedFilters(filterOptionItem)"
                    }
                  ],
                  staticClass: "filter"
                },
                [_vm._v(_vm._s(filterOptionItem.name))]
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-select-all", on: { click: _vm.selectAll } },
        [
          _c("i", { staticClass: "fa fa-plus-square" }),
          _vm._v("\n    SELECT ALL\n  ")
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-clear-filter", on: { click: _vm.removeAll } },
        [
          _c("i", { staticClass: "fa fa-minus-square" }),
          _vm._v("\n    REMOVE ALL\n  ")
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }