<template>
  <div class="contact-work-history">
    <div class="wrap-company-header">
      <div class="columns is-vcentered">
        <div class="column">
          <winbox-title :title="mainTitle" />
        </div>
        <div class="column is-narrow header-action-wrap" v-if="isEditMode">
          <button
            class="button"
            @click="openCreateModal"
          >
            <winbox-icon
              class="add-entity-icon"
              :color="Colors.system.lightGray"
              icon="plus"
            />
            <span>Create</span>
          </button>
        </div>
      </div>
    </div>
    <winbox-card>
      <winbox-data-table
        class="main-data-table"
        :data="dataset"
        :columns="columns"
        :options="tableOptions"
        :slots="specificSlots"
      >
        <tr class="visible-thead-row" slot="prependHead">
          <th>Title</th>
          <th>Company</th>
          <th>Start</th>
          <th>End</th>
          <th></th>
        </tr>
        <template :slot="tableProps.title" slot-scope="data">
         {{ data.row[tableProps.title] }}
        </template>
        <template :slot="tableProps.company" slot-scope="data">
         {{ data.row[tableProps.company].label }}
        </template>
        <template :slot="tableProps.start" slot-scope="data">
         {{ data.row[tableProps.start] }}
        </template>
        <template :slot="tableProps.end" slot-scope="data">
         {{ data.row[tableProps.end] }}
        </template>
        <template :slot="tableProps.options" slot-scope="data">
          <button v-if="isEditMode" class="button button-edit">
            <winbox-icon
                icon="pen"
                :color="Colors.system.primaryLight"
                @click.native="onEdit(data.row)"
            />
          </button>
          <button v-if="isEditMode" class="button button-delete">
            <winbox-icon
                icon="trash"
                :color="Colors.system.error"
                @click.native="removeEntity(data.row.id)"
            />
          </button>
        </template>
      </winbox-data-table>
    </winbox-card>
    <winbox-modal
        :ref="workHistoryModal"
        :name="workHistoryModal"
        :enable-footer-buttons="true"
        :click-to-close="false"
        :width="800"
        class="notes-modal"
        :title="modalTitle"
        save-text="Save"
        @on-cancel="onCancel"
        @on-save="onSave"
    >
      <winbox-input
        placeholder="Title"
        v-model.trim="$v.modalDataRow[tableProps.title].$model"
        :class="status($v.modalDataRow[tableProps.title])"
      />
      <winbox-remote-select
        v-model.trim="modalDataRow[tableProps.company]"
        :multiple="false"
        :taggable="true"
        :url="getOrganizationSearchUrl()"
        :responseFunction="responseOrganization"
        placeholder="Company"
        :class="status($v.modalDataRow[tableProps.company])"
      />
      <winbox-input
        :date-picker="true"
        :ref="`date-start-${modalDataRow.id}`"
        placeholder="Start"
        icon-left="calendar-alt"
        v-model.trim="$v.modalDataRow[tableProps.start].$model"
        @datepickerShow="
          setDisabledDate(
            `date-start-${modalDataRow.id}`,
            modalDataRow[tableProps.end],
            'right'
          )
        "
        :class="status($v.modalDataRow[tableProps.start])"
      />
      <winbox-input
        :date-picker="true"
        :ref="`date-end-${modalDataRow.id}`"
        placeholder="End"
        icon-left="calendar-alt"
        v-model.trim="$v.modalDataRow[tableProps.end].$model"
        @datepickerShow="
          setDisabledDate(
            `date-end-${modalDataRow.id}`,
            modalDataRow[tableProps.start],
            'left'
          )
        "
        :class="status($v.modalDataRow[tableProps.end])"
      />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxRemoteSelect from "@/components/Atoms/WinboxRemoteSelect";
import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxModal from "@/components/Molecules/WinboxModal";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { DatepickerHelperMixin } from "@/components/Pages/Generic/DatepickerHelperMixin";
import * as BusinessesApi from "@/api/businesses/businesses";

import { required } from "vuelidate/lib/validators";

const tableProps = {
  title: "title",
  company: "business",
  start: "from_date",
  end: "to_date",
  options: "__options",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "ContactWorkHistory",
  mixins: [DatepickerHelperMixin],
  components: {
    WinboxCard,
    WinboxTitle,
    WinboxModal,
    WinboxIcon,
    WinboxInput,
    WinboxRemoteSelect,
    WinboxDataTable,
  },
  data() {
    return {
      isLoaded: false,
      isWorkHistoryComponent: true, // Important: needed in ContactProfile refreshing
      Colors: Colors,
      dataset: [],
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.title]: "Title",
          [tableProps.company]: "Company",
          [tableProps.start]: "Start",
          [tableProps.end]: "End",
          [tableProps.options]: "",
        },
        sortable: [],
        filterable: [],
      },
      specificSlots: [
        tableProps.title,
        tableProps.company,
        tableProps.start,
        tableProps.end,
        tableProps.options,
      ],
      mainMenuOptions: this.prepareMainMenuOptions(),
      workHistoryDataCreate: [],
      workHistoryDataUpdate: [],
      timeout: null,
      workHistoryModal: "workHistoryModal",
      modalDataRow: {},
    };
  },
  validations: {
    modalDataRow: {
      title: {
        required
      },
      business: {
        required
      },
      from_date: {
        required
      },
      to_date: {
        required
      },
    }
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'personWorkHistories',
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Work History (${this.dataset.length})`;
    },
    workHistories() {
      return this.personWorkHistories
    },
    modalTitle() {
      return this.modalDataRow
        ? !this.modalDataRow.id
          ? "Create Work History"
          : "Edit Work History"
        : "";
    },
  },
  methods: {
    ...mapActions([
      'change',
    ]),
    getData() {
      this.isLoaded = false;
      this.dataset = this.modifyData(this.personWorkHistories);
      this.isLoaded = true;
    },
    modifyData(data) {
      let result = data.map((item) => {
        return this.modifyDataItem(item);
      });

      return Utils.sortNumericByPropComplex(result, "id", true);
    },
    modifyDataItem(item) {
      return {
        ...item,
        business: {
          id: item.business_id,
          value: item.business_id,
          label: item.business_name,
        },
      };
    },
    status(validation) {
      if(validation?.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    },
    onChangeRowData(row, key, e) {
      if (!this.validateDateFields(tableProps, row, key, e)) {
        return;
      }

      this.dataset.forEach((item) => {
        if (item.id === row.id) {
          item[key] = e;
        }
      });

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.tryToSaveWorkHistoryItems(this.dataset);
      }, 500);
    },
    tryToSaveWorkHistoryItems(items) {
      this.workHistoryDataCreate = [];
      this.workHistoryDataUpdate = [];

      items.forEach((item, index) => {
        if (!item.id && item.title && item.business) {
          this.workHistoryDataCreate.push(this.preparePayloadObject(item));
          items.splice(index, 1);
        } else if (item.id && item.title) {
          this.workHistoryDataUpdate.push(this.preparePayloadObject(item));
        }
      });

      this.checkWorkHistoriesSaveQueue();
    },
    checkWorkHistoriesSaveQueue() {
      this.workHistoryDataCreate.forEach((payloadItem) => {
        this.createWorkHistory(this.id, payloadItem);
      });

      this.workHistoryDataUpdate.forEach((payloadItem) => {
        const foundItem = this.workHistories.find(
          (item) => item.id === payloadItem.id
        );
        if (
          foundItem &&
          (foundItem[tableProps.title] !== payloadItem[tableProps.title] ||
            foundItem.business_id !== payloadItem.business_id ||
            foundItem[tableProps.start] !== payloadItem[tableProps.start] ||
            foundItem[tableProps.end] !== payloadItem[tableProps.end])
        ) {
          this.updateWorkHistory(payloadItem.id, payloadItem);
        }
      });
    },
    preparePayloadObject(item) {
      let result = {
        ...item,
        business_name: item.business.label,
        [tableProps.title]: item[tableProps.title],
        [tableProps.start]: item[tableProps.start]
          ? Utils.getServerDateFormat(item[tableProps.start])
          : null,
        [tableProps.end]: item[tableProps.end]
          ? Utils.getServerDateFormat(item[tableProps.end])
          : null,
      };

      if (item.business && item.business.id) {
        result.business_id = item.business.id;
      } else {
        result.business_id = null;
      }

      return result;
    },
    createWorkHistory(id, payload) {
      this.$store
        .dispatch("createPersonWorkHistory", {
          id: id,
          payload: payload,
        })
        .then(({ data }) => {
          this.dataset.unshift(data);
          this.dataset = this.modifyData(this.dataset);
        });
    },
    updateWorkHistory(id, payload) {
      // this.$store.dispatch("updatePersonWorkHistory", {
      //   id: this.id,
      //   workHistoryId: id,
      //   payload: payload,
      // });
    },
    prepareMainMenuOptions() {
      return [
        {
          title: "Add new",
          icon: "plus",
          iconColor: Colors.system.lightGray,
          action: () => {
            if (!this.dataset.find((item) => !item.id)) {
              this.addNewEntity();
            }
          },
        },
        {
          title: "Delete all",
          icon: "trash",
          iconColor: Colors.system.error,
          action: () => {
            if (confirm("Are you sure?")) {
              const deleteIds = this.dataset.map((item) => item.id);
              this.removeMultipleWorkHistoryItems(deleteIds);
              this.dataset = [];
            }
          },
        },
      ];
    },
    async removeMultipleWorkHistoryItems(items) {
      for (const id of items) {
        await this.removeWorkHistoryItem(id);
      }
    },
    addNewEntity() {
      let newObj = {
        id: null,
      };

      Object.values(this.tableProps).forEach((key) => {
        newObj[key] = "";
      });
      delete newObj[tableProps.options];
      this.dataset.unshift(newObj);
    },
    removeEntity(id) {
      let shouldBeConfirmed = id ? confirm("Are you sure?") : true;
      if (shouldBeConfirmed) {
        this.dataset = this.dataset.filter((item) => item.id !== id);
        this.change(true);
        // this.removeWorkHistoryItem(id);
      }
    },
    removeWorkHistoryItem(id) {
      return id
        ? this.$store.dispatch("removePersonWorkHistory", {
            id: this.id,
            workHistoryId: id,
          })
        : Promise.resolve();
    },
    requestOrganization(search) {
      return BusinessesApi.getAllBusinessesByType(search);
    },
    getOrganizationSearchUrl() {
      return Utils.getOrganizationsSearchUrl();
    },
    responseOrganization(data) {
      return data.map((item) => {
        return {
          id: item.id,
          label: item.name,
          value: item.id,
          type: item.type,
        };
      });
    },
    openCreateModal() {
      this.$v.$reset();
      this.modalDataRow = {
        id: null,
        [tableProps.title]: null,
        [tableProps.company]: null,
        [tableProps.start]: null,
        [tableProps.end]: null,
      };
      this.$refs[this.workHistoryModal].show();
    },
    async onEdit(row) {
      this.modalDataRow = {
        id: row.id,
        [tableProps.title]: row.title,
        [tableProps.company]: row.business,
        [tableProps.start]: row.from_date,
        [tableProps.end]: row.to_date,
      }

      this.$refs[this.workHistoryModal].show();
    },
    onCancel() {
      this.$refs[this.workHistoryModal].hide();
    },
    onSave() {
      this.$v.$touch();

      if (!this.$v.$error) {
        if (this.modalDataRow.id) {
          const index = this.dataset.map((workHistory) => workHistory.id).indexOf(this.modalDataRow.id);
          this.dataset = this.dataset.filter((workHistory) => workHistory.id != this.modalDataRow.id);
          this.dataset = [...this.dataset.slice(0,index), this.modalDataRow, ...this.dataset.slice(index)]
        }
        else {
          this.dataset = [...this.dataset, { id: this.workHistoryCount--, ...this.modalDataRow }];
        }

        this.change(true);

        this.$refs[this.workHistoryModal].hide();
      }
    },
    editCancel() {
      this.dataset = this.modifyData(this.personWorkHistories);
    },
    async editAccept() {
      const payload = {
        person_work_histories: this.dataset.map((data) => {
          return this.preparePayloadObject(data);
        })
      };

      this.$store
        .dispatch("createPersonWorkHistory", {
          id: this.id,
          payload: payload,
        })
        .then(() => {
          this.dataset = this.modifyData(this.personWorkHistories);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-work-history {
  padding-top: 0.5rem;

  ::v-deep {
    .card-content {
      background-color: $winbox-body-color;
      box-shadow: 0 3px 3px $winbox-body-color, 0 0 0 1px $winbox-body-color;
      padding: 1rem;
    }
    .main-data-table {
      min-height: 500px;

      thead {
        tr:not(.visible-thead-row) {
          display: none;
        }
        th {
          width: 150px;

          &:first-child {
            width: 200px;
          }
          &:nth-child(2) {
            width: 320px;
          }
          &:last-child {
            width: 110px;
            text-align: center;
          }
        }
      }
    }
    .action-button {
      @include button-reset-ui;
    }
  }
}
.header-action-wrap {
  text-align: right;

  .button {
    background: $winbox-primary-active-bg-color;
    border-color: transparent;
    color: #585656;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
</style>
