<template>
  <div class="winbox-link-entities-remote">
    <winbox-server-data-table
      ref="linkTableRef"
      class="winbox-link-entities-table"
      :url="url"
      :responseFunction="enhanceData"
      :columns="columns"
      :options="tableOptions"
      :slots="specificSlots"
      :perPage="10"
      theme="with-borders-theme"
      @row-click="onRowClick"
    >
      <tr class="visible-thead-row" slot="prependHead">
        <template v-for="(tableOptionHeading, key) in tableOptions.headings">
          <th v-if="key !== '_linked'" :key="key">{{ tableOptionHeading }}</th>
        </template>
        <th>
          <div class="select-all-box">
            <span>{{ allLinked ? "Deselect all" : "Select all" }}</span>
            <button
              class="button select-button"
              :class="{ active: allLinked }"
              @click="toggleLinkedAll"
            >
              <winbox-icon
                icon="plus"
                :color="
                  allLinked ? Colors.system.lightGray : Colors.system.primaryOrange
                "
              />
            </button>
          </div>
        </th>
      </tr>
      <template :slot="tableProps.linked" slot-scope="data">
        <button
          class="button select-button"
          :class="{ active: getLinked(data.row.id) }"
          @click="toggleLinked(data.row.id)"
        >
          <winbox-icon
            icon="plus"
            :color="
              data.row[tableProps.linked]
                ? Colors.system.lightGray
                : Colors.system.primaryLight
            "
          />
        </button>
      </template>
    </winbox-server-data-table>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxServerDataTable from "@/components/Molecules/WinboxServerDataTable";

import cloneDeep from "clone-deep";
import { Colors } from "@/Colors";

export default {
  name: "WinboxLinkEntitiesRemote",
  components: {
    WinboxIcon,
    WinboxServerDataTable,
  },
  props: {
    config: {
      required: true,
      type: Object,
    },
    url: {
      required: true,
      type: String,
    },
    responseFunc: {
      required: false,
      type: Function,
    },
  },
  data() {
    return {
      Colors,
      tableProps: this.config.tableProps,
      columns: Object.values(this.config.tableProps),
      tableOptions: this.config.tableOptions,
      specificSlots: [this.config.tableProps.linked],
      dataset: [],
    };
  },
  computed: {
    allLinked() {
      return this.dataset.every((item) => item[this.tableProps.linked]);
    },
  },
  methods: {
    toggleLinked(id) {
      const found = this.dataset.find((item) => item.id === id);
      if (found) {
        found[this.tableProps.linked] = !found[this.tableProps.linked];
      }
    },
    toggleLinkedAll() {
      const inverse = !this.allLinked;
      this.dataset.forEach((item) => (item[this.tableProps.linked] = inverse));
    },
    onRowClick(e) {
      this.toggleLinked(e.row.id);
    },
    enhanceData(data) {
      this.dataset = this.responseFunc ? this.responseFunc(data) : data;
      return this.dataset;
    },
    getLinked(id) {
      const found = this.dataset.find((item) => item.id === id);
      if (found) {
        return found[this.tableProps.linked];
      }
      return false;
    },
    getDeepCloneDataset() {
      return cloneDeep(this.dataset);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.winbox-link-entities {
  height: 360px;
  overflow: auto;
}

.winbox-link-entities-table {
  ::v-deep {
    thead {
      th {
        vertical-align: middle;
        &:last-child {
          width: 130px;
        }
      }
      tr:not(.visible-thead-row) {
        display: none;
      }
      .select-all-box {
        display: flex;
        background: rgba(255, 255, 255, 0.3);
        align-items: center;
        border-radius: 4px;
        font-size: $winbox-font-size-small;
        padding: 2px 6px;
        justify-content: space-between;

        .select-button {
          box-shadow: none;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
      td {
        border-left: none;
        border-right: none;

        &:first-child {
          border-left: 1px solid $winbox-secondary-title;
        }
        &:last-child {
          border-right: 1px solid $winbox-secondary-title;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
    .select-button {
      border-color: $winbox-primary-active-bg-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;

      &.active {
        background: $winbox-primary-active-bg-color;
      }
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .winbox-icon {
        font-size: $winbox-font-size-small;
      }
    }
  }
}
</style>
