var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-contacts-updated" },
    [
      _c("winbox-card", { staticClass: "item-overview" }, [
        _c(
          "div",
          { staticClass: "item-title" },
          [
            _c("winbox-title", {
              attrs: { title: _vm.value.title, type: "medium" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item-chart" }, [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "item-count" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("numeral")(this.value.count, _vm.baseNumFormat)
                    ) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-status" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.value.subtitle || _vm.value.title) +
                    " (Last\n            " +
                    _vm._s(_vm.value.currentLastDays.value) +
                    " days)\n          "
                )
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "item-info-list" },
                _vm._l(_vm.value.infoStatuses, function(item, key) {
                  return _c(
                    "li",
                    {
                      key: key,
                      staticClass:
                        "columns is-vcentered is-1 item-info-list-item",
                      class: { negative: item.tendence === "negative" }
                    },
                    [
                      _c("div", { staticClass: "column is-narrow" }, [
                        _c(
                          "div",
                          { staticClass: "icon-block" },
                          [
                            _c("winbox-icon", {
                              attrs: {
                                icon: item.icon,
                                color:
                                  item.tendence === "negative"
                                    ? _vm.Colors.system.error
                                    : _vm.Colors.system.primaryLight
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-narrow" }, [
                        _c("div", {
                          staticClass: "item-info-text",
                          domProps: { innerHTML: _vm._s(item.status) }
                        })
                      ])
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column is-6" }, [
              _c(
                "div",
                { staticClass: "item-graph" },
                [
                  _vm.value.chartComponent !== "horizontal-bar"
                    ? _c("winbox-multi-axes-chart", {
                        attrs: {
                          chartdata: _vm.chartData,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.value.chartComponent === "horizontal-bar"
                    ? _c("winbox-horizontal-bar-chart", {
                        attrs: {
                          chartdata: _vm.chartData,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }