<template>
  <div class="login-page">
    <winbox-title class="main-title" title="Winbox Reset Password" />
    <winbox-card>
      <form @submit.prevent="onSubmit">
        <p v-if="backendErrors" class="error-block">
          {{ backendErrors }}
        </p>
        <div class="columns is-variable is-1">
          <div class="column">
            <winbox-input
              placeholder="new password"
              type="password"
              v-model.trim="$v.entity.password.$model"
              :class="status($v.entity.password)"
            />
          </div>
        </div>
        <div class="columns is-variable is-1">
          <div class="column">
            <winbox-input
              placeholder="password confirmation"
              type="password"
              v-model.trim="$v.entity.password_confirmation.$model"
              :class="status($v.entity.password_confirmation)"
            />
          </div>
        </div>
        <div class="columns is-variable is-1">
          <div class="column">
            <button :disabled="!isValidForm" class="button is-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </winbox-card>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxInput from "@/components/Atoms/WinboxInput";

import { required } from "vuelidate/lib/validators";
import * as Utils from "@/utils";
import * as AuthApi from "@/api/auth/auth";

export default {
  name: 'ResetPasswordPage',
  components: {
    WinboxTitle,
    WinboxCard,
    WinboxInput,
  },
  validations: {
    entity: {
      password: {
        required
      },
      password_confirmation: {
        required
      },
    },
  },
  data () {
    return {
      entity: {
        password_confirmation: "",
        password: ""
      },
      backendErrors: ""
    }
  },
  created () {
  },
  computed: {
    isValidForm() {
      return !this.$v.entity.$error;
    },
    isValidForgotForm() {
      return !this.$v.forgotEntity.$error;
    }
  },
  methods: {
    status(validation) {
      return Utils.validationStatus(validation);
    },
    onSubmit() {
      this.$v.$touch();
      this.backendErrors = "";

      if (!this.$v.entity.$error) {
        const payload = {
          password_confirmation: this.entity.password_confirmation,
          password: this.entity.password,
          reset_password_token: this.$route.query["reset_password_token"],
          
        };

        AuthApi.changePassword(payload)
          .then(() => {
            this.$router.push({ name: "Login" });
          })
          .catch(e => {
            this.afterResetFail(e.response);
          });
      }
    },
    afterResetFail(response) {
      if (response && response.data) {
        const errors = response.data.errors;
        if (errors && errors.length) {
          this.backendErrors = this.constructErrorMessage(errors);
        }
      }
    },
    constructErrorMessage(errors) {
      return Object.values(errors).join(", ");
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.error-block {
  color: $winbox-error;
  margin: 0 0 1rem;
}
.login-page {
  width: 600px;
  margin: 0 auto;
}
.main-title {
  margin: 0 0 1rem;
}
.back-to-login {
  margin-top: 24px;
}
.forgot-box {
  margin: 0 0 1rem;
  &.success {
    color: $winbox-success;
  }
  &.error {
    color: $winbox-error;
  }
}
</style>
