<template>
  <div class="winbox-add-relationships">
    <div v-if="!showNextStep" class="step-one">
      <winbox-server-data-table
        class="winbox-add-relationships-table"
        :url="url"
        :columns="columns"
        :options="tableOptions"
        :slots="specificSlots"
        :perPage="10"
        :responseFunction="enhanceData"
        theme="with-borders-theme"
        @row-click="onRowClick"
        ref="winboxDataTable"
      >
        <template :slot="linkedIdSlotName">
          <div class="select-all-checkbox">
            <input
              type="checkbox"
              :checked="areAllSelected"
              @change="toggleSelectAll"
            />
          </div>
        </template>

        <template :slot="tableProps.linked" slot-scope="data">
          <button
            class="button select-button"
            v-bind:class="{ active: data.row[tableProps.linked] }"
          >
            <winbox-icon
              icon="plus"
              :color="
                data.row[tableProps.linked]
                  ? Colors.system.lightGray
                  : Colors.system.primaryLight
              "
            />
          </button>
        </template>
      </winbox-server-data-table>
    </div>
    <div v-if="showNextStep && entity" class="step-two">
      <div class="columns">
        <div class="column">
          <winbox-input
            placeholder="Name"
            :value="entity.map((entity) => entity.name).join(', ')"
            :readonly="true"
          />
        </div>
      </div>

      <div class="winbox-relationship-items">
        <div class="columns">
          <div class="column is-6">
            <winbox-select
              :value="services"
              :options="possibleServices"
              :multiple="true"
              placeholder="Services"
              label="name"
              :class="validation($v.services)"
              @input="e => onChangeEntityFields(e, 'services')"
            />
          </div>
          <div class="column is-6">
            <winbox-select
              :value="mediaTypes"
              :options="possibleMediaTypes"
              :multiple="true"
              label="name"
              placeholder="Media Type"
              :class="validation($v.mediaTypes)"
              @input="e => onChangeEntityFields(e, 'mediaTypes')"
            />
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-6">
          <winbox-select
            v-model.trim="status"
            :options="possibleStatuses"
            :searchable="false"
            placeholder="Status"
            :class="validation($v.status)"
          />
        </div>
        <div class="column is-6">
          <winbox-select
            v-if="possibleLocations.length >= 1"
            placeholder="Location"
            label="name"
            :class="validation($v.location)"
            v-model.trim="location"
            :options="possibleLocations"
            :disabled="possibleLocations.length == 1"
            :emptyText="emptyText"
          />
          <winbox-input
            v-else
            placeholder="Location"
            :readonly="true"
            :value="emptyText"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <button
            class="button action-button"
            :class="{ 'is-primary': is_aor }"
            @click="toggleAOR"
          >
            <input
              type="checkbox"
              :checked="is_aor"
            />
            <span>AOR</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxServerDataTable from "@/components/Molecules/WinboxServerDataTable";

import cloneDeep from "clone-deep";
import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { required } from "vuelidate/lib/validators";
import { BusinessStatuses } from "@/constants/BusinessStatuses";

export default {
  name: "WinboxAddMultipleRelationshipsRemote",
  components: {
    WinboxIcon,
    WinboxInput,
    WinboxSelect,
    WinboxServerDataTable,
  },
  props: {
    config: {
      required: true,
      type: Object,
    },
    url: {
      required: true,
      type: String,
    },
    responseFunction: Function,
    entityLocation: {
      required: false,
      type: Object,
    },
  },
  validations: {
    status: {
      required,
    },
    location: {
      required,
    },
    services: {
      required,
    },
    mediaTypes: {
      required: false,
    },
  },
  data() {
    return {
      entity: [],
      areAllSelected: false,
      services: [],
      mediaTypes: [],
      location: this.entityLocation,
      is_aor: null,
      status: null,
      showNextStep: false,
      Colors,
      selected: [],
      tableProps: this.config.tableProps,
      dataset: [],
      columns: Object.values(this.config.tableProps),
      tableOptions: this.config.tableOptions,
      specificSlots: this.config.specificSlots,
      totalCount: 0,
      multiselect: this.config.multiselect,
    };
  },
  computed: {
    ...mapState([
      'currentAgency',
      'businessLocations',
    ]),
    possibleServices() {
      return this.$store.getters.SERVICES;
    },
    possibleMediaTypes() {
      return this.$store.getters.MEDIA_TYPES;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    possibleLocations() {
      return this.businessLocations.filter((businessLocation) => businessLocation.status === 'active');
    },
    emptyText() {
      if (this.entity) {
        return `Please make sure that ${this.isAgency ? this.currentAgency.name : this.entity.name} has a location`;
      }

      return "Sorry, no matching options.";
    },
    isAgency() {
      let path = this.$router.currentRoute.path.substr(1);
      path = path.match(/(\w+)\/\d+\/(\w+)(\/(\w+))?/);
      return path[1] == 'agencies';
    },
    agencyId() {
      let path = this.$router.currentRoute.path.substr(1);
      path = path.match(/(\d+)/);
      return path[0];
    },
    linkedIdSlotName() {
      return `h__${this.tableProps.linked}`;
    },
  },
  methods: {
    toggleLinked(id) {
      if (this.multiselect) {
        const found = this.dataset.find(item => item.id === id);
        if (found) {
          found[this.tableProps.linked] = !found[this.tableProps.linked];
        }

        let exists = this.entity.filter((entity) => entity.id === id).length;

        if(exists) {
          this.entity = this.entity.filter((entity) => entity.id != id);
        }
        else {
          this.$set(this.entity, this.entity.length, found);
        }
        this.$emit('selectionChange', this.entity.length > 0);
        this.$refs.winboxDataTable.$refs.vTableRef.setData({
          data: { data: this.dataset, count: this.totalCount },
        });
      }
      else {
        this.dataset.forEach((item) => {
          item[this.tableProps.linked] = false;
        });
        const found = this.dataset.find((item) => item.id === id);
        if (found) {
          found[this.tableProps.linked] = !found[this.tableProps.linked];
          this.entity = {
            ...found,
            status: this.possibleStatuses[0],
          };
          this.$emit('selectionChange', true);
          this.$refs.winboxDataTable.$refs.vTableRef.setData({
            data: { data: this.dataset, count: this.totalCount },
          });
        }
      }
    },
    getSelected() {
      if (this.multiselect) {
        return this.entity;
      } else {
        return this.entity ? [this.entity] : [];
      }
    },
    goToNextStep() {
      this.showNextStep = true;
    },
    getDeepCloneDataset() {
      return cloneDeep(this.dataset);
    },
    validation(validation) {
      return Utils.validationStatus(validation);
    },
    onChangeEntityFields(e, type) {
      for(let i = 0; i < this.entity.length; i++) {
        if(type === 'services') {
          this.services = e;
          this.$v.services.$touch();
        }
        else if(type === 'mediaTypes') {
          this.mediaTypes = e;
          this.$v.mediaTypes.$touch();
        }
      }
    },
    toggleSelectAll() {
      if (this.areAllSelected) {
        this.entity = [];
        this.dataset.forEach(item => {
          item[this.tableProps.linked] = false;
        });
      } else {
        this.entity = [...this.dataset];
        this.dataset.forEach(item => {
          item[this.tableProps.linked] = true;
        });
      }

      this.areAllSelected = !this.areAllSelected;
      this.$emit("selectionChange", this.entity.length > 0);
      
      this.$refs.winboxDataTable.$refs.vTableRef.setData({
        data: { data: this.dataset, count: this.totalCount },
      });
    },
    onRowClick(e) {
      this.toggleLinked(e.row.id);
    },
    enhanceData(data, count) {
      this.totalCount = count;

      if(this.multiselect) {
        if(this.entity.length) {
          const ids = this.entity.map((entity) => entity.id);

          data = data.map((entity) => {
            return {
              ...entity,
              [this.tableProps.linked]: ids.includes(entity.id),
            };
          })
        }
      }

      this.dataset = this.responseFunction(data, count);
      return this.dataset;
    },
    toggleAOR() {
      this.is_aor = !this.is_aor;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-add-relationships-table {
  ::v-deep {
    thead {
      th {
        vertical-align: middle;
        &:last-child {
          width: 130px;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
      td {
        border-left: none;
        border-right: none;

        &:first-child {
          border-left: 1px solid $winbox-secondary-title;
        }
        &:last-child {
          border-right: 1px solid $winbox-secondary-title;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
    .select-button {
      border-color: $winbox-primary-active-bg-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;

      &.active {
        background: $winbox-primary-active-bg-color;
      }
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .winbox-icon {
        font-size: $winbox-font-size-small;
      }
    }
    .select-all-checkbox {
      text-align: right;
      padding-right: 10px;
    }
  }
}
.action-button {
  @include profile-action-button;
}
</style>
