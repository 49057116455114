var render = function render(_c, _vm) {
  return _vm._m(0)
}
var staticRenderFns = [
  function(_c, _vm) {
    return _c("div", { staticClass: "loading-indicator-large" }, [
      _c("div", { staticClass: "lds-roller" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }