import { MutationTypes } from "@/constants/MutationTypes";

export default {
  /* Companies */
  [MutationTypes.SET_COMPANIES]: (state, payload) => {
    state.companies = payload;
  },

  [MutationTypes.ADD_UPDATE_COMPANY]: (state, payload) => {
    state.currentCompany = payload;
  },

};
