var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entity-notes-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c("div", { staticClass: "entity-notes" }, [
            _c("div", { staticClass: "wrap-company-header" }, [
              _c("div", { staticClass: "columns is-vcentered" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [_c("winbox-title", { attrs: { title: _vm.mainTitle } })],
                  1
                ),
                _vm._v(" "),
                _vm.isEditMode
                  ? _c(
                      "div",
                      { staticClass: "column is-narrow header-action-wrap" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            on: { click: _vm.openCreateModal }
                          },
                          [
                            _c("winbox-icon", {
                              staticClass: "add-entity-icon",
                              attrs: {
                                color: _vm.Colors.system.lightGray,
                                icon: "plus"
                              }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Create")])
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-data-table", {
                    ref: "winboxDataTable",
                    staticClass: "main-data-table",
                    attrs: {
                      data: _vm.dataset,
                      columns: _vm.columns,
                      options: _vm.tableOptions,
                      slots: _vm.specificSlots
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "child_row",
                          fn: function(data) {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "child-full-description-td" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "child-full-description" },
                                      [
                                        _vm.showFullDescription(
                                          data.row.description
                                        )
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(data.row.description)
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("td", { staticClass: "child-spacer-td" }),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "additional-block" },
                                    [
                                      data.row.expire_date
                                        ? _c("div", [
                                            _c("strong", [_vm._v("Expires:")]),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("winboxDate")(
                                                    data.row.expire_date
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      data.row.reminder_date
                                        ? _c("div", [
                                            _c("strong", [_vm._v("Reminder:")]),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("winboxDate")(
                                                    data.row.reminder_date
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: _vm.tableProps.toggler,
                          fn: function(data) {
                            return [
                              _c("span", {
                                staticClass: "VueTables__child-row-toggler",
                                class: {
                                  "VueTables__child-row-toggler--closed": !data
                                    .row._expanded,
                                  "VueTables__child-row-toggler--open":
                                    data.row._expanded
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onTogglerClick(data)
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: _vm.tableProps.description,
                          fn: function(data) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("firstline")(
                                      _vm._f("truncate")(
                                        data.row[_vm.tableProps.description],
                                        _vm.maxSymbols
                                      )
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          }
                        },
                        {
                          key: _vm.tableProps.published,
                          fn: function(data) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("winboxDatetime")(
                                      data.row[_vm.tableProps.published]
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          }
                        },
                        {
                          key: _vm.tableProps.options,
                          fn: function(data) {
                            return [
                              _vm.isEditMode
                                ? _c(
                                    "button",
                                    { staticClass: "button button-edit" },
                                    [
                                      _c("winbox-icon", {
                                        attrs: {
                                          icon: "pen",
                                          color: _vm.Colors.system.primaryLight
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.onEdit(data.row)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isEditMode
                                ? _c(
                                    "button",
                                    { staticClass: "button button-delete" },
                                    [
                                      _c("winbox-icon", {
                                        attrs: {
                                          icon: "trash",
                                          color: _vm.Colors.system.error
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return (() => {
                                              _vm.collapseAllActiveRows()
                                              _vm.removeEntity(data.row.id)
                                            }).apply(null, arguments)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.reminderModal,
          staticClass: "notes-modal",
          attrs: {
            name: _vm.reminderModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            width: 800,
            title: _vm.noteModalTitle,
            "save-text": "Save"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _vm.modalDataRow
            ? _c("winbox-public-notes", {
                ref: "notesModalRef",
                attrs: { data: _vm.modalDataRow }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }