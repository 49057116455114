import {
    getGenericFullResponse,
} from "@/api/_generic/_generic";

export function exportCompanies(data) {
    const url = `/company-export?ids[]=${data.ids.join('&ids[]=')}&${data.query}`;
    return getGenericFullResponse(url);
}

export function exportAgencies(data) {
    const url = `/agency-export?ids[]=${data.ids.join('&ids[]=')}&${data.query}`;
    return getGenericFullResponse(url);
}

export function exportBrands(data) {
    const url = `/brand-export?ids[]=${data.ids.join('&ids[]=')}&${data.query}`;
    return getGenericFullResponse(url);
}
