import * as ReportsApi from "@/api/reports/reports";

import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getReports({ commit }) {
    return ReportsApi.getReports().then(data => {
      commit(MutationTypes.SET_REPORTS, data);
    });
  }
}
