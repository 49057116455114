var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "control winbox-input",
      class: {
        "has-icons-left": _vm.iconLeft,
        "has-icons-right": _vm.iconRight,
        "has-label": !!_vm.label
      }
    },
    [
      _vm.label
        ? _c("label", { staticClass: "label winbox-label" }, [
            _c("span", [_vm._v(_vm._s(_vm.label))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.editedText,
                expression: "editedText"
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            ref: "inputRef",
            staticClass: "input",
            attrs: {
              placeholder: _vm.placeholder,
              title: _vm.title,
              required: _vm.required,
              disabled: _vm.disabled,
              readonly: _vm.readonly,
              maxlength: _vm.maxlength,
              min: _vm.min,
              autocomplete: "nope",
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.editedText)
                ? _vm._i(_vm.editedText, null) > -1
                : _vm.editedText
            },
            on: {
              input: _vm.emit,
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              change: function($event) {
                var $$a = _vm.editedText,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.editedText = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.editedText = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.editedText = $$c
                }
              }
            }
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.editedText,
                expression: "editedText"
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            ref: "inputRef",
            staticClass: "input",
            attrs: {
              placeholder: _vm.placeholder,
              title: _vm.title,
              required: _vm.required,
              disabled: _vm.disabled,
              readonly: _vm.readonly,
              maxlength: _vm.maxlength,
              min: _vm.min,
              autocomplete: "nope",
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.editedText, null) },
            on: {
              input: _vm.emit,
              focus: _vm.onFocus,
              blur: _vm.onBlur,
              change: function($event) {
                _vm.editedText = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.editedText,
                expression: "editedText"
              },
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            ref: "inputRef",
            staticClass: "input",
            attrs: {
              placeholder: _vm.placeholder,
              title: _vm.title,
              required: _vm.required,
              disabled: _vm.disabled,
              readonly: _vm.readonly,
              maxlength: _vm.maxlength,
              min: _vm.min,
              autocomplete: "nope",
              type: _vm.type
            },
            domProps: { value: _vm.editedText },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) return
                  _vm.editedText = $event.target.value
                },
                _vm.emit
              ],
              focus: _vm.onFocus,
              blur: _vm.onBlur
            }
          }),
      _vm._v(" "),
      _vm.maxLengthExceeded
        ? _c("div", { staticClass: "max-length-message" }, [
            _vm._v("\n    Max character limit reached\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.iconLeft
        ? _c("winbox-icon", {
            staticClass: "icon-box",
            attrs: {
              icon: _vm.iconLeft,
              namespace: _vm.iconLeftNamespace,
              color: _vm.Colors.inputIcon,
              "container-class": "is-small is-left"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.iconRight
        ? _c("winbox-icon", {
            staticClass: "icon-box",
            attrs: {
              icon: _vm.iconRight,
              namespace: _vm.iconRightNamespace,
              color: _vm.Colors.inputIcon,
              "container-class": "is-small is-right"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }