var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-location" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("winbox-input", {
          class: _vm.status(_vm.$v.entity.name),
          attrs: { placeholder: "Name", field: "locationName" },
          model: {
            value: _vm.$v.entity.name.$model,
            callback: function($$v) {
              _vm.$set(
                _vm.$v.entity.name,
                "$model",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "$v.entity.name.$model"
          }
        }),
        _vm._v(" "),
        _c("winbox-select", {
          class: _vm.status(_vm.$v.entity.location_type),
          attrs: {
            placeholder: "Type",
            options: _vm.possibleTypes,
            clearable: true
          },
          model: {
            value: _vm.entity.location_type,
            callback: function($$v) {
              _vm.$set(
                _vm.entity,
                "location_type",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "entity.location_type"
          }
        }),
        _vm._v(" "),
        _c("winbox-input", {
          class: _vm.status(_vm.$v.entity.address_1),
          attrs: { placeholder: "Address 1" },
          model: {
            value: _vm.$v.entity.address_1.$model,
            callback: function($$v) {
              _vm.$set(
                _vm.$v.entity.address_1,
                "$model",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "$v.entity.address_1.$model"
          }
        }),
        _vm._v(" "),
        _c("winbox-input", {
          attrs: { placeholder: "Address 2" },
          model: {
            value: _vm.entity.address_2,
            callback: function($$v) {
              _vm.$set(
                _vm.entity,
                "address_2",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "entity.address_2"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column is-7" },
            [
              _c("winbox-input", {
                class: _vm.status(_vm.$v.entity.city),
                attrs: { placeholder: "City" },
                model: {
                  value: _vm.$v.entity.city.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.entity.city,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.entity.city.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-3" },
            [
              _c("winbox-select", {
                class: _vm.status(_vm.$v.entity.state),
                attrs: {
                  placeholder: _vm.statePlaceholder,
                  options: _vm.states
                },
                model: {
                  value: _vm.entity.state,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.entity,
                      "state",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "entity.state"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-2" },
            [
              _c("winbox-input", {
                class: _vm.status(_vm.$v.entity.zip),
                attrs: { placeholder: "Zip" },
                model: {
                  value: _vm.$v.entity.zip.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.entity.zip,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.entity.zip.$model"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.isUk
          ? _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column is-6" },
                [
                  _c("winbox-select", {
                    class: _vm.status(_vm.$v.entity.tv_region),
                    attrs: {
                      placeholder: "TV Region",
                      options: _vm.possibleTvRegions,
                      clearable: true
                    },
                    model: {
                      value: _vm.entity.tv_region,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.entity,
                          "tv_region",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "entity.tv_region"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-6" },
                [
                  _c("winbox-input", {
                    class: _vm.status(_vm.$v.entity.country),
                    attrs: { placeholder: "Country" },
                    model: {
                      value: _vm.entity.country,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.entity,
                          "country",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "entity.country"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column is-6" },
            [
              !_vm.isUk
                ? _c("winbox-input", {
                    attrs: {
                      placeholder: "Main Phone",
                      "icon-left": "phone-alt",
                      mask: "(###)-###-####"
                    },
                    model: {
                      value: _vm.entity.phone,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.entity,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "entity.phone"
                    }
                  })
                : _c("winbox-input", {
                    class: _vm.status(_vm.$v.entity.phone),
                    attrs: {
                      placeholder: "Main Phone",
                      "icon-left": "phone-alt"
                    },
                    model: {
                      value: _vm.entity.phone,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.entity,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "entity.phone"
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-6" },
            [
              !_vm.isUk
                ? _c("winbox-input", {
                    attrs: {
                      placeholder: "Toll Free",
                      "icon-left": "phone-alt",
                      mask: "(###)-###-####"
                    },
                    model: {
                      value: _vm.entity.toll_free,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.entity,
                          "toll_free",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "entity.toll_free"
                    }
                  })
                : _c("winbox-input", {
                    class: _vm.status(_vm.$v.entity.toll_free),
                    attrs: {
                      placeholder: "Toll Free",
                      "icon-left": "phone-alt"
                    },
                    model: {
                      value: _vm.entity.toll_free,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.entity,
                          "toll_free",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "entity.toll_free"
                    }
                  })
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }