var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wrap-sidebar-menu",
      class: { "with-separator": _vm.isProfilePage }
    },
    [
      _c("ul", { staticClass: "menu-list" }, [
        _vm.isAdmin()
          ? _c(
              "div",
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "menu-list-item has-tooltip-right",
                    attrs: { "data-tooltip": item.name }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "menu-link-item",
                        attrs: {
                          exact: item.path === "/",
                          to: { path: item.path }
                        }
                      },
                      [
                        _c("winbox-icon", {
                          staticClass: "menu-link-icon",
                          attrs: { icon: item.icon }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.name != "Data Import",
                        expression: "item.name!='Data Import'"
                      }
                    ],
                    key: index,
                    staticClass: "menu-list-item has-tooltip-right",
                    attrs: { "data-tooltip": item.name }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "menu-link-item",
                        attrs: {
                          exact: item.path === "/",
                          to: { path: item.path }
                        }
                      },
                      [
                        _c("winbox-icon", {
                          staticClass: "menu-link-icon",
                          attrs: { icon: item.icon }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
      ]),
      _vm._v(" "),
      _c("winbox-search-by-menu", {
        attrs: { searchPage: _vm.searchPage, filterOptions: _vm.filterOptions },
        on: { "open-popup-filter": _vm.openPopupFilter }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }