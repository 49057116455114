var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "switch-button-control" }, [
    _c(
      "div",
      {
        staticClass: "switch-button-wrap",
        class: { enabled: _vm.isEnabled },
        style: { "--color": _vm.color },
        on: { click: _vm.toggle }
      },
      [_c("div", { staticClass: "switch-button" })]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "switch-button-label" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }