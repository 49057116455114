var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-brands" },
    [
      _c("winbox-card", [
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-data-table",
                {
                  staticClass: "main-data-table",
                  attrs: {
                    data: _vm.dataset,
                    columns: _vm.columns,
                    options: _vm.tableOptions,
                    slots: _vm.specificSlots
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: _vm.tableProps.brand,
                        fn: function(data) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  "data-tooltip": data.row.id,
                                  to: {
                                    path: _vm.constructBrandLink(data.row.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(data.row[_vm.tableProps.brand]) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: _vm.tableProps.company,
                        fn: function(data) {
                          return data.row[_vm.tableProps.company]
                            ? [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      "data-tooltip":
                                        data.row[_vm.tableProps.company].id,
                                      to: {
                                        path: _vm.constructCompanyLink(
                                          data.row[_vm.tableProps.company].id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          data.row[_vm.tableProps.company].name
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            : undefined
                        }
                      },
                      {
                        key: _vm.tableProps.status,
                        fn: function(data) {
                          return [
                            _vm.isEditMode
                              ? _c(
                                  "button",
                                  { staticClass: "button remove-button" },
                                  [
                                    _c("winbox-icon", {
                                      attrs: {
                                        icon: "trash",
                                        color: _vm.Colors.system.error
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.removeEntity(data.row.id)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c(
                    "tr",
                    {
                      staticClass: "visible-thead-row",
                      attrs: { slot: "prependHead" },
                      slot: "prependHead"
                    },
                    [
                      _c("th", [_vm._v("Brand")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Company")]),
                      _vm._v(" "),
                      _c("th", [
                        _c("div", { staticClass: "add-new-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button create-button",
                              attrs: { disabled: _vm.isOrganizationEmpty },
                              on: { click: _vm.openModal }
                            },
                            [
                              _vm.isEditMode
                                ? _c("winbox-icon", {
                                    attrs: {
                                      icon: "plus-circle",
                                      color: _vm.Colors.system.white
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.linkBrandsModal,
          attrs: {
            name: _vm.linkBrandsModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            width: "800px",
            height: _vm.getBrands().length >= 10 ? "670px" : "400px",
            title: "Link Brands",
            "save-text": "Add Selected"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _c("winbox-link-entities-remote", {
            ref: "linkEntities",
            attrs: {
              url: _vm.getBrands(),
              responseFunc: _vm.enhanceData,
              config: _vm.linkEntitiesConfig
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }