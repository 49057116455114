import { mapActions, mapState } from "vuex";

import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxToggleMenu from "@/components/Molecules/WinboxToggleMenu";
import WinboxRelationShipItems from "@/components/Molecules/WinboxRelationshipItems";

import WinboxAddRelationshipsRemote from "@/components/Organisms/WinboxAddRelationshipsRemote";

import * as BusinessesApi from "@/api/businesses/businesses";

import { Colors } from "@/Colors";
import { required } from "vuelidate/lib/validators";

export const EntityRelationshipsRemoteMixin = {
  components: {
    WinboxSelect,
    WinboxLargeIndicator,
    WinboxDataTable,
    WinboxCompanyHeader,
    WinboxModal,
    WinboxToggleMenu,
    WinboxRelationShipItems,
    WinboxAddRelationshipsRemote
  },
  data() {
    return {
      linkModal: "linkModal",
      linkEditModal: "linkEditModal",
      linkConfig: {
        tableProps: {
          name: "name",
          location: "location",
          linked: "_linked"
        },
        specificSlots: ["_linked"],
        tableOptions: {
          headings: {
            name: "Name",
            location: "Location",
            _linked: ""
          },
          sortable: [],
          filterable: ["name", "location"]
        }
      },
      nextStepIsActive: false,
      rowOptions: this.prepareRowMenuOptions(),
      editEntity: null
    };
  },

  validations: {
    editEntity: {
      services: {
        required
      },
      mediaTypes: {
        required: false,
      },
      location: {
        required
      },
      is_aor: {
      },
    }
  },

  computed: {
    ...mapState([
      'businessLocations',
    ]),
    saveModalText() {
      return this.nextStepIsActive ? "Create" : "Add Selected";
    },
    possibleServices() {
      return this.$store.getters.SERVICES;
    },
    possibleMediaTypes() {
      return this.$store.getters.MEDIA_TYPES;
    },
    possibleLocations() {
      return this.businessLocations;
    },
    datasetRelationships() {
      return this.datasetRelationshipsMain.map(item => {
        return {
          ...item,
          _linked: false,
          services: [],
          mediaTypes: [],
          status: "",
          is_aor: false,
        };
      });
    },
    isAgency() {
      let path = this.$router.currentRoute.path.substr(1);
      path = path.match(/(\w+)\/\d+\/(\w+)(\/(\w+))?/);
      return path[1] == 'agencies';
    },
  },
  methods: {
    ...mapActions([
      'getBusinessLocations',
    ]),
    openLinkModal() {
      this.$refs[this.linkModal].show();
    },
    onCancelLinkModal() {
      this.$refs[this.linkModal].hide();
    },
    resetLinkNextStep() {
      this.nextStepIsActive = false;
    },
    findServiceNameById(id, fullObject = false) {
      const found = this.possibleServices.find(item => item.id === id);
      if (found) {
        return fullObject ? found : found.name;
      }
    },
    findMediaTypeNameById(id, fullObject = false) {
      const found = this.possibleMediaTypes.find(item => item.id === id);
      if (found) {
        return fullObject ? found : found.name;
      }
    },
    findLocationById(id, fullObject = false) {
      if(this.possibleLocations.length == 1) {
        const found = this.possibleLocations[0];
        return fullObject ? found : found.name;
      }
      else {
        const found = this.possibleLocations.find(item => item.id === id);
        if (found) {
          return fullObject ? found : found.name;
        }
      }
    },
    onStatusChange(row, e) {
      const item = {
        id: row.id
      };
      const payload = {
        status: e.value
      };
      this.callUpdateDispatch(item, payload);
    },
    onSaveLinkModal() {
      const vm = this.$refs.addRelationships;
      const $v = vm.$v;

      if(this.businessLocations.length == 1) {
        vm.entity.location = this.businessLocations[0];
      }

      if(typeof(vm.entity.location) === "string") {
        delete vm.entity.location;
      }

      if (!vm.entity) {
        return;
      }

      if (!this.nextStepIsActive) {
        this.nextStepIsActive = true;
        vm.goToNextStep();
      } else {
        $v.$touch();

        if (!$v.$error) {
          if (typeof this.saveNewRelationship === "function") {
            this.saveNewRelationship(vm.entity);
          }
          this.onCancelLinkModal();
        }
      }
    },
    prepareRowMenuOptions() {
      return [
        {
          id: 1,
          title: "Edit",
          icon: "edit",
          iconColor: Colors.system.primaryLight,
          action: data => {
            this.editRelationship(data);
          }
        },
        {
          id: 2,
          title: "Proof",
          icon: "",
          iconColor: Colors.system.primaryLight,
          action: data => {
            const type = this.getRelationshipType() ? 'company' : 'brand';
            this.proofRelationship(data, type);
          }
        },
      ];
    },
    async editRelationship(row) {
      if(!this.isAgency) {
        await this.getBusinessLocations(row.agency_id);
      }
      else {
        await this.getBusinessLocations(this.id);
      }

      this.editEntity = {
        id: row.id,
        business_id: row.business_id,
        services: row.services.map(a => this.findServiceNameById(a.id, true)),
        mediaTypes: row.media_types.map(a =>
          this.findMediaTypeNameById(a.id, true)
        ),
        location: this.findLocationById(row.agency_location_id, true),
        agency: row.agency,
        is_aor: row.is_aor,
      };
      this.openEditModal();
    },
    async proofRelationship(row, type) {
      try {
        const response = await BusinessesApi.submitBusinessRelationshipsProof(row.id);
        if (response && response.data) {
          let updateCommit;

          if (type === 'brand') {
            updateCommit = 'UPDATE_BRAND_BUSINESS_RELATIONSHIPS';
          } else if (type === 'company') {
            updateCommit = 'UPDATE_BUSINESS_RELATIONSHIPS';
          } else {
            console.error("Invalid type provided. Use 'brand' or 'company'.");
            return;
          }
          this.$store.commit(updateCommit, response.data);
        } else {
          console.error("Failed to get updated relationship from the response.");
        }
      } catch (error) {
        console.error("Error submitting proof:", error);
      }
    },
    openEditModal() {
      this.$refs[this.linkEditModal].show();
    },
    onEditCancel() {
      this.$refs[this.linkEditModal].hide();
      this.editEntity = null;
    },
    onEditSave() {
      if (!this.editEntity) {
        return;
      }

      this.$v.$touch();

      if (!this.$v.$error) {
        if (typeof this.updateRelationship === "function") {
          this.updateRelationship(this.editEntity);
        }
        this.onEditCancel();
      }
    },
    onEditEntityChange(e) {
      this.editEntity = {
        ...this.editEntity,
        ...e
      };
    },
    getRelationshipType() {
      let path = this.$router.currentRoute.path.substr(1);
      path = path.match(/(\w+)\/\d+\/(\w+)(\/(\w+))?/);
      return path[1] == 'companies';
    },
  }
};
