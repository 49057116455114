import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";
import { CompanyFilterOptions } from "@/constants/CompanyFilterOptions";
import { BrandFilterOptions } from "@/constants/BrandFilterOptions";
import { AgencyFilterOptions } from "@/constants/AgencyFilterOptions";
import { ContactFilterOptions } from "@/constants/ContactFilterOptions";
import { LocationFilterOptions } from "@/constants/LocationFilterOptions";
import { PostalCodesFilterOptions } from "@/constants/PostalCodesFilterOptions";
import { IndustriesFilterOptions } from "@/constants/IndustriesFilterOptions";
import * as TitleFunctionsApi from "@/api/title-functions/title-functions";
import * as TitleRanksApi from "@/api/title-ranks/title-ranks";

export default {
  getBrandFilterOptions({ commit }) {
    return commit(MutationTypes.SET_FILTER_OPTIONS, BrandFilterOptions);
  },
  getCompanyFilterOptions({ commit }) {
    return commit(MutationTypes.SET_FILTER_OPTIONS, CompanyFilterOptions);
  },
  getAgencyFilterOptions({ commit }) {
    return commit(MutationTypes.SET_FILTER_OPTIONS, AgencyFilterOptions);
  },
  getContactFilterOptions({ commit }) {
    return commit(MutationTypes.SET_FILTER_OPTIONS, ContactFilterOptions);
  },
  getLocationFilterOptions({ commit }) {
    return commit(MutationTypes.SET_LOCATION_FILTER_OPTIONS, LocationFilterOptions.map((location) => ({ id: location.id, name: location.name, code: location.abbr })));
  },
  getPostalCodesFilterOptions({ commit }) {
    return commit(MutationTypes.SET_POSTAL_CODES_FILTER_OPTIONS, PostalCodesFilterOptions);
  },
  getIndustriesFilterOptions({ commit }) {
    return commit(MutationTypes.SET_INDUSTRIES_FILTER_OPTIONS, IndustriesFilterOptions);
  },
  getJobFunctionFilterOptions({ commit }) {
    return TitleFunctionsApi.getAll()
      .then(({ data }) => {
        return commit(MutationTypes.SET_TITLE_FUNCTIONS_FILTER_OPTIONS, data.map((titleFunction) => ({ id: titleFunction.id, name: titleFunction.function, code: titleFunction.id })));
      })
      .catch(e => {
        throw e;
      });
  },
  getTitleRankFilterOptions({ commit }) {
    return TitleRanksApi.getAll()
      .then(({ data }) => {
        return commit(MutationTypes.SET_TITLE_RANKS_FILTER_OPTIONS, data.map((titleRank) => ({ id: titleRank.id, name: titleRank.rank, code: titleRank.id })));
      })
      .catch(e => {
        throw e;
      });
  },
};
