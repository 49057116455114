<template>
  <div class="brand-sponsorships-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="brand-sponsorships">
      <winbox-company-header
        :title="mainTitle"
        entity-icon="link"
        entity-label="Add Sponsorship"
        :enable-status-toggle="true"
        @toggle-status="updateVisibleStatus"
        @changed="openLinkModal"
      />
      <div class="columns">
        <div class="column">
          <winbox-data-table
            class="main-data-table"
            :data="visibleData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
          >
            <template :slot="tableProps.brand_name" slot-scope="data">
              <router-link
                :data-tooltip="data.row.sponsor_id"
                :to="{ path: constructBrandUrl(data.row.sponsor_id) }"
              >
                {{ data.row[tableProps.brand_name] }}
              </router-link>
            </template>
            <template :slot="tableProps.level" slot-scope="data">
              {{ data.row[tableProps.level] }}
            </template>
            <template :slot="tableProps.placement" slot-scope="data">
              {{ data.row[tableProps.placement] }}
            </template>
            <template :slot="tableProps.season" slot-scope="data">
              {{ data.row[tableProps.season] }}
            </template>
            <template :slot="tableProps.relationshipProof" slot-scope="data">
              {{ data.row[tableProps.relationshipProof] }}
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <winbox-select
                :value="data.row[tableProps.status]"
                :options="possibleStatuses"
                :searchable="false"
                @input="onStatusChange(data.row, $event)"
              />
            </template>
            <template :slot="tableProps.options" slot-scope="data">
              <winbox-toggle-menu :options="rowOptions" :data="data.row" />
            </template>
          </winbox-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="linkModal"
      :name="linkModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      width="1000px"
      height="475px"
      class="winbox-modal-scrollable"
      title="Add Sponsorship"
      :save-text="saveModalText"
      @on-cancel="onCancelLinkModal"
      @on-save="onSaveLinkModal"
      @enter-press="onSaveLinkModal"
      @before-close="resetLinkNextStep"
    >
      <winbox-add-brand-sponsorships
        ref="addSponsorships"
        :url="getBrandsExceptByAgencyUrl()"
        :config="linkConfig"
        :responseFunction="responseFunction"
      />
    </winbox-modal>
    <winbox-modal
      :ref="linkEditModal"
      :name="linkEditModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      width="1000px"
      height="475px"
      title="Edit Sponsorship"
      save-text="Edit"
      @on-cancel="onEditCancel"
      @on-save="onEditSave"
    >
      <div v-if="editEntity">
        <div class="columns">
          <div class="column is-4">
            <winbox-select
              form-label="Category"
              v-model.trim="$v.editEntity.category.$model"
              :options="possibleCategories"
              label="label"
              :class="status($v.editEntity.category)"
            />
          </div>
          <div class="column is-4">
            <winbox-select
              form-label="Affiliation"
              v-model.trim="$v.editEntity.affiliation.$model"
              :options="possibleAffiliations"
              label="label"
              :class="status($v.editEntity.affiliation)"
            />
          </div>
          <div class="column is-4">
            <winbox-select
              form-label="Level"
              v-model.trim="$v.editEntity.level.$model"
              :options="possibleLevels"
              label="label"
              :class="status($v.editEntity.level)"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <winbox-input
              label="Deal Amount"
              type="number"
              v-model="$v.editEntity.deal_amount.$model"
              :class="status($v.editEntity.deal_amount)"
            />
          </div>
          <div class="column is-4">
            <winbox-input
              label="Season Start Year"
              v-model="$v.editEntity.season_start_year.$model"
              :class="status($v.editEntity.season_start_year)"
            />
          </div>
          <div class="column is-4">
            <winbox-input
              label="Season End Year"
              v-model="$v.editEntity.season_end_year.$model"
              :class="status($v.editEntity.season_end_year)"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <winbox-textarea
              form-label="Placement"
              v-model.trim="$v.editEntity.placement.$model"
              :class="status($v.editEntity.placement)"
            />
          </div>
          <div class="column is-6">
            <winbox-textarea
              form-label="Description & Activation Details"
              v-model="$v.editEntity.activation.$model"
              :class="status($v.editEntity.activation)"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4" style="margin-left: auto; display: flex; justify-content: flex-end;">
            <div style="min-width: 150px; flex-shrink: 0;">
              <winbox-select
                form-label="Status"
                v-model.trim="$v.editEntity.status.$model"
                :options="possibleStatuses"
                label="label"
                :class="status($v.editEntity.status)"
              />
            </div>
          </div>
        </div>
      </div>
    </winbox-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxTextarea from "@/components/Atoms/WinboxTextarea";

import * as BrandsApi from "@/api/brands/brands";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxToggleMenu from "@/components/Molecules/WinboxToggleMenu";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxAddBrandSponsorships from "@/components/Organisms/WinboxAddBrandSponsorships";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { required, maxLength, minLength, numeric } from "vuelidate/lib/validators";

import { BusinessStatuses } from "@/constants/BusinessStatuses";
import { SponsorAffiliations, SponsorCategories, SponsorLevels } from "@/constants/BrandSponsorshipOptions";


const tableProps = {
  brand_name: "brand_name",
  level: "level",
  placement: "placement",
  season: "season",
  relationshipProof: "relationshipProof",
  status: "status",
  options: "__options"
};

const mainTableColumns = Object.values(tableProps);

export default {
  name: "BrandSponsorships",
  components: {
    WinboxSelect,
    WinboxModal,
    WinboxSelect,
    WinboxInput,
    WinboxTextarea,
    WinboxDataTable,
    WinboxToggleMenu,
    WinboxCompanyHeader,
    WinboxLargeIndicator,
    WinboxAddBrandSponsorships
  },
  data() {
    return {
      entity: null,
      visibileStatus: 'Active',
      Colors: Colors,
      isLoaded: true,
      nextStepIsActive: false,
      linkModal: "linkModal",
      linkEditModal: "linkEditModal",
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.brand_name]: "Brand Sponsor",
          [tableProps.level]: "Level",
          [tableProps.placement]: "Placement",
          [tableProps.season]: "Season",
          [tableProps.relationshipProof]: "Last Proof",
          [tableProps.status]: "Status",
          [tableProps.options]: "",
        },
        sortable: [
          tableProps.brand_name,
          tableProps.level,
          tableProps.placement,
          tableProps.season,
          tableProps.relationshipProof,
          tableProps.status,
        ],
        filterable: [
          tableProps.brand_name,
          tableProps.level,
          tableProps.placement,
          tableProps.season,
          tableProps.relationshipProof,
          tableProps.status,
        ],
      },
      specificSlots: [
        tableProps.brand_name,
        tableProps.level,
        tableProps.placement,
        tableProps.season,
        tableProps.relationshipProof,
        tableProps.status,
        tableProps.options,
      ],
      linkConfig: {
        tableProps: {
          name: "name",
          linked: "_linked"
        },
        specificSlots: ["_linked"],
        tableOptions: {
          headings: {
            name: "Name",
            _linked: ""
          },
          sortable: [],
          filterable: ["name"],
        },
        multiselect: false,
      },
      rowOptions: this.prepareRowMenuOptions(),
      editEntity: null,
    };
  },
  validations: {
    editEntity: {
      category: {
        required
      },
      affiliation: {
        required
      },
      level: {
        required
      },
      placement: {
      },
      season_start_year: {
        required,
        numeric,
        maxLength: maxLength(4),
        minLength: minLength(4)
      },
      season_end_year: {
        required,
        numeric,
        maxLength: maxLength(4),
        minLength: minLength(4),
        validEndYear(value) {
          if (this.editEntity.season_start_year && value) {
            return parseInt(value) >= parseInt(this.editEntity.season_start_year)
          }
          return true
        }
      },
      deal_amount: {
      },
      activation: {
      },
      status: {
        required
      }
    }
  },
  computed: {
    ...mapState([
      "brandSponsorships",
      "users",
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Sponsorships (${this.visibleData.length})`;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    saveModalText() {
      return this.nextStepIsActive ? "Create" : "Add Selected";
    },
    dataset() {
      return this.brandSponsorships.map(item => {
        return {
          ...item,
          original: item,
          [tableProps.brand_name]: item.brand_name,
          [tableProps.level]: item.level,
          [tableProps.placement]: this.truncateText(item.placement, 15),
          [tableProps.season]: item.season_start_year === item.season_end_year  ? `${item.season_end_year}` : `${item.season_start_year} - ${item.season_end_year}`,
          [tableProps.relationshipProof]: `${this.getUserNameById(item.proofed_by_id)} - ${this.formatDate(item.proofed_at)}`,
          [tableProps.status]: BusinessStatuses[item.status],
        };
      });
    },
    activeData() {
      return this.dataset.filter((data) => data.status.value === "active");
    },
    inactiveData() {
      return this.dataset.filter((data) => data.status.value === "inactive");
    },
    visibleData() {
      return this.visibileStatus === "Active" ? this.activeData : this.inactiveData;
    },
    possibleAffiliations() {
      return Object.values(SponsorAffiliations);
    },
    possibleCategories() {
      return Object.values(SponsorCategories);
    },
    possibleLevels() {
      return Object.values(SponsorLevels);
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getBrandSponsorships',
      'createBrandSponsorship',
      'getUsers'
    ]),
    async getData() {
      this.isLoaded = false;
      await this.getBrandSponsorships(this.id);
      await this.getUsers();
      this.isLoaded = true;
    },
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    onStatusChange(row, event) {
      const item = { id: row.id };
      const payload = { status: event.value };
      this.callUpdateDispatch(item, payload);
    },
    async editBrandSponsorship(row) {
      this.editEntity = {
        id: row.id,
        category: row.category,
        affiliation: row.affiliation,
        level: row.level,
        placement: row.original.placement,
        season_start_year: row.season_start_year,
        season_end_year: row.season_end_year,
        deal_amount: row.deal_amount,
        activation: row.activation,
        status: row.status,
      };
      this.openEditModal();
    },
    openEditModal() {
      this.$refs[this.linkEditModal].show();
    },
    callUpdateDispatch(item, payload) {
      return this.$store.dispatch("updateBrandSponsorship", {
        brandId: this.id,
        sponsorshipId: item.id,
        payload: payload
      });
    },
    updateBrandSponsorship(item) {
      const payload = {
        category: item.category,
        affiliation: item.affiliation,
        level: item.level,
        placement: item.placement,
        season_start_year: item.season_start_year,
        season_end_year: item.season_end_year,
        deal_amount: item.deal_amount,
        activation: item.activation,
        status: item.status.value,
      };

      this.callUpdateDispatch(item, payload);
    },
    prepareRowMenuOptions() {
      return [
        {
          id: 1,
          title: "Edit",
          icon: "edit",
          iconColor: Colors.system.primaryLight,
          action: data => {
            this.editBrandSponsorship(data);
          }
        },
        {
          id: 2,
          title: "Proof",
          icon: "",
          iconColor: Colors.system.primaryLight,
          action: data => {
            this.proofRelationship(data);
          }
        },
      ];
    },
    async saveNewSponsorship(item) {
      const payload_data = {
        sponsor_id: item.sponsor_id,
        sponsee_id: item.sponsee_id,
        category: item.category,
        affiliation: item.affiliation,
        level: item.level,
        placement: item.placement,
        season_start_year: item.season_start_year,
        season_end_year: item.season_end_year,
        deal_amount: item.deal_amount,
        activation: item.activation,
        status: item.status,
      };
      const payload = {
        brand_sponsorship: payload_data,
      };

      await this.createBrandSponsorship({ brandId: this.id, payload });
      this.getBrandSponsorships(this.id);
    },
    constructBrandUrl(id) {
      return Utils.getBrandProfileUrl(id);
    },
    getBrandsExceptByAgencyUrl() {
      return Utils.getBrandsExceptByAgencyUrl(this.id);
    },
    responseFunction(data, count) {
      this.totalCount = count;
      return data.map((item) => {
        return {
          ...item,
        };
      });
    },
    openLinkModal() {
      this.$refs[this.linkModal].show();
    },
    onCancelLinkModal() {
      this.$refs[this.linkModal].hide();
    },
    onEditCancel() {
      this.$refs[this.linkEditModal].hide();
      this.editEntity = null;
    },
    resetLinkNextStep() {
      this.nextStepIsActive = false;
    },
    onEditSave() {
      if (!this.editEntity) {
        return;
      }

      this.$v.$touch();

      if (!this.$v.$error) {
        if (typeof this.updateBrandSponsorship === "function") {
          this.updateBrandSponsorship(this.editEntity);
        }
        this.onEditCancel();
      }
    },
    onSaveLinkModal() {
      const vm = this.$refs.addSponsorships;
      const $v = vm.$v;

      if (!vm.entity) {
        return;
      }

      if (!this.nextStepIsActive) {
        this.nextStepIsActive = true;
        vm.goToNextStep();
      } else {
        $v.$touch();

        if (!$v.$error) {
          let item = {
            sponsor_id: vm.entity.id,
            sponsee_id: this.id,
            category: vm.entity.category,
            affiliation: vm.entity.affiliation,
            level: vm.entity.level,
            placement: vm.entity.placement,
            season_start_year: vm.entity.season_start_year,
            season_end_year: vm.entity.season_end_year,
            deal_amount: vm.entity.deal_amount,
            activation: vm.entity.activation,
            status: vm.entity.status.value,
          }

          this.saveNewSponsorship(item);
          this.onCancelLinkModal();
        }
      }
    },
    async proofRelationship(row) {
      const response = await BrandsApi.submitBrandSponsorshipsProof(row.id);
      this.$store.commit('UPDATE_BRAND_SPONSORSHIPS', response.data);
    },
    getUserNameById(userId) {
      const user = this.users.find(user => user.id === userId);
      return user ? `${user.first_name} ${user.last_name}` : '';
    },
    formatDate(timestamp) {
      if (!timestamp) return "";
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US');
    },
    truncateText(text, maxLength) {
      if (text?.length > maxLength) {
        return text.substring(0, maxLength);
      }
      return text;
    }
  },
};
</script>

<style scoped>
.brand-sponsorships {
  ::v-deep {
    .main-data-table {
      font-size: 13px;
      thead {
        th {
          /* Status */
          &:nth-child(6) {
            width: 150px;
          }
          /* Placement */
          &:nth-child(3) {
            width: 150px;
          }
        }
      }
      tbody {
        td[colspan="6"] {
          text-align: left !important;
        }
      }
    }
  }
}
</style>
