import * as BusinessesApi from "@/api/businesses/businesses";
import * as AgenciesApi from "@/api/agencies/agencies";
import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  reset({ commit }) {
    return commit(MutationTypes.RESET_STATES);
  },
  edit({ commit }, value) {
    return commit(MutationTypes.SET_EDIT, value);
  },
  save({ commit }, value) {
    return commit(MutationTypes.SET_SAVE, value);
  },
  cancel({ commit }, value) {
    return commit(MutationTypes.SET_CANCEL, value);
  },
  change({ commit }, value) {
    return commit(MutationTypes.SET_CHANGE, value);
  },
  invalid({ commit }, value) {
    return commit(MutationTypes.SET_INVALID, value);
  },
};
