import { render, staticRenderFns } from "./WinboxAssignEntityTasks.vue?vue&type=template&id=5541e315&scoped=true"
import script from "./WinboxAssignEntityTasks.vue?vue&type=script&lang=js"
export * from "./WinboxAssignEntityTasks.vue?vue&type=script&lang=js"
import style0 from "./WinboxAssignEntityTasks.vue?vue&type=style&index=0&id=5541e315&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5541e315",
  null
  
)

export default component.exports