export default {
  ORGANIZATIONS: state => {
    return state.organizations;
  },

  COMPANIES: state => {
    return state.companies;
  },

  AGENCIES: state => {
    return state.agencies;
  },

  BUSINESS_LOCATIONS: state => {
    return state.businessLocations;
  },

  BUSINESS_PROFILE_CONTACTS_BY_ID(state) {
    return id => {
      let result = state.businessProfileContacts[id] || [];
      result = result.map(item => {
        return {
          ...item,
          name: `${item.fname} ${item.lname}`
        };
      });
      return result;
    };
  },

  BUSINESS_SOCIALS_BY_ID(state) {
    return id => state.businessSocials[id] || [];
  },

  BUSINESS_NICKNAMES_BY_ID(state) {
    return id => state.businessNicknames[id] || [];
  },

  BUSINESS_TAGS_BY_ID(state) {
    return id => state.businessTags[id] || [];
  },

  BUSINESS_NOTES_BY_ID(state) {
    return id => state.businessNotes[id] || [];
  },

  BUSINESS_LOGS_BY_ID(state) {
    return id => state.businessLogs[id] || [];
  },

  BUSINESS_INDUSTRIES_BY_ID(state) {
    return id => state.businessIndustries[id] || [];
  },

  BUSINESS_DISCIPLINES_BY_ID(state) {
    return id => state.businessDisciplines[id] || [];
  },

  BUSINESS_SPECIALITIES_BY_ID(state) {
    return id => state.businessSpecialities[id] || [];
  },

  BUSINESS_RELATIONSHIPS_BY_ID(state) {
    return id => state.businessRelationships[id] || [];
  },
};
