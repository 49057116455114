<template>
  <div class="brands-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="brands-wrap-content">
      <!-- Removing "Sync to Winmo" and "Export" until a later date
      entity-secondary-label="Sync to Winmo"
      entity-secondary-icon="sync"
      @changedSecondary="openSyncModal"
      entity-third-label="Export"
      @changedThird="onExportData" -->
      <winbox-company-header
        v-if="isAdmin()"
        :title="mainTitle"
        :entity-secondary-disabled="!markedRows.length"
        entity-fourth-label="Assign"
        @changedFourth="onAssignModal"
      />
      <!-- Hides "Export" button
      entity-third-label="Export"
      @changedThird="onExportData" -->
      <winbox-company-header
        v-else
        :title="mainTitle"
      />
      <div class="columns">
        <div class="column">
          <winbox-server-data-table
            ref="mainTableRef"
            class="main-data-table"
            :url="getBrandsUrl()"
            :responseFunction="enhanceData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
            @rowsChange="onRowsChange"
            @filter="onFilter"
            @updateQuery="onUpdateQuery"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :data-tooltip="data.row.id"
                :to="{ path: constructProfileUrl(data.row.id) }"
              >
                {{ data.row[tableProps.name] }}
              </router-link>
            </template>
            <template :slot="tableProps.company" slot-scope="data">
              <router-link
                :data-tooltip="data.company"
                :to="{ path: constructCompanyProfileUrl(data.row.business) }"
              >
                {{ data.row.business ? data.row.business.name : '' }}
              </router-link>
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <div class="status-wrap">
                <winbox-icon :color="data.row.statusColor" :icon="data.row.statusIcon" />
              </div>
            </template>
          </winbox-server-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="syncModal"
      :name="syncModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Sync to Winmo"
      save-text="Sync"
      @on-cancel="onSyncCancel"
      @on-save="onSyncSave"
    >
      <p v-if="isSyncModalLoaded">Are you sure?</p>
      <winbox-large-indicator v-if="!isSyncModalLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="deleteModal"
      :name="deleteModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Delete brand"
      save-text="Ok"
      @on-cancel="onDeleteCancel"
      @on-save="onDeleteAccept"
    >
      <p v-if="isDeleteLoaded">Are you sure?</p>
      <winbox-large-indicator v-if="!isDeleteLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="assignModal"
      :name="assignModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Assign Brand Tasks"
      save-text="Assign"
      @on-cancel="onAssignCancel"
      @on-save="onAssignSave"
    >
      <winbox-assign-entity-tasks
        ref="assignEntityTasksRef"
        v-if="isAssignNewEntityTasksLoaded"
      />
      <winbox-large-indicator v-if="!isAssignNewEntityTasksLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxServerDataTable from "@/components/Molecules/WinboxServerDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxAssignEntityTasks from "@/components/Organisms/WinboxAssignEntityTasks";
import { WinboxAssignEntityTasksHelperMixin } from "@/components/Organisms/WinboxAssignEntityTasksHelperMixin";

import { BusinessEntitiesHelperMixin } from "@/components/Pages/Generic/BusinessEntitiesHelperMixin";
import { EditModeMixin } from "@/components/Pages/Generic/EditModeMixin";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { BusinessStatuses } from "@/constants/BusinessStatuses";

import * as ExportApi from "@/api/export/export";

const tableProps = {
  id: "id",
  name: "name",
  company: "company",
  industry: "__industries",
  description: "description",
  status: "status",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "Brands",
  mixins: [
    WinboxAssignEntityTasksHelperMixin,
    BusinessEntitiesHelperMixin,
    EditModeMixin,
    RolesMixin
  ],
  components: {
    WinboxLargeIndicator,
    WinboxServerDataTable,
    WinboxCompanyHeader,
    WinboxAssignEntityTasks,
    WinboxModal,
    WinboxIcon,
  },
  data() {
    return {
      isLoaded: false,
      markedRows: [],
      searchQuery: null,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.company]: "Company",
          [tableProps.industry]: "Industry",
          [tableProps.description]: "Description",
          [tableProps.status]: "Status",
        },
        sortable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
        filterable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
        filterAlgorithm: {
          name(row, query) {
            return row.name.includes(query.trim());
          },
          description(row, query) {
            return row.description && row.description.includes(query.trim());
          },
          industry(row, query) {
            return row.industry && row.industry.includes(query.trim());
          },
          company(row, query) {
            return row.company && row.company.includes(query.trim());
          },
        },
      },
      specificSlots: [
        tableProps.id,
        tableProps.name,
        tableProps.company,
        tableProps.status,
      ],
      Colors: Colors,
    };
  },
  computed: {
    mainTitle() {
      return `Brands (${this.itemsCount})`;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
  },
  beforeMount() {
    this.loadData();
    this.syncType = "brands";
  },
  watch: {
    '$route.query': {
      handler (oldUrl, newUrl) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    loadData() {
      // TODO need refactoring
      this.isLoaded = false;
      this.isLoaded = true;
      this.afterLoadData();
    },
    afterLoadData() {
      if (typeof this.tryToSetGlobalFilters === "function") {
        this.$nextTick(() => {
          this.tryToSetGlobalFilters();
        });
      }
    },
    constructProfileUrl(id) {
      return Utils.getBrandProfileUrl(id);
    },
    constructCompanyProfileUrl(business) {
      if (!business?.id) {
        return null;
      }
      return Utils.getCompanyProfileUrl(business.id);
    },
    getHumanIndustries(industries) {
      return Utils.makeArrayToString(industries);
    },
    updateLocationStatus(row, status) {
      const payload = {
        status: status,
      };
      row.statusColor = this.getStatusIconColor(status);
      row.statusIcon = this.getStatusIcon(status);
      this.$store.dispatch("updateBrand", {
        id: row.id,
        payload,
        type: "ADD_UPDATE_BRAND",
      });
    },
    getBrandCompanies(item) {
      let brand_businesses = this.$store.getters.BRAND_BUSINESSES_BY_ID(
        item.id
      );
      if (brand_businesses && brand_businesses.length > 1) {
        return (
          brand_businesses[0].name +
          " and " +
          brand_businesses.length -
          1 +
          "more"
        );
      }
      if (brand_businesses && brand_businesses.length == 1) {
        return brand_businesses[0].name;
      }
      return "";
    },
    getStatusIconColor(status) {
      if (status === null) return this.Colors.system.gray;

      return status
        ? this.Colors.system.primaryLight
        : this.Colors.system.inactive;
    },
    getStatusIcon(status) {
      if (status === null) return "circle";

      return status
        ? "circle"
        : "times";
    },
    sortByName(prev, next) {
      return prev.name > next.name ? 1 : next.name > prev.name ? -1 : 0;
    },
    onChangeStatus(row, e) {
      row[tableProps.status] = e;
      row.statusColor = this.getStatusIconColor(e.value);
      row.statusIcon = this.getStatusIcon(e.value);
    },
    getStatus(item) {
      let statusValue = BusinessStatuses.inactive.value;
      if (item[tableProps.status]) {
        statusValue = item[tableProps.status].value;
      }
      return statusValue;
    },
    updateStatus(id, status) {
      this.$store
        .dispatch("updateBrand", {
          id: id,
          payload: { status: status },
          type: "ADD_UPDATE_BRAND",
        })
        .then(() => {
          this.$forceUpdate();
        })
        .catch((e) => {});
    },
    getBrandsUrl() {
      const locations = this.$route.query.locations;
      return `${Utils.getBrandsPageUrl()}${locations && locations.length !== 0 ? `?locations=${locations}` : ''}`;
    },
    enhanceData(data, count) {
      this.itemsCount = count.toLocaleString();
      return data.map((item) => {
        return {
          ...item,
          [tableProps.industry]: this.getHumanIndustries(item.industries),
          [tableProps.company]: this.getBrandCompanies(item),
          statusColor: this.getStatusIconColor(item.published),
          statusIcon: this.getStatusIcon(item.published),
          [tableProps.status]: Utils.findStatusForSelect(
            item[tableProps.status],
            this.possibleStatuses
          ),
        };
      });
    },
    refreshOnDelete() {
      this.$refs.mainTableRef.refreshTable();
    },
    onExportData() {
      const locations = this.$route.query.locations;
      const locationQuery = (locations && locations.length !== 0 ? `locations=${locations}` : '');

      const textQuery = (this.searchQuery && this.searchQuery.length !== 0 ? `query=${this.searchQuery}` : '');

      const query = [locationQuery, textQuery].filter((item) => item != '').join('&');

      ExportApi.exportBrands({ ids: this.markedRows, query }).then((data) => {
        this.csvExport(data.data);
      });
    },
    onRowsChange(e) {
      this.markedRows = [...e];
    },
    onUpdateQuery(data) {
      this.searchQuery = data.query;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-data-table {
  ::v-deep {
    font-size: 13px;
    thead {
      th {
        &:nth-child(2),
        &:nth-child(3) {
          width: 300px;
        }
        &:nth-child(4) {
          min-width: 125px;
        }
        &:nth-child(5) {
          min-width: 125px;
        }
        &:nth-last-child(2) {
          min-width: 216px;
          width: 216px;
        }
        &:last-child {
          min-width: 100px;
          width: 100px;
        }
      }
    }
    .status-wrap {
      .icon {
        margin: 0 5px 0 0;
      }
      .winbox-select {
        display: inline-block;
        width: 150px;
      }
    }
  }
}
</style>
