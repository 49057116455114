<template>
  <div class="escalate-task">
    <div v-if="backendErrors" class="error-block">{{ backendErrors }}</div>
    <div class="admin-selection">
      <winbox-select class="input-gap"
        id="adminSelect"
        form-label="Escalate To"
        v-model="selectedAdmin"
        :options="adminOptions"
        placeholder="Select an Admin"
      />
    </div>
    <winbox-textarea class="textarea-gap"
      v-model="escalationDetails"
      form-label="Escalation Details"
      :rows="6"
      placeholder="Add details about the escalation..."
      :maxlength="4000"
    />
  </div>
</template>

<script>
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxTextarea from "@/components/Atoms/WinboxTextarea";
import { RoleTypes } from "@/constants/RoleTypes";

export default {
  name: "WinboxEscalateTask",
  components: {
    WinboxSelect,
    WinboxTextarea,
  },
  mixins: [RolesMixin],
  props: {
    backendErrors: {
      required: false,
      type: [String, Object],
      default: null,
    },
    defaultAdminId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      selectedAdmin: null,
      escalationDetails: "",
      adminOptions: [],
    };
  },
  computed: {
    users() {
      return this.$store.getters.USERS;
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch("getUserRoles");
      await this.$store.dispatch("getUsers");
      this.setAdminOptions();
      if (this.defaultAdminId) {
        this.selectedAdmin = this.adminOptions.find(admin => admin.id === this.defaultAdminId) || null;
      }
    },
    setAdminOptions() {
      if (this.users && this.users.length) {
        this.adminOptions = this.users
          .filter(user => user.role?.role === RoleTypes.ADMIN)
          .map(admin => ({
            id: admin.id,
            value: admin.id,
            label: admin.first_name + " " + admin.last_name,
          }));
      } else {
        this.adminOptions = [];
      }
    },
  },
  watch: {
    users: {
      immediate: true,
      handler() {
        this.setAdminOptions();
        if (this.defaultAdminId) {
          this.selectedAdmin = this.adminOptions.find(admin => admin.id === this.defaultAdminId) || null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.escalate-task {
  height: 200px;
}

.input-gap {
  margin-bottom: 1rem;
}

.textarea-gap {
  margin-top: 1rem;
}
</style>
