var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-agencies-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "company-agencies" },
            [
              _c(
                "winbox-company-header",
                _vm._g(
                  {
                    attrs: {
                      title: _vm.mainTitle,
                      "entity-secondary-label": "Link Agencies",
                      "entity-secondary-icon": "link",
                      "enable-status-toggle": true
                    },
                    on: {
                      "toggle-status": _vm.updateVisibleStatus,
                      changedSecondary: _vm.openLinkModal
                    }
                  },
                  _vm.showCreateButton ? { changed: _vm.openModal } : {}
                )
              ),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-data-table", {
                      staticClass: "main-data-table",
                      attrs: {
                        data: _vm.visibleData,
                        columns: _vm.columns,
                        options: _vm.tableOptions,
                        slots: _vm.specificSlots
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: _vm.tableProps.name,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      "data-tooltip": data.row.agency.id,
                                      to: {
                                        path: _vm.constructAgencyUrl(
                                          data.row.agency.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.row[_vm.tableProps.name]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.brandName,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      "data-tooltip": data.row.brand_id,
                                      to: {
                                        path: _vm.constructBrandUrl(
                                          data.row.brand_id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          data.row[_vm.tableProps.brandName]
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.services,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      data.row.isAOR
                                        ? data.row[_vm.tableProps.services]
                                            .length > 0
                                          ? "AOR,"
                                          : "AOR"
                                        : ""
                                    ) +
                                    "\n            " +
                                    _vm._s(
                                      data.row[_vm.tableProps.services]
                                        .map(a => _vm.findServiceNameById(a.id))
                                        .join(", ")
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.relationshipProof,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      data.row[_vm.tableProps.relationshipProof]
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.status,
                            fn: function(data) {
                              return [
                                _c("winbox-select", {
                                  attrs: {
                                    value: data.row[_vm.tableProps.status],
                                    options: _vm.possibleStatuses,
                                    searchable: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.onStatusChange(
                                        data.row,
                                        $event
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.options,
                            fn: function(data) {
                              return [
                                _c("winbox-toggle-menu", {
                                  attrs: {
                                    options: _vm.rowOptions,
                                    data: data.row
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.createModal,
          attrs: {
            name: _vm.createModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Create new",
            "save-text": "Create"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _vm.isCreateNewBusinessLoaded && _vm.newBusinessEntity
            ? _c("winbox-create-agency", {
                ref: "createBusinessRef",
                attrs: { data: _vm.newBusinessEntity }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isCreateNewBusinessLoaded
            ? _c("winbox-large-indicator")
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.linkModal,
          staticClass: "winbox-modal-scrollable",
          attrs: {
            name: _vm.linkModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            "save-disabled": !_vm.isSelectionMade,
            width: "1000px",
            height: "600px",
            title: "Link Agencies",
            "save-text": _vm.saveModalText
          },
          on: {
            "on-cancel": _vm.onCancelLinkModal,
            "on-save": _vm.onSaveAgencyModal,
            "enter-press": _vm.onSaveAgencyModal,
            "before-close": _vm.resetLinkNextStep
          }
        },
        [
          _c("winbox-add-relationships-remote", {
            ref: "addAgencyRelationships",
            attrs: {
              url: _vm.getAgencyExceptByCompanyUrl(),
              config: _vm.linkConfig,
              responseFunction: _vm.responseFunction
            },
            on: {
              selectionChange: function($event) {
                _vm.isAgencySelected = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: "brandModal",
          staticClass: "winbox-modal-scrollable",
          attrs: {
            name: "brandModal",
            title: "Select Brands",
            "save-text": "Add Selected",
            "enable-footer-buttons": true,
            "save-disabled": !_vm.isSelectionMade,
            width: "1000px",
            height: "600px"
          },
          on: {
            "on-cancel": _vm.onCancelBrandModal,
            "on-save": _vm.onSaveBrandModal,
            "enter-press": _vm.onSaveBrandModal,
            "before-close": _vm.resetLinkNextStep
          }
        },
        [
          _c("winbox-add-multiple-relationships-remote", {
            ref: "addRelationships",
            attrs: {
              url: _vm.getBrandsByCompanyUrl(),
              config: _vm.linkConfigForBrands,
              responseFunction: _vm.responseFunction
            },
            on: {
              selectionChange: function($event) {
                _vm.isBrandSelected = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.linkEditModal,
          attrs: {
            name: _vm.linkEditModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            width: "800px",
            height: "300px",
            title: "Edit",
            "save-text": "Edit"
          },
          on: {
            "on-cancel": _vm.onEditCancel,
            "on-save": _vm.onEditSave,
            "before-close": _vm.resetLinkNextStep
          }
        },
        [
          _vm.editEntity
            ? _c("winbox-relation-ship-items", {
                attrs: { value: _vm.$v.editEntity },
                on: { change: _vm.onEditEntityChange }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editEntity
            ? _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column is-6" },
                  [
                    _vm.possibleLocations.length >= 1
                      ? _c("winbox-select", {
                          class: _vm.status(_vm.$v.editEntity.location),
                          attrs: {
                            placeholder: "Location",
                            label: "name",
                            options: _vm.possibleLocations,
                            disabled: _vm.possibleLocations.length == 1,
                            emptyText: _vm.emptyText
                          },
                          model: {
                            value: _vm.$v.editEntity.location.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.editEntity.location,
                                "$model",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.editEntity.location.$model"
                          }
                        })
                      : _c("winbox-input", {
                          attrs: {
                            placeholder: "Location",
                            readonly: true,
                            value: _vm.emptyText
                          }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "column is-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button action-button",
                      class: { "is-primary": _vm.$v.editEntity.is_aor.$model },
                      on: { click: _vm.toggleAOR }
                    },
                    [
                      _c("input", {
                        attrs: { type: "checkbox" },
                        domProps: { checked: _vm.$v.editEntity.is_aor.$model }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("AOR")])
                    ]
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }