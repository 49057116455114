import { IndustriesUrl } from "@/constants/IndustriesUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllIndustries() {
  const url = IndustriesUrl.base;
  return getGenericFullResponse(url);
}

export function createIndustry(data) {
  const url = IndustriesUrl.base;
  return postGenericFullResponse(url, data);
}
