export function firstline(value) {
  if (!value) return "";
  const splitted = value.split("\n");
  return splitted[0];
}

export function withoutFirstline(value) {
  if (!value) return "";
  const splitted = value.split("\n");
  splitted.shift();
  return splitted.join("");
}
