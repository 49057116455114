export const BrandsUrl = {
  base: "/brands",
  counts: "counts",
  industries: "industries",
  brand_industries: "brand_industries",
  people: "people",
  brand_speciality_lists: "brand_speciality_lists",
  agencies: "agencies",
  sponsorships: "sponsorships",
  demographics: "demographics",
  businesses: "businesses",
  businesses_contacts: "businesses_contacts",
  brand_people: "brand_people",
  brand_agency_contacts: "brand_agency_contacts",
  bulk_update_brand_agency_contacts: "bulk-update-brand-agency-contacts",
};
