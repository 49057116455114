<template>
  <div class="winbox-data-table-filter-modal">
    <div class="columns is-vcentered">
      <div class="column">
        <button class="button clear-btn" @click="reset">Clear</button>
      </div>
      <div class="column filter-label">
        <strong>Filters</strong>
      </div>
      <div class="column">
        <button class="is-primary button done-btn" @click="doFiltering">
          Done
        </button>
      </div>
    </div>
    <ul>
      <li v-for="(item, key) in filterItems" :key="key"></li>
    </ul>
    <div class="add-new-block">
      <winbox-icon
        class="add-entity-icon"
        :color="Colors.system.lightGray"
        icon="plus"
      ></winbox-icon>
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import { Colors } from "@/Colors";

export default {
  name: "WinboxDataTableFilterModal",
  components: { WinboxIcon },
  props: {},
  data() {
    return {
      filterItems: [],
      Colors: Colors
    };
  },
  methods: {
    doFiltering() {
      this.emitState();
    },
    reset() {
      this.filterItems = [];
      this.emitState();
    },
    emitState() {
      this.$emit("changed", this.filterItems);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

$filter-modal-padding: 8px;

.winbox-data-table-filter-modal {
  background: $winbox-body-color;
  font-size: 12px;
  padding: $filter-modal-padding;

  .button {
    font-size: 12px;
  }
  .filter-label {
    font-size: 12px;
    padding: $filter-modal-padding;
  }
}
.add-new-block {
  background: $winbox-body-grey-bg;
  margin: -$filter-modal-padding;
  padding: $filter-modal-padding;
  text-align: center;
}
.add-entity-icon {
  border-radius: 50%;
  font-size: 11px;
  background: $winbox-primary-active-bg-color;
  cursor: pointer;
}
</style>
