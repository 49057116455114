import { DateFormats } from "@/constants/DateFormats";
import moment from "moment";

export function baseDateFilter(value) {
  if (!value) return "";
  return moment
    .utc(value)
    .local()
    .format(DateFormats.BASE);
}
export function baseDateTimeFilter(value) {
  if (!value) return "";
  return moment
    .utc(value)
    .local()
    .format(DateFormats.BASE_TIME);
}
