var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap-nicknames-block" }, [
    !_vm.entities.length ? _c("div", [_vm._v("No items")]) : _vm._e(),
    _vm._v(" "),
    _vm.entities.length
      ? _c(
          "ul",
          { staticClass: "nicknames-list" },
          _vm._l(_vm.entities, function(item, key) {
            return _c("li", { key: key }, [
              _c(
                "div",
                { staticClass: "columns is-vcentered is-variable is-1" },
                [
                  _c(
                    "div",
                    { staticClass: "column is-11" },
                    [
                      _c("winbox-input", {
                        attrs: {
                          placeholder: "Nickname",
                          disabled: _vm.disabled
                        },
                        on: { input: _vm.onNameChanged },
                        model: {
                          value: item.name,
                          callback: function($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column column-remove-entity" }, [
                    !_vm.disabled
                      ? _c(
                          "button",
                          { staticClass: "button button-delete icon-wrapper" },
                          [
                            _c("winbox-icon", {
                              attrs: {
                                icon: "trash",
                                color: _vm.Colors.system.error,
                                disabled: _vm.disabled
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.removeEntity(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }