<template>
  <winbox-large-indicator />
</template>

<script>
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import vueCookie from "vue-cookie";
import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  name: "LogoutPage",
  components: { WinboxLargeIndicator },
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      this.$store.commit(MutationTypes.SET_USER, null);
      this.$store.commit(MutationTypes.SET_AUTH, {});
      vueCookie.delete("session");
      this.$router.push(WinboxRoutePaths.LOGIN);
    }
  }
};
</script>
