<template src="../Generic/EntityAuditLogMixin.html" />

<script>
import { EntityAuditLogMixin } from "@/components/Pages/Generic/EntityAuditLogMixin";

export default {
  name: "BrandAuditLog",
  mixins: [EntityAuditLogMixin],
  computed: {
    dataset() {
      return this.modifyLogData(this.$store.getters.BRAND_LOGS_BY_ID(this.id));
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.getBrandLogs(this.id);
      this.isLoaded = true;
    }
  }
};
</script>

<style scoped lang="scss" src="../Generic/EntityAuditLogMixin.scss" />
