var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap-revenue-media" }, [
    !_vm.entities.length ? _c("div", [_vm._v("No items")]) : _vm._e(),
    _vm._v(" "),
    _vm.entities.length
      ? _c(
          "ul",
          { staticClass: "revenue-list" },
          _vm._l(_vm.entities, function(item) {
            return _c("li", { key: item.id }, [
              _c(
                "div",
                { staticClass: "columns is-variable is-1 is-vcentered" },
                [
                  _c(
                    "div",
                    { staticClass: "column is-2" },
                    [
                      _c("winbox-select", {
                        attrs: {
                          options: _vm.years,
                          disabled: _vm.disabled,
                          placeholder: "Year"
                        },
                        on: {
                          input: function($event) {
                            return _vm.change(true)
                          }
                        },
                        model: {
                          value: item.year,
                          callback: function($$v) {
                            _vm.$set(item, "year", $$v)
                          },
                          expression: "item.year"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-9" },
                    [
                      _c("winbox-input", {
                        directives: [
                          {
                            name: "currency",
                            rawName: "v-currency",
                            value: {},
                            expression: "{}"
                          }
                        ],
                        attrs: {
                          placeholder: "Revenue Value",
                          disabled: _vm.disabled
                        },
                        on: {
                          input: function($event) {
                            return _vm.change(true)
                          }
                        },
                        model: {
                          value: item.revenues,
                          callback: function($$v) {
                            _vm.$set(
                              item,
                              "revenues",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "item.revenues"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column column-remove-entity" }, [
                    !_vm.disabled
                      ? _c(
                          "button",
                          {
                            staticClass: "button button-delete icon-wrapper",
                            attrs: { disabled: _vm.disabled }
                          },
                          [
                            _c("winbox-icon", {
                              attrs: {
                                icon: "trash",
                                color: _vm.Colors.system.error
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.removeEntity(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }