// Brands
import Brands from "@/components/Pages/Brands/Brands";
import BrandEntry from "@/components/Pages/Brands/BrandEntry";
import BrandProfile from "@/components/Pages/Brands/BrandProfile";
import BrandContacts from "@/components/Pages/Brands/BrandContacts";
import BrandAgencyContacts from "@/components/Pages/Brands/BrandAgencyContacts";
import BrandAgencies from "@/components/Pages/Brands/BrandAgencies";
import BrandSponsorships from "@/components/Pages/Brands/BrandSponsorships";
import BrandHierarchy from "@/components/Pages/Brands/BrandHierarchy";
import BrandNotes from "@/components/Pages/Brands/BrandNotes";
import BrandTags from "@/components/Pages/Brands/BrandTags";
import BrandInternalNotes from "@/components/Pages/Brands/BrandInternalNotes";
import BrandAuditLog from "@/components/Pages/Brands/BrandAuditLog";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";

export default [
  {
    path: WinboxRoutePaths.BRANDS,
    name: "Brands",
    component: Brands,
    meta: {
      breadcrumb: "Brands"
    }
  },
  {
    path: `${WinboxRoutePaths.BRANDS}/:id`,
    component: BrandEntry,
    children: [
      {
        path: WinboxRoutePaths.BRAND_PROFILE,
        component: BrandProfile,
        meta: {
          breadcrumb: "Brand Profile"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_CONTACTS,
        component: BrandContacts,
        meta: {
          breadcrumb: "Brand Contacts"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_AGENCY_CONTACTS,
        component: BrandAgencyContacts,
        meta: {
          breadcrumb: "Agency Contacts"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_AGENCIES,
        component: BrandAgencies,
        meta: {
          breadcrumb: "Agencies"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_SPONSORSHIPS,
        component: BrandSponsorships,
        meta: {
          breadcrumb: "Sponsorships"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_HIERARCHY,
        component: BrandHierarchy,
        meta: {
          breadcrumb: "Hierarchy"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_NOTES,
        component: BrandNotes,
        meta: {
          breadcrumb: "Notes"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_TAGS,
        component: BrandTags,
        meta: {
          breadcrumb: "Tags"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_INTERNAL_NOTES,
        component: BrandInternalNotes,
        meta: {
          breadcrumb: "Internal Notes"
        }
      },
      {
        path: WinboxRoutePaths.BRAND_AUDIT_LOG,
        component: BrandAuditLog,
        meta: {
          breadcrumb: "Audit Log"
        }
      }
    ]
  }
];
