<template>
  <div class="agency-detail-page">
    <winbox-large-indicator v-if="!isLoaded" />
    <page-generic-entry
      v-if="isLoaded && entity"
      :has-assign="true"
      :entity="entity"
      :menu-items="menuItems"
      :menu-sub-items="subItems"
      :has-proofing="true"
      @submit-proof="submitProof"
      @on-assign="onAssignModal"
    >
      <template v-slot:entityName>
        <winbox-title :title="entity.name" />
      </template>
      <template v-if="entity.website" v-slot:baseInfo>
        <a :href="`http://${entity.website}`" target="_blank">
          {{ decodeURIComponent(entity.website) }}
        </a>
      </template>
      <template v-slot:entityLogo>
        <winbox-logo-input :value="logo_url" @input="onLogoChange" />
      </template>
    </page-generic-entry>
    <winbox-modal
      :ref="assignModal"
      :name="assignModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Assign Agency Task"
      save-text="Assign"
      @on-cancel="onAssignCancel"
      @on-save="onAssignSave"
    >
      <winbox-assign-entity-tasks
        ref="assignEntityTasksRef"
        v-if="isAssignNewEntityTasksLoaded"
      />
      <winbox-large-indicator v-if="!isAssignNewEntityTasksLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxLogoInput from "@/components/Molecules/WinboxLogoInput";
import WinboxModal from "@/components/Molecules/WinboxModal";

import PageGenericEntry from "@/components/Pages/PageGenericEntry";

import WinboxAssignEntityTasks from "@/components/Organisms/WinboxAssignEntityTasks";
import { WinboxAssignEntityTasksHelperMixin } from "@/components/Organisms/WinboxAssignEntityTasksHelperMixin";

import { NoteTypes } from "@/constants/NoteTypes";
import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { AnyEntityEntryMixin } from "@/components/Pages/Generic/AnyEntityEntryMixin";
import { SaveAnyEntitySupportMixin } from "@/components/Pages/Generic/SaveAnyEntitySupportMixin";

import * as AgenciesApi from "@/api/agencies/agencies";

export default {
  name: "AgencyEntry",
  mixins: [
    AnyEntityEntryMixin,
    SaveAnyEntitySupportMixin,
    WinboxAssignEntityTasksHelperMixin,
  ],
  components: {
    WinboxTitle,
    WinboxModal,
    WinboxLargeIndicator,
    WinboxLogoInput,
    PageGenericEntry,
    WinboxAssignEntityTasks,
  },
  data() {
    return {
      isLoaded: false,
      prevRoute: null,
      dataUpdate: {},
      saveTimeout: null,
      saveAfterTime: 1500,
    };
  },
  computed: {
    ...mapGetters([
      'BUSINESS_TAGS_BY_ID',
      'BUSINESS_NOTES_BY_ID',
    ]),
    ...mapState([
      'logo_url',
      'currentAgency',
      'entityCounts',
    ]),
    id() {
      return +this.$route.params.id;
    },
    locationsCount() {
      return this.entityCounts.locations_count;
    },
    contactsCounts() {
      return this.entityCounts.contacts_count;
    },
    brandsCounts() {
      return this.entityCounts.entity_brand_count;
    },
    hierarchiesCount() {
      return this.entityCounts.hierarchy_total_count;
    },
    notesCount() {
      return this.entityCounts.note_count;
    },
    tagsCount() {
      return this.entityCounts.tags_count;
    },
    internalNotesCount() {
      return this.entityCounts.internal_note_count;
    },
    entity() {
      return this.currentAgency;
    },
    menuItems() {
      if (!this.isLoaded) {
        return [];
      }

      return [
        {
          link: this.profileUrl,
          title: "Profile"
        },
        {
          link: this.locationsUrl,
          title: "Locations",
          count: this.locationsCount,
        },
        {
          link: this.contactsUrl,
          title: "Contacts",
          count: this.contactsCounts,
        },
        {
          link: this.brandsUrl,
          title: "Brands",
          count: this.brandsCounts
        },
        {
          link: this.hierarchyUrl,
          title: "Hierarchy",
          count: this.hierarchiesCount,
        },
        {
          link: this.notesUrl,
          title: "Notes",
          count: this.notesCount
        },
      ];
    },
    subItems() {
      return [
        {
          link: this.tagsUrl,
          title: "Tags",
          count: this.tagsCount
        },
        {
          link: this.internalNotesUrl,
          title: "Internal Notes",
          count: this.internalNotesCount,
        },
        {
          link: this.auditLogUrl,
          title: "Audit Log",
        },
      ];
    },
    baseUrl() {
      return `${WinboxRoutePaths.AGENCIES}/${this.id}`;
    },
    profileUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_PROFILE}`;
    },
    locationsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_LOCATIONS}`;
    },
    contactsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_CONTACTS}`;
    },
    brandsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_BRANDS}`;
    },
    hierarchyUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_HIERARCHY}`;
    },
    notesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_NOTES}`;
    },
    tagsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_TAGS}`;
    },
    internalNotesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_INTERNAL_NOTES}`;
    },
    auditLogUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.AGENCY_AUDIT_LOG}`;
    },
    markedRows() {
      return [this.id];
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getLogo',
      'getAgency',
      'getServices',
      'getMediaTypes',
      'updateAgency',
      'getAgencyCounts',
      'updateLogo',
    ]),
    async getData() {
      this.isLoaded = false;

      this.getLogo({ owner_id: this.id, owner_type: 'Agency' });
      this.getServices();
      this.getMediaTypes();
      this.getAgencyCounts(this.id);
      await this.getAgency(this.id);

      this.isLoaded = true;
    },
    onLogoChange(logo_url) {
      this.updateLogo({
        logo_url: logo_url,
        owner_type: 'Agency',
        owner_id: this.id,
      });
    },
    saveEntity() {
      if (!Object.keys(this.dataUpdate).length) {
        return;
      }
      const payload = { ...this.dataUpdate };
      this.updateAgency({ id: this.id, payload });
      this.afterSaveEntity();
    },
    submitProof() {
      AgenciesApi.submitAgenciesProof(this.id)
      .then(({ data: { proofed_by_name, proofed_at }}) => {
        this.$set(this.entity, 'proofed_by_name', proofed_by_name);
        this.$set(this.entity, 'proofed_at', proofed_at);
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

::v-deep {
  .highlight {
    color: $winbox-primary-active-bg-color;
  }
}
</style>
