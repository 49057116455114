<template>
  <div class="brand-agency-contacts-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="brand-agency-contacts">
      <winbox-company-header
        entity-secondary-label="Link Agency Contacts"
        entity-secondary-icon="link"
        :title="mainTitle"
        :enable-status-toggle="true"
        @toggle-status="updateVisibleStatus"
        @changedSecondary="openLinkModal"
      />
      <div class="columns">
        <div class="column">
          <winbox-data-table
            class="main-data-table"
            :data="visibleData"
            :columns="columns"
            :options="tableOptions"
            :enable-custom-filtering="false"
            :slots="specificSlots"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :to="{ path: constructContactProfileUrl(data.row.person_id) }"
              >
                {{ data.row[tableProps.name] }}
              </router-link>
            </template>
            <template :slot="tableProps.agency" slot-scope="data">
              <router-link
                :to="{ path: constructAgencyUrl(data.row.agency_id) }"
              >
                {{ data.row[tableProps.agency] }}
              </router-link>
            </template>
            <template :slot="tableProps.email" slot-scope="data">
              <a :href="constructEmailLink(data.row[tableProps.email])">
                {{ data.row[tableProps.email] }}
              </a>
            </template>
            <template :slot="tableProps.options" slot-scope="data">
              <button
                v-if="data.row.id != -1"
                class="button button-remove"
                @click="deleteRelationship(data.row)"
              >
                <winbox-icon icon="trash" :color="Colors.system.error" />
              </button>
            </template>
          </winbox-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="linkModal"
      :name="linkModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      class="winbox-modal-scrollable"
      width="1000px"
      height="600px"
      title="Link Agency Contacts"
      :save-text="saveModalText"
      @on-cancel="onCancelLinkModal"
      @on-save="onSaveLinkModal"
      @enter-press="onSaveLinkModal"
      @before-close="resetLinkNextStep"
    >
      <winbox-add-brand-contact
        v-if="businessProfileContacts"
        ref="addBrandContact"
        :data="datasetRelationshipsMain"
        :config="linkConfig"
        :errorMessage="message"
      />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import { EntityContactsMixin } from "@/components/Pages/Generic/EntityContactsMixin";
import { WinboxLinkContactHelperMixin } from "@/components/Organisms/WinboxLinkContactHelperMixin";

import { Colors } from "@/Colors";

import * as Utils from "@/utils";
import * as BusinessesApi from "@/api/businesses/businesses";

import cloneDeep from "clone-deep";

const tableProps = {
  name: "name",
  agency: "agency",
  title: "title",
  email: "email",
  options: "__options",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "BrandAgencyContacts",
  components: {
    WinboxLargeIndicator,
    WinboxDataTable,
    WinboxCompanyHeader,
    WinboxModal,
    WinboxIcon,
  },
  mixins: [EntityContactsMixin, WinboxLinkContactHelperMixin],
  data() {
    return {
      oldData: null,
      Colors: Colors,
      isLoaded: false,
      visibileStatus: 'Active',
      businessEntity: {},
      agenciesData: [],
      columns: [...mainTableColumns],
      tableProps: tableProps,
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.agency]: "Agency",
          [tableProps.title]: "Title",
          [tableProps.email]: "Email",
          [tableProps.options]: "",
        },
        sortable: [
          tableProps.name,
          tableProps.agency,
          tableProps.title,
          tableProps.email,
        ],
        filterable: [
          tableProps.name,
          tableProps.agency,
          tableProps.title,
          tableProps.email,
        ],
      },
      specificSlots: [
        tableProps.name,
        tableProps.agency,
        tableProps.email,
        tableProps.options,
      ],
      linkConfig: {
        tableProps: {
          name: "name",
          title: "title",
          email: "email",
          linked: "linked",
        },
        specificSlots: ["linked"],
        tableOptions: {
          headings: {
            name: "Name",
            email: "Email",
            linked: "",
          },
          sortable: [],
          filterable: ["name", "title"],
        },
        multiselect: true,
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapState([
      'brandAgencyContacts',
      'businessProfileContacts',
      'brandBusinessRelationships',
    ]),
    id() {
      return +this.$route.params.id;
    },
    message() {
      return 'In order to link Agency Contacts to a Brand, there needs to be a linked Agency. Please link an Agency and try again.';
    },
    mainTitle() {
      return `Agency Contacts (${this.visibleData.length})`;
    },
    constructTableItem(item, agency, id) {
      return {
        id: id,
        [tableProps.name]: item.full_name,
        [tableProps.agency]: agency.name,
        [tableProps.title]: item.title,
        [tableProps.email]: item.email,
        agency_id: agency.id,
        person_id: item.id,
      };
    },
    dataset() {
      return this.brandAgencyContacts.map((brandAgencyContact) => {
        return {
          id: brandAgencyContact.id,
          [tableProps.name]: brandAgencyContact.person.full_name,
          [tableProps.agency]: brandAgencyContact.agency.name,
          [tableProps.title]: brandAgencyContact.person.title,
          [tableProps.email]: brandAgencyContact.person.email,
          agency_id: brandAgencyContact.agency.id,
          person_id: brandAgencyContact.person.id,
          published: brandAgencyContact.person.published,
        };
      });
    },
    possibleLocations() {
      return this.locationsData.map((item) => {
        return {
          id: item.id,
          label: item.city,
          value: item.id,
        };
      });
    },
    datasetRelationshipsMain() {
      // let result = [];
      // if(this.businessProfileContacts.length > 0) {
      //   let unselectedContacts = this.businessProfileContacts.filter((contact) => {
      //     return (
      //       this.dataset.filter((item) => item.person_id === contact.id)
      //         .length === 0
      //     );
      //   });
      //   unselectedContacts.map((contact) => {
      //     contact["name"] = `${contact.fname} ${contact.lname}`;
      //     contact.linked = false;
      //     return contact;
      //   });
      //   result.push(...unselectedContacts);
      // }

      // return result;

      return this.businessProfileContacts
      .filter((businessProfileContact) => {
        return !this.dataset.some(dataset => {
          return businessProfileContact.id === dataset.person_id;
        });
      })
      .map((businessProfileContact) => {
        return {
          ...businessProfileContact,
          name: `${businessProfileContact.fname} ${businessProfileContact.lname}`,
          linked: false,
        };
      });
    },
    activeData() {
      return this.dataset.filter((data) => data.published);
    },
    inactiveData() {
      return this.dataset.filter((data) => !data.published);
    },
    visibleData() {
      if(this.visibileStatus === 'Active') {
        return this.activeData;
      }
      else {
        return this.inactiveData;
      }
    },
  },
  methods: {
     ...mapActions([
      'getBusinessContactsByBrand',
      'getBrandAgenciesContacts',
      'assignBrandAgenciesContacts',
      'unassignBrandAgenciesContacts',
    ]),
    async getData() {
      this.isLoaded = false;

      await Promise.all([
        this.getBrandAgenciesContacts(this.id),
        this.getBusinessContactsByBrand(this.id),
      ]);

      this.isLoaded = true;
    },
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    constructEmailLink(email) {
      return `mailto:${email}`;
    },
    getBusinessLocations(id) {
      return BusinessesApi.getBusinessLocations(id).then(({ data }) => {
        this.locationsData = data;
      });
    },
    constructContactProfileUrl(id) {
      return Utils.getContactProfileUrl(id);
    },
    constructAgencyUrl(id) {
      return Utils.getAgencyProfileUrl(id);
    },
    saveContactRelationship(items) {
      const payload = items.map((item) => {
        return {
          person_id: item.id,
          agency_id: item.agency_id,
        };
      });

      return this.assignBrandAgenciesContacts({
        id: this.id,
        payload: { data: payload },
      });
    },
    deleteRelationship(item) {
      return this.unassignBrandAgenciesContacts({
        id: item.person_id,
        brand_id: this.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

.brand-agency-contacts {
  padding-top: 0.5rem;
}

.main-data-table {
  ::v-deep {
    font-size: 13px;
    th {
      &:first-child {
        min-width: 150px;
      }
    }
  }
}
</style>
