<template>
  <div class="search-main">
    <div class="columns" :key="statusKey">
      <div class="column">
        <div>Entity</div>
        <router-link
          v-if="currentTask && possibleTasks.length > 0"
          :data-tooltip="currentTask.entityable_id"
          :to="{ path: constructEntityUrl(currentTask.entityable_id, currentTask.entityable_type) }"
        >
          {{ addElipsis(currentTask.entityable_name) }}
        </router-link>
        <div v-else>All your current tasks are complete.</div>
      </div>
      <div class="each-column">
        <div>Task</div>
        <router-link
          v-if="currentTask && possibleTasks.length > 0"
          :data-tooltip="currentTask.task.name"
          :to="{ path: constructEntityUrl(currentTask.entityable_id, currentTask.entityable_type) }"
        >
          {{ currentTask.task.name }}
        </router-link>
      </div>
      <div class="each-column detail-column">
        <div>Details</div>
        <router-link
          v-if="currentTask && possibleTasks.length > 0"
          :data-tooltip="currentTask.details"
          :to="{ path: constructEntityUrl(currentTask.entityable_id, currentTask.entityable_type) }"
        >
          {{ addElipsis(currentTask.details) }}
        </router-link>
      </div>
      <div class="each-column status-column" :style="{ 'paddingTop': 20 + 'px'}">
        <winbox-select
          v-if="currentTask && possibleTasks.length > 0"
          :style="styleObj"
          v-model="currentTask.status"
          :options="possibleStatuses"
          @input="onStatusChange()"
        />
        <winbox-select
          v-else
          :style="styleObj"
          :options="possibleStatuses"
          :disabled="true"
        />
      </div>
    </div>
    <winbox-modal
      ref="escalateTaskMainModal"
      name="escalateTaskMainModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Escalate Task"
      save-text="Escalate"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <winbox-escalate-task
        ref="escalateTaskMainRef"
        :backend-errors="backendErrors"
        :default-admin-id="defaultAdminId"
      />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxEscalateTask from "@/components/Molecules/WinboxEscalateTask";
import * as Utils from "@/utils";

export default {
  name: "WinboxSearchMain",
  components: {
    WinboxSelect,
    WinboxModal,
    WinboxEscalateTask,
  },
  data() {
    return {
      nextTask: null,
      escalateTaskMainModalRef: "escalateTaskMainModal",
      escalateTaskMainRef: "escalateTaskMainRef",
      backendErrors: null,
      defaultAdminId: null,
      statusKey: 0,
      originalStatus: null,

    };
  },
  beforeMount() {
    this.getUserTasks().then(() => {
      if (this.possibleTasks.length > 0) {
        this.nextTask = this.possibleTasks[0];
        this.originalStatus = this.nextTask?.status;
      }
    });
  },
  watch: {
    userTasks(newValue, oldValue) {
      this.nextTask = this.possibleTasks[0];
      this.originalStatus = this.nextTask?.status;
    },
  },
  computed: {
    ...mapState([
      'userTasks',
      'user'
    ]),
    styleObj() {
      return {
        backgroundColor: 'white',
      };
    },
    currentTask() {
      return this.nextTask;
    },
    possibleStatuses() {
      return [
        { label: 'In Progress', value: 0 },
        { label: 'Completed', value: 1 },
        { label: 'Unstarted', value: 2 },
        { label: 'Escalate', value: 3 },
      ];
    },
    possibleInProgressTasks() {
      return this.userTasks.filter(task => task.status === this.possibleStatuses[0].label);
    },
    possibleUnstartedTasks() {
      return this.userTasks.filter(task => task.status === this.possibleStatuses[2].label);
    },
    possibleEscalatedTasks() {
      return this.userTasks.filter(task => task.status === this.possibleStatuses[3].label);
    },
    possibleTasks() {
      return [...this.possibleInProgressTasks, ...this.possibleUnstartedTasks];
    },
  },
  methods: {
    ...mapActions([
      'getUserTasks',
      'updateUserTask',
      'updateUser',
    ]),
    onStatusChange() {
      if (this.currentTask.status.label === 'Escalate') {
        const user = this.user;
        this.defaultAdminId = user ? user.escalate_user_id : null;
        this.$refs[this.escalateTaskMainModalRef].show();
      } else {
        const item = { id: this.currentTask.id };
        const payload = { status: this.currentTask.status.label };
        this.updateUserTask({ id: item.id, payload });
      }
    },
    onCancel() {
      if (this.currentTask) {
        const item = { id: this.currentTask.id };
        const payload = { status: this.originalStatus };

        this.updateUserTask({ id: item.id, payload })
        .then(() => {
          this.$refs[this.escalateTaskMainModalRef].hide();
          this.statusKey += 1;
        })
        .catch(error => {
          console.error('Error reverting user task:', error);
        });
      }
    },
    onSave() {
      const selectedAdmin = this.$refs[this.escalateTaskMainRef].selectedAdmin;
      const escalationDetails = this.$refs[this.escalateTaskMainRef].escalationDetails;

      if (selectedAdmin) {
        this.reassignTaskToAdmin(selectedAdmin, escalationDetails);
      } else {
        this.backendErrors = "Please select an admin.";
      }
    },
    reassignTaskToAdmin(selectedAdmin, details) {
      const item = { id: this.currentTask.id };
      const payload = {
        status: "Escalated",
        user_id: selectedAdmin.id,
        details: details,
      };
      this.updateUserTask({ id: item.id, payload })
      .then(() => {
        return this.updateUser({ id: this.user.id, payload: { escalate_user_id: selectedAdmin.id } });
      })
      .then(() => {
        this.$refs[this.escalateTaskMainModalRef].hide();
        this.statusKey += 1;
      })
      .catch((error) => {
        this.backendErrors = error.response.data.message;
      });
    },
    constructEntityUrl(entityId, entityType) {
      switch(entityType) {
        case 'Company':
          return Utils.getCompanyProfileUrl(entityId);
        case 'Agency':
          return Utils.getAgencyProfileUrl(entityId);
        case 'Brand':
          return Utils.getBrandProfileUrl(entityId);
        case 'Person':
          return Utils.getContactProfileUrl(entityId);
      }
    },
    addElipsis(text) {
      if (!text) return "";  // return an empty string if text is null or undefined
      if(text.length > 85) {
        text = text.substring(0, 85) + '...';
      }

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.columns {
  width: 100%;
}

.each-column {
  display: block;
  flex-basis: 20%;
  padding: 0.75rem;
}

.detail-column {
  flex-basis: 40%;
}

.status-column {
  flex-basis: 15%;
}
</style>