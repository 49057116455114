<template src="../Generic/EntityLocationsDetail.html" />

<script>
import { mapState } from "vuex";
import { EditProfileMixin } from "@/components/Pages/Generic/EditProfileMixin";
import { WinboxCreateContactHelperMixin } from "@/components/Organisms/WinboxCreateContactHelperMixin";
import { EntityLocationsDetailMixin } from "@/components/Pages/Generic/EntityLocationsDetail";
import * as Utils from "@/utils";

export default {
  name: "AgencyLocationsDetail",
  mixins: [EditProfileMixin, EntityLocationsDetailMixin, WinboxCreateContactHelperMixin],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState([
      'currentAgency',
    ]),
    businessEntity() {
      return {
        ...this.currentAgency,
        location: {
          label: this.locationData.city,
          value: this.locationId
        }
      };
    }
  },
  beforeMount() {
    this.getData();
    this.$store.dispatch("getStates");
  },
  methods: {
    afterRemoveEntity() {
      this.$router.push(Utils.getAgencyLocationsUrl(this.id));
    }
  }
};
</script>

<style scoped lang="scss" src="../Generic/EntityLocationsDetail.scss" />
