var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-tags-wrap" },
    [
      _c(
        "winbox-card",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v("\n      Tags\n    ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _vm.isLoaded
              ? _c("div", { staticClass: "contact-tags" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("winbox-speciality-tags", {
                          attrs: {
                            "possible-tags": _vm.possibleTags,
                            "entity-tags": _vm.selectedTags,
                            "prop-selected-parent-id": "tag_id",
                            "placeholder-selected": "Selected Tags"
                          },
                          on: {
                            changed: function($event) {
                              return _vm.onTagsChanged($event, "name")
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }