<template>
  <div class="winbox-hierarchy-entity">
    <winbox-card>
      <template v-slot:header>
        {{ title }}
      </template>
      <template v-slot:headerIcon>
        <winbox-icon
          v-if="canAddEntity"
          icon="plus-circle"
          :color="Colors.system.white"
          @click.native="addNewTag"
        />
      </template>
      <div class="content">
        <p class="no-data" v-if="!selected.length">No items</p>
        <ul class="hierarchy-item-list">
          <li
            class="columns is-gapless hierarchy-item"
            v-for="item in selected"
            :key="item[propId]"
            :class="{
              _inactive: item.status === BusinessStatuses.inactive.value,
            }"
          >
            <div
              v-if="item.status === BusinessStatuses.inactive.value"
              class="column is-narrow"
            >
              <div class="item-indicator"></div>
            </div>
            <div class="column">
              <div
                class="hierarchy-item-columns columns is-gapless is-vcentered"
              >
                <div class="column is-9">
                  <router-link
                    :data-tooltip="item[propId]"
                    :to="{ path: constructProfileUrl(item) }"
                  >
                    <span>{{ item[propLabel] }}</span>
                  </router-link>
                  <div class="new-tag-input-wrap" v-if="!item.id">
                    <div v-if="!item.type" class="item-type">
                      <span class="please-select">Please select type:</span>
                      <ul class="columns is-variable is-1 business-types-list">
                        <li
                          class="column is-narrow"
                          v-for="(businessItem, key) in possibleBusinessTypes"
                          :key="key"
                        >
                          <a
                            href="#"
                            @click.prevent="item.type = businessItem"
                            >{{ businessItem }}</a
                          >
                        </li>
                      </ul>
                    </div>
                    <winbox-remote-select
                      v-if="item.type === 'company' || item.type === 'agency'"
                      :value="item"
                      :responseFunction="responseHierarchy"
                      :url="getHierarchyOrganizationsSearchUrl(item.type)"
                      @input="item._entity = $event"
                      :minSearchLength="3"
                      :emptyText="'Please search an entity.'"
                    />
                    <winbox-remote-select
                      v-if="item.type === 'brand'"
                      :value="item"
                      :responseFunction="responseHierarchy"
                      :url="getHierarchyBrandsSearchUrl()"
                      @input="item._entity = $event"
                      :minSearchLength="3"
                      :emptyText="'Please search an entity.'"
                    />
                  </div>
                </div>
                <div class="column is-3 action-right">
                  <button
                    v-if="!item.id && item.type"
                    class="button button-create"
                    @click="createNewTag(item)"
                  >
                    <winbox-icon
                      icon="check"
                      :color="Colors.system.primaryLight"
                    />
                  </button>
                  <button
                    v-if="serviceName != 'sibling'"
                    class="button button-remove"
                    @click="removeTag(item)"
                  >
                    <winbox-icon icon="trash" :color="Colors.system.error" />
                  </button>
                  <button v-else class="button default" disabled></button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </winbox-card>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxRemoteSelect from "@/components/Atoms/WinboxRemoteSelect";

import { BusinessTypes } from "@/constants/BusinessTypes";
import { BusinessStatuses } from "@/constants/BusinessStatuses";
import { Colors } from "@/Colors";
import * as Utils from "@/utils";

export default {
  name: "WinboxHierarchyEntity",
  components: {
    WinboxCard,
    WinboxIcon,
    WinboxRemoteSelect,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    entitySelected: {
      required: false,
      default: () => [],
      type: Array,
    },
    propLabel: {
      required: false,
      type: String,
      default: "name",
    },
    propId: {
      required: false,
      type: String,
      default: "child_id",
    },
    serviceName: {
      required: true,
      type: String,
    },
    entityType: {
      required: true,
      type: String,
    },
    canAddEntity: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Colors: Colors,
      BusinessStatuses,
      selected: [...this.entitySelected],
    };
  },
  watch: {
    entitySelected: {
      handler() {
        this.selected = [...this.entitySelected];
      },
    },
  },
  computed: {
    possibleBusinessTypes() {
      if (this.entityType == 'company' && this.serviceName == 'child') {
        return [BusinessTypes.company, BusinessTypes.agency, BusinessTypes.brand];
      }
      else {
        return [BusinessTypes.company, BusinessTypes.agency];
      }
    },
  },
  methods: {
    addNewTag() {
      // Disallow multiple creation
      if (this.selected.find((item) => !item.id)) {
        return;
      }
      this.selected.unshift({
        id: null,
        type: "",
        label: "",
        name: "",
        _entity: null,
      });
    },
    createNewTag(item) {
      if (item._entity) {
        this.emitState("newTags", item._entity);
      }
    },
    getHierarchyOrganizationsSearchUrl(type) {
      return Utils.getHierarchyOrganizationsSearchUrl(type);
    },
    getHierarchyBrandsSearchUrl() {
      return Utils.getHierarchyBrandsSearchUrl();
    },
    emitState(key, data) {
      this.$emit("changed", {
        [key]: data,
        serviceName: this.serviceName,
      });
    },
    removeTag(item) {
      let shouldBeConfirmed = item.id ? confirm("Are you sure?") : true;
      if (!item.id) {
        this.selected = this.selected.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      }
      if (shouldBeConfirmed) {
        this.$emit("removed", {
          data: item,
          serviceName: this.serviceName,
        });
      }
    },
    constructProfileUrl(item) {
      const serviceName = this.serviceName == 'sibling' ? 'child' : this.serviceName;
      const type = item[`${serviceName}_type`];

      switch (type?.toLowerCase()) {
        case BusinessTypes.company:
          return Utils.getCompanyProfileUrl(item[`${serviceName}_id`]);
        case BusinessTypes.agency:
          return Utils.getAgencyProfileUrl(item[`${serviceName}_id`]);
        case BusinessTypes.brand:
          return Utils.getBrandProfileUrl(item[`${serviceName}_id`]);
      }
    },
    responseHierarchy(data) {
      return data.map((item) => {
        return {
          id: item.id,
          label: item.name,
          value: item.id,
          type: item.type,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-hierarchy-entity {
  ::v-deep {
    .card-header {
      background: $winbox-primary-active-bg-color;
    }
    .card-content {
      padding: 0;
      min-height: 200px;
    }
  }
}
.content {
  font-size: $winbox-font-size-less-than-main;
}
.please-select {
  font-style: italic;
}
.new-tag-input-wrap {
  margin: 0;
}
.hierarchy-item-list {
  .hierarchy-item {
    border-bottom: 1px solid $winbox-tag-border-color;
    margin: 0;

    &._inactive {
      color: $winbox-body-grey-bg;
      margin: 0;
    }

    @include no-user-select;
    @include button-delete-universal-hover;
    @include button-create-universal-hover;

    &:last-child {
      border-bottom: 0;
    }

    .item-indicator {
      width: 7px;
      height: 100%;
      background: $winbox-negative;
    }
  }
  .hierarchy-item-columns {
    padding: 7px;
  }
}
.action-right {
  text-align: right;
}
.button-remove,
.button-create {
  border: transparent;
  border-radius: 50%;
  font-size: 14px;

  @include button-delete-universal;
}
.button-remove {
  background: $winbox-icon-negative-background;
}
.no-data {
  padding: 7px;
}

.default {
  border-color: white;
  cursor: default;
}
</style>
