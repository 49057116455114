<template>
  <div class="create-user">
    <div class="columns is-variable is-1">
      <div class="column is-6">
        <winbox-input
          placeholder="First Name"
          v-model.trim="$v.entity.first_name.$model"
          :class="status($v.entity.first_name)"
        />
      </div>
      <div class="column is-6">
        <winbox-input
          placeholder="Last Name"
          v-model.trim="$v.entity.last_name.$model"
          :class="status($v.entity.last_name)"
        />
      </div>
    </div>

    <div class="columns is-variable is-1">
      <div class="column is-6">
        <winbox-input
          placeholder="Email"
          v-model.trim="$v.entity.email.$model"
          :class="status($v.entity.email)"
        />
      </div>
      <div class="column is-6">
        <winbox-select
          :value="entity.role"
          :options="possibleRoles"
          placeholder="Role"
          :class="status($v.entity.role)"
          @input="$v.entity.role.$model = $event"
        />
      </div>
    </div>

    <div class="columns is-variable is-1">
      <div class="column is-6">
        <winbox-input
          :readonly="true"
          placeholder="Status"
          v-model.trim="entity.status"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import { required, email } from "vuelidate/lib/validators";
import * as Utils from "@/utils";

export default {
  name: "WinboxCreateUser",
  components: {
    WinboxInput,
    WinboxSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Colors: Colors,
      entity: {}
    };
  },
  validations: {
    entity: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        required,
        email
      },
      role: {
        required
      },
      status: {
        required
      }
    }
  },
  computed: {
    possibleRoles() {
      return this.$store.getters.USER_ROLES.map(item => {
        return {
          id: item.id,
          label: item.role,
          value: item.id
        };
      });
    }
  },
  beforeMount() {
    this.entity = cloneDeep(this.data);
  },
  methods: {
    status(validation) {
      return Utils.validationStatus(validation);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-user {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
</style>
