import { render, staticRenderFns } from "./BrandContacts.vue?vue&type=template&id=a69befd4&scoped=true"
import script from "./BrandContacts.vue?vue&type=script&lang=js"
export * from "./BrandContacts.vue?vue&type=script&lang=js"
import style0 from "./BrandContacts.vue?vue&type=style&index=0&id=a69befd4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a69befd4",
  null
  
)

export default component.exports