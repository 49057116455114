import { UserTasksUrl } from "@/constants/UserTasksUrl";
import { getGeneric, postGeneric, putGeneric, deleteGenericFullResponse } from "@/api/_generic/_generic";

export function getUserTasks() {
  const url = UserTasksUrl.base;
  return getGeneric(url);
}

export function createUserTask(data) {
  const url = UserTasksUrl.base;
  return postGeneric(url, data);
}

export function updateUserTask(id, data) {
  const url = `${UserTasksUrl.base}/${id}`;
  return putGeneric(url, data);
}

export function reassignTasks(data) {
  const url = `${UserTasksUrl.base}/reassign`;
  return postGeneric(url, data);
}

export function deleteUserTask(id) {
  const url = `${UserTasksUrl.base}/${id}`;
  return deleteGenericFullResponse(url);
}
