var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-add-brand-contact" }, [
    !_vm.showNextStep
      ? _c(
          "div",
          { staticClass: "step-one" },
          [
            _c("winbox-data-table", {
              staticClass: "winbox-add-relationships-table",
              attrs: {
                data: _vm.dataset,
                columns: _vm.columns,
                options: _vm.tableOptions,
                slots: _vm.specificSlots,
                theme: "with-borders-theme"
              },
              on: { "row-click": _vm.onRowClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: "linked",
                    fn: function(data) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "button select-button",
                            class: { active: data.row.linked }
                          },
                          [
                            _c("winbox-icon", {
                              attrs: {
                                icon: "plus",
                                color: data.row.linked
                                  ? _vm.Colors.system.lightGray
                                  : _vm.Colors.system.primaryLight
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3954356339
              )
            }),
            _vm._v(" "),
            !_vm.data.length
              ? _c("div", { staticClass: "pl px warning" }, [
                  _vm._v(_vm._s(_vm.errorMessage))
                ])
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showNextStep && _vm.entity
      ? _c(
          "div",
          { staticClass: "step-two" },
          [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-input", {
                    class: _vm.status(_vm.$v.entity.name),
                    attrs: { placeholder: "Name", readonly: true },
                    model: {
                      value: _vm.$v.entity.name.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.entity.name,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.entity.name.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("winbox-relation-ship-items", {
              attrs: { value: _vm.$v.entity },
              on: { change: _vm.onChangeEntityFields }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column is-6" },
                [
                  _c("winbox-select", {
                    class: _vm.status(_vm.$v.entity.status),
                    attrs: {
                      value: _vm.entity.status,
                      options: _vm.possibleStatuses,
                      searchable: false,
                      placeholder: "Status"
                    },
                    on: {
                      input: function($event) {
                        _vm.$v.entity.status.$model = $event
                      }
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }