import { SyncUrl } from "@/constants/SyncUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse,
  putGenericFullResponse,
  deleteGenericFullResponse
} from "@/api/_generic/_generic";

export function sync(data) {
  const url = `/${SyncUrl.base}`;
  return postGenericFullResponse(url, data);
}

export function getWinmo(data) {
  const url = `/${SyncUrl.base}`;
  return getGenericFullResponse(url, data);
}
