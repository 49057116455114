import { TagsUrl } from "@/constants/TagsUrl";
import {
  getGenericFullResponse,
  postGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllTags() {
  const url = `${TagsUrl.base}`;
  return getGenericFullResponse(url);
}

export function createTag(data) {
  const url = `${TagsUrl.base}`;
  return postGenericFullResponse(url, data);
}
