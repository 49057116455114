<template>
  <div class="login-page">
    <winbox-title class="main-title" title="Winbox Login" />
    <winbox-card v-if="!isForgotPasswordVisible">
      <form @submit.prevent="onSubmit">
        <p v-if="backendErrors" class="error-block">
          {{ backendErrors }}
        </p>
        <div class="columns is-variable is-1">
          <div class="column">
            <winbox-input
              placeholder="user@example.com"
              v-model.trim="$v.entity.login.$model"
              :class="status($v.entity.login)"
            />
          </div>
        </div>
        <div class="columns is-variable is-1">
          <div class="column">
            <winbox-input
              placeholder="password"
              type="password"
              v-model.trim="$v.entity.password.$model"
              :class="status($v.entity.password)"
            />
          </div>
        </div>
        <div class="forgot-password-block">
          <a href="#" @click.prevent="showForgot">Forgot Password?</a>
        </div>
        <div class="columns is-variable is-1">
          <div class="column">
            <button :disabled="!isValidForm" class="button is-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </winbox-card>

    <winbox-card v-if="isForgotPasswordVisible">
      <div class="forgot-box success" v-if="emailResetMessage">
        {{ emailResetMessage }}
      </div>
      <div class="forgot-box error" v-if="emailResetErrorMessage">
        {{ emailResetErrorMessage }}
      </div>
      <div v-if="!emailResetMessage" class="reset-password-wrap">
        <div class="columns is-variable is-1">
          <div class="column">
            <winbox-input
              placeholder="user@example.com"
              v-model.trim="$v.forgotEntity.login.$model"
              :class="status($v.forgotEntity.login)"
            />
          </div>
        </div>
        <div class="columns is-variable is-1">
          <div class="column">
            <button
              v-if="!isForgotLoaderVisible"
              :disabled="!isValidForgotForm"
              class="button is-primary"
              @click="onForgotSubmit"
            >
              Send me a link to reset password
            </button>
            <winbox-large-indicator v-if="isForgotLoaderVisible" />
          </div>
        </div>
      </div>

      <div class="back-to-login">
        <a href="#" @click.prevent="backToLogin">Back to Login</a>
      </div>
    </winbox-card>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxInput from "@/components/Atoms/WinboxInput";

import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import { required, email } from "vuelidate/lib/validators";
import * as Utils from "@/utils";
import * as AuthApi from "@/api/auth/auth";

export default {
  name: "LoginPage",
  components: {
    WinboxTitle,
    WinboxCard,
    WinboxInput,
    WinboxLargeIndicator
  },
  validations: {
    entity: {
      login: {
        required,
        email
      },
      password: {
        required
      }
    },
    forgotEntity: {
      login: {
        required,
        email
      }
    }
  },
  data() {
    return {
      entity: {
        login: "",
        password: ""
      },
      forgotEntity: {
        login: ""
      },
      isForgotPasswordVisible: false,
      isForgotLoaderVisible: false,
      emailResetMessage: null,
      emailResetErrorMessage: null,
      backendErrors: ""
    };
  },
  computed: {
    isValidForm() {
      return !this.$v.entity.$error;
    },
    isValidForgotForm() {
      return !this.$v.forgotEntity.$error;
    }
  },
  methods: {
    status(validation) {
      if(validation.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    },
    onSubmit() {
      this.$v.$touch();
      this.backendErrors = "";

      if (!this.$v.entity.$error) {
        const payload = {
          email: this.entity.login,
          password: this.entity.password
        };

        this.$store
          .dispatch("signIn", payload)
          .then(() => {
            this.$router.push({ name: "FrontDashboard" });
          })
          .catch(e => {
            this.afterLoginFail(e.response);
          });
      }
    },
    afterLoginFail(response) {
      if (response && response.data) {
        const errors = response.data.errors;
        if (errors && errors.length) {
          this.backendErrors = this.constructErrorMessage(errors);
        }
      }
    },
    constructErrorMessage(errors) {
      return Object.values(errors).join(", ");
    },
    clearErrors() {
      this.emailResetErrorMessage = null;
      this.emailResetMessage = null;
      this.backendErrors = "";
    },
    backToLogin() {
      this.isForgotPasswordVisible = false;
      this.clearErrors();
      this.$v.$reset();
    },
    onForgotSubmit() {
      this.clearErrors();
      this.$v.$touch();

      if (!this.$v.forgotEntity.$error) {
        this.isForgotLoaderVisible = true;
        const payload = {
          email: this.forgotEntity.login,
          // @TODO: Update to prod host
          redirect_url: AuthApi.changePasswordRedirectUrl
        };

        AuthApi.resetPassword(payload)
          .then(response => {
            if (response && response.message) {
              this.emailResetMessage = response.message;
            }
            this.isForgotLoaderVisible = false;
          })
          .catch(e => {
            const data = e.response.data;
            this.isForgotLoaderVisible = false;

            if (data && data.errors) {
              this.emailResetErrorMessage = this.constructErrorMessage(
                data.errors
              );
            }
          });
      }
    },
    showForgot() {
      this.isForgotPasswordVisible = true;
      this.$v.$reset();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.error-block {
  color: $winbox-error;
  margin: 0 0 1rem;
}
.login-page {
  width: 600px;
  margin: 0 auto;
}
.forgot-password-block {
  margin: 0 0 1rem;
}
.main-title {
  margin: 0 0 1rem;
}
.back-to-login {
  margin-top: 24px;
}
.forgot-box {
  margin: 0 0 1rem;
  &.success {
    color: $winbox-success;
  }
  &.error {
    color: $winbox-error;
  }
}
</style>
