export const MainIcons = {
  dashboard: "th-large",
  companies: "building",
  brands: "tshirt",
  agencies: "star",
  contacts: "user-friends",
  dataImport: "chart-line",
  settings: "cog",
  logout: "sign-out-alt",
};
