var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-link-entities-remote" },
    [
      _c(
        "winbox-server-data-table",
        {
          ref: "linkTableRef",
          staticClass: "winbox-link-entities-table",
          attrs: {
            url: _vm.url,
            responseFunction: _vm.enhanceData,
            columns: _vm.columns,
            options: _vm.tableOptions,
            slots: _vm.specificSlots,
            perPage: 10,
            theme: "with-borders-theme"
          },
          on: { "row-click": _vm.onRowClick },
          scopedSlots: _vm._u(
            [
              {
                key: _vm.tableProps.linked,
                fn: function(data) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "button select-button",
                        class: { active: _vm.getLinked(data.row.id) },
                        on: {
                          click: function($event) {
                            return _vm.toggleLinked(data.row.id)
                          }
                        }
                      },
                      [
                        _c("winbox-icon", {
                          attrs: {
                            icon: "plus",
                            color: data.row[_vm.tableProps.linked]
                              ? _vm.Colors.system.lightGray
                              : _vm.Colors.system.primaryLight
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        [
          _c(
            "tr",
            {
              staticClass: "visible-thead-row",
              attrs: { slot: "prependHead" },
              slot: "prependHead"
            },
            [
              _vm._l(_vm.tableOptions.headings, function(
                tableOptionHeading,
                key
              ) {
                return [
                  key !== "_linked"
                    ? _c("th", { key: key }, [
                        _vm._v(_vm._s(tableOptionHeading))
                      ])
                    : _vm._e()
                ]
              }),
              _vm._v(" "),
              _c("th", [
                _c("div", { staticClass: "select-all-box" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.allLinked ? "Deselect all" : "Select all")
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button select-button",
                      class: { active: _vm.allLinked },
                      on: { click: _vm.toggleLinkedAll }
                    },
                    [
                      _c("winbox-icon", {
                        attrs: {
                          icon: "plus",
                          color: _vm.allLinked
                            ? _vm.Colors.system.lightGray
                            : _vm.Colors.system.primaryOrange
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }