<template>
  <div class="card winbox-card">
    <div class="card-header" v-if="hasHeaderSlot">
      <div class="card-header-icon" v-if="hasHeaderLeftIconSlot">
        <slot name="headerLeftIcon" />
      </div>
      <div class="card-header-title">
        <slot name="header" />
      </div>
      <div class="card-header-icon" v-if="hasHeaderIconSlot">
        <slot name="headerIcon" />
      </div>
      <div class="card-header-icon _expand" v-if="expandable">
        <button class="button">
          <winbox-icon
            :icon="iconExpand"
            :color="Colors.system.primaryLight"
            @click.native="toggleCard"
          />
        </button>
      </div>
    </div>
    <div v-show="isExpanded" class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import { Colors } from "@/Colors";

export default {
  name: "WinboxCard",
  components: { WinboxIcon },
  props: {
    expandable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isExpanded: true,
      Colors: Colors
    };
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasHeaderLeftIconSlot() {
      return !!this.$slots.headerLeftIcon;
    },
    hasHeaderIconSlot() {
      return !!this.$slots.headerIcon;
    },
    iconExpand() {
      return this.isExpanded ? "caret-up" : "caret-down";
    }
  },
  methods: {
    toggleCard() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.winbox-card {
  border-radius: $winbox-border-radius;
  height: 100%;

  .card-header {
    background: $winbox-nav-bg-color;
    border-radius: $winbox-border-radius $winbox-border-radius 0 0;

    .card-header-title {
      font-weight: 400;
      color: $winbox-body-color;
    }
    ::v-deep {
      .winbox-icon {
        font-size: 24px;
      }
    }
    .card-header-icon {
      &._expand {
        .button {
          border-radius: 50%;
          border-color: transparent;
          font-size: 11px;
        }
        ::v-deep {
          .winbox-icon {
            font-size: 19px;
          }
        }
      }
    }
  }
}
</style>
