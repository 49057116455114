import { MutationTypes } from "@/constants/MutationTypes";

export default {
  /* Agencies */
  [MutationTypes.SET_AGENCIES]: (state, payload) => {
    state.agencies = payload;
  },

  [MutationTypes.ADD_UPDATE_AGENCY]: (state, payload) => {
    state.currentAgency = payload;
  },

  [MutationTypes.SET_AGENCY_RELATIONSHIP]: (state, payload) => {
    state.agencyRelationships = payload;
  },

  [MutationTypes.ADD_AGENCY_RELATIONSHIP]: (state, payload) => {
    state.agencyRelationships = [payload, ...state.agencyRelationships];
  },

  [MutationTypes.UPDATE_AGENCY_RELATIONSHIP]: (state, payload) => {
    state.agencyRelationships = [payload, ...state.agencyRelationships.filter(
      (agencyRelationship) => agencyRelationship.id != payload.id)];
  },

  [MutationTypes.SET_AGENCY_HOLDING_COMPANY]: (state, payload) => {
    state.agencyHoldingCompany = payload.agencyHoldingCompany;
  },

};
