<template>
  <div class="control winbox-textarea" :class="[{ 'has-label': !!formLabel }]">
    <label class="label winbox-label" v-if="formLabel"
      ><span>{{ formLabel }}</span></label
    >
    <textarea
      class="textarea"
      v-model="editedText"
      :rows="rows"
      :cols="cols"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      @input="emit"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "WinboxTextarea",
  props: {
    rows: {
      required: false,
      default: 4,
      type: Number
    },
    cols: {
      required: false,
      default: 35,
      type: Number
    },
    value: {
      required: false,
      default: "",
      type: String
    },
    placeholder: {
      required: false,
      default: "",
      type: String
    },
    formLabel: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    maxlength: {
      required: false,
      type: Number,
      default: 4000
    }
  },
  data() {
    return {
      editedText: ""
    };
  },
  watch: {
    value: {
      handler: function() {
        this.editedText = this.value;
      }
    }
  },
  mounted() {
    this.editedText = this.value;
  },
  methods: {
    emit() {
      this.$emit("updated", this.editedText);
      this.$emit("input", this.editedText);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-textarea {
  .winbox-label {
    @include form-input-label;
  }

  &.error {
    .textarea {
      border-color: $winbox-error;
      background: $winbox-icon-negative-background;

      &:focus {
        outline: $winbox-icon-negative-background;
      }
    }
  }

  &.has-label {
    ::v-deep {
      .vs__dropdown-toggle {
        padding: 5px 0 6px 0;
      }
    }
  }
}
.textarea {
  font-size: $winbox-font-size-main;

  &::placeholder {
    font-size: $winbox-font-size-small;
  }
}


</style>
