var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("winbox-title", {
        staticClass: "main-title",
        attrs: { title: "Winbox Reset Password" }
      }),
      _vm._v(" "),
      _c("winbox-card", [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _vm.backendErrors
              ? _c("p", { staticClass: "error-block" }, [
                  _vm._v("\n        " + _vm._s(_vm.backendErrors) + "\n      ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-variable is-1" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-input", {
                    class: _vm.status(_vm.$v.entity.password),
                    attrs: { placeholder: "new password", type: "password" },
                    model: {
                      value: _vm.$v.entity.password.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.entity.password,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.entity.password.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-variable is-1" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-input", {
                    class: _vm.status(_vm.$v.entity.password_confirmation),
                    attrs: {
                      placeholder: "password confirmation",
                      type: "password"
                    },
                    model: {
                      value: _vm.$v.entity.password_confirmation.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.entity.password_confirmation,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.entity.password_confirmation.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-variable is-1" }, [
              _c("div", { staticClass: "column" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    attrs: { disabled: !_vm.isValidForm }
                  },
                  [_vm._v("\n            Submit\n          ")]
                )
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }