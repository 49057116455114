import { BusinessesUrl } from "@/constants/BusinessesUrl";
import { BrandsUrl } from "@/constants/BrandsUrl";

import {
  getGeneric,
  getGenericFullResponse,
  postGenericFullResponse,
  putGenericFullResponse,
  deleteGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllBrands(name = null) {
  let url = BrandsUrl.base;
  if (name) {
    url += `?name=${name}`;
  }
  return getGenericFullResponse(url);
}

export function getBrandCounts(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.counts}`;
  return getGenericFullResponse(url);
}

export function getBrandById(id) {
  const url = `${BrandsUrl.base}/${id}`;
  return getGenericFullResponse(url);
}

export function updateBrandById(id, data) {
  const url = `${BrandsUrl.base}/${id}`;
  return putGenericFullResponse(url, data);
}

export function getBrandDemographics(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.demographics}`;
  return getGenericFullResponse(url);
}

export function updateBrandDemographics(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.demographics}`;
  return putGenericFullResponse(url, data);
}

export function getBrandIndustries(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.industries}`;
  return getGenericFullResponse(url);
}

export function deleteBrandIndustry(id) {
  const url = `/${BrandsUrl.brand_industries}/${id}`;
  return deleteGenericFullResponse(url);
}

export function updateBrandIndustryAssociation(id, payload) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.industries}`;
  return putGenericFullResponse(url, payload);
}

export function getBrandNicknames(id) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.business_nicknames}`;
  return getGenericFullResponse(url);
}

export function updateBrandNicknamesList(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.business_nicknames}`;
  return putGenericFullResponse(url, data);
}

export function getBrandPeople(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.people}`;
  return getGenericFullResponse(url);
}

export function assignBrandPeople(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.people}`;
  return postGenericFullResponse(url, data);
}

export function unassignBrandPeople(brand_id, person_id) {
  const url = `${BrandsUrl.base}/${brand_id}/${BrandsUrl.people}/${person_id}`;
  return deleteGenericFullResponse(url);
}

export function getBrandSocials(id) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.entity_socials}`;
  return getGenericFullResponse(url);
}

export function updateBrandSocialsList(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.entity_socials}`;
  return putGenericFullResponse(url, data);
}

export function getBrandTags(id) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.entity_tags}`;
  return getGenericFullResponse(url);
}

export function createBrandEntityTag(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.entity_tags}`;
  return postGenericFullResponse(url, data);
}

export function getBrandNotes(id) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.entity_notes}`;
  return getGenericFullResponse(url);
}

export function createBrandNote(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.entity_notes}`;
  return postGenericFullResponse(url, data);
}

export function getBrandLogs(id) {
  const url = `${BrandsUrl.base}/${id}/${BusinessesUrl.entity_audit_logs}`;
  return getGenericFullResponse(url);
}

export function rollbackBrandLog(data) {
  const url = `${BrandsUrl.base}/${BusinessesUrl.entity_audit_logs_rollback}`;
  return postGenericFullResponse(url, data);
}

export function getBrandSpecialities(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.brand_speciality_lists}`;
  return getGenericFullResponse(url);
}

export function assignBrandSpecialities(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.brand_speciality_lists}`;
  return putGenericFullResponse(url, data);
}

export function updateBrandSpeciality(id, data) {
  const url = `${BrandsUrl.brand_speciality_lists}/${id}`;
  return putGenericFullResponse(url, data);
}

export function deleteBrandSpeciality(id) {
  const url = `${BrandsUrl.brand_speciality_lists}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBrandAgencies(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.agencies}`;
  return getGenericFullResponse(url);
}

export function getBrandSponsorships(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.sponsorships}`;
  return getGenericFullResponse(url);
}

export function createBrandSponsorship(id, payload) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.sponsorships}`;
  return postGenericFullResponse(url, payload);
}

export function updateBrandSponsorship(id, sponsorshipId, payload) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.sponsorships}/${sponsorshipId}`;
  return putGenericFullResponse(url, payload);
}

export function submitBrandSponsorshipsProof(id) {
  const url = `/brand-sponsorships-proof/${id}`;
  return putGenericFullResponse(url);
}

export function getBrandBusiness(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.businesses}`;
  return getGenericFullResponse(url);
}

export function getBrandBusinessesContacts(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.businesses_contacts}`;
  return getGenericFullResponse(url);
}

export function getBrandAgenciesContacts(id) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.brand_agency_contacts}`;
  return getGenericFullResponse(url);
}

export function bulkUpdateBrandAgencyContacts(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.bulk_update_brand_agency_contacts}`;
  return postGenericFullResponse(url, data);
}

export function assignBrandAgenciesContacts(id, data) {
  const url = `${BrandsUrl.base}/${id}/${BrandsUrl.brand_agency_contacts}`;
  return postGenericFullResponse(url, data);
}

export function unassignBrandAgenciesContacts(id, brand_id) {
  const url = `${BrandsUrl.base}/${brand_id}/${BrandsUrl.brand_agency_contacts}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBrandHierarchies(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_hierarchies}?type=brand`;
  return getGenericFullResponse(url);
}

export function createBrandHierarchy(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_hierarchies}?type=brand`;
  return postGenericFullResponse(url, data);
}

export function removeBrandHierarchy(id) {
  const url = `/${BusinessesUrl.business_hierarchies}/${id}?type=brand`;
  return deleteGenericFullResponse(url);
}

export function submitBrandsProof(id) {
  const url = `/brands-proof/${id}`;
  return putGenericFullResponse(url);
}
