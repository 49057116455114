var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["notification", _vm.type, { "with-close": _vm.closeable }] },
    [
      _vm.closeable
        ? _c("winbox-icon", {
            staticClass: "close-icon",
            attrs: { color: "#fff", icon: "times" },
            nativeOn: {
              click: function($event) {
                return _vm.dismiss.apply(null, arguments)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("winbox-icon", {
            staticClass: "notification-icon",
            attrs: { color: "#fff", icon: _vm.notificationIcon }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "notification-content" },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }