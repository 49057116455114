export const singleItem = {
  id: 19999,
  type: "Agency",
  name: "__MOCK__: Watsica, Bechtelar and Rempel",
  location: "New York, NY",
  _location_id: 1,
  services: ["AOR", "Media Buying", "Media Planning"],
  status: "",
  mediaTypes: ["Some media type"],
  from: "January",
  to: "March",
  org_type: "private_type",
  ownership: "independent",
  description: "Synchronised tertiary success",
  fiscal_close: "Some string",
  year_founded: 1990,
  website: "fahey.com",
  published: true,
  sic: 5731,
  naics: 8031,
  duns: 24,
  nasdaq: "30-312-4277",
  is_closed: false,
  created_at: "2019-11-19T14:05:52.000Z",
  updated_at: "2019-11-19T14:05:52.000Z"
};

export const itemList = [
  { ...singleItem },
  {
    id: 29999,
    type: "Agency",
    name: "__MOCK__: Best Unreal Agency",
    location: "Bar Harbor, Maine",
    _location_id: 2,
    services: ["AOR", "Media Planning"],
    status: "",
    mediaTypes: ["Some media type"],
    from: "February",
    to: "April",
    org_type: "private_type",
    ownership: "independent",
    description: "Success is our second name.",
    fiscal_close: "Some string",
    year_founded: 1970,
    website: "bar-harbor-best222.com",
    published: true,
    sic: 231,
    naics: 4423,
    duns: 45,
    nasdaq: "30-212-4277",
    is_closed: false,
    created_at: "2017-11-19T14:05:52.000Z",
    updated_at: "2019-12-12T14:05:52.000Z"
  }
];
