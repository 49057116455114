<template>
  <div>
    <div class="logo-wrap">
      <div class="logo-img-box">
        <img
          width="80"
          height="80"
          :src="logoUrl"
        />
      </div>
      <button
        class="button add-logo-action"
        @click="openLogoModal"
      >
        <winbox-icon
          icon="pen"
          :color="Colors.system.primaryLight"
        />
      </button>
    </div>
    <winbox-modal
      :ref="logoModal"
      :name="logoModal"
      :enable-footer-buttons="true"
      title="Update Logo"
      save-text="Update"
      @on-save="onSaveLogoUrl"
      @on-cancel="closeLogoModal"
    >
      <winbox-input label="URL" v-model="newLogoUrl" />
    </winbox-modal>
  </div>
</template>

<script>

import { Colors } from "@/Colors";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxModal from "@/components/Molecules/WinboxModal";

export default {
  name: "WinboxLogoInput",
  components: { WinboxIcon, WinboxInput, WinboxModal },
  props: {
    value: {
      required: false,
      type: String,
      default: '',
      note: 'v-model'
    }
  },
  data() {
    return {
      logoUrl: "",
      newLogoUrl: "",
      Colors: Colors,
      logoModal: 'logoModal'
    };
  },
  created () {
    this.logoUrl = this.value;
  },
  watch: {
    value() {
      this.logoUrl = this.value;
    }
  },
  methods: {
    openLogoModal() {
      this.newLogoUrl = this.logoUrl;
      this.$refs.logoModal.show();
    },
    closeLogoModal() {
      this.$refs.logoModal.hide();
    },
    onSaveLogoUrl() {
      this.logoUrl = this.newLogoUrl;
      this.$emit('input', this.newLogoUrl);
      this.$refs.logoModal.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.logo-wrap {
  position: relative;

  &:hover {
    .add-logo-action {
      opacity: 1;
    }
  }
}
.add-logo-action {
  position: absolute;
  left: 60px;
  top: 60px;
  background: transparent;
  border-color: transparent;
  opacity: 0;
}
.logo-img-box {
  border: 1px solid $winbox-secondary-title;
  border-radius: 50%;
  overflow: hidden;
  width: 82px;
  height: 82px;
  margin: 0 1.5rem 1.5rem 0;
  display: flex;
}
</style>
