import { AuthUrl } from "@/constants/AuthUrl";
import {
  getGeneric,
  postGeneric,
  postGenericFullResponse,
  putGeneric
} from "@/api/_generic/_generic";

export function getCurrentAccount() {
  const url = AuthUrl.base;
  return getGeneric(url);
}

export function signIn(data) {
  const url = AuthUrl.sign_in;
  return postGenericFullResponse(url, data);
}

export function changePassword(data) {
  const url = AuthUrl.changePassword;
  return putGeneric(url, data);
}

export function resetPassword(data) {
  const url = AuthUrl.changePassword;
  return postGeneric(url, data);
}

export function changePasswordRedirectUrl() {
  const url = AuthUrl.changePasswordRedirectUrl;
  return url;
}
