var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "control winbox-textarea",
      class: [{ "has-label": !!_vm.formLabel }]
    },
    [
      _vm.formLabel
        ? _c("label", { staticClass: "label winbox-label" }, [
            _c("span", [_vm._v(_vm._s(_vm.formLabel))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.editedText,
            expression: "editedText"
          }
        ],
        staticClass: "textarea",
        attrs: {
          rows: _vm.rows,
          cols: _vm.cols,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          maxlength: _vm.maxlength
        },
        domProps: { value: _vm.editedText },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) return
              _vm.editedText = $event.target.value
            },
            _vm.emit
          ]
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }