import * as CompaniesApi from "@/api/companies/companies";
import * as BusinessesApi from "@/api/businesses/businesses";
import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getCompanies({ commit }) {
    return CompaniesApi.getCompanies().then(
      ({ data }) => {
        const dataWithMock = [...Utils.defaultTableDataset(), ...data];
        commit(MutationTypes.SET_COMPANIES, dataWithMock);
      }
    );
  },

  getCompany({ commit }, id) {
    return CompaniesApi.getCompany(id).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_COMPANY, data);
    });
  },

  getCompanyCounts({ commit }, id) {
    return CompaniesApi.getCompanyCounts(id).then(({ data }) => {
      commit(MutationTypes.SET_COUNTS, data);
    });
  },

  updateCompany({ commit, getters }, { id, payload }) {
    return CompaniesApi.updateCompany(id, payload).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_COMPANY, data);
    });
  },


  getCompanyHierarchies({ commit }, id) {
    return CompaniesApi.getCompanyHierarchies(id).then(({ data }) => {
      commit(MutationTypes.SET_ENTITY_PARENT_HIERARCHIES, data.parents);
      commit(MutationTypes.SET_ENTITY_CHILD_HIERARCHIES, data.children);
      commit(MutationTypes.SET_ENTITY_SIBLING_HIERARCHIES, data.siblings);
    });
  },

  createCompanyHierarchy({ state, commit, getters }, { id, payload }) {
    return CompaniesApi.createCompanyHierarchy(id, payload).then(
      ({ data }) => {
        commit(MutationTypes.UPDATE_COUNTS, {
          stateVariableName: 'hierarchy_total_count', 
          count: state.entityCounts.hierarchy_total_count + 1,
        });
        if(payload.hierarchy_type === 'parent') {
          commit(MutationTypes.UPDATE_ENTITY_PARENT_HIERARCHIES, data);
        }
        else {
          commit(MutationTypes.UPDATE_ENTITY_CHILD_HIERARCHIES, data);
        }
      }
    );
  },

  removeCompanyHierarchy({ state, commit, getters }, { id, type }) {
    return CompaniesApi.removeCompanyHierarchy(id).then(() => {
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'hierarchy_total_count', 
        count: state.entityCounts.hierarchy_total_count - 1,
      });
      if(type === 'parent') {
        commit(MutationTypes.REMOVE_ENTITY_PARENT_HIERARCHIES, id);
      }
      else {
        commit(MutationTypes.REMOVE_ENTITY_CHILD_HIERARCHIES, id);
      }
    });
  },

  getBrandAgenciesByCompany({ commit }, id) {
    return CompaniesApi.getBrandAgenciesByCompany(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_RELATIONSHIPS, data);
    });
  },

  getBrandAgencyContactsByCompany({ commit }, id) {
    return CompaniesApi.getBrandAgencyContactsByCompany(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_AGENCY_CONTACTS, data);
    });
  },
  unassignBrandAgencyContactsByCompany({ state, commit }, { id, business_id, brand_ids }) {
    return CompaniesApi.unassignBrandAgencyContactsByCompany(id, business_id, brand_ids)
    .then(() => {
      commit(MutationTypes.REMOVE_BUSINESS_AGENCY_CONTACTS, id);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'entity_agency_contacts_count', 
        count: state.entityCounts.entity_agency_contacts_count - 1,
      });
    })
    .catch(error => {
      console.error("Failed to unassign agency people:", error);
    });
  }
};
