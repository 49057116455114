<template>
  <div class="entity-notes-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="entity-notes">
      <div class="wrap-company-header">
        <div class="columns is-vcentered">
          <div class="column">
            <winbox-title :title="mainTitle" />
          </div>
          <div class="column is-narrow header-action-wrap" v-if="isEditMode">
            <button
              class="button"
              @click="openCreateModal"
            >
              <winbox-icon
                class="add-entity-icon"
                :color="Colors.system.lightGray"
                icon="plus"
              />
              <span>Create</span>
            </button>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <winbox-data-table
              class="main-data-table"
              :data="dataset"
              :columns="columns"
              :options="tableOptions"
              :slots="specificSlots"
              ref="winboxDataTable"
          >
            <template slot="child_row" slot-scope="data">
              <tr>
                <td class="child-full-description-td">
                  <div class="child-full-description">
                    <div v-if="showFullDescription(data.row.description)">{{ data.row.description }}</div>
                  </div>
                </td>
                <td class="child-spacer-td" />
                <td>
                  <div class="additional-block">
                    <div v-if="data.row.expire_date">
                      <strong>Expires:</strong>
                      {{ data.row.expire_date | winboxDate }}
                    </div>
                    <div v-if="data.row.reminder_date">
                      <strong>Reminder:</strong>
                      {{ data.row.reminder_date | winboxDate }}
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template :slot="tableProps.toggler" slot-scope="data">
              <span class="VueTables__child-row-toggler"
                    :class="{
                      'VueTables__child-row-toggler--closed' : !data.row._expanded,
                      'VueTables__child-row-toggler--open' : data.row._expanded}"
                    @click="onTogglerClick(data)"></span>
            </template>
            <template :slot="tableProps.description" slot-scope="data">
              {{ data.row[tableProps.description] | truncate(maxSymbols) | firstline }}
            </template>
            <template :slot="tableProps.published" slot-scope="data">
              {{ data.row[tableProps.published] | winboxDatetime }}
            </template>
            <template :slot="tableProps.options" slot-scope="data">
              <button v-if="isEditMode" class="button button-edit">
                <winbox-icon
                    icon="pen"
                    :color="Colors.system.primaryLight"
                    @click.native="onEdit(data.row)"
                />
              </button>
              <button v-if="isEditMode" class="button button-delete">
                <winbox-icon
                    icon="trash"
                    :color="Colors.system.error"
                    @click.native="() => {
                        collapseAllActiveRows();
                        removeEntity(data.row.id)
                    }"
                />
              </button>
            </template>
          </winbox-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
        :ref="reminderModal"
        :name="reminderModal"
        :enable-footer-buttons="true"
        :click-to-close="false"
        :width="800"
        class="notes-modal"
        :title="noteModalTitle"
        save-text="Save"
        @on-cancel="onCancel"
        @on-save="onSave"
    >
      <winbox-public-notes
          ref="notesModalRef"
          v-if="modalDataRow"
          :data="modalDataRow"
      />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxPublicNotes from "@/components/Organisms/WinboxPublicNotes";
import * as NoteRemindersApi from "@/api/note-reminders/note-reminders";

import { DateFormats } from "@/constants/DateFormats";
import moment from "moment";
import { Colors } from "@/Colors";
import { NoteTypes } from "@/constants/NoteTypes";
import { EntityNoteTypes } from "@/constants/EntityNoteTypes";

const tableProps = {
  toggler: "__toggler",
  description: "description",
  published: "created_at",
  author: "author",
  options: "__options"
};
const mainTableColumns = Object.values(tableProps);
import * as Utils from "@/utils";

export default {
  name: "ContactNotes",
  components: {
    WinboxTitle,
    WinboxIcon,
    WinboxLargeIndicator,
    WinboxDataTable,
    WinboxModal,
    WinboxPublicNotes
  },
  data() {
    return {
      Colors: Colors,
      entity: null,
      dataset: [],
      noteCount: 0,
      noteReminders: [],
      maxSymbols: 58, // Agreed with Julia lol
      isLoaded: false,
      noteType: NoteTypes.public,
      entityNoteType: EntityNoteTypes.financial,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        showChildRowToggler: false,
        headings: {
          [tableProps.toggler]: "",
          [tableProps.description]: "Description",
          [tableProps.published]: "Published",
          [tableProps.author]: "Author",
          [tableProps.options]: ""
        },
        sortable: [],
        filterable: [...mainTableColumns]
      },
      specificSlots: [
        tableProps.toggler,
        tableProps.description,
        tableProps.published,
        tableProps.options,
        "child_row"
      ],
      reminderModal: "reminderModal",
      modalDataRow: null
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'user',
      'users',
      'personNotes',
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Notes (${this.dataset.length})`;
    },
    possibleTypes() {
      return ["Financial"];
    },
    noteModalTitle() {
      return this.modalDataRow
        ? !this.modalDataRow.id
          ? "Create Note"
          : "Edit Note"
        : "";
    },
  },
  methods: {
    ...mapActions([
      'getPersonNotes',
      'change',
    ]),
    async getData() {
      this.isLoaded = false;
      await this.getPersonNotes(this.id);
      this.dataset = this.modifyData(this.personNotes);
      this.isLoaded = true;
    },
    modifyData(data) {
      return data.filter(item => item.note_type === this.noteType)
        .map(item => this.extendNoteData(item));
    },
    extendNoteData(item) {
      return {
        ...item,
        [tableProps.author]: Utils.findUserName(
          item.author_id,
          this.users
        )
      };
    },
    getNoteReminders(id) {
      return NoteRemindersApi.getNoteReminders(id).then(({ data }) => {
        return data;
      });
    },
    removeEntity(id) {
      let shouldBeConfirmed = confirm("Are you sure?");
      if (shouldBeConfirmed) {
        this.dataset = this.dataset.filter(note => note.id !== id);
        this.change(true);
      }
    },
    openCreateModal() {
      this.collapseAllActiveRows();

      this.modalDataRow = {
        id: null,
        [tableProps.description]: "",
        [tableProps.author]: Utils.findUserName(
          this.user.id,
          this.users
        ),
        reminder: null,
        expire_date: null
      };
      this.$refs[this.reminderModal].show();
    },
    collapseAllActiveRows() {
      const vTable = this.getTableRef();
      if (vTable) {
        for (let row of vTable.filteredData) {
          this.collapseActiveChildRow(vTable, row);
        }
      }
    },
    collapseActiveChildRow(vTable, row) {
      if (vTable) {
        if (row._expanded) {
          vTable.toggleChildRow(row.id);
          row._expanded = false;
        }
      }
    },
    showFullDescription(description) {
      let result = false;

      if (
        description.length > this.maxSymbols ||
        this.$options.filters.withoutFirstline(description)
      ) {
        result = true;
      }
      return result;
    },
    async onEdit(row) {
      this.collapseAllActiveRows();

      this.noteReminders = await this.getNoteReminders(row.id);
      this.modalDataRow = {
        ...row,
        created: moment(row.created).format(DateFormats.BASE),
        [tableProps.author]: Utils.findUserName(
          row.author_id,
          this.users
        ),
        reminder: this.noteReminders.length
          ? this.noteReminders[0].reminder_date
          : null
      };
      this.$refs[this.reminderModal].show();
    },
    onCancel() {
      this.$refs[this.reminderModal].hide();
    },
    onSave() {
      const entity = this.$refs.notesModalRef.getDeepCloneDataset();
      if (entity.id) {
        const index = this.dataset.map((note) => note.id).indexOf(entity.id);
        this.dataset = this.dataset.filter((note) => note.id != entity.id);
        this.dataset = [...this.dataset.slice(0,index), entity, ...this.dataset.slice(index)]
      }
      else {
        this.dataset = [...this.dataset, { id: this.noteCount--, author_id: this.user.id, ...entity }];
      }

      this.change(true);

      this.$refs[this.reminderModal].hide();
    },
    getCreateNotePayload(data) {
      return {
        description: data.description,
        expire_date: data.expire_date
          ? new Date(data.expire_date).toISOString().slice(0, 19).replace("T", " ")
          : null,
        reminder_date: data.reminder_date
          ? new Date(data.reminder_date).toISOString().slice(0, 19).replace("T", " ")
          : null,
        note_type: this.noteType,
        type: this.entityNoteType,
        author_id: this.user.id
      };
    },
    getUpdateNotePayload(data) {
      return {
        ...data,
        expire_date: data.expire_date
          ? Utils.getServerDateFormat(data.expire_date)
          : null
      };
    },
    /* Custom toggler logic */
    async onTogglerClick(data) {
      const vTable = this.getTableRef();

      if (data.row._expanded === undefined) {
        data.row._expanded = true;
        if (data.row.reminder_date === undefined) {
          const dateReminders = await this.getNoteReminders(data.row.id);
          data.row.reminder_date = dateReminders.length
            ? dateReminders[0].reminder_date
            : null;
        }
      } else {
        data.row._expanded = !data.row._expanded;
      }

      if (vTable) {
        vTable.toggleChildRow(data.row.id);
      }
    },
    getTableRef() {
      return this.$refs.winboxDataTable.$refs.vTableRef;
    },
    editCancel() {
      this.dataset = this.modifyData(this.personNotes);
    },
    async editAccept() {
      const payload = {
        entity_notes: this.dataset.map((data) => {
          return this.getCreateNotePayload(data);
        })
      };

      return this.$store.dispatch("createPersonEntityNote", {
        id: this.id,
        payload
      }).then(() => {
        this.dataset = this.modifyData(this.personNotes);
      });
    },
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.entity-notes {
  padding-top: 0.5rem;
  .main-data-table {
    position: relative;
    ::v-deep {
      @include entity-notes-main-table-styles;
    }
  }
}
.notes-modal {
  ::v-deep {
    .winbox-modal-header {
      background: $winbox-primary-bg-color;
    }
  }
}
.header-action-wrap {
  text-align: right;

  .button {
    background: $winbox-primary-active-bg-color;
    border-color: transparent;
    color: #585656;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
</style>
