<template src="../Generic/EntityLocationsMixin.html" />
<script>
import { mapState, mapActions } from "vuex";

import { EntityLocationsMixin } from "@/components/Pages/Generic/EntityLocationsMixin";
import * as Utils from "@/utils";

export default {
  name: "CompanyLocations",
  mixins: [EntityLocationsMixin],
  components: {},
  data() {
    return {
      isLoaded: true
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapState([
      'currentCompany',
    ]),
    businessEntity() {
      return this.currentCompany;
    },
  },
  methods: {
    ...mapActions([
      'getBusinessLocations',
    ]),
    async getData() {
      this.isLoaded = false;

      await this.getBusinessLocations(this.id);

      this.isLoaded = true;
    },
    constructDetailLocationUrl(locationId) {
      return Utils.getCompanyLocationDetailUrl(
        this.businessEntity.id,
        locationId
      );
    }
  }
};
</script>
<style scoped lang="scss" src="../Generic/EntityLocationsMixin.scss" />
