import { mapActions } from "vuex";
import { SearchEqualities } from "@/constants/SearchEqualities";
import * as SyncApi from "@/api/sync/sync";

export const BusinessEntitiesHelperMixin = {
  data() {
    return {
      itemsCount: 0,
      isSyncModalLoaded: true,
      syncModal: "syncModal",
      markedRows: [],
      syncType: ""
    };
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getBrands",
      "getAgencies",
      "getAllPeople",
      "getDisciplines",
      "sync"
    ]),
    onRowsChange(e) {
      this.markedRows = [...e];
    },
    openSyncModal() {
      this.$refs[this.syncModal].show();
    },
    onSyncCancel() {
      this.$refs[this.syncModal].hide();
    },
    onSyncSave() {
      SyncApi.sync({ ids: this.markedRows, type: this.syncType });
      this.markedRows = [];
      this.$refs.mainTableRef.resetCheckboxes();
      this.onSyncCancel();
    },
    onFilter(data) {
    },
    onExportData() {

    },
    csvExport(exportData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.values(exportData.headers).map(item => `"${item}"`).join(","),
        ...exportData.content.map(item => Object.keys(exportData.headers).map(k => this.convertContentField(`${item[k]}`)).join(","))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    convertContentField(contentStr) {
      contentStr = contentStr.replace(/"/g, '\'');
      contentStr = contentStr.replace(/,/g, '\,');
      contentStr = contentStr.replace(/'/g, '\'');
      return `"${contentStr}"`;
    },
    tryToSetGlobalFilters() {
      if (this.$route && this.$route.query) {
        let query = this.$route.query;
        if (query.globalSearch) {
          this.$refs.mainTableRef.$refs.vTableRef.query = query.globalSearch;
        }

        if (query.count)
          this.itemsCount = query.count;
      }
    }
  }
};
