var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-modal" },
    [
      _c(
        "modal",
        {
          attrs: {
            name: _vm.name,
            "click-to-close": _vm.clickToClose,
            width: _vm.width,
            height: _vm.height,
            transition: "nice-modal-fade"
          },
          on: {
            "before-open": function($event) {
              return _vm.$emit("before-open", $event)
            },
            "before-close": function($event) {
              return _vm.$emit("before-close", $event)
            },
            opened: function($event) {
              return _vm.$emit("opened", $event)
            },
            closed: function($event) {
              return _vm.$emit("closed", $event)
            }
          }
        },
        [
          _vm.title
            ? _c("div", { staticClass: "winbox-modal-header" }, [
                _c("div", { staticClass: "winbox-modal-header-title" }, [
                  _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
                ]),
                _vm._v(" "),
                _vm.showHeaderCloseIcon
                  ? _c("div", { staticClass: "winbox-modal-header-icon" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { tabindex: "0", type: "button" },
                          on: {
                            click: _vm.reset,
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.onEnter.apply(null, arguments)
                            }
                          }
                        },
                        [_c("winbox-icon", { attrs: { icon: "times" } })],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "winbox-modal-container" },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _vm.enableFooterButtons
            ? _c("div", { staticClass: "winbox-footer-buttons" }, [
                _c(
                  "button",
                  { staticClass: "button", on: { click: _vm.onCancel } },
                  [_vm._v(_vm._s(_vm.cancelText))]
                ),
                _vm._v(" "),
                _vm.saveText
                  ? _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        attrs: { disabled: _vm.saveDisabled },
                        on: { click: _vm.onSave }
                      },
                      [_vm._v("\n        " + _vm._s(_vm.saveText) + "\n      ")]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }