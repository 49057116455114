var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-progress-chart" },
    [
      _c("radial-progress-bar", {
        attrs: {
          diameter: _vm.diameter,
          "completed-steps": _vm.completedSteps,
          "total-steps": _vm.totalSteps,
          "start-color": _vm.startColor,
          "stop-color": _vm.stopColor,
          "inner-stroke-color": _vm.innerStrokeColor,
          "stroke-width": _vm.strokeWidth
        }
      }),
      _vm._v(" "),
      _vm.showLabels
        ? _c("div", { staticClass: "winbox-progress-chart-labels" }, [
            _c("div", { staticClass: "progress-value" }, [
              _vm._v(_vm._s(_vm.completedSteps) + "%")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "progress-status" }, [_vm._v("Completed")])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }