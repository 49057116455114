import { NoteTypes } from "@/constants/NoteTypes";

export const AnyEntityEntryMixin = {
  data() {
    return {
      prevRoute: null
    };
  },
  watch: {
    $route: function () {
      if (
        this.prevRoute &&
        this.prevRoute.params.id !== this.$route.params.id
      ) {
        this.getData();
      }
    },
    "entity.published": function (newVal, oldVal) {
      if (oldVal != newVal && newVal != undefined) {
        this.addToDataUpdate("published", newVal);
      }
    }
  },
  computed: {
    id() {
      return +this.$route.params.id;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.prevRoute = from;
    next();
  }
};
