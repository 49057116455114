var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "business-entity-profile-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded && _vm.entity
        ? _c("div", { staticClass: "business-entity-profile" }, [
            _c("div", { staticClass: "columns is-desktop" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "winbox-card",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v("\n            General\n          ")
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "headerIcon",
                            fn: function() {
                              return [
                                _vm.isEditMode
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "button save-button",
                                        attrs: { disabled: !_vm.isChanged },
                                        on: {
                                          click: function($event) {
                                            return _vm.save(true)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              Save\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isEditMode
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "button cancel-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.cancel(true)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              Cancel\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isEditMode
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "button edit-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.edit(true)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              Edit\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1589452232
                      )
                    },
                    [
                      _vm._v(" "),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("winbox-input", {
                            class: _vm.status(_vm.$v.entity.name),
                            attrs: {
                              label: "Company Name",
                              disabled: !_vm.isEditMode,
                              field: "name"
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "name",
                                  _vm.entity.name
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.entity.name.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entity.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.entity.name.$model"
                            }
                          }),
                          _vm._v(" "),
                          !_vm.isUk
                            ? _c("div", { staticClass: "columns" }, [
                                _c(
                                  "div",
                                  { staticClass: "column is-12" },
                                  [
                                    _c("winbox-select", {
                                      attrs: {
                                        "form-label": "NAICS Code",
                                        options: _vm.possibleNaics,
                                        disabled: !_vm.isEditMode,
                                        clearable: _vm.isEditMode
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.addToDataUpdate(
                                            "naics",
                                            _vm.entity &&
                                              _vm.entity.naics &&
                                              _vm.entity.naics.value
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.entity.naics,
                                        callback: function($$v) {
                                          _vm.$set(_vm.entity, "naics", $$v)
                                        },
                                        expression: "entity.naics"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "columns general-columns" },
                            [
                              _c(
                                "div",
                                { staticClass: "column is-3" },
                                [
                                  _c("winbox-select", {
                                    class: _vm.status(_vm.$v.entity.org_type),
                                    attrs: {
                                      "form-label": "Type",
                                      options: _vm.entityTypes,
                                      disabled: !_vm.isEditMode,
                                      clearable: _vm.isEditMode
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.addToDataUpdate(
                                          "org_type",
                                          _vm.entity &&
                                            _vm.entity.org_type &&
                                            _vm.entity.org_type.value
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.entity.org_type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.entity, "org_type", $$v)
                                      },
                                      expression: "entity.org_type"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column is-2" },
                                [
                                  _c("winbox-select", {
                                    attrs: {
                                      "form-label": "Employee Number",
                                      options: _vm.possibleEmployeeRanges,
                                      disabled: !_vm.isEditMode,
                                      clearable: _vm.isEditMode
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.addToDataUpdate(
                                          "employee_range_id",
                                          _vm.entity &&
                                            _vm.entity.employeeRange &&
                                            _vm.entity.employeeRange.value
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.entity.employeeRange,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.entity,
                                          "employeeRange",
                                          $$v
                                        )
                                      },
                                      expression: "entity.employeeRange"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column is-2" },
                                [
                                  _c("winbox-input", {
                                    class: _vm.status(
                                      _vm.$v.entity.year_founded
                                    ),
                                    attrs: {
                                      label: "Founded",
                                      maxlength: "4",
                                      disabled: !_vm.isEditMode
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.addToDataUpdate(
                                          "year_founded",
                                          _vm.entity.year_founded
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.$v.entity.year_founded.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.entity.year_founded,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.entity.year_founded.$model"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column is-3" },
                                [
                                  _c("winbox-select", {
                                    attrs: {
                                      "form-label": "Fiscal Close",
                                      options: _vm.fiscalCloses,
                                      disabled: !_vm.isEditMode,
                                      clearable: _vm.isEditMode
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.addToDataUpdate(
                                          "fiscal_close",
                                          _vm.entity.fiscal_close
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.entity.fiscal_close,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.entity,
                                          "fiscal_close",
                                          $$v
                                        )
                                      },
                                      expression: "entity.fiscal_close"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column is-2" },
                                [
                                  _c("winbox-input", {
                                    attrs: {
                                      label: "Stock Symbol",
                                      disabled: !_vm.isEditMode
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.addToDataUpdate(
                                          "stock_symbol",
                                          _vm.entity.stock_symbol
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.entity.stock_symbol,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.entity,
                                          "stock_symbol",
                                          $$v
                                        )
                                      },
                                      expression: "entity.stock_symbol"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("winbox-input", {
                            class: _vm.status(_vm.$v.entity.short_description),
                            attrs: {
                              label: "Short Description",
                              disabled: !_vm.isEditMode
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "short_description",
                                  _vm.entity.short_description
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.entity.short_description.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entity.short_description,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.entity.short_description.$model"
                            }
                          }),
                          _vm._v(" "),
                          _vm.isEditMode
                            ? _c("winbox-input", {
                                class: _vm.status(_vm.$v.entity.website),
                                attrs: {
                                  label: "Web Address",
                                  "icon-left": "globe-americas",
                                  field: "website"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.addToDataUpdate(
                                      "website",
                                      _vm.entity.website
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.$v.entity.website.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.entity.website,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.entity.website.$model"
                                }
                              })
                            : _c("fieldset", { staticClass: "border" }, [
                                _c(
                                  "legend",
                                  { staticClass: "float-none w-auto" },
                                  [_vm._v("Web Address")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "website-container" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "website-link",
                                        attrs: {
                                          href: `http://${_vm.entity.website}`,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              decodeURIComponent(
                                                _vm.entity.website
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                          _vm._v(" "),
                          _c("winbox-textarea", {
                            class: _vm.status(_vm.$v.entity.long_description),
                            attrs: {
                              "form-label": "Long Description",
                              disabled: !_vm.isEditMode
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "long_description",
                                  _vm.entity.long_description
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.entity.long_description.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entity.long_description,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.entity.long_description.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c("winbox-input", {
                            class: _vm.status(_vm.$v.entity.nickname),
                            attrs: {
                              label: "Nickname",
                              disabled: !_vm.isEditMode
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "nickname",
                                  _vm.entity.nickname
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.entity.nickname.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entity.nickname,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.entity.nickname.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-desktop" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "winbox-card",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v("\n            Social Media\n          ")
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "headerIcon",
                            fn: function() {
                              return [
                                _vm.isEditMode
                                  ? _c("winbox-icon", {
                                      attrs: {
                                        icon: "plus-circle",
                                        color: _vm.Colors.system.primaryLight
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.$refs.winboxSocialMediaRef.addNewSocialEntity.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2034013214
                      )
                    },
                    [
                      _vm._v(" "),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("winbox-social-media", {
                            ref: "winboxSocialMediaRef",
                            attrs: {
                              items: _vm.socialEntities,
                              disabled: !_vm.isEditMode
                            },
                            on: { changed: _vm.onSocialChanged }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "winbox-card",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(
                                  "\n            Company Revenue\n          "
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "headerIcon",
                            fn: function() {
                              return [
                                _vm.isEditMode
                                  ? _c("winbox-icon", {
                                      attrs: {
                                        icon: "plus-circle",
                                        color: _vm.Colors.system.primaryLight
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.$refs.winboxRevenueRef.addNewEntity.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1198259426
                      )
                    },
                    [
                      _vm._v(" "),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("winbox-revenue", {
                            ref: "winboxRevenueRef",
                            attrs: {
                              items: _vm.revenueItems,
                              disabled: !_vm.isEditMode
                            },
                            on: { changed: _vm.onRevenueChanged }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "winbox-card",
                    {
                      attrs: { expandable: true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _vm._v(
                                  "\n            Speciality Lists\n          "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1172775670
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("winbox-speciality-tags-list", {
                            attrs: {
                              "possible-tags": _vm.possibleTags,
                              "entity-tags": _vm.selectedTags,
                              "org-type": _vm.modelName,
                              disabled: !_vm.isEditMode
                            },
                            on: {
                              changed: function($event) {
                                return _vm.onTagsChanged($event, "name")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }