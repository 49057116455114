var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "business-wrap" }, [
    _c(
      "div",
      { staticClass: "business-wrap-content" },
      [
        _vm.isAdmin()
          ? _c("winbox-company-header", {
              attrs: {
                title: _vm.mainTitle,
                "enable-status-toggle": true,
                "active-label": "Unstarted",
                "inactive-label": "Completed",
                "in-progress-label": "In Progress",
                "escalated-label": "Escalated"
              },
              on: { "toggle-status": _vm.updateVisibleStatus }
            })
          : _c("winbox-company-header", {
              attrs: {
                title: _vm.mainTitle,
                "enable-status-toggle": true,
                "active-label": "Unstarted",
                "inactive-label": "Completed",
                "in-progress-label": "In Progress"
              },
              on: { "toggle-status": _vm.updateVisibleStatus }
            }),
        _vm._v(" "),
        _c("div", { key: _vm.statusKey, staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c("winbox-data-table", {
                staticClass: "main-data-table",
                attrs: {
                  data: _vm.visibleData,
                  columns: _vm.columns,
                  options: _vm.tableOptions,
                  slots: _vm.specificSlots
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: _vm.tableProps.entity,
                      fn: function(data) {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                "data-tooltip": data.row.id,
                                to: {
                                  path: _vm.constructEntityUrl(
                                    data.row.entityable_id,
                                    data.row.entityable_type
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(data.row[_vm.tableProps.entity]) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: _vm.tableProps.task,
                      fn: function(data) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(data.row[_vm.tableProps.task].name) +
                              "\n          "
                          )
                        ]
                      }
                    },
                    {
                      key: _vm.tableProps.details,
                      fn: function(data) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(data.row[_vm.tableProps.details]) +
                              "\n          "
                          )
                        ]
                      }
                    },
                    {
                      key: _vm.tableProps.completionGoal,
                      fn: function(data) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(data.row[_vm.tableProps.completionGoal]) +
                              "\n          "
                          )
                        ]
                      }
                    },
                    {
                      key: _vm.tableProps.priority,
                      fn: function(data) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(data.row[_vm.tableProps.priority]) +
                              "\n          "
                          )
                        ]
                      }
                    },
                    {
                      key: _vm.tableProps.status,
                      fn: function(data) {
                        return [
                          _c("winbox-select", {
                            key: data.row.id,
                            attrs: {
                              value: data.row[_vm.tableProps.status],
                              options: _vm.possibleStatuses,
                              searchable: false
                            },
                            on: {
                              input: function($event) {
                                return _vm.onStatusChange(data.row, $event)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              _c(
                "winbox-modal",
                {
                  ref: _vm.escalateModalMyWorkRef,
                  attrs: {
                    name: _vm.escalateModalMyWorkRef,
                    "enable-footer-buttons": true,
                    "click-to-close": false,
                    title: "Escalate Task",
                    "save-text": "Escalate"
                  },
                  on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
                },
                [
                  _c("winbox-escalate-task", {
                    ref: "escalateMyWorkTaskRef",
                    attrs: {
                      "backend-errors": _vm.backendErrors,
                      "default-admin-id": _vm.defaultAdminId
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }