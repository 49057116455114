export default {
  BRANDS: state => {
    return state.brands;
  },

  BRAND_TAGS_BY_ID(state) {
    return id => state.brandTags[id] || [];
  },

  BRAND_SOCIALS_BY_ID(state) {
    return id => state.brandSocials[id] || [];
  },

  BRAND_NICKNAMES_BY_ID(state) {
    return id => state.brandNicknames[id] || [];
  },

  BRAND_INDUSTRIES_BY_ID(state) {
    return id => state.brandIndustries[id] || [];
  },

  BRAND_CONTACTS_BY_ID(state) {
    return id => state.brandContacts[id] || [];
  },

  BRAND_NOTES_BY_ID(state) {
    return id => state.brandNotes[id] || [];
  },

  BRAND_LOGS_BY_ID(state) {
    return id => state.brandLogs[id] || [];
  },

  BRAND_SPECIALITIES_BY_ID(state) {
    return id => state.brandSpecialities[id] || [];
  },

  BRAND_BUSINESSES_BY_ID(state) {
    return id => state.brandBusinesses[id] || [];
  },
};
