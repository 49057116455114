// Companies
import Companies from "@/components/Pages/Companies/Companies";
import CompanyEntry from "@/components/Pages/Companies/CompanyEntry";
import CompanyProfile from "@/components/Pages/Companies/CompanyProfile";
import CompanyLocations from "@/components/Pages/Companies/CompanyLocations";
import CompanyLocationsDetail from "@/components/Pages/Companies/CompanyLocationsDetail";
import CompanyContacts from "@/components/Pages/Companies/CompanyContacts";
import CompanyAgencies from "@/components/Pages/Companies/CompanyAgencies";
import CompanyBrands from "@/components/Pages/Companies/CompanyBrands";
import CompanyHierarchy from "@/components/Pages/Companies/CompanyHierarchy";
import CompanyNotes from "@/components/Pages/Companies/CompanyNotes";
import CompanyInternalNotes from "@/components/Pages/Companies/CompanyInternalNotes";
import CompanyTags from "@/components/Pages/Companies/CompanyTags";
import CompanyAuditLog from "@/components/Pages/Companies/CompanyAuditLog";
import CompanyAgencyContacts from "@/components/Pages/Companies/CompanyAgencyContacts";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";

export default [
  {
    path: WinboxRoutePaths.COMPANIES,
    name: "Companies",
    component: Companies,
    meta: {
      breadcrumb: "Companies"
    }
  },
  {
    path: `${WinboxRoutePaths.COMPANIES}/:id`,
    component: CompanyEntry,
    redirect: { name: "Company Profile" },
    children: [
      {
        path: WinboxRoutePaths.COMPANY_PROFILE,
        component: CompanyProfile,
        name: "Company Profile",
        meta: {
          breadcrumb: "Company Profile"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_LOCATIONS,
        component: CompanyLocations,
        meta: {
          breadcrumb: "Locations"
        }
      },
      {
        path: `${WinboxRoutePaths.COMPANY_LOCATIONS}/:locationId`,
        component: CompanyLocationsDetail,
        meta: {
          breadcrumb: "Location Details"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_CONTACTS,
        component: CompanyContacts,
        meta: {
          breadcrumb: "Contacts"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_AGENCY_CONTACTS,
        component: CompanyAgencyContacts,
        meta: {
          breadcrumb: "Agency Contacts"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_AGENCIES,
        component: CompanyAgencies,
        meta: {
          breadcrumb: "Agencies"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_BRANDS,
        component: CompanyBrands,
        meta: {
          breadcrumb: "Brands"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_HIERARCHY,
        component: CompanyHierarchy,
        meta: {
          breadcrumb: "Hierarchy"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_NOTES,
        component: CompanyNotes,
        meta: {
          breadcrumb: "Notes"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_TAGS,
        component: CompanyTags,
        meta: {
          breadcrumb: "Tags"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_INTERNAL_NOTES,
        component: CompanyInternalNotes,
        meta: {
          breadcrumb: "Internal Notes"
        }
      },
      {
        path: WinboxRoutePaths.COMPANY_AUDIT_LOG,
        component: CompanyAuditLog,
        meta: {
          breadcrumb: "Audit Log"
        }
      }
    ]
  }
];
