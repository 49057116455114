import { mapActions, mapState } from "vuex";
import * as UserTasksApi from "@/api/user-tasks/user-tasks";

export const WinboxAssignEntityTasksHelperMixin = {
  data() {
    return {
      isAssignNewEntityTasksLoaded: false,
      assignNewEntityTasks: null,
      assignModal: "assignModal"
    };
  },
  computed: {
    ...mapState([
      'tasks',
      'users',
    ]),
  },
  methods: {
    ...mapActions([
      'getTasks',
    ]),
    async onAssignModal() {
      await this.getTasks();
      this.isAssignNewEntityTasksLoaded = true;
      this.$refs[this.assignModal].show();
    },
    onAssignCancel() {
      this.$refs[this.assignModal].hide();
    },
    onAssignSave() {
      const locations = this.$route.query.locations;
      const jobs = this.$route.query.jobs;
      const ranks = this.$route.query.ranks;
      const assignEntityTasksVm = this.$refs.assignEntityTasksRef;
      const $v = assignEntityTasksVm.$v;

      $v.$touch();

      if (!$v.$error) {
        this.isAssignNewEntityTasksLoaded = false;
        const payload = {
          task: {
            task_type_id: assignEntityTasksVm.task.taskType.value,
            details: assignEntityTasksVm.task.details,
            user_id: assignEntityTasksVm.task.user.value,
            entity_ids: this.markedRows,
            entity_type: this.$router.currentRoute.path.substr(1, this.$router.currentRoute.path.split('/')[1].length),
            completion_goal: new Date(assignEntityTasksVm.task.completionGoalTo).toISOString().slice(0, 19).replace("T", " "),
            priority: assignEntityTasksVm.task.priority
          },
          query: this.searchQuery,
          locations,
          jobs,
          ranks,
        };

        UserTasksApi.createUserTask(payload)
          .catch((e) => {
            this.afterSaveFail(e.response, payload.name);
            throw e;
          })
          .finally(() => {
            this.isAssignNewEntityTasksLoaded = true;
            this.onAssignCancel();
          });
      }
    },
    afterSaveFail(response, name) {
      if (response && response.data) {
        const errors = response.data.errors;
        if (errors) {
          Object.entries(errors).forEach(([key, value]) => {
            value.forEach( error => { this.$notify({ type: 'error', text: key+' '+error, title: 'Error message', group: "main" });} );
          });
        }
      }
    }
  }
};
