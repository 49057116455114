export const singleItem = {
  id: 1,
  business_id: 1,
  name: "Abbott-Kub",
  location_type: "corporate_hq",
  primary: false,
  address_1: "778 Hand Common",
  address_2: "Suite 934",
  address_3: "Apt. 336",
  city: "North Wesley",
  state: "Maryland",
  zip: "56233-5865",
  fax: "139.935.3538",
  phone: "527.127.4296",
  toll_free: "295.488.9384",
  country: "Cayman Islands",
  created_at: "2019-11-19T14:05:52.179Z",
  updated_at: "2019-11-19T14:05:52.179Z",
  _relatedContacts: 2
};

export const itemList = [
  { ...singleItem },
  {
    id: 2,
    name: "Bergen County",
    location_type: "corporate_hq",
    city: "Alpine",
    status: "",
    _relatedContacts: 2
  }
];
