import { LocationsUrl } from "@/constants/LocationsUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllLocations() {
  const url = LocationsUrl.base;
  return getGenericFullResponse(url);
}

export function getLocationPeople(id) {
  const url = `${LocationsUrl.base}/${id}/location_persons`;
  return getGenericFullResponse(url);
}
