<template>
  <div :class="['notification', type, { 'with-close': closeable }]">
    <winbox-icon
      v-if="closeable"
      color="#fff"
      class="close-icon"
      icon="times"
      @click.native="dismiss"
    />
    <div>
      <winbox-icon
        color="#fff"
        class="notification-icon"
        :icon="notificationIcon"
      />
      <div class="notification-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";

export default {
  name: "WinboxNotification",
  components: {
    WinboxIcon
  },
  computed: {
    notificationIcon() {
      let result = "";

      switch (this.type) {
        case "info":
          result = "exclamation-circle";
          break;
      }

      return result;
    }
  },
  props: {
    type: {
      type: String,
      required: true,
      note: 'Possible values: "info"'
    },
    closeable: {
      type: Boolean,
      default: true,
      required: false,
      note: 'Added an icon "close" to a notification'
    }
  },
  methods: {
    dismiss() {
      this.$el.classList.add("fadeOut");

      setTimeout(() => {
        if (this.$el) {
          this.$el.classList.add("dismiss");
        }
      }, 500);

      this.$emit("dismiss");
    },
    resetDismiss() {
      this.$el.classList.remove("fadeOut");
      this.$el.classList.remove("dismiss");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

$padding-inside: 12px;
$transition-speed: 0.4s;

.notification {
  position: relative;
  padding: $padding-inside;
  margin-bottom: 20px;
  border-radius: 4px;
  opacity: 1;
  transition: all $transition-speed;
  color: #fff;

  &.with-close {
    padding-right: 45px;
  }

  &.fadeOut {
    opacity: 0;
  }

  &.dismiss {
    display: none;
  }

  .close-icon {
    position: absolute;
    top: $padding-inside;
    right: $padding-inside;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity $transition-speed;

    &:hover {
      opacity: 1;
    }
  }

  &.info {
    background-color: $winbox-success;
  }

  a {
    color: #fff;
  }
}
.notification-icon {
  margin-right: 10px;
}
.notification-content {
  display: inline-block;
}
</style>
