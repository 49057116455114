<template>
  <div class="winbox-add-brand-sponsorship">
    <div v-if="!showNextStep" class="step-one">
      <winbox-server-data-table
        class="winbox-add-relationships-table"
        :url="url"
        :columns="columns"
        :options="tableOptions"
        :slots="specificSlots"
        :perPage="10"
        :responseFunction="enhanceData"
        theme="with-borders-theme"
        @row-click="onRowClick"
        ref="winboxDataTable"
      >
        <template :slot="tableProps.linked" slot-scope="data">
          <button
            class="button select-button"
            v-bind:class="{ active: data.row[tableProps.linked] }"
          >
            <winbox-icon
              icon="plus"
              :color="
                data.row[tableProps.linked]
                  ? Colors.system.lightGray
                  : Colors.system.primaryLight
              "
            />
          </button>
        </template>
      </winbox-server-data-table>
    </div>
    <div v-if="showNextStep && entity" class="step-two">
      <div class="columns">
        <div class="column is-4">
          <winbox-select
            form-label="Category"
            v-model="entity.category"
            :options="possibleCategories"
            label="label"
            :class="status($v.entity.category)"
          />
        </div>
        <div class="column is-4">
          <winbox-select
            form-label="Affiliation"
            v-model="entity.affiliation"
            :options="possibleAffiliations"
            label="label"
            :class="status($v.entity.affiliation)"
          />
        </div>
        <div class="column is-4">
          <winbox-select
            form-label="Level"
            v-model="entity.level"
            :options="possibleLevels"
            label="label"
            :class="status($v.entity.level)"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <winbox-input
            label="Deal Amount"
            type="number"
            v-model="entity.deal_amount"
            :class="status($v.entity.deal_amount)"
          />
        </div>
        <div class="column is-4">
          <winbox-input
            label="Season Start Year"
            v-model="entity.season_start_year"
            :class="status($v.entity.season_start_year)"
          />
        </div>
        <div class="column is-4">
          <winbox-input
            label="Season End Year"
            v-model="entity.season_end_year"
            :class="status($v.entity.season_end_year)"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <winbox-textarea
            form-label="Placement"
            v-model="entity.placement"
            :class="status($v.entity.placement)"
          />
        </div>
        <div class="column is-6">
          <winbox-textarea
            form-label="Description & Activation Details"
            v-model="entity.activation"
            :class="status($v.entity.activation)"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4" style="margin-left: auto; display: flex; justify-content: flex-end;">
          <div style="min-width: 150px; flex-shrink: 0;">
            <winbox-select
              form-label="Status"
              v-model="entity.status"
              :options="possibleStatuses"
              label="label"
              :class="status($v.entity.status)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxTextarea from "@/components/Atoms/WinboxTextarea";
import WinboxServerDataTable from "@/components/Molecules/WinboxServerDataTable";

import cloneDeep from "clone-deep";
import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { required, maxLength, minLength, numeric } from "vuelidate/lib/validators";

import { BusinessStatuses } from "@/constants/BusinessStatuses";
import { SponsorAffiliations, SponsorCategories, SponsorLevels } from "@/constants/BrandSponsorshipOptions";


export default {
  name: "WinboxAddBrandSponsorships",
  components: {
    WinboxIcon,
    WinboxInput,
    WinboxSelect,
    WinboxTextarea,
    WinboxServerDataTable,
  },
  props: {
    config: {
      required: true,
      type: Object,
    },
    url: {
      required: true,
      type: String,
    },
    responseFunction: Function,
  },
  validations: {
    entity: {
      category: {
        required
      },
      affiliation: {
        required
      },
      level: {
        required
      },
      placement: {
      },
      season_start_year: {
        required,
        numeric,
        maxLength: maxLength(4),
        minLength: minLength(4)
      },
      season_end_year: {
        required,
        numeric,
        maxLength: maxLength(4),
        minLength: minLength(4),
        validEndYear(value) {
          if (this.entity.season_start_year && value) {
            return parseInt(value) >= parseInt(this.entity.season_start_year)
          }
          return true
        }
      },
      deal_amount: {
      },
      activation: {
      },
      status: {
        required
      }
    },
  },
  data() {
    return {
      entity: {
        category: null,
        affiliation: null,
        level: null,
        placement: '',
        season_start_year: null,
        season_end_year: null,
        deal_amount: null,
        activation: '',
        status: null,
      },
      showNextStep: false,
      Colors,
      tableProps: this.config.tableProps,
      dataset: [],
      columns: Object.values(this.config.tableProps),
      tableOptions: this.config.tableOptions,
      specificSlots: this.config.specificSlots,
      totalCount: 0,
    };
  },
  computed: {
    ...mapState([
      'brandSponsorships',
    ]),
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    possibleAffiliations() {
      return Object.values(SponsorAffiliations);
    },
    possibleCategories() {
      return Object.values(SponsorCategories);
    },
    possibleLevels() {
      return Object.values(SponsorLevels);
    },
  },
  methods: {
    ...mapActions([
    ]),
    toggleLinked(id) {
      this.dataset.forEach((item) => {
        this.$set(item, this.tableProps.linked, false);
      });
      const found = this.dataset.find((item) => item.id === id);
      if (found) {
        this.$set(found, this.tableProps.linked, !found[this.tableProps.linked]);
        this.entity = {
          ...found,
          status: this.possibleStatuses[0],
        };
        this.$refs.winboxDataTable.$refs.vTableRef.setData({
          data: { data: this.dataset, count: this.totalCount },
        });
      }
    },
    goToNextStep() {
      this.showNextStep = true;
    },
    getDeepCloneDataset() {
      return cloneDeep(this.dataset);
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    onChangeEntityFields(e) {
      this.entity = {
        ...this.entity,
        ...e,
      };
    },
    onRowClick(e) {
      this.toggleLinked(e.row.id);
    },
    enhanceData(data, count) {
      this.totalCSount = count;
      this.dataset = this.responseFunction(data, count);
      return this.dataset;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-add-relationships-table {
  ::v-deep {
    thead {
      th {
        vertical-align: middle;
        &:last-child {
          width: 130px;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
      td {
        border-left: none;
        border-right: none;

        &:first-child {
          border-left: 1px solid $winbox-secondary-title;
        }
        &:last-child {
          border-right: 1px solid $winbox-secondary-title;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
    .select-button {
      border-color: $winbox-primary-active-bg-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;

      &.active {
        background: $winbox-primary-active-bg-color;
      }
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .winbox-icon {
        font-size: $winbox-font-size-small;
      }
    }
  }
}
.action-button {
  @include profile-action-button;
}
</style>
