<template>
  <span class="icon" :class="[containerClass]">
    <i :class="classNames" :style="style" />
  </span>
</template>

<script>
export default {
  name: "WinboxIcon",
  props: {
    namespace: {
      required: false,
      default: "fas",
      type: String,
      note: "Default namespace"
    },
    icon: {
      required: true,
      type: String,
      note:
        'the name of the fontAwesome icon to display ie. "cross" for "fa-cross"'
    },
    additionalClass: {
      required: false,
      default: "",
      type: String
    },
    containerClass: {
      required: false,
      type: String,
      default: () => ""
    },
    color: {
      default: "#fff",
      type: String,
      note: "the color of the icon in hex"
    }
  },
  computed: {
    classNames() {
      return `${this.namespace} fa-${this.icon} ${this.additionalClass} winbox-icon`;
    },
    style() {
      return `color: ${this.color};`;
    }
  }
};
</script>

<style scoped></style>
