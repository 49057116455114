<template>
  <div class="app-header">
    <winbox-search-main />
  </div>
</template>

<script>
import WinboxSearchMain from "@/components/Molecules/WinboxSearchMain";

export default {
  name: "WinboxAppHeader",
  components: {
    WinboxSearchMain
  },
  data() {
    return {
      task: null,
    };
  },
  computed: {
    possibleTasks() {
      let tasks = [
        {
          label: 'Task1',
          value: 1,
        },
        {
          label: 'Task2',
          value: 2,
        },
        {
          label: 'Task3',
          value: 3,
        },
      ];
      return tasks;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  ::v-deep {
    .search-main {
      flex-grow: 1;
      display: flex;
    }
  }
}
</style>
