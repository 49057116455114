import * as DataImportApi from "@/api/data_import/data_import";
import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  importData({ commit, getters }, { payload }) {
    return DataImportApi.importData(payload).then(({ data }) => {
      return { data };
    });
  },

  getDataImportTemplates({ commit }) {
    return DataImportApi.getDataImportTemplates().then((data) => {
      commit(MutationTypes.SET_DATA_IMPORT_TEMPLATES, data);
    });
  },
};
