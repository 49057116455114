<template>
  <div class="winbox-task-completion">
    <winbox-card class="item-overview">
      <div class="item-title">
        <div class="columns">
          <div class="column is-narrow">
            <winbox-title title="Newly Added" type="medium" />
          </div>
          <div class="column item-label is-narrow">
            <winbox-title :title="title" type="medium" />
          </div>
        </div>
      </div>
      <div class="item-graph">
        <winbox-multi-axes-chart
          :chartData="currentChartData"
          :options="chartOptions"
        />
      </div>
      <div class="item-draggable">
        <winbox-toggle-menu :options="rowOptions" />
      </div>
    </winbox-card>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxToggleMenu from "@/components/Molecules/WinboxToggleMenu";

import WinboxMultiAxesChart from "@/components/Molecules/charts/WinboxMultiAxesChart";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

export default {
  name: "WinboxTotalConfirmed",
  components: {
    WinboxCard,
    WinboxTitle,
    WinboxToggleMenu,
    WinboxMultiAxesChart,
  },
  props: {
    value: {
      required: true,
      type: Object,
      note: "Main data object",
    },
  },
  data() {
    return {
      Colors: Colors,
      chartData: this.value.chartData,
      chartOptions: Utils.getDefaultChartOptions(),
      selectedType: 1,
      rowOptions: [
        {
          id: 1,
          title: "Companies",
          icon: "",
          action: (data) => {
            this.selectedType = 1;
          },
        },
        {
          id: 2,
          title: "Agencies",
          icon: "",
          action: (data) => {
            this.selectedType = 2;
          },
        },
        {
          id: 4,
          title: "Brands",
          icon: "",
          action: (data) => {
            this.selectedType = 4;
          },
        },
        {
          id: 5,
          title: "Contacts",
          icon: "",
          action: (data) => {
            this.selectedType = 5;
          },
        },
      ],
    };
  },
  computed: {
    currentChartData() {
      let currentChart = {};
      currentChart.labels = this.chartData.labels;
      currentChart.datasets = [this.chartData.datasets[this.selectedType - 1]];
      return currentChart;
    },
    title() {
      return `${this.value.from.format("MMM Y")} - ${this.value.to.format(
        "MMM Y"
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.item-draggable {
  @include card-draggable-icon;
}
.item-title {
  .winbox-title {
    margin: 0 0 1rem;
  }
}
.item-label {
  ::v-deep {
    .subtitle {
      font-weight: 300;
      color: $winbox-secondary-title;
    }
  }
}
.item-graph {
  ::v-deep {
    canvas {
      height: 220px !important;
    }
  }
}
</style>
