var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap-speciality-widget" }, [
    _c("div", { staticClass: "columns is-desktop" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "winbox-card",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.hideSearchModal,
                  expression: "hideSearchModal"
                }
              ],
              scopedSlots: _vm._u(
                [
                  _vm.addSearchSupport
                    ? {
                        key: "headerLeftIcon",
                        fn: function() {
                          return [
                            _c("winbox-icon", {
                              staticClass: "search-main-icon",
                              class: {
                                active: _vm.filterValue || _vm.isSearchVisible
                              },
                              attrs: {
                                icon: "search",
                                color: "rgba(255, 255, 255, 0.75)"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.showSearchModal.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : null,
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.placeholderPossible) +
                            "\n        "
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "headerIcon",
                    fn: function() {
                      return [
                        !_vm.disabled
                          ? _c("winbox-icon", {
                              attrs: {
                                icon: "plus-circle",
                                color: _vm.Colors.system.white
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.addNewTag.apply(null, arguments)
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _vm._v(" "),
              _vm._v(" "),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "left-search-wrap",
                  class: { visible: _vm.isSearchVisible }
                },
                [
                  _c(
                    "div",
                    { staticClass: "left-search-box" },
                    [
                      _c("winbox-input", {
                        attrs: { type: "search", placeholder: "Search..." },
                        model: {
                          value: _vm.filterValue,
                          callback: function($$v) {
                            _vm.filterValue = $$v
                          },
                          expression: "filterValue"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                !_vm.unselectedPossibleTags.length
                  ? _c("p", { staticClass: "no-data" }, [_vm._v("No items")])
                  : _c(
                      "ul",
                      { staticClass: "tags-list" },
                      [
                        _vm._l(_vm.unselectedPossibleTags, function(item) {
                          return [
                            _c("li", { key: item.id }, [
                              _c(
                                "div",
                                {
                                  staticClass: "columns is-gapless is-vcentered"
                                },
                                [
                                  _c("div", { staticClass: "column" }, [
                                    item[_vm.propId]
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item[_vm.propDesc]) +
                                              "\n                    "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !item[_vm.propId]
                                      ? _c(
                                          "div",
                                          { staticClass: "new-tag-input-wrap" },
                                          [
                                            _c("winbox-input", {
                                              model: {
                                                value: item[_vm.propDesc],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    _vm.propDesc,
                                                    $$v
                                                  )
                                                },
                                                expression: "item[propDesc]"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "column is-narrow action-right"
                                    },
                                    [
                                      item[_vm.propId]
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "button",
                                              attrs: {
                                                disabled:
                                                  item.isSelected ||
                                                  _vm.disabled
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addTagToSelected(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("winbox-icon", {
                                                attrs: {
                                                  icon: "arrow-right",
                                                  color:
                                                    _vm.Colors.system
                                                      .primaryLight
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item[_vm.propId]
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "button",
                                              on: {
                                                click: function($event) {
                                                  return _vm.createNewTag(item)
                                                }
                                              }
                                            },
                                            [
                                              _c("winbox-icon", {
                                                attrs: {
                                                  icon: "check",
                                                  color:
                                                    _vm.Colors.system
                                                      .primaryLight
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item[_vm.propId]
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "button",
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeNewlyTag(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("winbox-icon", {
                                                attrs: {
                                                  icon: "trash",
                                                  color: _vm.Colors.system.error
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        })
                      ],
                      2
                    )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "winbox-card",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.placeholderSelected) +
                          "\n        "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                !_vm.selected.length || !_vm.possibleTags.length
                  ? _c("p", { staticClass: "no-data" }, [_vm._v("No items")])
                  : _c(
                      "ul",
                      { staticClass: "tags-list" },
                      _vm._l(_vm.selected, function(item, key) {
                        return _c("li", { key: key }, [
                          _c(
                            "div",
                            { staticClass: "columns is-gapless is-vcentered" },
                            [
                              _c("div", { staticClass: "column is-7 is-10" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item[_vm.propDesc]) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column is-2 action-right" },
                                [
                                  !_vm.disabled
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "button button-remove",
                                          on: {
                                            click: function($event) {
                                              return _vm.removeTag(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("winbox-icon", {
                                            attrs: {
                                              icon: "trash",
                                              color: _vm.Colors.system.error
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    )
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }