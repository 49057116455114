import { mapActions, mapGetters, mapState } from "vuex";

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxTextarea from "@/components/Atoms/WinboxTextarea";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxSocialMedia from "@/components/Molecules/CompanyProfile/WinboxSocialMedia";
import WinboxNicknames from "@/components/Molecules/CompanyProfile/WinboxNicknames";
import WinboxIndustries from "@/components/Molecules/CompanyProfile/WinboxIndustries";
import WinboxDisciplines from "@/components/Molecules/CompanyProfile/WinboxDisciplines";
import WinboxRevenue from "@/components/Molecules/CompanyProfile/WinboxRevenue";
import WinboxDemographics from "../../Molecules/BrandProfile/WinboxDemographics.vue";

import WinboxSpecialityTags from "@/components/Organisms/WinboxSpecialityTags";
import WinboxSpecialityTagsList from "@/components/Organisms/WinboxSpecialityTagsList";

import * as BusinessesApi from "@/api/businesses/businesses";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { OrgTypes } from "@/constants/OrgTypes";
import { CurrencyOptions } from "@/constants/CurrencyOptions";
import { DateYearMonths } from "@/constants/DateYearMonths";
import { required, maxLength } from "vuelidate/lib/validators";
import cloneDeep from "clone-deep";

import { EditProfileMixin } from "@/components/Pages/Generic/EditProfileMixin";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

export const BusinessEntityProfileMixin = {
  mixins: [
    EditProfileMixin,
    RolesMixin
  ],
  components: {
    WinboxIcon,
    WinboxCard,
    WinboxInput,
    WinboxTextarea,
    WinboxSelect,
    WinboxLargeIndicator,
    WinboxSocialMedia,
    WinboxNicknames,
    WinboxIndustries,
    WinboxDemographics,
    WinboxDisciplines,
    WinboxSpecialityTags,
    WinboxSpecialityTagsList,
    WinboxRevenue
  },
  data() {
    return {
      isLoaded: false,
      revenuesLoaded: false,
      entity: null,
      Colors,
      revenueItems: [],
      revenuesDataUpdate: [],
      revenuesDataCreate: [],
      employeeRangeTimeout: null,
      editMode: false,
    };
  },
  validations: {
    entity: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      org_type: {
        required
      },
      nickname: {
      },
      long_description: {
      },
      short_description: {
      },
      year_founded: {
        winboxValidYear: Utils.winboxValidYear
      },
      website: {
        required,
        winboxValidUrl: Utils.winboxValidUrl,
        maxLength: maxLength(40)
      }
    }
  },
  beforeMount() {
    this.initData = cloneDeep(this.entity);
  },
  computed: {
    ...mapState([
      'employeeRanges',
      'naics',
      'specialities',
      'businessRevenues',
      'businessSpecialities',
    ]),
    id() {
      return +this.$route.params.id;
    },
    entityTypes() {
      return Object.entries(OrgTypes).map(item => {
        return {
          value: item[0],
          label: item[1]
        };
      });
    },
    fiscalCloses() {
      return [...DateYearMonths];
    },
    possibleNaics() {
      return this.naics.map(item => {
        return {
          value: item.code,
          label: `${item.code} - ${item.title}`
        };
      });
    },
    possibleEmployeeRanges() {
      return this.employeeRanges.map(item => {
        return {
          value: item.id,
          label: item.range
        };
      });
    },
    possibleTags() {
      return Utils.sortAlphabetic(this.specialities, "name");
    },
    entitySpecialities() {
      return this.businessSpecialities;
    },
    selectedTags() {
      return Utils.sortAlphabetic(
        this.entitySpecialities.map(item => {
          return {
            ...item,
            name: this.findTagEntityName(item.speciality_list_id)
          };
        }),
        "name"
      );
    }
  },
  methods: {
    ...mapActions([
      'getBusinessLogs',
      'getBrandLogs',
      'updateBusinessRevenuesList',
      'getBusinessRevenues',
    ]),
    onRevenueChanged(e) {
      this.tryToSaveRevenues([...e]);
    },
    preparePayloadObject(item) {
      return {
        id: item.id,
        revenues: Number(item.revenues.replace(/[^0-9.-]+/g,"")),
        year: item.year
      };
    },
    tryToSaveRevenues(revenues) {
      this.revenuesDataCreate = [];

      revenues.forEach((item, index) => {
        this.revenuesDataCreate.push(this.preparePayloadObject(item));
      });

      this.checkRevenuesSaveQueue(this.revenuesDataCreate);
    },
    checkRevenuesSaveQueue(revenues) {
      this.updateBusinessRevenuesList(this.id, revenues);
      this.revenueItems = revenues;
    },
    updateBusinessRevenuesList(id, payload) {
      this.$store
        .dispatch("updateBusinessRevenuesList", {
          id: id,
          payload: {
            entity_revenues: payload,
          },
        })
    },
    updateBusinessRevenues(id, payload) {
      this.revenuesLoaded = false;

      BusinessesApi.updateBusinessRevenueById(id, payload)
        .then(() => {
          this.revenuesLoaded = true;
        })
        .catch(e => {
          this.revenuesLoaded = true;
          throw e;
        });
    },
    sortRevenueItems() {
      Utils.sortNumericByPropComplex(this.revenueItems, "year", true);
    },
    removeBusinessRevenues(revenueId) {
      this.revenuesLoaded = false;

      BusinessesApi.deleteBusinessRevenueById(revenueId).then(() => {
        this.revenuesLoaded = true;
      });
    },
    getBusinessRevenues() {
      this.revenuesLoaded = false;

      return BusinessesApi.getBusinessRevenues(this.id).then(({ data }) => {
        this.revenueItems = data;
        this.revenuesLoaded = true;
        this.sortRevenueItems();
      });
    },
    createNewTagEntity(newTags) {
      const payload = newTags[0];

      return this.$store.dispatch("createSpeciality", payload);
    },
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        speciality_list_ids: selectedTags.map((selectedTag) => selectedTag.speciality_list_id),
      };

      return this.$store.dispatch("assignBusinessSpeciality", {
        id: this.id,
        payload: payload
      });
    },
    updateBusinessTagEntity(updatedTags) {
      // Only year on update
      const payload = {
        year: updatedTags[0].year
      };

      return this.$store.dispatch("updateBusinessSpeciality", {
        id: this.id,
        specialityId: updatedTags[0].id,
        payload: payload
      });
    },
    removeBusinessTagEntity(removedTags) {
      const deleteItem = removedTags[0];

      return this.$store.dispatch("deleteBusinessSpeciality", {
        id: this.id,
        specialityId: deleteItem.id
      });
    },
    findTagEntityName(id) {
      const foundItem = this.possibleTags.find(item => item.id === id);
      return foundItem ? foundItem.name : "";
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    updateBreadcrumbsTitles(entity, type) {
      let newTitle = type == 'Contact' ? `${entity.fname} ${entity.lname}` : entity.name;
      window.breadcrumbs[window.breadcrumbs.length - 1].to.meta.breadcrumb = newTitle;
      Utils.EventBus.$emit('updateBreadcrumbs');
    },
    saveEntity() {
    },
    afterSaveEntity() {
      this.initData = cloneDeep(this.entity);
      this.dataUpdate = {};
    },
  }
};
