var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entity-tags-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "entity-tags" },
            [
              _c("winbox-title", {
                staticClass: "main-title",
                attrs: { title: _vm.mainTitle }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-speciality-tags", {
                      attrs: {
                        "possible-tags": _vm.possibleTags,
                        "entity-tags": _vm.selectedTags,
                        "prop-selected-parent-id": "tag_id",
                        "placeholder-selected": "Selected Tags"
                      },
                      on: {
                        changed: function($event) {
                          return _vm.onTagsChanged($event, "name")
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }