<template>
  <div class="winbox-modal">
    <modal
      :name="name"
      :click-to-close="clickToClose"
      :width="width"
      :height="height"
      transition="nice-modal-fade"
      @before-open="$emit('before-open', $event)"
      @before-close="$emit('before-close', $event)"
      @opened="$emit('opened', $event)"
      @closed="$emit('closed', $event)"
    >
      <div v-if="title" class="winbox-modal-header">
        <div class="winbox-modal-header-title">
          {{ title }}
        </div>
        <div v-if="showHeaderCloseIcon" class="winbox-modal-header-icon">
          <button
            tabindex="0"
            type="button"
            class="button"
            @click="reset"
            @keydown.enter="onEnter"
          >
            <winbox-icon icon="times" />
          </button>
        </div>
      </div>
      <div class="winbox-modal-container">
        <slot />
      </div>
      <div v-if="enableFooterButtons" class="winbox-footer-buttons">
        <button class="button" @click="onCancel">{{ cancelText }}</button>
        <button
          v-if="saveText"
          class="button is-primary"
          :disabled="saveDisabled"
          @click="onSave"
        >
          {{ saveText }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";

export default {
  name: "WinboxModal",
  components: {
    WinboxIcon
  },
  props: {
    name: {
      type: String,
      required: true
    },
    showHeaderCloseIcon: {
      required: false,
      default: true
    },
    width: {
      type: [String, Number],
      required: false,
      default: 600
    },
    height: {
      type: [String, Number],
      required: false,
      default: "auto"
    },
    clickToClose: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    enableFooterButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelText: {
      type: String,
      required: false,
      default: "Cancel"
    },
    saveText: {
      type: String,
      required: false,
      default: "Save"
    },
    saveDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    canReset: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.onEnter();
      }
    });
  },
  methods: {
    reset() {
      if(this.canReset) {
        this.onCancel();
      }
      else {
        this.hide();
      }
    },
    show() {
      this.$modal.show(this.name);
    },
    hide() {
      this.$modal.hide(this.name);
    },
    onCancel() {
      this.$emit("on-cancel");
    },
    onSave() {
      this.$emit("on-save");
    },
    onEnter() {
      this.$emit('enter-press');
    },
  },
  beforeDestroy() {
    window.removeEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.onEnter();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

::v-deep {
  .v--modal-overlay {
    .v--modal-box {
      overflow: auto;
    }
  }
}

.winbox-modal-header {

  background: $winbox-primary-active-bg-color;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: $winbox-font-size-base;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.winbox-modal-header-icon {
  .button {
    background: transparent;
    border-color: transparent;
    &:focus {
      border-color: #fff;
    }
  }
  ::v-deep {
    .winbox-icon {
      font-size: $winbox-font-size-big;
    }
  }
}
.winbox-modal-container,
.winbox-footer-buttons {
  padding: 1rem;
}
.winbox-footer-buttons {
  display: flex;
  justify-content: space-between;
}

.winbox-modal-scrollable {
  .winbox-footer-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .winbox-modal-container {
    overflow: auto;
    position: absolute;
    width: 100%;
    bottom: 68px;
    top: 52px;
  }
}
</style>
