<template>
  <div class="entry-wrap">
    <div class="columns is-vcentered entry-top">
      <div class="column">
        <slot name="entityName" />
      </div>
      <div
        v-if="hasProofing && entity.proofed_by_name"
        class="column is-narrow header-action-wrap"
      >
        Last Proof: {{ entity.proofed_by_name }} - {{ new Date(entity.proofed_at).toLocaleDateString() }}
      </div>
      <div v-if="hasProofing" class="column is-narrow header-action-wrap"
      >
        <button
          class="button"
          @click="$emit('submit-proof')"
        >
          <span>Proof</span>
        </button>
      </div>
      <div class="column is-narrow header-action-wrap">
        <button
          class="button"
          v-if="hasAssign && isAdmin()"
          @click="$emit('on-assign')"
        >
          <winbox-icon
            class="add-entity-icon"
            :color="Colors.system.lightGray"
            icon="plus"
          />
          <span>Assign</span>
        </button>
      </div>
      <div v-if="enableSwitcher" class="column is-narrow">
        <div class="columns is-vcentered is-gapless">
          <div class="column is-narrow">
            <span class="switch-label">Active?</span>
          </div>
          <div class="column is-narrow">
            <winbox-switch v-model="entity.published" />
          </div>
        </div>
      </div>
    </div>
    <div class="columns company-entry-content">
      <div class="column is-3">
        <div class="entry-base-information">
          <slot name="parentLink" />
        </div>
        <div v-if="entity && entity.website" class="entry-base-information">
          <slot name="baseInfo" />
        </div>
        <div>
          <slot name="entityLogo" />
        </div>
        <winbox-tabs>
          <winbox-tab-item
            v-for="(item, key) in menuItems"
            :key="key"
            :link="item.link"
            :additional-class="getAdditionalActive(item)"
          >
            {{ item.title }}
            <span class="item-count" v-if="item.count" :title="item.count">{{
              item.count
            }}</span>
          </winbox-tab-item>
        </winbox-tabs>

        <div v-if="menuSubItems.length" class="in-house-title">
          In - House
        </div>

        <winbox-tabs>
          <winbox-tab-item
            v-for="(item, key) in menuSubItems"
            :key="key"
            :link="item.link"
          >
            {{ item.title }}
            <span class="item-count" v-if="item.count" :title="item.count">{{
              item.count
            }}</span>
          </winbox-tab-item>
        </winbox-tabs>
      </div>
      <div class="column is-9 column-route-view">
        <router-view v-if="entity" />
      </div>
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxSwitch from "@/components/Atoms/WinboxSwitch";
import WinboxTabItem from "@/components/Atoms/WinboxTabItem";

import { Colors } from "@/Colors";

import WinboxTabs from "@/components/Molecules/WinboxTabs";
import { MutationTypes } from "@/constants/MutationTypes";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

export default {
  name: "PageGenericEntry",
  mixins: [RolesMixin],
  components: {
    WinboxSwitch,
    WinboxTabItem,
    WinboxTabs,
    WinboxIcon,
  },
  props: {
    entity: {
      required: true,
      type: Object
    },
    menuItems: {
      required: true,
      type: Array
    },
    menuSubItems: {
      required: true,
      type: Array
    },
    enableSwitcher: {
      required: false,
      type: Boolean,
      default: true
    },
    hasProofing: {
      required: false,
      type: Boolean,
      default: false,
    },
    hasAssign: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      Colors: Colors,
    }
  },
  mounted() {
    this.$store.commit(MutationTypes.SET_PROFILE_PAGE, true);
  },
  beforeDestroy() {
    this.$store.commit(MutationTypes.SET_PROFILE_PAGE, false);
  },
  methods: {
    getAdditionalActive(item) {
      let result = "";
      const found = this.$route.matched.find(
        item => item.name === "ContactProfile"
      );
      const excludedRoutes = [
        "ContactTags",
        "ContactInternalNotes",
        "ContactAuditLog"
      ];
      const excludedFound = this.$route.matched.find(item =>
        excludedRoutes.includes(item.name)
      );
      if (found && !excludedFound) {
        result = "router-link-active";
      }
      return result;
    },
    submitProof() {
      this.$emit('submit-proof');
    },
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.entry-wrap {
  @include page-entry-wrap-style;
  min-height: calc(100vh - 100px);
}

.entry-top {
  color: #fff;
  ::v-deep {
    .title {
      color: #fff;
    }
  }
}
.header-action-wrap {
  text-align: right;

  .button {
    background: $winbox-primary-active-bg-color;
    border-color: transparent;
    color: #585656;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
.item-count {
  @include search-count-styles;
  font-size: $winbox-font-size-main;
  min-width: 22px;
  max-width: 70px;
  @include text-overflow-ellipsis;
}
.switch-label {
  font-size: 12px;
  padding-right: 8px;
  color: $winbox-body-color;
}
.entry-base-information {
  margin: 0 0 1.5rem;
  color: $winbox-body-grey-bg;
}
.in-house-title {
  text-transform: uppercase;
  margin: 1rem 0 1rem -24px;
  padding: 0.5rem;
  font-size: $winbox-font-size-big;
  text-align: center;
  background: $winbox-secondary-title;
  color: $winbox-primary-text-color;
}
.column-route-view {
  background: $winbox-body-color;
  margin-left: -12px;
  min-height: 500px;
}
</style>
