<template>
  <div class="public-notes-wrap">
    <winbox-textarea
      v-model.trim="$v.entity.description.$model"
      placeholder="Description"
      :class="status($v.entity.description)"
    />
    <div class="columns is-variable is-1">
      <div class="column">
        <winbox-input v-model="entity.author" readonly label="Author" />
      </div>
      <div class="column">
        <winbox-input
          v-model="entity.reminder_date"
          :date-picker="true"
          ref="reminder-date"
          label="Reminder"
          class="active-icon-bg"
          icon-right="clock"
          @datepickerShow="
            setDisabledDate('reminder-date', entity.expire_date, 'right')
          "
        />
      </div>
      <div class="column">
        <winbox-input
          v-model="entity.expire_date"
          :date-picker="true"
          ref="expire-date"
          label="Expire"
          class="active-icon-bg"
          icon-right="hourglass-half"
          @datepickerShow="
            setDisabledDate('expire-date', entity.reminder_date, 'left')
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxTextarea from "@/components/Atoms/WinboxTextarea";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import { required } from "vuelidate/lib/validators";
import * as Utils from "@/utils";
import { DatepickerHelperMixin } from "@/components/Pages/Generic/DatepickerHelperMixin";

export default {
  name: "WinboxPublicNotes",
  mixins: [DatepickerHelperMixin],
  components: {
    WinboxInput,
    WinboxTextarea
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Colors: Colors,
      entity: {}
    };
  },
  validations: {
    entity: {
      description: {
        required
      }
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.entity = cloneDeep(this.data);
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    getDeepCloneDataset() {
      return cloneDeep(this.entity);
    },
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.public-notes-wrap {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
.active-icon-bg {
  ::v-deep {
    @include input-icon-box;
  }
}
</style>
