var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "escalate-task" },
    [
      _vm.backendErrors
        ? _c("div", { staticClass: "error-block" }, [
            _vm._v(_vm._s(_vm.backendErrors))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "admin-selection" },
        [
          _c("winbox-select", {
            staticClass: "input-gap",
            attrs: {
              id: "adminSelect",
              "form-label": "Escalate To",
              options: _vm.adminOptions,
              placeholder: "Select an Admin"
            },
            model: {
              value: _vm.selectedAdmin,
              callback: function($$v) {
                _vm.selectedAdmin = $$v
              },
              expression: "selectedAdmin"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("winbox-textarea", {
        staticClass: "textarea-gap",
        attrs: {
          "form-label": "Escalation Details",
          rows: 6,
          placeholder: "Add details about the escalation...",
          maxlength: 4000
        },
        model: {
          value: _vm.escalationDetails,
          callback: function($$v) {
            _vm.escalationDetails = $$v
          },
          expression: "escalationDetails"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }