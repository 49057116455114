import { DisciplinesUrl } from "@/constants/DisciplinesUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllDisciplines() {
  const url = DisciplinesUrl.base;
  return getGenericFullResponse(url);
}

export function createDiscipline(data) {
  const url = DisciplinesUrl.base;
  return postGenericFullResponse(url, data);
}
