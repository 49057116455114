<template src="../Generic/EntityNotesMixin.html" />

<script>
import { mapActions } from "vuex";
import { EntityNotesMixin } from "@/components/Pages/Generic/EntityNotesMixin";

export default {
  name: "AgencyNotes",
  mixins: [EntityNotesMixin],
  components: {},
  data() {
    return {
      isLoaded: true
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
     ...mapActions([
      'getBusinessNotes',
    ]),
   async getData() {
      this.isLoaded = false;

      await this.getBusinessNotes(this.id);

      this.isLoaded = true;
    },
  },
};
</script>

<style scoped lang="scss" src="../Generic/EntityNotesMixin.scss" />
