import { MutationTypes } from "@/constants/MutationTypes";

export default {
  [MutationTypes.RESET_STATES]: (state) => {
    state.isEditMode = false;
    state.isSaving = false;
    state.isCanceling = false;
    state.isChanged = false;
  },

  [MutationTypes.SET_EDIT]: (state, value) => {
    state.isEditMode = value;
    state.isSaving = false;
    state.isCanceling = false;
    state.isChanged = false;
  },

  [MutationTypes.SET_SAVE]: (state, value) => {
    state.isSaving = value;
    state.isCanceling = false;
  },

  [MutationTypes.SET_CANCEL]: (state, value) => {
    state.isCanceling = value;
    // state.isEditMode = false;
    // state.isChanged = false;
    state.isSaving = false;
  },

  [MutationTypes.SET_CHANGE]: (state, value) => {
    state.isChanged = value;
    state.isCanceling = false;
    state.isSaving = false;
  },

  [MutationTypes.SET_INVALID]: (state, value) => {
    state.isInvalid = value;
  },
};
