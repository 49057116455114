import { render, staticRenderFns } from "./WinboxTabs.vue?vue&type=template&id=66218492&scoped=true&functional=true"
import script from "./WinboxTabs.vue?vue&type=script&lang=js"
export * from "./WinboxTabs.vue?vue&type=script&lang=js"
import style0 from "./WinboxTabs.vue?vue&type=style&index=0&id=66218492&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "66218492",
  null
  
)

export default component.exports