var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-entity" }, [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.name),
            attrs: { placeholder: "Company Name", field: "name" },
            model: {
              value: _vm.$v.entity.name.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.name,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.name.$model"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.short_description),
            attrs: { placeholder: "Short Description" },
            model: {
              value: _vm.$v.entity.short_description.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.short_description,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.short_description.$model"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.website),
            attrs: { placeholder: "URL", field: "website" },
            model: {
              value: _vm.$v.entity.website.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.website,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.website.$model"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }