import { render, staticRenderFns } from "./WinboxCreateBrand.vue?vue&type=template&id=8194d466&scoped=true"
import script from "./WinboxCreateBrand.vue?vue&type=script&lang=js"
export * from "./WinboxCreateBrand.vue?vue&type=script&lang=js"
import style0 from "./WinboxCreateBrand.vue?vue&type=style&index=0&id=8194d466&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8194d466",
  null
  
)

export default component.exports