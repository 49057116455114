export const LocationTypes = {
  corporate_hq: "Corporate HQ",
  us_corporate_hq: "US Corporate HQ",
  marketing_hq: "Marketing HQ",
  regional_office: "Regional HQ",
  local_office: "Local Office"
};

export const UkLocationTypes = {
  corporate_hq: "Corporate HQ",
  marketing_hq: "Marketing HQ",
  regional_office: "Regional HQ",
  local_office: "Local Office"
};
