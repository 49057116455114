var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "admin-users" },
    [
      _c("winbox-company-header", {
        staticClass: "main-title",
        attrs: { title: _vm.mainTitle },
        on: { changed: _vm.openModal }
      }),
      _vm._v(" "),
      _c("winbox-data-table", {
        staticClass: "main-data-table",
        attrs: {
          data: _vm.dataset,
          columns: _vm.columns,
          options: _vm.tableOptions,
          slots: _vm.specificSlots
        },
        scopedSlots: _vm._u(
          [
            {
              key: _vm.tableProps.fname,
              fn: function(data) {
                return [
                  _c("winbox-input", {
                    attrs: {
                      placeholder: "First Name",
                      disabled: _vm.isDisabled(data.row)
                    },
                    model: {
                      value: data.row[_vm.tableProps.fname],
                      callback: function($$v) {
                        _vm.$set(
                          data.row,
                          _vm.tableProps.fname,
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.row[tableProps.fname]"
                    }
                  })
                ]
              }
            },
            {
              key: _vm.tableProps.lname,
              fn: function(data) {
                return [
                  _c("winbox-input", {
                    attrs: {
                      placeholder: "Last Name",
                      disabled: _vm.isDisabled(data.row)
                    },
                    model: {
                      value: data.row[_vm.tableProps.lname],
                      callback: function($$v) {
                        _vm.$set(
                          data.row,
                          _vm.tableProps.lname,
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.row[tableProps.lname]"
                    }
                  })
                ]
              }
            },
            {
              key: _vm.tableProps.role,
              fn: function(data) {
                return [
                  _c("winbox-select", {
                    attrs: {
                      placeholder: "Role",
                      options: _vm.possibleRoles,
                      disabled: _vm.isDisabled(data.row)
                    },
                    model: {
                      value: data.row[_vm.tableProps.role],
                      callback: function($$v) {
                        _vm.$set(data.row, _vm.tableProps.role, $$v)
                      },
                      expression: "data.row[tableProps.role]"
                    }
                  })
                ]
              }
            },
            {
              key: _vm.tableProps.email,
              fn: function(data) {
                return [
                  _c("winbox-input", {
                    attrs: {
                      placeholder: "Email",
                      disabled: _vm.isDisabled(data.row)
                    },
                    model: {
                      value: data.row[_vm.tableProps.email],
                      callback: function($$v) {
                        _vm.$set(
                          data.row,
                          _vm.tableProps.email,
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.row[tableProps.email]"
                    }
                  })
                ]
              }
            },
            {
              key: _vm.tableProps.status,
              fn: function(data) {
                return [
                  _c("winbox-select", {
                    attrs: {
                      placeholder: "Status",
                      options: _vm.possibleStatuses,
                      disabled: _vm.isDisabled(data.row)
                    },
                    model: {
                      value: data.row[_vm.tableProps.status],
                      callback: function($$v) {
                        _vm.$set(data.row, _vm.tableProps.status, $$v)
                      },
                      expression: "data.row[tableProps.status]"
                    }
                  })
                ]
              }
            },
            {
              key: _vm.tableProps.created_at,
              fn: function(data) {
                return [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("winboxDatetime")(
                          data.row[_vm.tableProps.created_at]
                        )
                      ) +
                      "\n    "
                  )
                ]
              }
            },
            {
              key: _vm.tableProps.options,
              fn: function(data) {
                return [
                  data.row.id != -1 && _vm.isDisabled(data.row)
                    ? _c(
                        "button",
                        {
                          staticClass: "button button-remove",
                          on: {
                            click: function($event) {
                              return _vm.onDeleteUser(data.row)
                            }
                          }
                        },
                        [
                          _c("winbox-icon", {
                            attrs: {
                              icon: "trash",
                              color: _vm.Colors.system.error
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  data.row.id != -1 && _vm.isDisabled(data.row)
                    ? _c(
                        "button",
                        {
                          staticClass: "button button-edit",
                          on: {
                            click: function($event) {
                              return _vm.editMode(data.row)
                            }
                          }
                        },
                        [
                          _c("winbox-icon", {
                            attrs: {
                              icon: "edit",
                              color: _vm.Colors.system.primaryLight
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  data.row.id != -1 && !_vm.isDisabled(data.row)
                    ? _c(
                        "button",
                        {
                          staticClass: "button button-edit",
                          on: {
                            click: function($event) {
                              return _vm.editUser(data.row)
                            }
                          }
                        },
                        [
                          _c("winbox-icon", {
                            attrs: {
                              icon: "check",
                              color: _vm.Colors.system.primaryLight
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  data.row.id != -1 && !_vm.isDisabled(data.row)
                    ? _c(
                        "button",
                        {
                          staticClass: "button button-edit",
                          on: {
                            click: function($event) {
                              return _vm.notEditMode(data.row)
                            }
                          }
                        },
                        [
                          _c("winbox-icon", {
                            attrs: {
                              icon: "times",
                              color: _vm.Colors.system.error
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.createModal,
          attrs: {
            name: _vm.createModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Create new user",
            "save-text": "Create"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _vm.newUser && _vm.isCreateNewLoaded
            ? _c("winbox-create-user", {
                ref: "createUserRef",
                attrs: { data: _vm.newUser }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isCreateNewLoaded ? _c("winbox-large-indicator") : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.deleteModal,
          attrs: {
            name: _vm.deleteModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Delete user",
            "save-text": "Ok"
          },
          on: { "on-cancel": _vm.onDeleteCancel, "on-save": _vm.onDeleteAccept }
        },
        [
          _vm.isDeleteLoaded ? _c("p", [_vm._v("Are you sure?")]) : _vm._e(),
          _vm._v(" "),
          !_vm.isDeleteLoaded ? _c("winbox-large-indicator") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }