export const Colors = {
  system: {
    white: "#fff",
    black: "#000",
    gray:  "#888",
    lightGray: "#585656",
    green: "#53BA82",
    primaryLight: "#00C0B5",
    primaryOrange: "#FF3B1E",
    error: "#B03D00",
    info: "#31708f",
    warning: "#8a6d3b",
    inactive: "#FF3B1E"
  },
  draggableIcon: "#303133",
  inputIcon: "#d6d6d6",
  progress: "#007BFF",
  progressBackground: "#DADADA"
};
