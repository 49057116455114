import agencyMutations from "./agency";
import businessMutations from "./business";
import brandMutations from "./brand";
import companyMutations from "./company";
import personMutations from "./person";
import helperMutations from "./helper";
import userMutations from "./user";
import taskMutations from "./task";
import userTaskMutations from "./user-task";
import dataImport from "./data_import";
import filterMutations from "./filter_options";
import appHeader from "./app-header";
import entity from "./entity";
import report from "./report";

const mutations = {
  ...agencyMutations,
  ...businessMutations,
  ...brandMutations,
  ...companyMutations,
  ...personMutations,
  ...helperMutations,
  ...userMutations,
  ...taskMutations,
  ...userTaskMutations,
  ...filterMutations,
  ...dataImport,
  ...appHeader,
  ...entity,
  ...report
};

export default mutations;
