var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEntityPage
    ? _c("aside", { staticClass: "search-options" }, [
        _c("div", { attrs: { id: "search-filters" } }, [
          _c(
            "div",
            [
              _c("button", { staticClass: "btn-filter-disabled" }, [
                _vm._v("\n          Search\n        "),
                _c("span", [_c("span", [_vm._v(_vm._s(_vm.searchPage))])]),
                _vm._v("\n          by\n      ")
              ]),
              _vm._v(" "),
              _vm._l(_vm.filterOptions, function(filterOption) {
                return _c(
                  "button",
                  {
                    key: filterOption.id,
                    ref: filterOption.name,
                    refInFor: true,
                    staticClass: "btn-filter",
                    attrs: { id: filterOption.name },
                    on: {
                      click: function($event) {
                        return _vm.openPopupFilter(
                          filterOption.name,
                          filterOption.code
                        )
                      }
                    }
                  },
                  [
                    !filterOption.checked
                      ? _c("i", { staticClass: "far fa-square" })
                      : _vm._e(),
                    _vm._v(" "),
                    filterOption.checked
                      ? _c("i", { staticClass: "far fa-check-square" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " + _vm._s(filterOption.name) + "\n      "
                    )
                  ]
                )
              })
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }