import { MutationTypes } from "@/constants/MutationTypes";

export default {
  [MutationTypes.SET_USER_TASKS]: (state, payload) => {
    state.userTasks = payload;
  },
  [MutationTypes.UPDATE_USER_TASK]: (state, payload) => {
    let updatedUserTask = state.userTasks.find((userTask) => userTask.id === payload.id);
    updatedUserTask = {
      ...updatedUserTask,
      ...payload,
    };
    state.userTasks = [updatedUserTask, ...state.userTasks.filter(
      (userTask) => userTask.id != payload.id)];
  },
};
