<template>
  <div class="company-hierarchy-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="company-hierarchy">
      <winbox-title :title="mainTitle" class="main-title" />
      <div class="winbox-hierarchy-wrap">
        <div class="columns is-variable is-2">
          <div v-if="serviceNameParents" class="column is-4">
            <winbox-hierarchy-entity
              title="Parent"
              entity-type="brand"
              :entity-selected="parents"
              :service-name="serviceNameParents"
              @changed="onChanged"
              @removed="onRemoved"
              prop-id="parent_id"
              :can-add-entity="false"
            />
          </div>
          <div v-if="serviceNameSiblings" class="column is-4">
            <winbox-hierarchy-entity
              title="Siblings"
              entity-type="brand"
              :entity-selected="siblings"
              :service-name="serviceNameSiblings"
              :can-add-entity="false"
            />
          </div>
          <div v-if="serviceNameChildren" class="column is-4">
            <winbox-hierarchy-entity
              title="Children"
              entity-type="brand"
              :entity-selected="children"
              :service-name="serviceNameChildren"
              @changed="onChanged"
              @removed="onRemoved"
              :can-add-entity="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MutationTypes } from "@/constants/MutationTypes";

import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxHierarchyEntity from "@/components/Molecules/WinboxHierarchyEntity";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { BusinessHierarchyTypes } from "@/constants/BusinessHierarchyTypes";
import { BusinessTypes } from "@/constants/BusinessTypes";

export default {
  name: "BrandHierarchy",
  components: {
    WinboxTitle,
    WinboxLargeIndicator,
    WinboxHierarchyEntity
  },
  data() {
    return {
      isLoaded: true,
      Colors: Colors,
      serviceNameParents: BusinessHierarchyTypes.parent,
      serviceNameChildren: BusinessHierarchyTypes.child,
      serviceNameSiblings: BusinessHierarchyTypes.sibling
    };
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    this.getAgencies().then(() => {
      const path = this.$router.currentRoute.path;
      if(!path.includes('/hierarchy')) {
        this.$store.commit(MutationTypes.SET_AGENCIES, []);
      }
    });

    this.getBrands().then(() => {
      const path = this.$router.currentRoute.path;
      if(!path.includes('/hierarchy')) {
        this.$store.commit(MutationTypes.SET_BRANDS, []);
      }
    });

    this.getCompanies().then(() => {
      const path = this.$router.currentRoute.path;
      if(!path.includes('/hierarchy')) {
        this.$store.commit(MutationTypes.SET_COMPANIES, []);
      }
    });
  },
  computed: {
    ...mapState([
      'currentBrand',
      'entityParentHierarchies',
      'entityChildHierarchies',
      'entitySiblingHierarchies',
      'companies',
      'agencies',
      'brands',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return "Hierarchy";
    },
    allBusiness() {
      return [...this.allCompanies, ...this.allAgencies];
    },
    companyParents() {
      return this.entityParentHierarchies.filter((entity) => entity.parent_type === 'company');
    },
    companyChildren() {
      return this.entityChildHierarchies.filter((entity) => entity.child_type === 'company');
    },
    companySiblings() {
      return this.entitySiblingHierarchies.filter((entity) => entity.child_type === 'company');
    },
    agencyParents() {
      return this.entityParentHierarchies.filter((entity) => entity.parent_type === 'agency');
    },
    agencyChildren() {
      return this.entityChildHierarchies.filter((entity) => entity.child_type === 'agency');
    },
    agencySiblings() {
      return this.entitySiblingHierarchies.filter((entity) => entity.child_type === 'agency');
    },
    brandParents() {
      return this.entityParentHierarchies.filter((entity) => entity.parent_type === 'brand');
    },
    brandChildren() {
      return this.entityChildHierarchies.filter((entity) => entity.child_type === 'brand');
    },
    brandSiblings() {
      return this.entitySiblingHierarchies.filter((entity) => entity.child_type === 'brand');
    },
    parents() {
      return this.entityParentHierarchies;
    },
    children() {
      return this.entityChildHierarchies;
    },
    siblings() {
      return this.entitySiblingHierarchies;
    },
  },
  methods: {
    ...mapActions([
      'getBrands',
      'getAgencies',
      'getCompanies',
      'createBrandHierarchy',
      'removeBrandHierarchy',
      'getBrandHierarchies',
    ]),
    async getData() {
      this.isLoaded = false;

      await this.getBrandHierarchies(this.id);      

      this.isLoaded = true;
    },
    onChanged(e) {
      this.addHierarchy(e.newTags, e.serviceName);
    },
    onRemoved(e) {
      this.removeHierarchy(e.data, e.serviceName);
    },
    addHierarchy(data, type) {
      const entityType = data.type === undefined ? "brand" : data.type;
      const payload = {
        entity_id: data.id,
        entity_type: entityType,
        hierarchy_type: type,
      };

      this.createBrandHierarchy({
        id: this.id,
        payload,
      });
    },
    removeHierarchy(data, type) {
      if(data.id) {
        return this.removeBrandHierarchy({
          id: data.id,
          type
        });
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(MutationTypes.SET_BRANDS, []);
    this.$store.commit(MutationTypes.SET_AGENCIES, []);
    this.$store.commit(MutationTypes.SET_COMPANIES, []);
  },
};
</script>

<style scoped lang="scss">
  .company-hierarchy-wrap {
    padding-top: 0.5rem;
  }
  .main-title {
    margin: 0 0 1rem;
  }
</style>
