import { mapActions, mapState } from "vuex";

import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxCreateLocation from "@/components/Organisms/WinboxCreateLocation";
import WinboxCreateContact from "@/components/Organisms/WinboxCreateContact";
import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import * as BusinessesApi from "@/api/businesses/businesses";
import cloneDeep from "clone-deep";
import { required, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import { LocationTypes, UkLocationTypes } from "@/constants/LocationTypes";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

const tableProps = {
  name: "name",
  title: "title",
  email: "email"
};
const servicesTableProps = {
  brand_name: "brand_name",
  service: "service"
};
const mainTableColumns = Object.values(tableProps);
const servicesTableColumns = Object.values(servicesTableProps);
const zipLengthValidation = (value, vm) => {
  const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
  const isUk = isUKMetaTag && isUKMetaTag.getAttribute('content') === 'true';
  if (!isUk) {
    return (vm.country === 'USA' && value.length === 5) ||
           (vm.country === 'Canada' && value.length === 7) ||
           (vm.country === null && (value.length === 5 || value.length === 7));
  } else {
    return true;
  }
};
const phoneLengthValidation = (value, vm) => {
  const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
  const isUk = isUKMetaTag && isUKMetaTag.getAttribute('content') === 'true';
  if (isUk && value != null) {
    return value.length <= 20;
  } else {
    return true;
  }
};

export const EntityLocationsDetailMixin = {
  components: {
    WinboxLargeIndicator,
    WinboxCard,
    WinboxIcon,
    WinboxDataTable,
    WinboxCompanyHeader,
    WinboxModal,
    WinboxCreateLocation,
    WinboxCreateContact,
    WinboxInput,
    WinboxSelect
  },
  data() {
    return {
      Colors: Colors,
      entity: {},
      submitStatus: false,
      isLoaded: false,
      cardTitle: "Location Details",
      locationData: null,
      tableProps: tableProps,
      servicesTableProps: servicesTableProps,
      columns: [...mainTableColumns],
      servicesColumns: [...servicesTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.title]: "Title",
          [tableProps.email]: "Email"
        },
        sortable: [...mainTableColumns],
        filterable: [...mainTableColumns]
      },
      servicesTableOptions: {
        headings: {
          [servicesTableProps.brand_name]: "Brand Name",
          [servicesTableProps.service]: "Service"
        },
        sortable: [...servicesTableColumns],
        filterable: [...servicesTableColumns]
      },
      specificSlots: [tableProps.name, tableProps.email],
      servicesSpecificSlots: [servicesTableProps.brand_name, servicesTableProps.service],
      visibileStatus: 'Active',
      brandServiceVisibleStatus: 'Active'
    };
  },
  validations: {
    entity: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      location_type: {
        required
      },
      address_1: {
        required
      },
      city: {
        required
      },
      state: {
        required
      },
      tv_region: {
        required: requiredIf(function () {
          return this.isUk;
        }),
      },
      country: {
        required: requiredIf(function () {
          return this.isUk;
        }),
      },
      zip: {
        required,
        zipLengthValidation,
      },
      phone: {
        phoneLengthValidation,
      },
      toll_free: {
        phoneLengthValidation,
      },
    }
  },
  computed: {
    ...mapState([
      'location_people',
      'agencyRelationships',
      'tvRegions'
    ]),
    id() {
      return +this.$route.params.id;
    },
    isUk() {
      const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
      return isUKMetaTag && isUKMetaTag.getAttribute('content') == 'true';
    },
    isAgency() {
      let path = this.$router.currentRoute.path.substr(1);
      path = path.match(/(\w+)\/\d+\/(\w+)(\/(\w+))?/);
      return path[1] == 'agencies';
    },
    currentLocationContacts() {
      const locationData = this.location_people.map((item) => {
        return {
          ...item,
          name: `${item.fname} ${item.lname}`,
        }
      });
      return locationData;
    },
    currentLocationBrandServices() {
      const brandServicesData = this.agencyRelationships.map((item) => {
        return {
          ...item,
          brand_name: item.brand.name,
          service: item.services.map(service => service.name)
        };
      });
      return brandServicesData;
    },
    locationId() {
      return +this.$route.params.locationId;
    },
    agencyId() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Related Contacts (${this.visibleData.length})`;
    },
    relatedBrandsTitle() {
      return `Related Brands (${this.visibleBrandServiceData.length})`;
    },
    possibleTypes() {
      if (this.isUk) {
        return Object.entries(UkLocationTypes).map(item => {
          return {
            value: item[0],
            label: item[1]
          };
        });
      } else {
        return Object.entries(LocationTypes).map(item => {
          return {
            value: item[0],
            label: item[1]
          };
        });
      };
    },
    states() {
      return this.$store.getters.STATES.map(item => item.state_abbr);
    },
    possibleTvRegions() {
      return this.tvRegions.map(item => {
        return {
          value: item.id,
          label: item.tv_region
        };
      });
    },
    activeData() {
      return this.currentLocationContacts.filter((data) => data.published);
    },
    inactiveData() {
      return this.currentLocationContacts.filter((data) => !data.published);
    },
    activeBrandServiceData() {
      return this.currentLocationBrandServices.filter((data) => data.status === 'active');
    },
    inactiveBrandServiceData() {
      return this.currentLocationBrandServices.filter((data) => data.status === 'inactive');
    },
    visibleData() {
      if(this.visibileStatus === 'Active') {
        return this.activeData;
      }
      else {
        return this.inactiveData;
      }
    },
    visibleBrandServiceData() {
      if(this.brandServiceVisibleStatus === 'Active') {
        return this.activeBrandServiceData;
      }
      else {
        return this.inactiveBrandServiceData;
      }
    },
  },
  methods: {
    ...mapActions([
      'edit',
      'createBusinessContact',
      'getLocationPeople',
      'getAgencyRelationship',
      'updateBusinessLocation',
      'getTvRegions',
    ]),
    getData() {
      this.isLoaded = false;

      this.getTvRegions()
      .then(() => {
        return Promise.all([
          this.getBusinessLocation(),
          this.getLocationPeople(this.locationId),
          this.getAgencyRelationship(this.agencyId)
        ]);
      })
      .then(() => {
        this.isLoaded = true;
      });
    },
    async saveFn(payload) {
      await this.createBusinessContact({
        id: this.id,
        payload: this.modifyCreateContactPayload(payload)
      });

      return this.getLocationPeople(this.locationId);
    },
    getBusinessLocation() {
      return BusinessesApi.getBusinessLocation(this.locationId).then(
        ({ data }) => {
          this.locationData = data;
          this.entity = data;
          this.initData = cloneDeep(this.entity);
          if (this.entity?.tv_region_id) {
            this.entity.tv_region = this.possibleTvRegions.find(
              item => item.value === this.entity.tv_region_id
            );
            return Promise.resolve(this.entity);
          }
        }
      );
    },
    constructContactProfileUrl(id) {
      return Utils.getContactProfileUrl(id);
    },
    constructBrandProfileUrl(id) {
      return Utils.getBrandProfileUrl(id);
    },
    afterSave() {
      this.onCancel();
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    constructEmailLink(email) {
      return `mailto:${email}`;
    },
    afterSaveEntity() {
      this.initData = cloneDeep(this.entity);
      this.dataUpdate = {};
    },
    saveEntity() {
      this.$v.$touch();
      this.backendErrors = "";

      if (this.$v.$error) {
        return;
      }
      if (!Object.keys(this.dataUpdate).length) {
        return;
      }
      const payload = {
        ...this.dataUpdate,
        tv_region_id: this.dataUpdate.tv_region?.value,
      };

      this.updateBusinessLocation({
        id: this.id,
        locationId: this.locationId,
        payload: payload
      });

      this.edit(false);

      this.afterSaveEntity();
    },
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    updateBrandServiceVisibleStatus(value) {
      this.brandServiceVisibleStatus = value;
    },
  }
};
