var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-user" }, [
    _c("div", { staticClass: "columns is-variable is-1" }, [
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.first_name),
            attrs: { placeholder: "First Name" },
            model: {
              value: _vm.$v.entity.first_name.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.first_name,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.first_name.$model"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.last_name),
            attrs: { placeholder: "Last Name" },
            model: {
              value: _vm.$v.entity.last_name.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.last_name,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.last_name.$model"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-variable is-1" }, [
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.email),
            attrs: { placeholder: "Email" },
            model: {
              value: _vm.$v.entity.email.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.email,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.email.$model"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-select", {
            class: _vm.status(_vm.$v.entity.role),
            attrs: {
              value: _vm.entity.role,
              options: _vm.possibleRoles,
              placeholder: "Role"
            },
            on: {
              input: function($event) {
                _vm.$v.entity.role.$model = $event
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-variable is-1" }, [
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-input", {
            attrs: { readonly: true, placeholder: "Status" },
            model: {
              value: _vm.entity.status,
              callback: function($$v) {
                _vm.$set(
                  _vm.entity,
                  "status",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "entity.status"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }