export const PostalCodesFilterOptions = [
  {
    id: 1,
    name: 'Location',
  },
  {
    id: 2,
    name: 'Postal Codes',
  }
];
