<template>
  <div class="winbox-task-completion">
    <winbox-card class="item-overview">
      <div class="item-title">
        <winbox-title title="My Completed Work" type="medium" />
      </div>
      <div class="item-chart">
        <div class="columns">
          <div class="column">
            <winbox-progress-chart :completed-steps="value.progress" />
          </div>
          <div class="column">
            <div class="item-status">
              <winbox-icon
                class="item-status-icon"
                icon="award"
                :color="Colors.draggableIcon"
              />
              <p class="main-status">{{ progressMessage(value.progress) }}</p>
              <p class="additional-status">{{ currentStatus }}</p>
            </div>
          </div>
        </div>
      </div>
    </winbox-card>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxProgressChart from "@/components/Molecules/charts/WinboxProgressChart";

import { Colors } from "@/Colors";

export default {
  name: "WinboxTaskCompletion",
  components: { WinboxCard, WinboxTitle, WinboxIcon, WinboxProgressChart },
  props: {
    value: {
      required: true,
      type: Object,
      note: "Main data object"
    },
    user: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      Colors: Colors,
      currentStatus: "Use this tool to help maintain your goals each month!",
    };
  },
  methods: {
    progressMessage(progress) {
      if (progress === 0) {
        return 'Let\'s Get Started!';
      }
      if (progress >= 1 && progress < 40) {
        return 'Nice job, keep it up';
      }
      else if (progress >= 40 && progress < 60) {
        return 'About halfway there, good work';
      }
      else if (progress >= 60 && progress < 80) {
        return 'Solid progress, almost there';
      }
      else if (progress >= 80 && progress < 95) {
        return 'Great job, almost done!';
      }
      else if (progress >= 95 && progress <= 100) {
        return 'Excellent work!';
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.item-draggable {
  @include card-draggable-icon;
}
.item-title {
  .winbox-title {
    margin: 0 0 1rem;
  }
}
.item-status {
  text-align: center;
  margin: 3rem 0 0;

  .item-status-icon {
    font-size: $winbox-font-size-huge;
  }
  .main-status {
    font-size: $winbox-font-size-average;
    font-weight: bold;
    margin: 1rem 0;
  }
  .additional-status {
    color: $winbox-secondary-title;
    font-weight: 600;
  }
}
</style>
