var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-add-relationships" }, [
    !_vm.showNextStep
      ? _c(
          "div",
          { staticClass: "step-one" },
          [
            _c(
              "winbox-server-data-table",
              {
                ref: "winboxDataTable",
                staticClass: "winbox-add-relationships-table",
                attrs: {
                  url: _vm.url,
                  columns: _vm.columns,
                  options: _vm.tableOptions,
                  slots: _vm.specificSlots,
                  perPage: 10,
                  responseFunction: _vm.enhanceData,
                  theme: "with-borders-theme"
                },
                on: { "row-click": _vm.onRowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: _vm.tableProps.linked,
                      fn: function(data) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "button select-button",
                              class: { active: data.row[_vm.tableProps.linked] }
                            },
                            [
                              _c("winbox-icon", {
                                attrs: {
                                  icon: "plus",
                                  color: data.row[_vm.tableProps.linked]
                                    ? _vm.Colors.system.lightGray
                                    : _vm.Colors.system.primaryLight
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c("template", { slot: _vm.linkedIdSlotName }, [
                  _c("div", { staticClass: "select-all-checkbox" }, [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.areAllSelected },
                      on: { change: _vm.toggleSelectAll }
                    })
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showNextStep && _vm.entity
      ? _c("div", { staticClass: "step-two" }, [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("winbox-input", {
                  attrs: {
                    placeholder: "Name",
                    value: _vm.entity.map(entity => entity.name).join(", "),
                    readonly: true
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "winbox-relationship-items" }, [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column is-6" },
                [
                  _c("winbox-select", {
                    class: _vm.validation(_vm.$v.services),
                    attrs: {
                      value: _vm.services,
                      options: _vm.possibleServices,
                      multiple: true,
                      placeholder: "Services",
                      label: "name"
                    },
                    on: { input: e => _vm.onChangeEntityFields(e, "services") }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-6" },
                [
                  _c("winbox-select", {
                    class: _vm.validation(_vm.$v.mediaTypes),
                    attrs: {
                      value: _vm.mediaTypes,
                      options: _vm.possibleMediaTypes,
                      multiple: true,
                      label: "name",
                      placeholder: "Media Type"
                    },
                    on: {
                      input: e => _vm.onChangeEntityFields(e, "mediaTypes")
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-6" },
              [
                _c("winbox-select", {
                  class: _vm.validation(_vm.$v.status),
                  attrs: {
                    options: _vm.possibleStatuses,
                    searchable: false,
                    placeholder: "Status"
                  },
                  model: {
                    value: _vm.status,
                    callback: function($$v) {
                      _vm.status = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "status"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-6" },
              [
                _vm.possibleLocations.length >= 1
                  ? _c("winbox-select", {
                      class: _vm.validation(_vm.$v.location),
                      attrs: {
                        placeholder: "Location",
                        label: "name",
                        options: _vm.possibleLocations,
                        disabled: _vm.possibleLocations.length == 1,
                        emptyText: _vm.emptyText
                      },
                      model: {
                        value: _vm.location,
                        callback: function($$v) {
                          _vm.location =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "location"
                      }
                    })
                  : _c("winbox-input", {
                      attrs: {
                        placeholder: "Location",
                        readonly: true,
                        value: _vm.emptyText
                      }
                    })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-6" }, [
              _c(
                "button",
                {
                  staticClass: "button action-button",
                  class: { "is-primary": _vm.is_aor },
                  on: { click: _vm.toggleAOR }
                },
                [
                  _c("input", {
                    attrs: { type: "checkbox" },
                    domProps: { checked: _vm.is_aor }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("AOR")])
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }