var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entity-location-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "entity-location" },
            [
              _c(
                "winbox-card",
                {
                  staticClass: "main-winbox-card",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n        " + _vm._s(_vm.cardTitle) + "\n      "
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "headerIcon",
                        fn: function() {
                          return [
                            _vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button save-button",
                                    attrs: { disabled: !_vm.isChanged },
                                    on: { click: _vm.editAccept }
                                  },
                                  [_vm._v("\n          Save\n        ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button cancel-button",
                                    on: { click: _vm.editCancel }
                                  },
                                  [_vm._v("\n          Cancel\n        ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button edit-button",
                                    on: { click: _vm.editEntity }
                                  },
                                  [_vm._v("\n          Edit\n        ")]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2019580557
                  )
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  [
                    _c("div", { staticClass: "create-location" }, [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("winbox-input", {
                            class: _vm.status(_vm.$v.entity.name),
                            attrs: {
                              placeholder: "Name",
                              disabled: !_vm.isEditMode,
                              field: "locationName"
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "name",
                                  _vm.entity.name
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.entity.name.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entity.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.entity.name.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "columns is-variable is-1 mb-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "column is-9" },
                                [
                                  _c("winbox-select", {
                                    class: _vm.status(
                                      _vm.$v.entity.location_type
                                    ),
                                    attrs: {
                                      placeholder: "Type",
                                      value: _vm.entity.location_type,
                                      options: _vm.possibleTypes,
                                      clearable: true,
                                      disabled: !_vm.isEditMode
                                    },
                                    on: {
                                      input: $event => {
                                        _vm.entity.location_type = $event
                                        _vm.addToDataUpdate(
                                          "location_type",
                                          $event.value
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "column is-3" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "columns is-variable is-1 is-vcentered"
                                  },
                                  [
                                    _c("div", { staticClass: "column" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button action-button",
                                          class: {
                                            "is-primary": _vm.entity.primary
                                          },
                                          attrs: { disabled: !_vm.isEditMode },
                                          on: {
                                            click: () => {
                                              _vm.entity.primary = !_vm.entity
                                                .primary
                                              _vm.addToDataUpdate(
                                                "primary",
                                                _vm.entity.primary
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.entity.primary,
                                                expression: "entity.primary"
                                              }
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              disabled: !_vm.isEditMode
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.entity.primary
                                              )
                                                ? _vm._i(
                                                    _vm.entity.primary,
                                                    null
                                                  ) > -1
                                                : _vm.entity.primary
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a = _vm.entity.primary,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.entity,
                                                        "primary",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.entity,
                                                        "primary",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.entity,
                                                    "primary",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v("Primary Address")
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("winbox-input", {
                            class: _vm.status(_vm.$v.entity.address_1),
                            attrs: {
                              placeholder: "Address 1",
                              disabled: !_vm.isEditMode
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "address_1",
                                  _vm.entity.address_1
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.entity.address_1.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entity.address_1,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.entity.address_1.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c("winbox-input", {
                            attrs: {
                              placeholder: "Address 2",
                              disabled: !_vm.isEditMode
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "address_2",
                                  _vm.entity.address_2
                                )
                              }
                            },
                            model: {
                              value: _vm.entity.address_2,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.entity,
                                  "address_2",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "entity.address_2"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "columns" }, [
                            _c(
                              "div",
                              { staticClass: "column is-7" },
                              [
                                _c("winbox-input", {
                                  class: _vm.status(_vm.$v.entity.city),
                                  attrs: {
                                    placeholder: "City",
                                    disabled: !_vm.isEditMode
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.addToDataUpdate(
                                        "city",
                                        _vm.entity.city
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.$v.entity.city.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.entity.city,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "$v.entity.city.$model"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "column is-3" },
                              [
                                _c("winbox-select", {
                                  class: _vm.status(_vm.$v.entity.state),
                                  attrs: {
                                    placeholder: "State",
                                    options: _vm.states,
                                    disabled: !_vm.isEditMode
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.addToDataUpdate(
                                        "state",
                                        _vm.entity.state
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.entity.state,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.entity,
                                        "state",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "entity.state"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "column is-2" },
                              [
                                _c("winbox-input", {
                                  class: _vm.status(_vm.$v.entity.zip),
                                  attrs: {
                                    placeholder: "Zip",
                                    disabled: !_vm.isEditMode
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.addToDataUpdate(
                                        "zip",
                                        _vm.entity.zip
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.$v.entity.zip.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.entity.zip,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "$v.entity.zip.$model"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm.isUk
                            ? _c("div", { staticClass: "columns" }, [
                                _c(
                                  "div",
                                  { staticClass: "column is-6" },
                                  [
                                    _c("winbox-select", {
                                      class: _vm.status(
                                        _vm.$v.entity.tv_region
                                      ),
                                      attrs: {
                                        placeholder: "TV Region",
                                        options: _vm.possibleTvRegions,
                                        disabled: !_vm.isEditMode
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.addToDataUpdate(
                                            "tv_region",
                                            _vm.entity.tv_region
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.entity.tv_region,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.entity,
                                            "tv_region",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "entity.tv_region"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "column is-6" },
                                  [
                                    _c("winbox-input", {
                                      class: _vm.status(_vm.$v.entity.country),
                                      attrs: {
                                        placeholder: "Country",
                                        disabled: !_vm.isEditMode
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.addToDataUpdate(
                                            "country",
                                            _vm.entity.country
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.entity.country,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.entity,
                                            "country",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "entity.country"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "columns" }, [
                            _c(
                              "div",
                              { staticClass: "column is-6" },
                              [
                                !_vm.isUk
                                  ? _c("winbox-input", {
                                      attrs: {
                                        placeholder: "Main Phone",
                                        "icon-left": "phone-alt",
                                        mask: "(###)-###-####",
                                        disabled: !_vm.isEditMode
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.addToDataUpdate(
                                            "phone",
                                            _vm.entity.phone
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.entity.phone,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.entity,
                                            "phone",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "entity.phone"
                                      }
                                    })
                                  : _c("winbox-input", {
                                      class: _vm.status(_vm.$v.entity.phone),
                                      attrs: {
                                        placeholder: "Main Phone",
                                        "icon-left": "phone-alt",
                                        disabled: !_vm.isEditMode
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.addToDataUpdate(
                                            "phone",
                                            _vm.entity.phone
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.entity.phone,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.entity,
                                            "phone",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "entity.phone"
                                      }
                                    })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "column is-6" },
                              [
                                !_vm.isUk
                                  ? _c("winbox-input", {
                                      attrs: {
                                        placeholder: "Toll Free",
                                        "icon-left": "phone-alt",
                                        mask: "(###)-###-####",
                                        disabled: !_vm.isEditMode
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.addToDataUpdate(
                                            "toll_free",
                                            _vm.entity.toll_free
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.entity.toll_free,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.entity,
                                            "toll_free",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "entity.toll_free"
                                      }
                                    })
                                  : _c("winbox-input", {
                                      class: _vm.status(
                                        _vm.$v.entity.toll_free
                                      ),
                                      attrs: {
                                        placeholder: "Toll Free",
                                        "icon-left": "phone-alt",
                                        disabled: !_vm.isEditMode
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.addToDataUpdate(
                                            "toll_free",
                                            _vm.entity.toll_free
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.entity.toll_free,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.entity,
                                            "toll_free",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "entity.toll_free"
                                      }
                                    })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ]
                ],
                2
              ),
              _vm._v(" "),
              _c("winbox-company-header", {
                attrs: {
                  title: _vm.mainTitle,
                  "enable-location-contact-status-toggle": true
                },
                on: {
                  changed: _vm.openModal,
                  "toggle-status": _vm.updateVisibleStatus
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-data-table", {
                      staticClass: "main-data-table",
                      attrs: {
                        data: _vm.visibleData,
                        columns: _vm.columns,
                        options: _vm.tableOptions,
                        "enable-custom-filtering": false,
                        slots: _vm.specificSlots
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: _vm.tableProps.name,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: _vm.constructContactProfileUrl(
                                          data.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.row[_vm.tableProps.name]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.email,
                            fn: function(data) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.constructEmailLink(
                                        data.row[_vm.tableProps.email]
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.row[_vm.tableProps.email]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.isAgency
                ? _c(
                    "div",
                    [
                      _c("winbox-company-header", {
                        attrs: {
                          title: _vm.relatedBrandsTitle,
                          "enable-brand-service-status-toggle": true
                        },
                        on: {
                          "toggle-status": _vm.updateBrandServiceVisibleStatus
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns" }, [
                        _c(
                          "div",
                          { staticClass: "column" },
                          [
                            _c("winbox-data-table", {
                              staticClass: "main-data-table",
                              attrs: {
                                data: _vm.visibleBrandServiceData,
                                columns: _vm.servicesColumns,
                                options: _vm.servicesTableOptions,
                                "enable-custom-filtering": false,
                                slots: _vm.servicesSpecificSlots
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: _vm.servicesTableProps.brand_name,
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                path: _vm.constructBrandProfileUrl(
                                                  data.row.brand_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(data.row.brand_name) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: _vm.servicesTableProps.service,
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              data.row.service.join(", ")
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.createModal,
          attrs: {
            name: _vm.createModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Create new contact",
            "save-text": "Create"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _vm.newContactEntity && _vm.isCreateNewContactLoaded
            ? _c("winbox-create-contact", {
                ref: "createContactRef",
                attrs: { data: _vm.newContactEntity }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isCreateNewContactLoaded
            ? _c("winbox-large-indicator")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }