var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entity-locations-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "entity-locations" },
            [
              _c("winbox-company-header", {
                attrs: { title: _vm.mainTitle },
                on: { changed: _vm.openModal }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-data-table", {
                      staticClass: "main-data-table",
                      attrs: {
                        data: _vm.locations,
                        columns: _vm.columns,
                        options: _vm.tableOptions,
                        slots: _vm.specificSlots
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: _vm.tableProps.name,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: _vm.constructDetailLocationUrl(
                                          data.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.row[_vm.tableProps.name]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.type,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("humanLocationType")(
                                        data.row[_vm.tableProps.type]
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.status,
                            fn: function(data) {
                              return [
                                _c("winbox-select", {
                                  attrs: {
                                    value: data.row[_vm.tableProps.status],
                                    options: _vm.possibleStatuses,
                                    searchable: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateLocationStatus(
                                        data.row,
                                        $event.value
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.createModal,
          attrs: {
            name: _vm.createModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Create new location",
            "save-text": "Create"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _vm.isCreateNewLocationLoaded
            ? _c("winbox-create-location", {
                ref: "createLocation",
                attrs: { data: _vm.newLocationEntity }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isCreateNewLocationLoaded
            ? _c("winbox-large-indicator")
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.validationModal,
          attrs: {
            name: _vm.validationModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Warning",
            "save-text": _vm.saveModalText,
            canReset: true
          },
          on: {
            "enter-press": _vm.onValidationCancel,
            "on-cancel": _vm.onValidationCancel,
            "on-save": _vm.onValidationSave
          }
        },
        [
          !_vm.nextStepIsActive
            ? _c("div", { staticClass: "step-one" }, [
                _vm.activeRelationships.length > 0 &&
                _vm.activeLocations.length > 1
                  ? _c("div", [
                      _vm._v(
                        "There are active relationships for this location. To continue you must select a new active location to all the existing relationships using this location. Continue for a bulk update."
                      )
                    ])
                  : _vm.activeRelationships.length > 0 &&
                    _vm.activeLocations.length === 1
                  ? _c("div", [
                      _vm._v(
                        "There are active relationships for this location. You must activate a new location before making this location inactive."
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.nextStepIsActive
            ? _c(
                "div",
                { staticClass: "step-two" },
                [
                  _c("winbox-select", {
                    attrs: { options: _vm.possibleActiveLocations },
                    model: {
                      value: _vm.updatedLocation,
                      callback: function($$v) {
                        _vm.updatedLocation = $$v
                      },
                      expression: "updatedLocation"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }