export const OrgTypes = {
  public_type: "Public",
  private_type: "Private",
  nonprofit: "Non-Profit",
  government: "Government",
  cooperative: "Cooperative",
  association: "Association",
  partnership: "Partnership",
  joint_venture: "Joint Venture",
  foundation: "Foundation",
};
