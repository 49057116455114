import { PeopleUrl } from "@/constants/PeopleUrl";
import { BusinessesUrl } from "@/constants/BusinessesUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse,
  putGenericFullResponse,
  deleteGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllPeople() {
  const url = PeopleUrl.base;
  return getGenericFullResponse(url);
}

export function createPerson(data) {
  const url = PeopleUrl.base;
  return postGenericFullResponse(url, data);
}

export function getPerson(id) {
  const url = `${PeopleUrl.base}/${id}`;
  return getGenericFullResponse(url);
}

export function updatePerson(id, data) {
  const url = `${PeopleUrl.base}/${id}`;
  return putGenericFullResponse(url, data);
}

export function getPersonSocials(id) {
  const url = `${PeopleUrl.base}/${id}/${BusinessesUrl.entity_socials}`;
  return getGenericFullResponse(url);
}

export function updatePersonSocialsList(id, data) {
  const url = `${PeopleUrl.base}/${id}/${BusinessesUrl.entity_socials}`;
  return putGenericFullResponse(url, data);
}

export function getPersonTags(id) {
  const url = `${PeopleUrl.base}/${id}/${BusinessesUrl.entity_tags}`;
  return getGenericFullResponse(url);
}

export function createPersonEntityTag(id, data) {
  const url = `${PeopleUrl.base}/${id}/${BusinessesUrl.entity_tags}`;
  return postGenericFullResponse(url, data);
}

export function getPersonNotes(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_notes}`;
  return getGenericFullResponse(url);
}

export function createPersonNote(id, data) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_notes}`;
  return postGenericFullResponse(url, data);
}

export function getPersonSpecialities(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_speciality_lists}`;
  return getGenericFullResponse(url);
}

export function assignPersonSpecialities(id, data) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_speciality_lists}`;
  return putGenericFullResponse(url, data);
}

export function updatePersonSpeciality(id, data) {
  const url = `${PeopleUrl.person_speciality_lists}/${id}`;
  return putGenericFullResponse(url, data);
}

export function deletePersonSpeciality(id) {
  const url = `${PeopleUrl.person_speciality_lists}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getPersonLogs(id) {
  const url = `${PeopleUrl.base}/${id}/${BusinessesUrl.entity_audit_logs}`;
  return getGenericFullResponse(url);
}

export function rollbackPersonLog(data) {
  const url = `${PeopleUrl.base}/${BusinessesUrl.entity_audit_logs_rollback}`;
  return postGenericFullResponse(url, data);
}

export function getPersonTitleFunctions(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_title_functions}`;
  return getGenericFullResponse(url);
}

export function updatePersonTitleFunctionList(id, data) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_title_functions}`;
  return putGenericFullResponse(url, data);
}

export function removePersonTitleFunction(id) {
  const url = `/${PeopleUrl.person_title_functions}/${id}`;
  return deleteGenericFullResponse(url);
}

export function removeAllPersonTitleFunctions(id) {
  const url = `/${PeopleUrl.person_title_functions}/all/${id}`;
  return deleteGenericFullResponse(url);
}

export function getPersonWorkHistories(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_work_histories}`;
  return getGenericFullResponse(url);
}

export function createPersonWorkHistory(id, data) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_work_histories}`;
  return postGenericFullResponse(url, data);
}

export function updatePersonWorkHistory(id, data) {
  const url = `/${PeopleUrl.person_work_histories}/${id}`;
  return putGenericFullResponse(url, data);
}

export function removePersonWorkHistory(id) {
  const url = `/${PeopleUrl.person_work_histories}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getPersonOrganizations(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_organizations}`;
  return getGenericFullResponse(url);
}

export function getPersonLocations(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_locations}`;
  return getGenericFullResponse(url);
}

export function getPersonBrands(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_brands}`;
  return getGenericFullResponse(url);
}

export function getPersonAgencyBrands(id) {
  const url = `${PeopleUrl.base}/${id}/${PeopleUrl.person_brands_list}?isAgency=1`;
  return getGenericFullResponse(url);
}

export function removeAllPersonCompanyBrands(id) {
  const url = `/${PeopleUrl.person_brand_companies}/all/${id}`;
  return deleteGenericFullResponse(url);
}

export function removeAllPersonAgencyBrands(id) {
  const url = `/${PeopleUrl.person_brand_agencies}/all/${id}`;
  return deleteGenericFullResponse(url);
}

export function removeAllPersonBusinessAgencyContacts(id) {
  const url = `/${PeopleUrl.person_business_agency_contacts}/all/${id}`;
  return deleteGenericFullResponse(url);
}

export function submitPeopleProof(id) {
  const url = `/people-proof/${id}`;
  return putGenericFullResponse(url);
}
