<template>
  <div class="winbox-upload-file">
    <form ref="uploadForm" enctype="multipart/form-data" novalidate>
      <div class="file is-primary">
        <label class="file-label" :class="disabledStyleObj">
          <input
            type="file"
            :name="uploadFile"
            :multiple="multiple"
            @change="filesChange"
            :accept="accept"
            :disabled="disabled"
            class="file-input"
          />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload" />
            </span>
            <span class="file-label" :class="disabledStyleObj">
              Choose a File
            </span>
          </span>
        </label>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "WinboxUploadFile",
  components: {},
  props: {
    accept: {
      type: String,
      default:
        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    },
    uploadFile: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      fileCount: null,
      fileData: null
    };
  },
  computed: {
    disabledStyleObj() {
      return {
        'disabled': this.disabled,
      };
    },
  },
  methods: {
    filesChange(e) {
      this.fileCount = e.target.files.length;
      this.fileData = e.target.files;
      this.$emit("changed", this.fileData);
    },
    resetFileUpload() {
      this.$refs.uploadForm.reset();
      this.fileData = null;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-upload-file {
  font-size: 18px;
  
  .file-label.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
