var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "columns winbox-data-table-search-columns" },
    [
      _c("div", { staticClass: "column is-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.mainFilter,
              expression: "mainFilter"
            }
          ],
          staticClass: "input main-search-input",
          attrs: { type: "text", placeholder: _vm.placeholderText },
          domProps: { value: _vm.mainFilter },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) return
                _vm.mainFilter = $event.target.value
              },
              _vm.onInput
            ]
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-1" },
        [
          _c(
            "button",
            {
              staticClass: "button is-primary custom-filters-btn",
              on: { click: _vm.showFilterModal }
            },
            [_vm._v("\n      Custom Filter\n    ")]
          ),
          _vm._v(" "),
          _c("winbox-data-table-filter-modal", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.filterModalIsVisible,
                expression: "filterModalIsVisible"
              }
            ],
            staticClass: "filter-modal",
            on: { changed: _vm.onFiltersChange }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }