var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-task-completion" },
    [
      _c("winbox-card", { staticClass: "item-overview" }, [
        _c("div", { staticClass: "item-title" }, [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-narrow" },
              [
                _c("winbox-title", {
                  attrs: { title: "Newly Added", type: "medium" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column item-label is-narrow" },
              [
                _c("winbox-title", {
                  attrs: { title: _vm.title, type: "medium" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-graph" },
          [
            _c("winbox-multi-axes-chart", {
              attrs: {
                chartData: _vm.currentChartData,
                options: _vm.chartOptions
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-draggable" },
          [_c("winbox-toggle-menu", { attrs: { options: _vm.rowOptions } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }