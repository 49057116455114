<template>
  <div class="contact-social-media">
    <winbox-large-indicator v-if="!isLoaded" />
    <winbox-card v-if="isLoaded">
      <template v-slot:header>
        Social Media
      </template>
      <template v-slot:headerIcon>
        <winbox-icon
          icon="plus-circle"
          :color="Colors.system.primaryLight"
          @click.native="$refs.winboxSocialMediaRef.addNewSocialEntity"
          v-if="isEditMode"
        />
      </template>
      <div class="content">
        <winbox-social-media
          ref="winboxSocialMediaRef"
          :items="socialEntities"
          @changed="onSocialChanged"
          :disabled="!isEditMode"
        />
      </div>
    </winbox-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxSocialMedia from "@/components/Molecules/CompanyProfile/WinboxSocialMedia";

import { NicknamesAndSocialsMixin } from "@/components/Pages/Generic/NicknamesAndSocialsMixin";
import { Colors } from "@/Colors";

export default {
  name: "ContactSocialMedia",
  mixins: [NicknamesAndSocialsMixin],
  components: {
    WinboxLargeIndicator,
    WinboxIcon,
    WinboxCard,
    WinboxSocialMedia
  },
  data() {
    return {
      Colors,
      isLoaded: false
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapState([
      'isEditMode',
      'personSocials',
    ]),
    id() {
      return +this.$route.params.id;
    }
  },
  methods: {
    ...mapActions([
      'getSocials',
      'getPersonSocials',
      'updatePersonSocialList',
      'edit',
      'reset',
      'save',
    ]),
    getData() {
      this.isLoaded = false;
      Promise.all([this.getSocials(), this.getPersonSocials(this.id)]).then(() => {
        this.socialEntities = this.personSocials;
        this.isLoaded = true;
      });
    },
    /* @Override */
    updateBusinessSocialsList(id, payload) {
      this.updatePersonSocialList({
        id,
        payload: {
          entity_socials: payload,
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-social-media {
  padding: 1rem;
  ::v-deep {
    .card-content {
      @include profile-card-content-base-styles;
    }
  }
}
</style>
