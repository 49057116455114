import { BusinessesUrl } from "@/constants/BusinessesUrl";
import { CompaniesUrl } from "@/constants/CompaniesUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse,
  putGenericFullResponse,
  deleteGenericFullResponse
} from "@/api/_generic/_generic";

export function getCompanies() {
  const url = CompaniesUrl.base;
  return getGenericFullResponse(url);
}

export function getCompanyCounts(id) {
  const url = `${CompaniesUrl.base}/${id}/${CompaniesUrl.counts}`;
  return getGenericFullResponse(url);
}

export function createCompany(data) {
  const url = CompaniesUrl.base;
  return postGenericFullResponse(url, data);
}

export function getCompany(id) {
  const url = `${CompaniesUrl.base}/${id}`;
  return getGenericFullResponse(url);
}

export function updateCompany(id, data) {
  const url = `${CompaniesUrl.base}/${id}`;
  return putGenericFullResponse(url, data);
}

export function getCompanyHierarchies(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_hierarchies}?type=company`;
  return getGenericFullResponse(url);
}

export function createCompanyHierarchy(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_hierarchies}?type=company`;
  return postGenericFullResponse(url, data);
}

export function removeCompanyHierarchy(id) {
  const url = `/${BusinessesUrl.business_hierarchies}/${id}?type=company`;
  return deleteGenericFullResponse(url);
}

export function getBrandAgenciesByCompany(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.agencies}`;
  return getGenericFullResponse(url);
}

export function getBrandAgencyContactsByCompany(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.brand_agency_contacts}`;
  return getGenericFullResponse(url);
}

export function unassignBrandAgencyContactsByCompany(id, business_id, brand_ids) {
  const url = `${BusinessesUrl.base}/${business_id}/${BusinessesUrl.brand_agency_contacts}/${id}?brand_ids=${brand_ids.join(',')}`;
  return deleteGenericFullResponse(url);
}

export function getBrandIdsByPersonAndCompany(data) {
  const url = `${BusinessesUrl.base}/${BusinessesUrl.brand_agency_contacts}`;
  return postGenericFullResponse(url, data);
}

export function submitCompaniesProof(id) {
  const url = `/companies-proof/${id}`;
  return putGenericFullResponse(url);
}
