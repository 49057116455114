var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "admin-console-page" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c("page-generic-entry", {
            attrs: {
              entity: _vm.entity,
              "menu-items": _vm.menuItems,
              "menu-sub-items": _vm.subItems,
              "enable-switcher": false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "entityName",
                  fn: function() {
                    return [
                      _c("winbox-title", { attrs: { title: "Admin Console" } })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "baseInfo",
                  fn: function() {
                    return undefined
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2028326355
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }