var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "front-dashboard-wrap" },
    [
      _c("winbox-notification", { attrs: { type: "info" } }, [
        _vm._v(_vm._s(_vm.notifMessage))
      ]),
      _vm._v(" "),
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c("div", { staticClass: "front-dashboard-content" }, [
            _c("div", { staticClass: "columns title-area" }, [
              _c("div", { staticClass: "column is-narrow" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  staticClass: "column is-narrow filter-by-last-days"
                },
                [
                  _c("winbox-select", {
                    attrs: {
                      value: _vm.currentLastDays,
                      options: _vm.filterDayOptions,
                      theme: "green-theme"
                    },
                    on: { input: _vm.onFilterDaysChanged }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns top-overview-cards" },
              _vm._l(_vm.overviewCards, function(item, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "column is-3" },
                  [
                    _c("winbox-card", { staticClass: "item-overview" }, [
                      _c(
                        "div",
                        { staticClass: "item-title" },
                        [
                          _c("winbox-title", {
                            attrs: { title: item.title, type: "medium" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-count" }, [
                        _vm._v(_vm._s(_vm._f("numeral")(item.count, "0,0")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-descr" }, [
                        _vm._v(_vm._s(item.descr))
                      ])
                    ])
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column is-12" },
                [
                  _c("winbox-user-tasks", {
                    on: { "task-completed": _vm.updateCompletionPercentage }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-desktop top-graph-cards" }, [
              _vm.isAdmin()
                ? _c(
                    "div",
                    { staticClass: "column is-half-desktop" },
                    [
                      _c("winbox-total-confirmed", {
                        attrs: { value: _vm.confirmedData }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-half-desktop align-right" },
                [
                  _c("winbox-task-completion", {
                    attrs: {
                      value: _vm.completionPercentage,
                      user: _vm.currentUser
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                staticClass: "columns total-updates-wrap"
              },
              [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-front-dashboard-widget", {
                      attrs: { value: _vm.totalUpdatesdData }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }