<template>
  <div class="contacts-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="contacts-wrap-content">
      <!-- Removing "Sync to Winmo" until a later date
      entity-secondary-label="Sync to Winmo"
      entity-secondary-icon="sync"
      @changedSecondary="openSyncModal" -->
      <winbox-company-header
        v-if="isAdmin()"
        :title="mainTitle"
        :entity-secondary-disabled="!markedRows.length"
        entity-fourth-label="Assign"
        @changedFourth="onAssignModal"
      />
      <winbox-company-header
        v-else
        :title="mainTitle"
      />
      <div class="columns">
        <div class="column">
          <winbox-server-data-table
            ref="mainTableRef"
            class="main-data-table"
            :url="getPeopleUrl()"
            :responseFunction="enhanceData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
            @rowsChange="onRowsChange"
            @filter="onFilter"
            @updateQuery="onUpdateQuery"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :data-tooltip="data.row.id"
                :to="{ path: constructContactProfileUrl(data.row.id) }"
              >
                {{ data.row[tableProps.name] }}
              </router-link>
            </template>
            <template :slot="tableProps.business" slot-scope="data">
              <router-link
                :data-tooltip="data.row[tableProps.business].id"
                :to="{ path: data.row[tableProps.business].url }"
              >
                {{ data.row[tableProps.business].name }}
              </router-link>
            </template>
            <template :slot="tableProps.email" slot-scope="data">
              <a :href="constructEmailLink(data.row[tableProps.email])">
                {{ data.row[tableProps.email] }}
              </a>
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <div class="status-wrap">
                <winbox-icon :color="data.row.statusColor" :icon="data.row.statusIcon" />
              </div>
            </template>
            <template :slot="tableProps.location" slot-scope="data">
              <router-link
                :data-tooltip="data.row[tableProps.location].location_id"
                :to="{ path: constructLocationUrl(data.row[tableProps.business].id, data.row[tableProps.location].location_id) }"
              >
                {{data.row[tableProps.location].name}}
              </router-link>
            </template>
          </winbox-server-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="createModal"
      :name="createModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Create new contact"
      save-text="Create"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <winbox-create-contact
        ref="createContactRef"
        v-if="newContactEntity && isCreateNewContactLoaded"
        :data="newContactEntity"
        :possible-locations="possibleLocations"
        :possible-orgs="possibleOrgs"
        :backend-errors="backendErrors"
        @changed="onCreateContactChanged"
      />
      <winbox-large-indicator v-if="!isCreateNewContactLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="syncModal"
      :name="syncModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Sync to Winmo"
      save-text="Sync"
      @on-cancel="onSyncCancel"
      @on-save="onSyncSave"
    >
      <p v-if="isSyncModalLoaded">Are you sure?</p>
      <winbox-large-indicator v-if="!isSyncModalLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="deleteModal"
      :name="deleteModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Delete contact"
      save-text="Ok"
      @on-cancel="onDeleteCancel"
      @on-save="onDeleteAccept"
    >
      <p v-if="isDeleteLoaded">Are you sure?</p>
      <winbox-large-indicator v-if="!isDeleteLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="assignModal"
      :name="assignModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Assign Contact Tasks"
      save-text="Assign"
      @on-cancel="onAssignCancel"
      @on-save="onAssignSave"
    >
      <winbox-assign-entity-tasks
        ref="assignEntityTasksRef"
        v-if="isAssignNewEntityTasksLoaded"
      />
      <winbox-large-indicator v-if="!isAssignNewEntityTasksLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxServerDataTable from "@/components/Molecules/WinboxServerDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxCreateContact from "@/components/Organisms/WinboxCreateContact";

import WinboxAssignEntityTasks from "@/components/Organisms/WinboxAssignEntityTasks";
import { WinboxAssignEntityTasksHelperMixin } from "@/components/Organisms/WinboxAssignEntityTasksHelperMixin";

import { WinboxCreateContactHelperMixin } from "@/components/Organisms/WinboxCreateContactHelperMixin";
import { BusinessEntitiesHelperMixin } from "@/components/Pages/Generic/BusinessEntitiesHelperMixin";
import { EditModeMixin } from "@/components/Pages/Generic/EditModeMixin";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

import { BusinessStatuses } from "@/constants/BusinessStatuses";
import * as BusinessesApi from "@/api/businesses/businesses";

const tableProps = {
  id: "id",
  name: "name",
  business: "business",
  title: "title",
  email: "email",
  location: "location",
  status: "status",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "Contacts",
  mixins: [
    WinboxAssignEntityTasksHelperMixin,
    WinboxCreateContactHelperMixin,
    BusinessEntitiesHelperMixin,
    EditModeMixin,
    RolesMixin
  ],
  components: {
    WinboxIcon,
    WinboxLargeIndicator,
    WinboxServerDataTable,
    WinboxCompanyHeader,
    WinboxAssignEntityTasks,
    WinboxModal,
    WinboxCreateContact,
  },
  data() {
    return {
      isLoaded: false,
      markedRows: [],
      searchQuery: null,
      businessEntity: {},
      locationsData: [],
      orgsData: [],
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.title]: "Title",
        },
        sortable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
        filterable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
        filterAlgorithm: {
          name(row, query) {
            return row.name.includes(query.trim());
          },
          business(row, query) {
            return (
              row.business &&
              Object.keys(row.business).length > 0 &&
              row.business.name.includes(query.trim())
            );
          },
          location(row, query) {
            return (
              row.location &&
              Object.keys(row.location).length > 0 &&
              row.location.includes(query.trim())
            );
          },
          title(row, query) {
            return row.title && row.title.includes(query.trim());
          },
          email(row, query) {
            return row.email && row.email.includes(query.trim());
          },
        },
      },
      specificSlots: [
        tableProps.id,
        tableProps.name,
        tableProps.business,
        tableProps.email,
        tableProps.location,
        tableProps.status
      ],
      Colors: Colors,
    };
  },
  computed: {
    mainTitle() {
      return `Contacts (${this.itemsCount})`;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    companies() {
      return this.$store.getters.COMPANIES;
    },
    agencies() {
      return this.$store.getters.AGENCIES;
    },
    possibleOrgs() {
      return [...this.companies, ...this.agencies].map((item) => {
        return {
          id: item.id,
          label: item.name,
          value: item.id,
        };
      });
    },
    possibleLocations() {
      return this.locationsData.map((item) => {
        return {
          id: item.id,
          label: item.city,
          value: item.id,
        };
      });
    },
  },
  beforeMount() {
    this.getData();
    this.syncType = "contacts";
  },
  watch: {
    '$route.query': {
      handler (oldUrl, newUrl) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    getData() {
      this.isLoaded = false;
      this.isLoaded = true;
      this.afterLoadData();
    },
    getBusinessLocations(id) {
      return BusinessesApi.getBusinessLocations(id).then(({ data }) => {
        this.locationsData = data;
      });
    },
    onCreateContactChanged(e) {
      if (e && e.organization) {
        const createContactVm = this.$refs.createContactRef;

        this.getBusinessLocations(e.organization.id);
        createContactVm.entity.location = null;
      }
    },
    afterLoadData() {
      if (typeof this.tryToSetGlobalFilters === "function") {
        this.$nextTick(() => {
          this.tryToSetGlobalFilters();
        });
      }
    },
    enhanceData(data, count) {
      this.itemsCount = count.toLocaleString();
      return data.map((item) => {
        return {
          ...item,
          [tableProps.name]: `${item.fname} ${item.lname}`,
          statusColor: this.getStatusIconColor(item.published),
          statusIcon: this.getStatusIcon(item.published),
          [tableProps.business]: this.getHumanBusiness(item.organization),
          [tableProps.location]: this.getHumanBusinessLocation(
            item.organization
          ),
          [tableProps.status]: Utils.findStatusForSelect(
            item[tableProps.status],
            this.possibleStatuses
          ),
        };
      });
    },
    /* TODO: waiting for backend prop "type" in Person.organization */
    getHumanBusiness(organization) {
      let result = {};

      if (organization && organization.id) {
        result = {
          id: organization.id,
          name: organization.name,
          url: this.getBusinessUrl(organization),
        };
      }
      return result;
    },
    getBusinessUrl(organization) {
      let functionName = `get${organization.type}ProfileUrl`;
      return Utils[functionName](organization.id);
    },
    getHumanBusinessLocation(organization) {
      let result = { name: "", location_id: "" };
      if (organization && organization.location) {
        result = {
          name: `${organization.location.city}, ${organization.location.state}`,
          location_id: organization.location.id,
        };
      }
      return result;
    },
    constructContactProfileUrl(id) {
      return Utils.getContactProfileUrl(id);
    },
    constructBusinessUrl(id) {
      return Utils.getCompanyProfileUrl(id);
    },
    constructLocationUrl(companyId, locationId) {
      return Utils.getCompanyLocationDetailUrl(companyId, locationId);
    },
    constructEmailLink(email) {
      return `mailto:${email}`;
    },
    getStatusIconColor(status) {
      if (status === null) return this.Colors.system.gray;

      return status
        ? this.Colors.system.primaryLight
        : this.Colors.system.inactive;
    },
    getStatusIcon(status) {
      if (status === null) return "circle";

      return status
        ? "circle"
        : "times";
    },
    onChangeStatus(row, e) {
      row[tableProps.status] = e;
      row.statusColor = this.getStatusIconColor(e.value);
      row.statusIcon = this.getStatusIcon(e.value);
    },
    updateStatus(id, status) {
      this.$store
        .dispatch("updatePerson", {
          id: id,
          payload: { status: status },
          type: "ADD_UPDATE_PROFILE_CONTACT",
        })
        .then(() => {
          this.$forceUpdate();
        })
        .catch((e) => {});
    },
    getStatus(item) {
      let statusValue = BusinessStatuses.inactive.value;
      if (item[tableProps.status]) {
        statusValue = item[tableProps.status].value;
      }
      return statusValue;
    },
    getPeopleUrl() {
      const locations = this.$route.query.locations;
      const jobs = this.$route.query.jobs;
      const ranks = this.$route.query.ranks;
      const locationQuery = (locations && locations.length !== 0 ? `locations=${locations}` : '');
      const jobQuery = (jobs && jobs.length !== 0 ? `jobs=${jobs}` : '');
      const rankQuery = (ranks && ranks.length !== 0 ? `ranks=${ranks}` : '');

      const query = [locationQuery, jobQuery, rankQuery].filter((item) => item != '').join('&');
      return `${Utils.getPeoplePageUrl()}${query.length > 0 ? `?${query}` : ''}`;
    },
    onRowsChange(e) {
      this.markedRows = [...e];
    },
    onUpdateQuery(data) {
      this.searchQuery = data.query;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-data-table {
  ::v-deep {
    font-size: 13px;
    thead {
      th {
        &:nth-last-child(2) {
          width: 165px;
        }
        &:last-child {
          width: 100px;
        }
      }
    }
    .status-wrap {
      .icon {
        margin: 0 5px 0 0;
      }
      .winbox-select {
        display: inline-block;
        width: 110px;
      }
    }
  }
}
</style>
