export const UserStatuses = {
  active: {
    label: "Active",
    value: "active"
  },
  inactive: {
    label: "Inactive",
    value: "inactive"
  }
};
