var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "icon", class: [_vm.containerClass] }, [
    _c("i", { class: _vm.classNames, style: _vm.style })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }