<template src="../Generic/BusinessEntityEntry.html" />

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxLogoInput from "@/components/Molecules/WinboxLogoInput";
import WinboxModal from "@/components/Molecules/WinboxModal";

import PageGenericEntry from "@/components/Pages/PageGenericEntry";

import WinboxAssignEntityTasks from "@/components/Organisms/WinboxAssignEntityTasks";
import { WinboxAssignEntityTasksHelperMixin } from "@/components/Organisms/WinboxAssignEntityTasksHelperMixin";

import { NoteTypes } from "@/constants/NoteTypes";
import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";

import { AnyEntityEntryMixin } from "@/components/Pages/Generic/AnyEntityEntryMixin";
import { SaveAnyEntitySupportMixin } from "@/components/Pages/Generic/SaveAnyEntitySupportMixin";

import * as CompaniesApi from "@/api/companies/companies";
import * as BusinessesApi from "@/api/businesses/businesses";

export default {
  name: "CompanyEntry",
  mixins: [
    AnyEntityEntryMixin, 
    SaveAnyEntitySupportMixin,
    WinboxAssignEntityTasksHelperMixin,
  ],
  components: {
    WinboxTitle,
    WinboxModal,
    WinboxLargeIndicator,
    WinboxLogoInput,
    PageGenericEntry,
    WinboxAssignEntityTasks,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  watch: {
    '$route': {
      handler (oldUrl, newUrl) {
        const regex = /locations\/\d+/g;
        const isLocationsPage = this.$route.path.match(regex);
        if(isLocationsPage) {
          BusinessesApi.getBusinessLocation(this.$route.params.locationId)
          .then(({ data: { proofed_by_name, proofed_at }}) => {
            this.$set(this.entity, 'proofed_by_name', proofed_by_name);
            this.$set(this.entity, 'proofed_at', proofed_at);
          });
        }
        else {
          CompaniesApi.getCompany(this.id)
          .then(({ data: { proofed_by_name, proofed_at }}) => {
            this.$set(this.entity, 'proofed_by_name', proofed_by_name);
            this.$set(this.entity, 'proofed_at', proofed_at);
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'BUSINESS_TAGS_BY_ID',
      'BUSINESS_NOTES_BY_ID',
      'BUSINESS_SOCIALS_BY_ID',
      'BUSINESS_NICKNAMES_BY_ID',
    ]),
    ...mapState([
      'logo_url',
      'currentCompany',
      'entityCounts',
      'businessAgencyContacts',
    ]),
    id() {
      return +this.$route.params.id;
    },
    locationsCount() {
      return this.entityCounts.locations_count;
    },
    contactsCounts() {
      return this.entityCounts.contacts_count;
    },
    companyAgenciesContactsCount() {
      return this.entityCounts.entity_agency_contacts_count;
    },
    agenciesCounts() {
      return this.entityCounts.entity_agency_count;
    },
    brandsCounts() {
      return this.entityCounts.entity_brand_count;
    },
    hierarchiesCount() {
      return this.entityCounts.hierarchy_total_count;
    },
    notesCount() {
      return this.entityCounts.note_count;
    },
    tagsCount() {
      return this.entityCounts.tags_count;
    },
    internalNotesCount() {
      return this.entityCounts.internal_note_count;
    },
    entity() {
      return this.currentCompany;
    },
    menuItems() {
      if (!this.isLoaded) {
        return [];
      }
      return [
        { 
          link: this.profileUrl, 
          title: "Profile",
        },
        {
          link: this.locationsUrl,
          title: "Locations",
          count: this.locationsCount,
        },
        {
          link: this.contactsUrl,
          title: "Contacts",
          count: this.contactsCounts,
        },
        {
          link: this.agencyContactsUrl,
          title: "Agency Contacts",
          count: this.companyAgenciesContactsCount,
        },
        {
          link: this.agenciesUrl,
          title: "Agencies",
          count: this.agenciesCounts,
        },
        { link: this.brandsUrl, 
          title: "Brands", 
          count: this.brandsCounts,
        },
        {
          link: this.hierarchyUrl,
          title: "Hierarchy",
          count: this.hierarchiesCount,
        },
        { 
          link: this.notesUrl, 
          title: "Notes", 
          count: this.notesCount,
        },
      ];
    },
    subItems() {
      return [
        { 
          link: this.tagsUrl, 
          title: "Tags", 
          count: this.tagsCount,
        },
        {
          link: this.internalNotesUrl,
          title: "Internal Notes",
          count: this.internalNotesCount,
        },
        {
          link: this.auditLogUrl,
          title: "Audit Log",
        }
      ];
    },
    baseUrl() {
      return `${WinboxRoutePaths.COMPANIES}/${this.id}`;
    },
    profileUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_PROFILE}`;
    },
    locationsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_LOCATIONS}`;
    },
    contactsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_CONTACTS}`;
    },
    agencyContactsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_AGENCY_CONTACTS}`;
    },
    agenciesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_AGENCIES}`;
    },
    brandsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_BRANDS}`;
    },
    hierarchyUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_HIERARCHY}`;
    },
    notesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_NOTES}`;
    },
    tagsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_TAGS}`;
    },
    internalNotesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_INTERNAL_NOTES}`;
    },
    auditLogUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.COMPANY_AUDIT_LOG}`;
    },
    markedRows() {
      return [this.id];
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getLogo',
      'getCompany',
      'getServices',
      'getIndustries',
      'getMediaTypes',
      'updateCompany',
      'getCompanyCounts',
      'updateLogo',
    ]),
    async getData() {
      this.isLoaded = false;
      
      this.getLogo({ owner_id: this.id, owner_type: 'Company' });
      this.getServices();
      this.getIndustries();
      this.getMediaTypes();
      this.getCompanyCounts(this.id);
      
      await this.getCompany(this.id);

      this.isLoaded = true;
    },
    onLogoChange(logo_url) {
      this.updateLogo({
        logo_url: logo_url,
        owner_type: 'Company',
        owner_id: this.id,
      });
    },
    saveEntity() {
      if (!Object.keys(this.dataUpdate).length) {
        return;
      }
      const payload = { ...this.dataUpdate };
      this.updateCompany({ id: this.id, payload });
      this.afterSaveEntity();
    },
    submitProof() {
      const regex = /locations\/\d+/g;
      const isLocationsPage = this.$route.path.match(regex);
      if(isLocationsPage) {
        BusinessesApi.submitBusinessLocationsProof(this.$route.params.locationId)
        .then(({ data: { proofed_by_name, proofed_at }}) => {
          this.$set(this.entity, 'proofed_by_name', proofed_by_name);
          this.$set(this.entity, 'proofed_at', proofed_at);
        });
      }
      else {
        CompaniesApi.submitCompaniesProof(this.id)
        .then(({ data: { proofed_by_name, proofed_at }}) => {
          this.$set(this.entity, 'proofed_by_name', proofed_by_name);
          this.$set(this.entity, 'proofed_at', proofed_at);
        });
      }
    }
  },
};
</script>

<style scoped lang="scss" />
