var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-social-media" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "winbox-card",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [_vm._v("\n      Social Media\n    ")]
                    },
                    proxy: true
                  },
                  {
                    key: "headerIcon",
                    fn: function() {
                      return [
                        _vm.isEditMode
                          ? _c("winbox-icon", {
                              attrs: {
                                icon: "plus-circle",
                                color: _vm.Colors.system.primaryLight
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.$refs.winboxSocialMediaRef.addNewSocialEntity.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3682993566
              )
            },
            [
              _vm._v(" "),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("winbox-social-media", {
                    ref: "winboxSocialMediaRef",
                    attrs: {
                      items: _vm.socialEntities,
                      disabled: !_vm.isEditMode
                    },
                    on: { changed: _vm.onSocialChanged }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }