var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap-company-header" }, [
    _c("div", { staticClass: "columns is-vcentered" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-title", { attrs: { title: _vm.title } }),
          _vm._v(" "),
          _vm.enableStatusToggle
            ? _c("div", { staticClass: "radio-status" }, [
                _c("input", {
                  attrs: {
                    type: "radio",
                    id: "active",
                    name: "status",
                    checked: ""
                  },
                  domProps: { value: _vm.activeLabel },
                  on: {
                    click: function($event) {
                      return _vm.$emit("toggle-status", _vm.activeLabel)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "status", attrs: { for: "active" } },
                  [_vm._v(_vm._s(_vm.activeLabel))]
                ),
                _c("br"),
                _vm._v(" "),
                _vm.inProgressLabel
                  ? _c("span", [
                      _c("input", {
                        attrs: {
                          type: "radio",
                          id: "in-progress",
                          name: "status"
                        },
                        domProps: { value: _vm.inProgressLabel },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "toggle-status",
                              _vm.inProgressLabel
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "status",
                          attrs: { for: "in-progress" }
                        },
                        [_vm._v(_vm._s(_vm.inProgressLabel))]
                      ),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "radio", id: "past", name: "status" },
                  domProps: { value: _vm.inactiveLabel },
                  on: {
                    click: function($event) {
                      return _vm.$emit("toggle-status", _vm.inactiveLabel)
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { staticClass: "status", attrs: { for: "past" } }, [
                  _vm._v(_vm._s(_vm.inactiveLabel))
                ]),
                _c("br"),
                _vm._v(" "),
                _vm.escalatedLabel
                  ? _c("span", [
                      _c("input", {
                        attrs: {
                          type: "radio",
                          id: "escalated",
                          name: "status"
                        },
                        domProps: { value: _vm.escalatedLabel },
                        on: {
                          click: function($event) {
                            return _vm.$emit(
                              "toggle-status",
                              _vm.escalatedLabel
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "status", attrs: { for: "escalated" } },
                        [_vm._v(_vm._s(_vm.escalatedLabel))]
                      ),
                      _c("br")
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.enableLocationContactStatusToggle
            ? _c("div", { staticClass: "radio-status" }, [
                _c("input", {
                  attrs: {
                    type: "radio",
                    id: "active",
                    name: "status",
                    checked: ""
                  },
                  domProps: { value: _vm.locationContactActiveLabel },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "toggle-status",
                        _vm.locationContactActiveLabel
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "status", attrs: { for: "location-active" } },
                  [_vm._v(_vm._s(_vm.locationContactActiveLabel))]
                ),
                _c("br"),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "radio", id: "inactive", name: "status" },
                  domProps: { value: _vm.locationContactInactiveLabel },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "toggle-status",
                        _vm.locationContactInactiveLabel
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "status",
                    attrs: { for: "location-inactive" }
                  },
                  [_vm._v(_vm._s(_vm.locationContactInactiveLabel))]
                ),
                _c("br")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.enableBrandServiceStatusToggle
            ? _c("div", { staticClass: "radio-status" }, [
                _c("input", {
                  attrs: {
                    type: "radio",
                    id: "active",
                    name: "brand-status",
                    checked: ""
                  },
                  domProps: { value: _vm.brandServiceActiveLabel },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "toggle-status",
                        _vm.brandServiceActiveLabel
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "status",
                    attrs: { for: "brand-service-active" }
                  },
                  [_vm._v(_vm._s(_vm.brandServiceActiveLabel))]
                ),
                _c("br"),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "radio",
                    id: "inactive",
                    name: "brand-status"
                  },
                  domProps: { value: _vm.brandServiceInactiveLabel },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "toggle-status",
                        _vm.brandServiceInactiveLabel
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "status",
                    attrs: { for: "brand-service-inactive" }
                  },
                  [_vm._v(_vm._s(_vm.brandServiceInactiveLabel))]
                ),
                _c("br")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "column is-narrow header-action-wrap" }, [
        _vm.hasEventHandler
          ? _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: !_vm.hasEventHandler },
                on: {
                  click: function($event) {
                    return _vm.$emit(_vm.mainEventName, {})
                  }
                }
              },
              [
                _c("winbox-icon", {
                  staticClass: "add-entity-icon",
                  attrs: {
                    color: _vm.Colors.system.lightGray,
                    icon: _vm.entityIcon
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.entityLabel))])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.hasEventSecondaryHandler
        ? _c("div", { staticClass: "column is-narrow header-action-wrap" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: _vm.entitySecondaryDisabled },
                on: {
                  click: function($event) {
                    return _vm.$emit(_vm.secondaryEventName, {})
                  }
                }
              },
              [
                _vm.entitySecondaryIcon
                  ? _c("winbox-icon", {
                      staticClass: "add-entity-icon",
                      attrs: {
                        color: _vm.Colors.system.lightGray,
                        icon: _vm.entitySecondaryIcon
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.entitySecondaryLabel
                  ? _c("span", [_vm._v(_vm._s(_vm.entitySecondaryLabel))])
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "column is-narrow header-action-wrap" }, [
        _vm.hasEventFourthHandler
          ? _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: _vm.entityFourthDisabled },
                on: {
                  click: function($event) {
                    return _vm.$emit(_vm.fourthEventName, {})
                  }
                }
              },
              [
                _c("winbox-icon", {
                  staticClass: "add-entity-icon",
                  attrs: {
                    color: _vm.Colors.system.lightGray,
                    icon: _vm.entityIcon
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.entityFourthLabel))])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.hasEventThirdHandler
        ? _c("div", { staticClass: "column is-narrow header-action-wrap" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: _vm.entityThirdDisabled },
                on: {
                  click: function($event) {
                    return _vm.$emit(_vm.thirdEventName, {})
                  }
                }
              },
              [
                _vm.entityThirdIcon
                  ? _c("winbox-icon", {
                      staticClass: "add-entity-icon",
                      attrs: {
                        color: _vm.Colors.system.lightGray,
                        icon: _vm.entityThirdIcon
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.entityThirdLabel
                  ? _c("span", [_vm._v(_vm._s(_vm.entityThirdLabel))])
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }