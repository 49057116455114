<template>
<div class="entity-locations-wrap">
  <winbox-large-indicator v-if="!isLoaded"/>
  <div v-if="isLoaded" class="entity-locations">
    <winbox-company-header :title="mainTitle" @changed="openModal"/>
    <div class="columns">
      <div class="column">
        <winbox-data-table
            class="main-data-table"
            :data="locations"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
        >
          <template :slot="tableProps.name" slot-scope="data">
            <router-link
                :to="{ path: constructDetailLocationUrl(data.row.id) }"
            >
              {{ data.row[tableProps.name] }}
            </router-link>
          </template>
          <template :slot="tableProps.type" slot-scope="data">
            {{ data.row[tableProps.type] | humanLocationType }}
          </template>
          <template :slot="tableProps.status" slot-scope="data">
            <winbox-select
                :value="data.row[tableProps.status]"
                :options="possibleStatuses"
                :searchable="false"
                @input="updateLocationStatus(data.row, $event.value)"
            />
          </template>
        </winbox-data-table>
      </div>
    </div>
  </div>
  <winbox-modal
      :ref="createModal"
      :name="createModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Create new location"
      save-text="Create"
      @on-cancel="onCancel"
      @on-save="onSave"
  >
    <winbox-create-location
        v-if="isCreateNewLocationLoaded"
        ref="createLocation"
        :data="newLocationEntity"
    />
    <winbox-large-indicator v-if="!isCreateNewLocationLoaded"/>
  </winbox-modal>
  <winbox-modal
      :ref="validationModal"
      :name="validationModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Warning"
      :save-text="saveModalText"
      :canReset="true"
      @enter-press="onValidationCancel"
      @on-cancel="onValidationCancel"
      @on-save="onValidationSave"
  >
    <div v-if="!nextStepIsActive" class="step-one">
      <div v-if="activeRelationships.length > 0 && activeLocations.length > 1">There are active relationships for this location. To continue you must select a new active location to all the existing relationships using this location. Continue for a bulk update.</div>
      <div v-else-if="activeRelationships.length > 0 && activeLocations.length === 1">There are active relationships for this location. You must activate a new location before making this location inactive.</div>
    </div>

    <div v-if="nextStepIsActive" class="step-two">
      <winbox-select
          v-model="updatedLocation"
          :options="possibleActiveLocations"
      />
    </div>
  </winbox-modal>
</div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";

import WinboxCreateLocation from "@/components/Organisms/WinboxCreateLocation";

import * as BusinessesApi from "@/api/businesses/businesses";

import { Colors } from "@/Colors";
import { BusinessStatuses } from "@/constants/BusinessStatuses";
import { LocationTypes } from "@/constants/LocationTypes";
import * as Utils from "@/utils";

const tableProps = {
  name: "name",
  count: "_relatedContacts",
  type: "location_type",
  city: "city",
  status: "status"
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "AgencyLocations",
  components: {
    WinboxModal,
    WinboxSelect,
    WinboxLargeIndicator,
    WinboxCompanyHeader,
    WinboxDataTable,
    WinboxCreateLocation
  },
  filters: {
    humanLocationType(key) {
      return LocationTypes[key];
    }
  },
  data() {
    return {
      newLocationEntity: {
        name: "",
        type: "",
        address_1: "",
        city: "",
        zip: "",
        status: BusinessStatuses.active
      },
      Colors: Colors,
      isLoaded: true,
      nextStepIsActive: false,
      isCreateNewLocationLoaded: true,
      updatedLocation: null,
      currentLocation: null,
      currentStatus: null,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      locationsData: [],
      activeRelationships: [],
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.count]: "Contacts",
          [tableProps.type]: "Type",
          [tableProps.city]: "City",
          [tableProps.status]: "Status"
        },
        sortable: [...mainTableColumns],
        filterable: [...mainTableColumns]
      },
      specificSlots: [tableProps.name, tableProps.type, tableProps.status],
      createModal: "createModal",
      validationModal: "validationModal",
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapState([
      'currentAgency',
      'businessLocations',
      'agencyRelationships',
      'businessProfileContacts',
    ]),
    id() {
      return +this.$route.params.id;
    },
    saveModalText() {
      if(this.activeRelationships.length > 0 && this.activeLocations.length === 1) {
        return null;
      }
      else if(this.nextStepIsActive) {
        return "Update Location";
      }
      else {
        return "Continue";
      }
    },
    mainTitle() {
      return `Locations (${this.locationsData.length})`;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    possibleActiveLocations() {
      return this.activeLocations.filter((activeLocation) => {
        return activeLocation.id != this.currentLocation.id;
      }).map((activeLocation) => {
        return {
          label: activeLocation.name,
          value: activeLocation.id,
          location_type: activeLocation.location_type,
        }
      });
    },
    activeLocations() {
      return this.businessLocations.filter((businessLocation) => businessLocation.status === 'active');
    },
    locations() {
      return this.locationsData;
    },
    contactsData() {
      return this.businessProfileContacts || [];
    },
    businessEntity() {
      return this.currentAgency;
    },
  },
  methods: {
    ...mapActions([
      'getAgencyRelationshipForAgencyLocation',
      'getBusinessLocations',
    ]),
    async getData() {
      this.isLoaded = false;

      await this.getBusinessLocations(this.id);
      await this.getAgencyRelationshipForAgencyLocation(this.id);
      this.updateLocationData();

      this.isLoaded = true;
    },
    constructDetailLocationUrl(locationId) {
      return Utils.getAgencyLocationDetailUrl(
        this.businessEntity.id,
        locationId
      );
    },
    openValidationModal() {
      this.$refs[this.validationModal].show();
    },
    onValidationCancel() {
      this.activeRelationships = [];
      this.nextStepIsActive = false;
      this.$refs[this.validationModal].hide();
    },
    onValidationSave() {
      if (this.activeRelationships.length > 0 && this.activeLocations.length !== 1) {
        if (!this.nextStepIsActive) {
          this.nextStepIsActive = true;
        }
        else {
          const payload = {
            relationship_ids: this.activeRelationships.map((activeRelationship) => activeRelationship.id),
            agency_location_id: this.updatedLocation.value,
          }

          BusinessesApi.bulkUpdateBusinessRelationships(payload).then(() => {
            this.getAgencyRelationshipForAgencyLocation(this.id);
          });
          this.updateLocation(this.currentLocation, this.currentStatus);
          this.onValidationCancel();
        }
      }
      else {
        this.onValidationCancel();
      }
    },
    openModal() {
      this.$refs[this.createModal].show();
    },
    onCancel() {
      this.$refs[this.createModal].hide();
    },
    onSave() {
      const createLocationVm = this.$refs.createLocation;
      const $v = createLocationVm.$v;
      createLocationVm.submitStatus = true;
      $v.$touch();

      if (!$v.$error) {
        this.isCreateNewLocationLoaded = false;
        const payload = {
          ...createLocationVm.entity,
          location_type: createLocationVm.entity.location_type.value,
          tv_region_id: createLocationVm.entity?.tv_region?.value
        };

        this.$store
          .dispatch("createBusinessLocation", {
            id: this.id,
            payload: payload
          })
          .then(() => {
            this.updateLocationData();
            this.isCreateNewLocationLoaded = true;
            this.onCancel();
          });
      }
    },
    updateLocationData() {
      this.locationsData = this.businessLocations.map(item => {
        return {
          ...item,
          [tableProps.count]: item.persons.length,
          [tableProps.status]: Utils.findStatusForSelect(
            item[tableProps.status],
            this.possibleStatuses
          )
        };
      });
    },
    updateLocationStatus(data, status) {
      let isValidation = true;

      this.currentLocation = data;
      this.activeRelationships = this.agencyRelationships.filter((agencyRelationship) => agencyRelationship.agency_location_id === data.id);

      if(status === 'inactive') {

        if(this.activeRelationships.length > 0) {
          setTimeout(() => this.openValidationModal(), 300);
        }
        else {
          isValidation = false;
        }
      }
      else {
        isValidation = false;
      }

      if(!isValidation) {
        this.updateLocation(data, status);
      }
      else {
        this.updatedLocation = this.possibleActiveLocations[0];
        this.currentStatus = status;
        let index = this.locationsData.findIndex((locationData) => locationData.id == data.id);

        this.$set(this.locationsData, index, { ...data, status: this.possibleStatuses[0] });
      }
    },
    updateLocation(data, status) {
      const payload = {
        location_type: data.location_type,
        status: status
      };

      this.$store.dispatch("updateBusinessLocation", {
        locationId: data.id,
        payload,
      }).then(() => {
        this.updateLocationData();
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

.entity-locations {
  padding-top: 0.5rem;
  .main-data-table {
    ::v-deep {
    font-size: 13px;
      thead {
        th {
          &:last-child {
            width: 150px;
          }
        }
      }
    }
  }
}
</style>
