import * as UsersApi from "@/api/users/users";
import * as AuthApi from "@/api/auth/auth";
import * as UserRolesApi from "@/api/roles/roles";
import vueCookie from "vue-cookie";

import { pick } from "lodash";
import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getUsers({ commit }) {
    return UsersApi.getUsers().then(data => {
      commit(MutationTypes.SET_USERS, data);
    });
  },

  signIn({ commit, getters }, payload) {
    return AuthApi.signIn(payload).then(response => {
      const authHeaders = pick(response.headers, [
        "access-token",
        "client",
        "expiry",
        "uid",
        "token-type"
      ]);

      commit(MutationTypes.SET_AUTH, authHeaders);
      commit(MutationTypes.SET_USER, response.data.data);

      const contents = {
        tokens: authHeaders,
        user: response.data.data
      };
      vueCookie.set("session", JSON.stringify(contents), { expires: "1D" });
    });
  },

  createUser({ commit, getters }, payload) {
    const allData = getters.USERS;

    return UsersApi.createUser(payload).then(data => {
      allData.push(data);
      commit(MutationTypes.SET_USERS, allData);
    });
  },

  updateUser({ commit, getters }, { id, payload }) {
    let allData = getters.USERS;

    return UsersApi.updateUser(id, payload).then(data => {
      allData = Utils.spliceArrayItem(allData, data);
      commit(MutationTypes.SET_USERS, allData);
      let currentUser = getters.USER;
      if (id === currentUser.id) {
        AuthApi.getCurrentAccount().then(data => {
          commit(MutationTypes.SET_USER, data);
        });
      }
    });
  },

  getUserRoles({ commit }) {
    return UserRolesApi.getRoles().then(data => {
      commit(MutationTypes.SET_USER_ROLES, data);
    });
  },

  getUser({ commit }) {
    return AuthApi.getCurrentAccount().then(data => {
      commit(MutationTypes.SET_USER, data);
    });
  },

  deleteUser({ commit, getters }, { id }) {
    let allData = getters.USERS;

    return UsersApi.deleteUser(
      id
    ).then(({ data }) => {
      allData = allData.filter(item => item.id !== id);
      commit(MutationTypes.SET_USERS, allData );
      return { data };
    });
  },
};
