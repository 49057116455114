<template functional>
  <li>
    <router-link
      :exact="props.exact"
      :to="props.link"
      :class="[{ disabled: props.disabled }, props.additionalClass]"
    >
      <slot />
    </router-link>
  </li>
</template>

<script>
export default {
  name: "WinboxTabItem",
  props: {
    link: {
      type: [String, Object],
      required: true,
      note: "Link to relocate"
    },
    exact: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Is tab disabled"
    },
    additionalClass: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

::v-deep {
  .router-link-active {
    font-weight: 600;
  }
}
</style>
