<template>
  <div class="wrap-disciplines-block">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="disciplines">
      <div class="columns">
        <div class="column">
          <winbox-disciplines-list
            :possible-tags="possibleDisciplineTags"
            :entity-tags="selectedDisciplineTags"
            :placeholder-possible="placeholderPossible"
            :placeholder-selected="placeholderSelected"
            prop-selected-parent-id="discipline_id"
            @changed="onTagsChanged($event, 'name')"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDisciplinesList from "@/components/Organisms/WinboxDisciplinesList";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

export default {
  name: "WinboxDisciplines",
  mixins: [WinboxSpecialityTagsHelperMixin],
  components: {
    WinboxLargeIndicator,
    WinboxDisciplinesList
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      Colors: Colors,
      isLoaded: true,
      placeholderPossible: "Add one or more options",
      placeholderSelected: "Related disciplines"
    };
  },
  computed: {
    ...mapState([
      'disciplines',
      'businessDisciplines',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return "Disciplines";
    },
    possibleDisciplineTags() {
      return Utils.sortAlphabetic(this.disciplines, "name");
    },
    selectedDisciplineTags() {
      return Utils.sortAlphabetic(
        this.businessDisciplines.map(item => {
          return {
            ...item,
            name: this.findTagEntityName(item.discipline_id)
          };
        }),
        "name"
      );
    }
  },
  methods: {
    ...mapActions([
      'createDiscipline',
      'updateBusinessDisciplines',
    ]),
    createNewTagEntity(newTags) {
      const payload = newTags[0];
      this.createDiscipline(payload);
    },
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        discipline_list_ids: selectedTags.map((selectedTag) => selectedTag.discipline_id),
      };

      this.updateBusinessDisciplines({ id: this.id, payload });
    },
    findTagEntityName(id) {
      const foundItem = this.possibleDisciplineTags.find(item => item.id === id);
      return foundItem ? foundItem.name : "";
    }
  }
};
</script>

<style scoped lang="scss">
.disciplines {
  padding-top: 0.5rem;
  ::v-deep {
    .wrap-speciality-widget {
      .card-content {
        height: 100%;
        max-height: 600px;
      }
    }
  }
}
.main-title {
  margin: 0 0 1rem;
}
</style>
