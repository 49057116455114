var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-profile-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded && _vm.entity
        ? _c(
            "div",
            { staticClass: "contact-profile" },
            [
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c(
                      "winbox-card",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [_vm._v(" Contact Information ")]
                              },
                              proxy: true
                            },
                            {
                              key: "headerIcon",
                              fn: function() {
                                return [
                                  _vm.isEditMode
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "button save-button",
                                          attrs: { disabled: !_vm.isChanged },
                                          on: {
                                            click: function($event) {
                                              return _vm.save(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Save\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isEditMode
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "button cancel-button",
                                          on: {
                                            click: function($event) {
                                              return _vm.cancel(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Cancel\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isEditMode
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "button edit-button",
                                          on: {
                                            click: function($event) {
                                              return _vm.edit(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Edit\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1188233650
                        )
                      },
                      [
                        _vm._v(" "),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm.backendErrors
                            ? _c("p", { staticClass: "error-block" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.backendErrors) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "columns is-gapless" }, [
                            _c("div", { staticClass: "column is-narrow" }, [
                              _c("div", { staticClass: "avatar-wrap" }, [
                                _c("div", { staticClass: "avatar-img-box" }, [
                                  _c("img", {
                                    staticClass: "avatar-img",
                                    attrs: {
                                      width: "80",
                                      height: "80",
                                      src: _vm.logo_url
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "button add-avatar-action",
                                    on: { click: _vm.openAvatarModal }
                                  },
                                  [
                                    _c("winbox-icon", {
                                      attrs: {
                                        icon: "pen",
                                        color: _vm.Colors.system.primaryLight
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "div",
                                { staticClass: "columns is-variable is-1" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "column is-3" },
                                    [
                                      _c("winbox-input", {
                                        class: _vm.status(_vm.$v.entity.fname),
                                        attrs: {
                                          label: "First Name",
                                          disabled: !_vm.isEditMode
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.addToDataUpdate(
                                              "fname",
                                              _vm.entity.fname
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.$v.entity.fname.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.entity.fname,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "$v.entity.fname.$model"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "column is-3" },
                                    [
                                      _c("winbox-input", {
                                        class: _vm.status(_vm.$v.entity.lname),
                                        attrs: {
                                          label: "Last Name",
                                          disabled: !_vm.isEditMode
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.addToDataUpdate(
                                              "lname",
                                              _vm.entity.lname
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.$v.entity.lname.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.entity.lname,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "$v.entity.lname.$model"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "column is-3" },
                                    [
                                      _c("winbox-input", {
                                        staticClass: "active-icon-bg",
                                        class: _vm.status(_vm.$v.entity.phone),
                                        attrs: {
                                          label: "Direct",
                                          "icon-right": "phone-alt",
                                          mask: "(###) ###-####",
                                          disabled: !_vm.isEditMode
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.addToDataUpdate(
                                              "phone",
                                              _vm.entity.phone
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.$v.entity.phone.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.entity.phone,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "$v.entity.phone.$model"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "column" },
                                    [
                                      _c("winbox-input", {
                                        attrs: {
                                          label: "Extension",
                                          mask: "XXXXXXXXXX",
                                          disabled: !_vm.isEditMode
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.addToDataUpdate(
                                              "phone_ext",
                                              _vm.normalizedPhoneExt
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.entity.phone_ext,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.entity,
                                              "phone_ext",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "entity.phone_ext"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "columns is-variable is-1" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "column is-6" },
                                    [
                                      _c("winbox-input", {
                                        class: _vm.status(_vm.$v.entity.title),
                                        attrs: {
                                          label: "Title",
                                          disabled: !_vm.isEditMode
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.addToDataUpdate(
                                              "title",
                                              _vm.entity.title
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.$v.entity.title.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.entity.title,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "$v.entity.title.$model"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "column is-6" },
                                    [
                                      _c("winbox-select", {
                                        class: _vm.status(
                                          _vm.$v.entity.title_rank
                                        ),
                                        attrs: {
                                          options: _vm.possibleRanks,
                                          searchable: false,
                                          "form-label": "Title Rank",
                                          disabled: !_vm.isEditMode,
                                          clearable: _vm.isEditMode
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.addToDataUpdate(
                                              "title_rank",
                                              _vm.entity &&
                                                _vm.entity.title_rank
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.entity.title_rank,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.entity,
                                              "title_rank",
                                              $$v
                                            )
                                          },
                                          expression: "entity.title_rank"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "columns is-variable is-1" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "column is-7" },
                                    [
                                      _c("winbox-remote-select", {
                                        class: _vm.status(
                                          _vm.$v.entity.organization
                                        ),
                                        attrs: {
                                          value: _vm.entity.organization,
                                          "form-label": "Organization",
                                          url: _vm.getOrganizationSearchUrl(),
                                          responseFunction:
                                            _vm.responseOrganization,
                                          minSearchLength: 3,
                                          disabled: !_vm.isEditMode
                                        },
                                        on: { input: _vm.onChangedOrg }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "column is-5" },
                                    [
                                      _c("winbox-select", {
                                        class: _vm.status(
                                          _vm.$v.entity.location
                                        ),
                                        attrs: {
                                          options: _vm.possibleLocations,
                                          "form-label": "Location",
                                          emptyText: _vm.emptyLocationText,
                                          disabled: !_vm.isEditMode
                                        },
                                        on: { input: _vm.onChangedLocation },
                                        model: {
                                          value: _vm.entity.location,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.entity,
                                              "location",
                                              $$v
                                            )
                                          },
                                          expression: "entity.location"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "columns is-variable is-1" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "column is-7 pb-0" },
                                    [
                                      _vm.isEditMode
                                        ? _c("winbox-input", {
                                            staticClass: "active-icon-bg",
                                            class: _vm.status(
                                              _vm.$v.entity.email
                                            ),
                                            attrs: {
                                              label: "Email",
                                              "icon-right": "envelope",
                                              "icon-right-namespace": "far"
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.addToDataUpdate(
                                                  "email",
                                                  _vm.entity.email
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.$v.entity.email.$model,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.$v.entity.email,
                                                  "$model",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "$v.entity.email.$model"
                                            }
                                          })
                                        : _c(
                                            "fieldset",
                                            { staticClass: "border" },
                                            [
                                              _c(
                                                "legend",
                                                {
                                                  staticClass:
                                                    "float-none w-auto"
                                                },
                                                [_vm._v("Email")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "email-container"
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "email-link",
                                                      attrs: {
                                                        href: _vm.constructEmailLink(
                                                          _vm.entity.email
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.entity.email
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon-container"
                                                    },
                                                    [
                                                      _c("winbox-icon", {
                                                        staticClass: "icon-box",
                                                        attrs: {
                                                          icon: "envelope",
                                                          namespace: "far",
                                                          "container-class":
                                                            "is-small is-right",
                                                          color: "dbdbdb"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "column is-5" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "columns is-variable is-1 is-vcentered"
                                      },
                                      [
                                        _c("div", { staticClass: "column" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button action-button",
                                              class: {
                                                "is-primary":
                                                  _vm.entity.is_provided
                                              },
                                              attrs: {
                                                disabled: !_vm.isEditMode
                                              },
                                              on: {
                                                click: () => {
                                                  _vm.entity.is_provided = !_vm
                                                    .entity.is_provided
                                                  _vm.addToDataUpdate(
                                                    "is_provided",
                                                    _vm.entity.is_provided
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.entity.is_provided,
                                                    expression:
                                                      "entity.is_provided"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  disabled: !_vm.isEditMode
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.entity.is_provided
                                                  )
                                                    ? _vm._i(
                                                        _vm.entity.is_provided,
                                                        null
                                                      ) > -1
                                                    : _vm.entity.is_provided
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.entity.is_provided,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.entity,
                                                            "is_provided",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.entity,
                                                            "is_provided",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.entity,
                                                        "is_provided",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Provided")])
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "column" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button action-button",
                                              class: {
                                                "is-primary":
                                                  _vm.entity.is_verified
                                              },
                                              attrs: {
                                                disabled: !_vm.isEditMode
                                              },
                                              on: {
                                                click: () => {
                                                  _vm.entity.is_verified = !_vm
                                                    .entity.is_verified
                                                  _vm.addToDataUpdate(
                                                    "is_verified",
                                                    _vm.entity.is_verified
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.entity.is_verified,
                                                    expression:
                                                      "entity.is_verified"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  disabled: !_vm.isEditMode
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.entity.is_verified
                                                  )
                                                    ? _vm._i(
                                                        _vm.entity.is_verified,
                                                        null
                                                      ) > -1
                                                    : _vm.entity.is_verified
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.entity.is_verified,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.entity,
                                                            "is_verified",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.entity,
                                                            "is_verified",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.entity,
                                                        "is_verified",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("Verified")])
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "contact-profile-tabs" },
                [
                  _vm.routeMatchWithTabs
                    ? _c(
                        "winbox-tabs",
                        { attrs: { theme: "horizontal-theme" } },
                        _vm._l(_vm.profileTabs, function(item, key) {
                          return _c(
                            "winbox-tab-item",
                            { key: key, attrs: { link: item.link } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.title) +
                                  "\n        "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns profile-tab-content" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("router-view", {
                          ref: "tabContent",
                          on: { mounted: _vm.onTabContentMount }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "winbox-modal",
                {
                  ref: _vm.uploadAvatar,
                  attrs: {
                    name: _vm.uploadAvatar,
                    "enable-footer-buttons": true,
                    title: "Update Logo",
                    "save-text": "Update"
                  },
                  on: {
                    "on-cancel": _vm.onCancelLogo,
                    "on-save": _vm.onSaveLogo
                  }
                },
                [
                  _c("winbox-input", {
                    attrs: { label: "URL" },
                    model: {
                      value: _vm.newLogoUrl,
                      callback: function($$v) {
                        _vm.newLogoUrl = $$v
                      },
                      expression: "newLogoUrl"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }