import { WinboxRoutePaths } from "./WinboxRoutePaths";
import { MainIcons } from "./MainIcons";

export const MainWinboxMenuItems = [
  {
    name: "Dashboard",
    icon: MainIcons.dashboard,
    path: WinboxRoutePaths.FRONT_DASHBOARD
  },
  {
    name: "Companies",
    icon: MainIcons.companies,
    path: WinboxRoutePaths.COMPANIES
  },
  { name: "Brands", icon: MainIcons.brands, path: WinboxRoutePaths.BRANDS },
  {
    name: "Agencies",
    icon: MainIcons.agencies,
    path: WinboxRoutePaths.AGENCIES
  },
  {
    name: "Contacts",
    icon: MainIcons.contacts,
    path: WinboxRoutePaths.CONTACTS
  },
  {
    name: "Data Import",
    icon: MainIcons.dataImport,
    path: WinboxRoutePaths.DATA_IMPORT
  },
  {
    name: "Admin Console",
    icon: MainIcons.settings,
    path: `${WinboxRoutePaths.SETTINGS}`
  },
  { name: "Logout", icon: MainIcons.logout, path: WinboxRoutePaths.LOGOUT }
];
