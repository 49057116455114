var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "agency-detail-page" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded && _vm.entity
        ? _c("page-generic-entry", {
            attrs: {
              "has-assign": true,
              entity: _vm.entity,
              "menu-items": _vm.menuItems,
              "menu-sub-items": _vm.subItems,
              "has-proofing": true
            },
            on: {
              "submit-proof": _vm.submitProof,
              "on-assign": _vm.onAssignModal
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "entityName",
                  fn: function() {
                    return [
                      _c("winbox-title", { attrs: { title: _vm.entity.name } })
                    ]
                  },
                  proxy: true
                },
                _vm.entity.website
                  ? {
                      key: "baseInfo",
                      fn: function() {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `http://${_vm.entity.website}`,
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    decodeURIComponent(_vm.entity.website)
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "entityLogo",
                  fn: function() {
                    return [
                      _c("winbox-logo-input", {
                        attrs: { value: _vm.logo_url },
                        on: { input: _vm.onLogoChange }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.assignModal,
          attrs: {
            name: _vm.assignModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Assign Agency Task",
            "save-text": "Assign"
          },
          on: { "on-cancel": _vm.onAssignCancel, "on-save": _vm.onAssignSave }
        },
        [
          _vm.isAssignNewEntityTasksLoaded
            ? _c("winbox-assign-entity-tasks", { ref: "assignEntityTasksRef" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAssignNewEntityTasksLoaded
            ? _c("winbox-large-indicator")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }