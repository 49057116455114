var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-data-table", class: [_vm.theme] },
    [
      _vm.enableCustomFiltering
        ? _c("winbox-data-table-search", {
            attrs: { data: _vm.datasetBackup },
            on: { search: _vm.onSearch }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-client-table",
        {
          ref: "vTableRef",
          attrs: {
            columns: _vm.columns,
            data: _vm.dataset,
            options: _vm.tableOptions
          },
          on: {
            "row-click": function($event) {
              return _vm.$emit("row-click", $event)
            },
            filter: _vm.onFilter
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.slots, function(slot, key) {
                return {
                  key: slot,
                  fn: function(props) {
                    return [
                      slot === _vm.idKey
                        ? _c("div", { key: key }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.markedRows,
                                  expression: "markedRows"
                                }
                              ],
                              attrs: { type: "checkbox", id: "checkbox" },
                              domProps: {
                                value: props.row[_vm.idKey],
                                checked: Array.isArray(_vm.markedRows)
                                  ? _vm._i(
                                      _vm.markedRows,
                                      props.row[_vm.idKey]
                                    ) > -1
                                  : _vm.markedRows
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.markedRows,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = props.row[_vm.idKey],
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.markedRows = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.markedRows = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.markedRows = $$c
                                    }
                                  },
                                  _vm.toggleCheckbox
                                ]
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      slot !== _vm.idKey
                        ? _vm._t(slot, null, { row: props.row })
                        : _vm._e()
                    ]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        [
          _vm.hasIdSlot
            ? _c("template", { slot: _vm.headerIdSlotName }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.allMarked,
                      expression: "allMarked"
                    }
                  ],
                  staticClass: "checkbox check-all-header",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.allMarked)
                      ? _vm._i(_vm.allMarked, null) > -1
                      : _vm.allMarked
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.allMarked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.allMarked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.allMarked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.allMarked = $$c
                        }
                      },
                      _vm.toggleAllCheckboxes
                    ]
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.$slots, function(_, name) {
            return _vm._t(name, null, { slot: name })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }