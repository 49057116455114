<template src="../Generic/EntityNotesMixin.html" />

<script>
import { mapActions } from "vuex";

import { EntityNotesMixin } from "@/components/Pages/Generic/EntityNotesMixin";
import { NoteTypes } from "@/constants/NoteTypes";

export default {
  name: "BrandInternalNotes",
  mixins: [EntityNotesMixin],
  data() {
    return {
      noteType: NoteTypes.internal,
      isLoaded: true,
    };
  },
  computed: {
    mainTitle() {
      return `Internal Notes (${this.notesData.length})`;
    },
    /* @Override */
    notesData() {
      return this.$store.getters
        .BRAND_NOTES_BY_ID(this.id)
        .filter(item => item.note_type === this.noteType)
        .map(item => this.extendNoteData(item));
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getBrandNotes',
    ]),
   async getData() {
      this.isLoaded = false;

      await this.getBrandNotes(this.id);

      this.isLoaded = true;
    },
    /* @Override */
    createNewNote(data) {
      const payload = this.getCreateNotePayload(data);

      return this.$store.dispatch("createBrandEntityNote", {
        id: this.id,
        payload: payload
      });
    },
    /* @Override */
    updateNoteEntity(data) {
      const payload = this.getUpdateNotePayload(data);

      return this.$store.dispatch("updateBrandEntityNote", {
        id: this.id,
        noteId: data.id,
        payload: payload
      });
    },
    /* @Override */
    removeEntity(item) {
      let shouldBeConfirmed = item.id ? confirm("Are you sure?") : true;
      if (shouldBeConfirmed) {
        this.$store.dispatch("removeBrandEntityNote", {
          id: this.id,
          noteId: item.id
        });
      }
    }
  }
};
</script>

<style scoped lang="scss" src="../Generic/EntityNotesMixin.scss" />
