var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-data-table-filter-modal" }, [
    _c("div", { staticClass: "columns is-vcentered" }, [
      _c("div", { staticClass: "column" }, [
        _c(
          "button",
          { staticClass: "button clear-btn", on: { click: _vm.reset } },
          [_vm._v("Clear")]
        )
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "column" }, [
        _c(
          "button",
          {
            staticClass: "is-primary button done-btn",
            on: { click: _vm.doFiltering }
          },
          [_vm._v("\n        Done\n      ")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.filterItems, function(item, key) {
        return _c("li", { key: key })
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "add-new-block" },
      [
        _c("winbox-icon", {
          staticClass: "add-entity-icon",
          attrs: { color: _vm.Colors.system.lightGray, icon: "plus" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column filter-label" }, [
      _c("strong", [_vm._v("Filters")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }