var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-template-file" }, [
    _c("div", { staticClass: "file is-primary" }, [
      _c("label", { staticClass: "file-label", class: _vm.disabledStyleObj }, [
        _c(
          "span",
          {
            staticClass: "file-cta",
            class: _vm.disabledStyleObj,
            on: { click: _vm.triggerDownloadTemplate }
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("span", { staticClass: "file-label" }, [
              _vm._v("\n          Download Template\n        ")
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "file-icon" }, [
      _c("i", { staticClass: "fas fa-download" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }