<template>
  <div class="wrap-demographics-block">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="demographics">
      <div class="dropdown-row">
        <div
          v-for="(entity, key) in entities"
          :key="key"
          class="dropdown-item"
        >
          <winbox-select
            v-if="entity.label !== 'Profile Description'"
            :form-label="entity.label"
            v-model.trim="entity.value"
            :options="entity.options"
            label="label"
            :disabled="!isEditMode"
            :multiple="true"
            @input="onDemographicChanged(entity)"
            />
        </div>
      </div>
      <winbox-textarea
        v-model.trim="entities.profile_description.value"
        form-label="Profile Description"
        :disabled="!isEditMode"
        @input="onDemographicChanged(entities.profile_description)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxTextarea from "@/components/Atoms/WinboxTextarea";

import { DemographicAllowedValues } from "@/constants/BrandDemographicOptions";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";

export default {
  name: "WinboxDemographics",
  mixins: [],
  components: {
    WinboxSelect,
    WinboxTextarea,
  },
  props: {
    items: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      Colors: Colors,
      isLoaded: true,
      entities: {},
      originalEntities: {},
    };
  },
  computed: {
    ...mapState([
      'brandDemographics',
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return "Demographics";
    },
    possibleValues() {
      return {
        brand_focus: DemographicAllowedValues.brand_focus,
        gender: DemographicAllowedValues.gender,
        region: DemographicAllowedValues.region,
        region_type: DemographicAllowedValues.region_type,
        age: DemographicAllowedValues.age,
        generation: DemographicAllowedValues.generation,
        ethnicity: DemographicAllowedValues.ethnicity,
        household_income: DemographicAllowedValues.household_income,
        marital_status: DemographicAllowedValues.marital_status,
        life_stage: DemographicAllowedValues.life_stage
      };
    }
  },
  watch: {
    isCanceling(newValue) {
      if (newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue) {
      if (newValue) {
        this.editAccept();
      }
    },
  },
  methods: {
    ...mapActions([
      'change'
    ]),
    prepareEntities(items) {
      const allowedValues = this.possibleValues;
      const preparedEntities = {};

      for (const key in allowedValues) {
        if (allowedValues.hasOwnProperty(key)) {
          preparedEntities[key] = {
            label: key.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '),
            options: allowedValues[key],
            value: items[key] ? items[key] : []
          };
        }
      }
      preparedEntities.profile_description = {
        label: 'Profile Description',
        value: items.profile_description || ''
      };
      this.originalEntities = cloneDeep(preparedEntities);

      return preparedEntities;
    },
    onDemographicChanged() {
      this.change(true);
      this.$emit('changed', this.entities);
    },
    prepareDemographicsPayload() {
      const payload = {
        id: this.items.id,
        brand_id: this.id,
      };

      for (const key in this.entities) {
        if (this.entities.hasOwnProperty(key)) {
          payload[key] = this.entities[key].value;
        }
      }

      return payload;
    },
    editCancel() {
      this.entities = cloneDeep(this.originalEntities);
    },
    editAccept() {
      this.$emit("changed", this.entities);
    },
  },
  created() {
    this.entities = this.prepareEntities(this.items);
  },
};
</script>

<style scoped lang="scss">
.dropdown-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.dropdown-item {
  flex: 1 0 calc(33.33% - 1rem);
  margin-right: 1rem;
}

.dropdown-item:nth-child(3n) {
  margin-right: 0;
}

.winbox-textarea {
  margin-top: 1rem;
}
</style>
