var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brand-contacts-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "brand-contacts" },
            [
              _c("winbox-company-header", {
                attrs: {
                  "entity-secondary-label": "Link Contacts",
                  "entity-secondary-icon": "link",
                  title: _vm.mainTitle,
                  "enable-status-toggle": true
                },
                on: {
                  "toggle-status": _vm.updateVisibleStatus,
                  changedSecondary: _vm.openLinkModal
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-data-table", {
                      staticClass: "main-data-table",
                      attrs: {
                        data: _vm.visibleData,
                        columns: _vm.columns,
                        options: _vm.tableOptions,
                        "enable-custom-filtering": false,
                        slots: _vm.specificSlots
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: _vm.tableProps.name,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: _vm.constructContactProfileUrl(
                                          data.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.row.name) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.email,
                            fn: function(data) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.constructEmailLink(
                                        data.row[_vm.tableProps.email]
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.row[_vm.tableProps.email]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.options,
                            fn: function(data) {
                              return [
                                data.row.id != -1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "button button-remove",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteRelationship(
                                              data.row
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("winbox-icon", {
                                          attrs: {
                                            icon: "trash",
                                            color: _vm.Colors.system.error
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.linkModal,
          staticClass: "winbox-modal-scrollable",
          attrs: {
            name: _vm.linkModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            width: "1000px",
            height: "600px",
            title: "Link Contacts",
            "save-text": _vm.saveModalText
          },
          on: {
            "on-cancel": _vm.onCancelLinkModal,
            "on-save": _vm.onSaveLinkModal,
            "enter-press": _vm.onSaveLinkModal,
            "before-close": _vm.resetLinkNextStep
          }
        },
        [
          _vm.brandBusinessesContacts
            ? _c("winbox-add-brand-contact", {
                ref: "addBrandContact",
                attrs: {
                  data: _vm.datasetRelationshipsMain,
                  config: _vm.linkConfig
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }