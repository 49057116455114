<template>
  <div class="brand-detail-page">
    <winbox-large-indicator v-if="!isLoaded" />
    <page-generic-entry
      v-if="isLoaded && entity"
      :has-assign="true"
      :entity="entity"
      :menu-items="menuItems"
      :menu-sub-items="subItems"
      :has-proofing="true"
      @submit-proof="submitProof"
      @on-assign="onAssignModal"
    >
      <template v-slot:entityName>
        <winbox-title :title="entity.name" />
      </template>
      <template v-slot:parentLink>
        <span :key="entity.id">
          <winbox-icon icon="building" :color="Colors.system.primaryLight" />
          <router-link :to="{ path: constructCompanyLink(entity.business.id) }">
            {{ entity.business.name }}
          </router-link>
        </span>
      </template>
      <template v-if="entity.website" v-slot:baseInfo>
        <a :href="`http://${entity.website}`" target="_blank">
          {{ decodeURIComponent(entity.website) }}
        </a>
      </template>
      <template v-slot:entityLogo>
        <winbox-logo-input :value="logo_url" @input="onLogoChange" />
      </template>
    </page-generic-entry>
    <winbox-modal
      :ref="assignModal"
      :name="assignModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Assign Brand Task"
      save-text="Assign"
      @on-cancel="onAssignCancel"
      @on-save="onAssignSave"
    >
      <winbox-assign-entity-tasks
        ref="assignEntityTasksRef"
        v-if="isAssignNewEntityTasksLoaded"
      />
      <winbox-large-indicator v-if="!isAssignNewEntityTasksLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxLogoInput from "@/components/Molecules/WinboxLogoInput";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxModal from "@/components/Molecules/WinboxModal";

import PageGenericEntry from "@/components/Pages/PageGenericEntry";

import WinboxAssignEntityTasks from "@/components/Organisms/WinboxAssignEntityTasks";
import { WinboxAssignEntityTasksHelperMixin } from "@/components/Organisms/WinboxAssignEntityTasksHelperMixin";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import * as Utils from "@/utils";
import { AnyEntityEntryMixin } from "@/components/Pages/Generic/AnyEntityEntryMixin";
import { SaveAnyEntitySupportMixin } from "@/components/Pages/Generic/SaveAnyEntitySupportMixin";
import { NoteTypes } from "@/constants/NoteTypes";
import { MutationTypes } from "@/constants/MutationTypes";
import { Colors } from "@/Colors";

import * as BrandsApi from "@/api/brands/brands";

export default {
  name: "BrandEntry",
  mixins: [
    AnyEntityEntryMixin,
    SaveAnyEntitySupportMixin,
    WinboxAssignEntityTasksHelperMixin,
  ],
  components: {
    WinboxIcon,
    WinboxTitle,
    WinboxModal,
    WinboxLargeIndicator,
    WinboxLogoInput,
    PageGenericEntry,
    WinboxAssignEntityTasks,
  },
  data() {
    return {
      isLoaded: false,
      Colors: Colors,
    };
  },
  computed: {
    ...mapGetters([
      'BRAND_TAGS_BY_ID',
      'BRAND_NOTES_BY_ID',
      'BRAND_INDUSTRIES_BY_ID',
    ]),
    ...mapState([
      'logo_url',
      'currentBrand',
      'brandBusinesses',
      'entityCounts',
    ]),
    entity() {
      return this.currentBrand;
    },
    menuItems() {
      return [
        {
          link: this.profileUrl,
          title: "Profile"
        },
        {
          link: this.contactsUrl,
          title: "Brand Contacts",
          count: this.brandContactsCount,
        },
        {
          link: this.agencyContactsUrl,
          title: "Agency Contacts",
          count: this.brandAgenciesContactsCount,
        },
        {
          link: this.agenciesUrl,
          title: "Agencies",
          count: this.brandAgenciesCount,
        },
        {
          link: this.sponsorshipsUrl,
          title: "Sponsorships",
          count: this.brandSponsorshipsCount,
        },
        {
          link: this.hierarchyUrl,
          title: "Hierarchy",
          count: this.hierarchiesCount,
        },
        {
          link: this.notesUrl,
          title: "Notes",
          count: this.notesCount
        },
      ];
    },
    subItems() {
      return [
        {
          link: this.tagsUrl,
          title: "Tags",
          count: this.tagsCount
        },
        {
          link: this.internalNotesUrl,
          title: "Internal Notes",
          count: this.internalNotesCount,
        },
        {
          link: this.auditLogUrl,
          title: "Audit Log",
        },
      ];
    },
    baseUrl() {
      return `${WinboxRoutePaths.BRANDS}/${this.id}`;
    },
    profileUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_PROFILE}`;
    },
    contactsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_CONTACTS}`;
    },
    agencyContactsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_AGENCY_CONTACTS}`;
    },
    industriesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_INDUSTRIES}`;
    },
    agenciesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_AGENCIES}`;
    },
    sponsorshipsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_SPONSORSHIPS}`;
    },
    hierarchyUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_HIERARCHY}`;
    },
    notesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_NOTES}`;
    },
    tagsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_TAGS}`;
    },
    internalNotesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_INTERNAL_NOTES}`;
    },
    auditLogUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.BRAND_AUDIT_LOG}`;
    },
    brandContactsCount() {
      return this.entityCounts.contacts_count;
    },
    brandAgenciesCount() {
      return this.entityCounts.entity_agency_count;
    },
    brandSponsorshipsCount() {
      return this.entityCounts.entity_sponsorship_count;
    },
    brandAgenciesContactsCount() {
      return this.entityCounts.entity_agency_contacts_count;
    },
    hierarchiesCount() {
      return this.entityCounts.hierarchy_total_count;
    },
    tagsCount() {
      return this.entityCounts.tags_count;
    },
    notesCount() {
      return this.entityCounts.note_count;
    },
    internalNotesCount() {
      return this.entityCounts.internal_note_count;
    },
    markedRows() {
      return [this.id];
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getLogo',
      'getBrand',
      'getServices',
      'updateBrand',
      'getMediaTypes',
      'getBrandBusiness',
      'getBrandCounts',
      'updateLogo',
    ]),
    async getData() {
      this.isLoaded = false;

      this.getLogo({ owner_id: this.id, owner_type: 'Brand' });
      this.getServices();
      this.getMediaTypes();
      this.getBrandBusiness(this.id);
      this.getBrandCounts(this.id);
      await this.getBrand(this.id);

      this.isLoaded = true;
    },
    constructCompanyLink(companyId) {
      return Utils.getCompanyProfileUrl(companyId);
    },
    onLogoChange(logo_url) {
      this.updateLogo({
        logo_url: logo_url,
        owner_type: 'Brand',
        owner_id: this.id,
      });
    },
    saveEntity() {
      if (!Object.keys(this.dataUpdate).length) {
        return;
      }
      const payload = { ...this.dataUpdate };
      this.updateBrand({ id: this.id, payload });
      this.afterSaveEntity();
    },
    submitProof() {
      BrandsApi.submitBrandsProof(this.id)
      .then(({ data: { proofed_by_name, proofed_at }}) => {
        this.$set(this.entity, 'proofed_by_name', proofed_by_name);
        this.$set(this.entity, 'proofed_at', proofed_at);
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

::v-deep {
  .highlight {
    color: $winbox-primary-active-bg-color;
  }
}
</style>
