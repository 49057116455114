import { EntitiesUrl } from "@/constants/EntitiesUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse,
} from "@/api/_generic/_generic";

export function getLogo(owner_id, owner_type) {
  const url = `${EntitiesUrl.base}/${owner_type}/${EntitiesUrl.logo}/${owner_id}`;
  return getGenericFullResponse(url);
}

export function updateLogo(logo_url, owner_type, owner_id) {
  const url = `${EntitiesUrl.base}/${owner_type}/${EntitiesUrl.logo}/${owner_id}`;
  const payload = { logo_url };
  return postGenericFullResponse(url, payload);
}
