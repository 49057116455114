<template>
  <div class="admin-tasks">
    <winbox-card>
      <template v-slot:header>
        Reassign Tasks
      </template>
      <div class="content">
        <div class="columns">
          <div class="column">
            <winbox-input
              label="Number of Tasks"
              v-model.number="numberOfTasks"
              type="number"
              :min="1"
              required
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <winbox-select
              form-label="From"
              v-model="fromUser"
              :options="userOptions"
              required
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <winbox-select
              form-label="To"
              v-model="toUser"
              :options="userOptions"
              required
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <button
              class="button is-primary"
              :disabled="!canSubmit"
              @click="reassignTasks"
            >
              {{ submitButtonText }}
            </button>
          </div>
        </div>
      </div>
      <div class="response-box success" v-if="successMessage">
      {{ successMessage }}
    </div>
    <div class="response-box error" v-if="infoMessage">
      {{ infoMessage }}
    </div>
    </winbox-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import * as UserTasksApi from "@/api/user-tasks/user-tasks";

export default {
  name: "AdminTasks",
  components: {
    WinboxCard,
    WinboxInput,
    WinboxSelect
  },
  data() {
    return {
      numberOfTasks: null,
      fromUser: null,
      toUser: null,
      userOptions: [],
      successMessage: '',
      infoMessage: ''
    };
  },
  computed: {
    users() {
      return this.$store.getters.USERS;
    },
    canSubmit() {
      return this.numberOfTasks > 0 && this.fromUser && this.toUser && this.fromUser !== this.toUser;
    },

    submitButtonText() {
      if (!this.canSubmit) {
        return 'Reassign';
      }
      return `Reassign ${this.numberOfTasks} from ${this.fromUser.label} to ${this.toUser.label}`;
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch("getUsers");
      this.setUserOptions();
    },

    setUserOptions() {
      if (this.users && this.users.length) {
        this.userOptions = this.users.map(user => ({
          id: user.id,
          value: user.id,
          label: `${user.first_name} ${user.last_name}`
        }));
      } else {
        this.userOptions = [];
      }
    },

    async reassignTasks() {
      if (!this.canSubmit) return;

      this.successMessage = null;
      this.infoMessage = null;

      try {
        const response = await UserTasksApi.reassignTasks({
          number_of_tasks: this.numberOfTasks,
          from_user: this.fromUser.value,
          to_user: this.toUser.value
        });

        if (response.success) {
          this.successMessage = response.message;
          this.numberOfTasks = null;
          this.fromUser = null;
          this.toUser = null;
        } else {
          this.infoMessage = `There was a problem: ${response.message}`;
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.infoMessage = error.response.data.message || 'There was a problem with your request.';
        } else {
          console.error("Error reassigning tasks:", error);
          this.infoMessage = 'An error occurred while reassigning tasks.';
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

.response-box {
  margin: 1rem 0 .3rem;

  &.success {
    color: $winbox-success;
  }
  &.error {
    color: $winbox-error;
  }
}
</style>
