export default {
  PROFILE_CONTACTS: state => {
    return state.profileContacts;
  },

  PERSON_SOCIALS_BY_ID(state) {
    return id => state.personSocials[id] || [];
  },

  PERSON_TAGS_BY_ID(state) {
    return id => state.personTags[id] || [];
  },

  PERSON_NOTES_BY_ID(state) {
    return id => state.personNotes[id] || [];
  },

  PERSON_SPECIALITIES_BY_ID(state) {
    return id => state.personSpecialities[id] || [];
  },

  PERSON_LOGS_BY_ID(state) {
    return id => state.personLogs[id] || [];
  },

  PERSON_TITLE_FUNCTIONS_BY_ID(state) {
    return id => state.personTitleFunctions[id] || [];
  },

  PERSON_WORK_HISTORIES_BY_ID(state) {
    return id => state.personWorkHistories[id] || [];
  },

  PERSON_ORGANIZATION_BY_ID(state) {
    return id => state.personOrganization[id] || [];
  },

  PERSON_LOCATION_BY_ID(state) {
    return id => state.personLocation[id] || [];
  },
};
