var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-add-relationships" }, [
    !_vm.showNextStep
      ? _c(
          "div",
          { staticClass: "step-one" },
          [
            _c("winbox-server-data-table", {
              ref: "winboxDataTable",
              staticClass: "winbox-add-relationships-table",
              attrs: {
                url: _vm.url,
                columns: _vm.columns,
                options: _vm.tableOptions,
                slots: _vm.specificSlots,
                perPage: 10,
                responseFunction: _vm.enhanceData,
                theme: "with-borders-theme"
              },
              on: { "row-click": _vm.onRowClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: _vm.tableProps.linked,
                    fn: function(data) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "button select-button",
                            class: { active: data.row[_vm.tableProps.linked] }
                          },
                          [
                            _c("winbox-icon", {
                              attrs: {
                                icon: "plus",
                                color: data.row[_vm.tableProps.linked]
                                  ? _vm.Colors.system.lightGray
                                  : _vm.Colors.system.primaryLight
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showNextStep && _vm.entity
      ? _c(
          "div",
          { staticClass: "step-two" },
          [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("winbox-input", {
                    class: _vm.status(_vm.$v.entity.name),
                    attrs: { placeholder: "Name", readonly: true },
                    model: {
                      value: _vm.$v.entity.name.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.entity.name,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.entity.name.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("winbox-relation-ship-items", {
              attrs: { value: _vm.$v.entity },
              on: { input: _vm.onChangeEntityFields }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column is-6" },
                [
                  _c("winbox-select", {
                    class: _vm.status(_vm.$v.entity.status),
                    attrs: {
                      value: _vm.entity.status,
                      options: _vm.possibleStatuses,
                      searchable: false,
                      placeholder: "Status"
                    },
                    on: {
                      input: function($event) {
                        _vm.$v.entity.status.$model = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-6" },
                [
                  _vm.possibleLocations.length >= 1
                    ? _c("winbox-select", {
                        class: _vm.status(_vm.$v.entity.location),
                        attrs: {
                          placeholder: "Location",
                          label: "name",
                          options: _vm.possibleLocations,
                          disabled: _vm.possibleLocations.length == 1,
                          emptyText: _vm.emptyText
                        },
                        model: {
                          value: _vm.entity.location,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.entity,
                              "location",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "entity.location"
                        }
                      })
                    : _c("winbox-input", {
                        attrs: {
                          placeholder: "Location",
                          readonly: true,
                          value: _vm.emptyText
                        }
                      })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "button action-button",
                    class: { "is-primary": _vm.entity.is_aor },
                    on: { click: _vm.toggleAOR }
                  },
                  [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.entity.is_aor }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("AOR")])
                  ]
                )
              ])
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }