import { MutationTypes } from "@/constants/MutationTypes";

export default {
  /* Brands */
  [MutationTypes.SET_BRANDS]: (state, payload) => {
    state.brands = payload;
  },

  [MutationTypes.ADD_UPDATE_BRAND]: (state, payload) => {
    state.currentBrand = payload;
  },

  [MutationTypes.SET_BRAND_TAGS]: (state, payload) => {
    state.brandTags = {
      ...state.brandTags,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_BRAND_SOCIALS]: (state, payload) => {
    state.brandSocials = payload;
  },

  [MutationTypes.SET_BRAND_NICKNAMES]: (state, payload) => {
    state.brandNicknames = payload;
  },

  [MutationTypes.SET_BRAND_INDUSTRIES]: (state, payload) => {
    state.brandIndustries = payload;
  },

  [MutationTypes.SET_BRAND_DEMOGRAPHICS]: (state, payload) => {
    state.brandDemographics = payload;
  },

  [MutationTypes.SET_BRAND_CONTACTS]: (state, payload) => {
    state.brandContacts = payload;
  },

  [MutationTypes.REMOVE_BRAND_CONTACTS]: (state, brandContactId) => {
    state.brandContacts = [...state.brandContacts.filter(
      (brandContact) => brandContact.id != brandContactId)];
  },

  [MutationTypes.SET_BRAND_NOTES]: (state, payload) => {
    state.brandNotes = {
      ...state.brandNotes,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_BRAND_LOGS]: (state, payload) => {
    state.brandLogs = {
      ...state.brandLogs,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_BRAND_SPECIALITIES]: (state, payload) => {
    state.brandSpecialities = payload;
  },

  [MutationTypes.SET_BRAND_SPONSORSHIPS]: (state, payload) => {
    state.brandSponsorships = payload;
  },

  [MutationTypes.ADD_BRAND_SPONSORSHIPS]: (state, payload) => {
    state.brandSponsorships = [payload, ...state.brandSponsorships];
  },

  [MutationTypes.UPDATE_BRAND_SPONSORSHIPS]: (state, payload) => {
    state.brandSponsorships = [payload, ...state.brandSponsorships.filter(
      (brandSponsorship) => brandSponsorship.id != payload.id)];
  },

  [MutationTypes.SET_BRAND_BUSINESS_RELATIONSHIPS]: (state, payload) => {
    state.brandBusinessRelationships = payload;
  },

  [MutationTypes.ADD_BRAND_BUSINESS_RELATIONSHIPS]: (state, payload) => {
    state.brandBusinessRelationships = [payload, ...state.brandBusinessRelationships];
  },

  [MutationTypes.UPDATE_BRAND_BUSINESS_RELATIONSHIPS]: (state, payload) => {
    state.brandBusinessRelationships = [payload, ...state.brandBusinessRelationships.filter(
      (brandBusinessRelationship) => brandBusinessRelationship.id != payload.id)];
  },

  [MutationTypes.SET_BRAND_BUSINESSES]: (state, payload) => {
    state.brandBusinesses = payload;
  },

  [MutationTypes.SET_BRAND_BUSINESSES_CONTACTS]: (state, payload) => {
    state.brandBusinessesContacts = payload;
  },

  [MutationTypes.SET_BRAND_AGENCY_CONTACTS]: (state, payload) => {
    state.brandAgencyContacts = payload;
  },

  [MutationTypes.UPDATE_BRAND_AGENCY_CONTACTS]: (state, payload) => {
    state.brandAgencyContacts = [...payload, ...state.brandAgencyContacts];
  },

  [MutationTypes.REMOVE_BRAND_AGENCY_CONTACTS]: (state, brandAgencyContactId) => {
    state.brandAgencyContacts = [...state.brandAgencyContacts.filter(
      (brandAgencyContacts) => brandAgencyContacts.person.id != brandAgencyContactId)];
  },
};
