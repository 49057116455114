import acgencyActions from './agency';
import businessActions from './business';
import brandActions from './brand';
import companyActions from './company';
import personActions from './person';
import helperActions from './helper';
import userActions from './user';
import taskActions from './task';
import userTaskActions from './user-task';
import filterActions from './filter_options';
import dataImport from './data_import';
import appHeader from './app-header';
import entity from './entity';
import report from './report';

const actions = {
  ...acgencyActions,
  ...businessActions,
  ...brandActions,
  ...companyActions,
  ...personActions,
  ...helperActions,
  ...userActions,
  ...taskActions,
  ...userTaskActions,
  ...filterActions,
  ...dataImport,
  ...appHeader,
  ...entity,
  ...report
};

export default actions;
