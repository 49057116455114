var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-hierarchy-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "company-hierarchy" },
            [
              _c("winbox-title", {
                staticClass: "main-title",
                attrs: { title: _vm.mainTitle }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "winbox-hierarchy-wrap" }, [
                _c("div", { staticClass: "columns is-variable is-2" }, [
                  _vm.serviceNameParents
                    ? _c(
                        "div",
                        { staticClass: "column is-4" },
                        [
                          _c("winbox-hierarchy-entity", {
                            attrs: {
                              title: "Parent",
                              "entity-type": "agency",
                              "entity-selected": _vm.parents,
                              "service-name": _vm.serviceNameParents,
                              "prop-id": "parent_id"
                            },
                            on: {
                              changed: _vm.onChanged,
                              removed: _vm.onRemoved
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.serviceNameSiblings
                    ? _c(
                        "div",
                        { staticClass: "column is-4" },
                        [
                          _c("winbox-hierarchy-entity", {
                            attrs: {
                              title: "Siblings",
                              "entity-type": "agency",
                              "entity-selected": _vm.siblings,
                              "service-name": _vm.serviceNameSiblings,
                              "can-add-entity": false
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.serviceNameChildren
                    ? _c(
                        "div",
                        { staticClass: "column is-4" },
                        [
                          _c("winbox-hierarchy-entity", {
                            attrs: {
                              title: "Children",
                              "entity-type": "agency",
                              "entity-selected": _vm.children,
                              "service-name": _vm.serviceNameChildren
                            },
                            on: {
                              changed: _vm.onChanged,
                              removed: _vm.onRemoved
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }