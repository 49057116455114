<template>
  <div class="create-brand">
    <div class="content">
      <winbox-input
        placeholder="Brand Name"
        v-model.trim="$v.entity.name.$model"
        :class="status($v.entity.name)"
        field="brandName"
      />
      <winbox-input
        placeholder="Description"
        v-model.trim="$v.entity.description.$model"
        :class="status($v.entity.description)"
      />
      <winbox-select
        v-model="entity.industry"
        :options="possibleIndustries"
        :class="status($v.entity.industry)"
        :clearable="true"
        :multiple="true"
        placeholder="Industry"
      />
    </div>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import { required, maxLength } from "vuelidate/lib/validators";
import * as Utils from "@/utils";

export default {
  name: "WinboxCreateBrand",
  components: {
    WinboxInput,
    WinboxSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Colors: Colors,
      entity: {},
    };
  },
  validations: {
    entity: {
      name: {
        required,
        maxLength: maxLength(75),
      },
      description: {
        required
      },
      industry: {
        required
      }
    }
  },
  computed: {
    possibleIndustries() {
      return Utils.sortAlphabetic(
        this.$store.getters.INDUSTRIES.map(item => {
          return { label: item.name, value: item.id };
        }),
        "label"
      );
    }
  },
  beforeMount() {
    this.entity = cloneDeep(this.data);
  },
  methods: {
    status(validation) {
      if(validation.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-brand {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
</style>
