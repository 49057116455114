<template>
  <div class="contact-detail-page">
    <winbox-large-indicator v-if="!isLoaded" />
    <page-generic-entry
      v-if="isLoaded && entity"
      :has-assign="true"
      :entity="entity"
      :menu-items="menuItems"
      :menu-sub-items="subItems"
      :has-proofing="true"
      @submit-proof="submitProof"
      @on-assign="onAssignModal"
    >
      <template v-slot:entityName>
        <winbox-title :title="constructName(entity)" />
      </template>
      <template v-if="entity.organization" v-slot:parentLink>
        <router-link
          :to="{ path: constructCompanyLink(entity.organization) }"
        >
          {{ entity.organization.name }}
        </router-link>
      </template>
    </page-generic-entry>
    <winbox-modal
      :ref="assignModal"
      :name="assignModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Assign Contact Task"
      save-text="Assign"
      @on-cancel="onAssignCancel"
      @on-save="onAssignSave"
    >
      <winbox-assign-entity-tasks
        ref="assignEntityTasksRef"
        v-if="isAssignNewEntityTasksLoaded"
      />
      <winbox-large-indicator v-if="!isAssignNewEntityTasksLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxModal from "@/components/Molecules/WinboxModal";

import PageGenericEntry from "@/components/Pages/PageGenericEntry";

import WinboxAssignEntityTasks from "@/components/Organisms/WinboxAssignEntityTasks";
import { WinboxAssignEntityTasksHelperMixin } from "@/components/Organisms/WinboxAssignEntityTasksHelperMixin";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { AnyEntityEntryMixin } from "@/components/Pages/Generic/AnyEntityEntryMixin";
import { SaveAnyEntitySupportMixin } from "@/components/Pages/Generic/SaveAnyEntitySupportMixin";
import { NoteTypes } from "@/constants/NoteTypes";
import { MutationTypes } from "@/constants/MutationTypes";
import * as Utils from "@/utils";

import * as PeopleApi from "@/api/people/people";

export default {
  name: "ContactEntry",
  mixins: [
    AnyEntityEntryMixin,
    SaveAnyEntitySupportMixin,
    WinboxAssignEntityTasksHelperMixin,
  ],
  components: {
    WinboxTitle,
    WinboxModal,
    WinboxLargeIndicator,
    PageGenericEntry,
    WinboxAssignEntityTasks,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters([
      'PERSON_TAGS_BY_ID',
      'PERSON_NOTES_BY_ID',
    ]),
    ...mapState([
      'currentContact',
    ]),
    entity() {
      return this.currentContact;
    },
    menuItems() {
      return [{ link: this.profileTitleFunctionsUrl, title: "Profile" }];
    },
    subItems() {
      return [
        { link: this.tagsUrl, title: "Tags", count: this.tagsCount },
        {
          link: this.internalNotesUrl,
          title: "Internal Notes",
          count: this.internalNotesCount,
        },
        {
          link: this.auditLogUrl,
          title: "Audit Log",
        },
      ];
    },
    baseUrl() {
      return `${WinboxRoutePaths.CONTACTS}/${this.id}`;
    },
    profileTitleFunctionsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE}/${WinboxRoutePaths.CONTACT_PROFILE_TITLE_FUNCTIONS}`;
    },
    tagsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE}/${WinboxRoutePaths.CONTACT_PROFILE_TAGS}`;
    },
    internalNotesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE}/${WinboxRoutePaths.CONTACT_PROFILE_INTERNAL_NOTES}`;
    },
    auditLogUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE}/${WinboxRoutePaths.CONTACT_PROFILE_AUDIT_LOG}`;
    },
    /* @Override */
    tagsCount() {
      let result = null;
      const tags = this.PERSON_TAGS_BY_ID(this.id);

      if (tags && tags.length) {
        result = tags.length;
      }
      return result;
    },
    /* @Override */
    internalNotesCount() {
      let result = null;
      const notes = this.PERSON_NOTES_BY_ID(this.id)
        .filter((item) => item.note_type === NoteTypes.internal);

      if (notes && notes.length) {
        result = notes.length;
      }
      return result;
    },
    markedRows() {
      return [this.id];
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getTags',
      'getPerson',
      'updatePerson',
      'getTitleRanks',
      'getPersonTags',
      'getPersonNotes',
      'getPersonBrands',
      'getSpecialities',
      'getTitleFunctions',
      'getPersonLocations',
      'getAllOrganizations',
      'getPersonSpecialities',
      'getPersonOrganizations',
      'getPersonWorkHistories',
      'getPersonTitleFunctions',
      'getBrandAgenciesContacts',
    ]),
    getData() {
      this.isLoaded = false;

      this.getTags();
      this.getTitleRanks();

      Promise.all([this.getPerson(this.id)]).finally(() => {
        Promise.all([
          this.getPersonOrganizations(this.id),
          this.getPersonLocations(this.id),
          this.getPersonTags(this.id),
          this.getPersonNotes(this.id),
          this.getTitleFunctions(this.entity.organization?.type),
          this.getPersonTitleFunctions(this.id),
          this.getPersonWorkHistories(this.id),
          this.getSpecialities(this.entity.organization?.type),
          this.getPersonSpecialities(this.id),
        ]).finally(() => {
          this.isLoaded = true;
        });
      });
    },
    constructName(item) {
      return item ? `${item.fname} ${item.lname}` : "";
    },
    constructCompanyLink(organization) {
      if(organization.type === 'Company') {
        return Utils.getCompanyProfileUrl(organization.id);
      }
      else {
        return Utils.getAgencyProfileUrl(organization.id);
      }
    },
    saveEntity() {
      if (!Object.keys(this.dataUpdate).length) {
        return;
      }
      const payload = { ...this.dataUpdate };
      this.updatePerson({ id: this.id, payload });
      this.afterSaveEntity();
    },
    submitProof() {
      PeopleApi.submitPeopleProof(this.id)
      .then(({ data: { proofed_by_name, proofed_at }}) => {
        this.$set(this.entity, 'proofed_by_name', proofed_by_name);
        this.$set(this.entity, 'proofed_at', proofed_at);
      });
    },
  },
  beforeDestroy() {
    this.$store.commit(MutationTypes.SET_ORGANIZATIONS, []);
  },
};
</script>
