var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-title-functions" },
    [
      _c("winbox-card", [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("winbox-contact-title-functions-list", {
              attrs: {
                "possible-tags": _vm.possibleTags,
                "entity-tags": _vm.selectedTags,
                "placeholder-selected": _vm.placeholderSelected,
                "prop-selected-parent-id": "title_function_id",
                disabled: !_vm.isEditMode
              },
              on: {
                changed: function($event) {
                  return _vm.onTagsChanged($event, "name")
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }