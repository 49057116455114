<template>
  <div class="create-entity">
    <div class="columns">
      <div class="column">
        <winbox-input
          placeholder="Company Name"
          v-model.trim="$v.entity.name.$model"
          :class="status($v.entity.name)"
          field="name"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <winbox-input
          placeholder="Short Description"
          v-model.trim="$v.entity.short_description.$model"
          :class="status($v.entity.short_description)"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <winbox-input
          placeholder="URL"
          v-model.trim="$v.entity.website.$model"
          :class="status($v.entity.website)"
          field="website"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import { required, maxLength } from "vuelidate/lib/validators";
import * as Utils from "@/utils";

export default {
  name: "WinboxCreateBusinessEntity",
  components: {
    WinboxInput
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Colors: Colors,
      entity: {}
    };
  },
  validations: {
    entity: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      short_description: {
      },
      website: {
        required,
        winboxValidUrl: Utils.winboxValidUrl,
        maxLength: maxLength(40)
      }
    }
  },
  computed: {},
  beforeMount() {
    this.entity = cloneDeep(this.data);
  },
  methods: {
    status(validation) {
      if(validation.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-entity {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
</style>
