<template src="../Generic/EntityNotesMixin.html" />

<script>
import { mapActions } from "vuex";

import { EntityNotesMixin } from "@/components/Pages/Generic/EntityNotesMixin";
import { NoteTypes } from "@/constants/NoteTypes";

export default {
  name: "CompanyInternalNotes",
  mixins: [EntityNotesMixin],
  data() {
    return {
      noteType: NoteTypes.internal,
      isLoaded: true
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    mainTitle() {
      return `Internal Notes (${this.notesData.length})`;
    }
  },
  methods: {
     ...mapActions([
      'getBusinessNotes',
    ]),
   async getData() {
      this.isLoaded = false;

      await this.getBusinessNotes(this.id);

      this.isLoaded = true;
    },
  },
};
</script>

<style scoped lang="scss" src="../Generic/EntityNotesMixin.scss" />
