var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-agency" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("winbox-input", {
          class: _vm.status(_vm.$v.entity.name),
          attrs: { placeholder: "Agency Name", field: "name" },
          model: {
            value: _vm.$v.entity.name.$model,
            callback: function($$v) {
              _vm.$set(
                _vm.$v.entity.name,
                "$model",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "$v.entity.name.$model"
          }
        }),
        _vm._v(" "),
        _c("winbox-input", {
          class: _vm.status(_vm.$v.entity.short_description),
          attrs: { placeholder: "Short Description" },
          model: {
            value: _vm.$v.entity.short_description.$model,
            callback: function($$v) {
              _vm.$set(
                _vm.$v.entity.short_description,
                "$model",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "$v.entity.short_description.$model"
          }
        }),
        _vm._v(" "),
        _c("winbox-select", {
          class: _vm.status(_vm.$v.entity.discipline),
          attrs: {
            options: _vm.possibleDiscipline,
            clearable: true,
            multiple: true,
            placeholder: "Discipline"
          },
          model: {
            value: _vm.entity.discipline,
            callback: function($$v) {
              _vm.$set(_vm.entity, "discipline", $$v)
            },
            expression: "entity.discipline"
          }
        }),
        _vm._v(" "),
        _c("winbox-input", {
          class: _vm.status(_vm.$v.entity.website),
          attrs: { placeholder: "URL", field: "website" },
          model: {
            value: _vm.$v.entity.website.$model,
            callback: function($$v) {
              _vm.$set(
                _vm.$v.entity.website,
                "$model",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "$v.entity.website.$model"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }