import { render, staticRenderFns } from "../Generic/EntityAuditLogMixin.html?vue&type=template&id=12052da4&scoped=true&external"
import script from "./CompanyAuditLog.vue?vue&type=script&lang=js"
export * from "./CompanyAuditLog.vue?vue&type=script&lang=js"
import style0 from "../Generic/EntityAuditLogMixin.scss?vue&type=style&index=0&id=12052da4&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12052da4",
  null
  
)

export default component.exports