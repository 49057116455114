import * as BusinessesApi from "@/api/businesses/businesses";
import * as AgenciesApi from "@/api/agencies/agencies";
import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getAgencies({ commit }) {
    return AgenciesApi.getAgencies().then(
      ({ data }) => {
        const dataWithMock = [...Utils.defaultAgencies(), ...data];
        commit(MutationTypes.SET_AGENCIES, dataWithMock);
      }
    );
  },

  getAgency({ commit }, id) {
    return AgenciesApi.getAgency(id).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_AGENCY, data);
    });
  },

  getAgencyCounts({ commit }, id) {
    return AgenciesApi.getAgencyCounts(id).then(({ data }) => {
      commit(MutationTypes.SET_COUNTS, data);
    });
  },

  updateAgency({ commit, getters }, { id, payload }) {
    return AgenciesApi.updateAgency(id, payload).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_AGENCY, data);
    });
  },

  getAgencyRelationship({ commit }, id) {
    return AgenciesApi.getAgencyRelationship(id).then(({ data }) => {
      commit(MutationTypes.SET_AGENCY_RELATIONSHIP, data);
    });
  },

  getAgencyRelationshipForAgencyLocation({ commit }, id) {
    return AgenciesApi.getAgencyRelationshipForAgencyLocation(id).then(({ data }) => {
      commit(MutationTypes.SET_AGENCY_RELATIONSHIP, data);
    });
  },

  createAgencyRelationship({ state, commit, getters }, { id, payload }) {
    return BusinessesApi.createBusinessRelationship(id, payload).then (({ data }) => {
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'entity_brand_count',
        count: state.entityCounts.entity_brand_count + 1
      });
    });
  },

  updateAgencyRelationship({ state, commit, getters }, { id, relationshipId, payload }) {
    return BusinessesApi.updateBusinessRelationship(id, relationshipId, payload).then(({ data }) => {
      commit(MutationTypes.UPDATE_AGENCY_RELATIONSHIP, data);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'entity_brand_count',
        count: state.entityCounts.entity_brand_count - 1
      });
    });
  },

  getAgencyHierarchies({ commit }, id) {
    return AgenciesApi.getAgencyHierarchies(id).then(({ data }) => {
      commit(MutationTypes.SET_ENTITY_PARENT_HIERARCHIES, data.parents);
      commit(MutationTypes.SET_ENTITY_CHILD_HIERARCHIES, data.children);
      commit(MutationTypes.SET_ENTITY_SIBLING_HIERARCHIES, data.siblings);
    });
  },

  createAgencyHierarchy({ state, commit, getters }, { id, payload }) {
    return AgenciesApi.createAgencyHierarchy(id, payload).then(
      ({ data }) => {
        commit(MutationTypes.UPDATE_COUNTS, {
          stateVariableName: 'hierarchy_total_count',
          count: state.entityCounts.hierarchy_total_count + 1,
        });
        if(payload.hierarchy_type === 'parent') {
          commit(MutationTypes.UPDATE_ENTITY_PARENT_HIERARCHIES, data);
        }
        else {
          commit(MutationTypes.UPDATE_ENTITY_CHILD_HIERARCHIES, data);
        }
      }
    );
  },

  removeAgencyHierarchy({ state, commit, getters }, { id, type }) {
    return AgenciesApi.removeAgencyHierarchy(id).then(() => {
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'hierarchy_total_count',
        count: state.entityCounts.hierarchy_total_count - 1,
      });
      if(type === 'parent') {
        commit(MutationTypes.REMOVE_ENTITY_PARENT_HIERARCHIES, id);
      }
      else {
        commit(MutationTypes.REMOVE_ENTITY_CHILD_HIERARCHIES, id);
      }
    });
  },
};
