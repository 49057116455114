var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap-social-media" }, [
    !_vm.entities.length ? _c("div", [_vm._v("No items")]) : _vm._e(),
    _vm._v(" "),
    _vm.entities.length
      ? _c(
          "ul",
          { staticClass: "social-entities-list" },
          _vm._l(_vm.entities, function(item, key) {
            return _c("li", { key: key }, [
              _c(
                "div",
                { staticClass: "columns is-variable is-1 is-vcentered" },
                [
                  _c(
                    "div",
                    { staticClass: "column is-4" },
                    [
                      _c("winbox-select", {
                        attrs: {
                          value: item,
                          options: _vm.possibleSocials,
                          taggable: true,
                          placeholder: "Social",
                          disabled: _vm.disabled
                        },
                        on: {
                          input: function($event) {
                            return _vm.onSocialChanged(item, $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-7" },
                    [
                      !_vm.disabled
                        ? _c("winbox-input", {
                            class: _vm.status(
                              _vm.$v.entities.$each.$iter[key].website
                            ),
                            attrs: {
                              placeholder: "Web Address",
                              "icon-left": "globe-americas"
                            },
                            on: {
                              input: function($event) {
                                return _vm.change(true)
                              }
                            },
                            model: {
                              value:
                                _vm.$v.entities.$each.$iter[key].website.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entities.$each.$iter[key].website,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.entities.$each.$iter[key].website.$model"
                            }
                          })
                        : _c("fieldset", { staticClass: "border" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: item.website, target: "_blank" }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(decodeURIComponent(item.website)) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column column-remove-entity" }, [
                    !_vm.disabled
                      ? _c(
                          "button",
                          { staticClass: "button button-delete icon-wrapper" },
                          [
                            _c("winbox-icon", {
                              attrs: {
                                icon: "trash",
                                color: _vm.Colors.system.error
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.removeEntity(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }