<template>
  <div class="data-import-page" v-if="isLoaded">
    <div
      class="columns is-variable is-gapless is-vcentered data-import-header-title"
    >
      <div class="column">
        <winbox-title class="main-title" title="Import Data to Winbox" />
      </div>
    </div>

    <div class="main-descr">
      Upload a CSV file and map fields to corresponding fields in Winbox
    </div>

    <div class="columns is-variable is-1 is-vcentered">
      <div class="column is-2">
        <winbox-select
          v-model="selectedTemplate"
          form-label="Select a Template"
          :options="possibleTemplates"
          @input="selectTemplate"
        />
      </div>
      <div
        class="column is-narrow column-upload-file"
      >
        <winbox-upload-file
          ref="fileUpload"
          :upload-file="uploadedFile"
          @changed="onFileUploadChange"
          :disabled="!selectedTemplate"
        />
      </div>
      <div
        class="column is-narrow column-download-file"
      >
        <winbox-template-file
          :disabled="!selectedTemplate"
          @download-template="downloadTemplate"
        />
      </div>
      <div v-if="fileData" class="column is-narrow">
        <div class="upload-file-label">Uploaded File:</div>
        <div class="upload-file-wrap">
          <span :title="fileData.name">{{ fileData.name }}</span>
          <button v-if="isLoaded" class="button button-delete">
            <winbox-icon
              icon="times"
              :color="Colors.system.error"
              @click.native="removeFile"
            />
          </button>
        </div>
      </div>
      <div v-if="fileData" class="column">
        <button v-if="isLoaded" class="button" @click="goToUploaData">
          Upload
        </button>
      </div>
    </div>

    <div v-if="isUploading" class="container">
      <div class="alert" :class="alertStyleObj">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import WinboxUploadFile from "@/components/Molecules/WinboxUploadFile";
import WinboxTemplateFile from "@/components/Molecules/WinboxTemplateFile";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

import * as DataImportApi from "@/api/data_import/data_import";

export default {
  name: "DataImportPage",
  components: {
    WinboxIcon,
    WinboxTitle,
    WinboxSelect,
    WinboxUploadFile,
    WinboxTemplateFile,
  },
  validations: {},
  data() {
    return {
      selectedTemplate: null,
      isLoaded: false,
      Colors,
      uploadedFile: "mappingFile",
      fileData: null,
      isUploading: false,
      message: '',
      error: false,
    };
  },
  created: function() {
    this.loadData();
  },
  computed: {
    ...mapState([
      'dataImportTemplates',
    ]),
    possibleTemplates() {
      return this.dataImportTemplates.map((template) => {
        return {
          label: template.name,
          value: template.id,
        };
      });
    },
    alertStyleObj() {
      return {
        'alert-success': !this.error,
        'alert-danger': this.error
      };
    },
  },
  methods: {
    ...mapActions([
      'getDataImportTemplates',
    ]),
    loadData() {
      this.isLoaded = false;
      this.getDataImportTemplates().then(() => {
        this.isLoaded = true;
      });
    },
    selectTemplate(value) {
      this.selectedTemplate = value;
    },
    onFileUploadChange(e) {
      this.fileData = e[0];
    },
    removeFile() {
      this.fileData = null;
      this.$refs.fileUpload.resetFileUpload();
      this.resetFinalStep();
    },
    goToUploaData() {
      let formData = new FormData();
      formData.append('file', this.fileData);
      formData.append('templateId', this.selectedTemplate.value);

      DataImportApi.validateFileFormat(formData)
      .then(({data}) => {
        this.error = data.error;
        this.message = data.message;
        this.isUploading = true;

        if(!data.error) {
          this.onImportFile(formData);
        }
      });
    },
    downloadTemplate() {
      const templateName = this.selectedTemplate.label;
      const fileName = `${templateName.toLowerCase()} template.csv`;

      DataImportApi.downloadTemplate(templateName)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
      })
      .catch(error => {
        console.error("Error downloading CSV:", error);
      });
    },
    resetFinalStep() {
      this.isUploading = false;
    },
    onImportFile(formData) {
      DataImportApi.importData(formData)
      .then(({data}) => {
        // Finished the import
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.data-import-page {
  padding: 0 0 7rem;
}
.data-import-header-title {
  margin: 0 !important;
}
.main-title,
.main-descr {
  margin: 0 0 1rem;
}
.column-download-file {
  min-width: 360px;
}
.upload-file-wrap {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: $winbox-uploaded-file;
  border-radius: 2px;
  padding: 4px 8px;
  margin: 0 5px;
  color: #fff;
  min-width: 100px;
  font-weight: 600;
  max-width: 300px;

  > span {
    @include text-overflow-ellipsis;
  }
}
.upload-file-label {
  display: inline-block;
  font-weight: bold;
  min-width: 100px;
}
.button-delete {
  @include button-reset-ui;
  margin: 0 0 0 7px;
}
.container {
  margin: auto;
  text-align: center;
  width: 50%;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }

  &-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
}
</style>
