import * as UserTasksApi from "@/api/user-tasks/user-tasks";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getUserTasks({ commit }) {
    return UserTasksApi.getUserTasks().then((data) => {
      commit(MutationTypes.SET_USER_TASKS, data);
    });
  },

  updateUserTask({ commit }, { id, payload }) {
    return UserTasksApi.updateUserTask(id, payload).then((data) => {
      commit(MutationTypes.UPDATE_USER_TASK, { id, ...payload });
    });
  },
};
