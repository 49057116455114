import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxTitle from "@/components/Atoms/WinboxTitle";

import WinboxSpecialityTags from "@/components/Organisms/WinboxSpecialityTags";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

export const EntityTagsMixin = {
  components: {
    WinboxLargeIndicator,
    WinboxTitle,
    WinboxSpecialityTags
  },
  data() {
    return {
      Colors,
      isLoaded: false
    };
  },
  computed: {
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return "Tags";
    },
    possibleTags() {
      return Utils.sortAlphabetic(this.$store.getters.TAGS, "name");
    },
    selectedTags() {
      let result = this.$store.getters.BUSINESS_TAGS_BY_ID(this.id);

      return this.modifySelectedTags(result);
    }
  },
  methods: {
    modifySelectedTags(data) {
      let result = data.map(item => {
        return {
          ...item,
          name: item.name ? item.name : this.findItemNameFromTags(item.tag_id)
        };
      });

      return Utils.sortAlphabetic(result, "name");
    },
    findItemNameFromTags(tagId) {
      const found = this.possibleTags.find(item => item.id === tagId);
      return found ? found.name : "";
    },
    createNewTagEntity(newTags) {
      const payload = newTags[0];
      this.$store.dispatch("createTag", payload);
    },
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        tag_id: selectedTags[0].id
      };

      this.$store.dispatch("createBusinessEntityTag", {
        id: this.id,
        payload: payload
      });
    },
    removeBusinessTagEntity(removedTags) {
      const deleteItem = removedTags[0];

      this.$store.dispatch("removeBusinessEntityTag", {
        id: this.id,
        tagId: deleteItem.id
      });
    }
  }
};
