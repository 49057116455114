import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";

import { Colors } from "@/Colors";
import { AuditLogActions } from "@/constants/AuditLogActions";
import { mapActions } from "vuex";

const tableProps = {
  date: "created_at",
  user: "user",
  action: "action",
  /* Custom getters */
  element: "audited_changes_element",
  from: "__audited_changes_from",
  to: "__audited_changes_to",
  undo: "__undo"
};
const mainTableColumns = Object.values(tableProps);
const actionsAliases = {
  create: 'Added',
  destroy: 'Removed',
  update: 'Updated'
}

export const EntityAuditLogMixin = {
  components: {
    WinboxLargeIndicator,
    WinboxTitle,
    WinboxIcon,
    WinboxDataTable
  },
  data() {
    return {
      Colors,
      AuditLogActions,
      isLoaded: false,
      tableProps: tableProps,
      actionsAliases: actionsAliases,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.date]: "Date",
          [tableProps.user]: "User",
          [tableProps.action]: "Action",
          [tableProps.element]: "Data",
          [tableProps.from]: "From",
          [tableProps.to]: "To",
          [tableProps.undo]: ""
        },
        sortable: [...mainTableColumns].filter(
          item => item !== tableProps.undo
        ),
        filterable: [...mainTableColumns].filter(
          item => item !== tableProps.undo
        )
      },
      specificSlots: [tableProps.date, tableProps.undo]
    };
  },
  computed: {
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Audit Log (${this.dataset.length})`;
    },
    dataset() {
      return this.modifyLogData(
        this.$store.getters.BUSINESS_LOGS_BY_ID(this.id)
      );
    }
  },
  methods: {
    ...mapActions([
      "getBusinessLogs",
      "getBrandLogs",
      "getPersonLogs",
      "rollbackBusinessLog",
      "rollbackBusinessLog",
      "rollbackPersonLog"
    ]),
    modifyLogData(data) {
      return data.map(item => {
        let result = {
          ...item
        };
        result[tableProps.action] = `${this.actionAlias(item.action)} ${item.auditable_type}`;
        result[tableProps.user] = item.email;
        result[tableProps.element] = this.getLogPropName(item.audited_changes);
        result[tableProps.from] = this.getLogPropFrom(item.audited_changes);
        result[tableProps.to] = this.getLogPropTo(item.audited_changes);

        return result;
      });
    },
    getLogPropName(changes) {
      let result = [];
      let data = Object.entries(changes);

      data.forEach(item => result.push(item[0]));

      return result.join(", ");
    },
    getLogPropFrom(changes) {
      let result = [];
      let data = Object.entries(changes);
      data.forEach(item => {
        if (typeof item[1] === "object" && item[1] !== null) {
          result.push(item[1][0] + "");
        } else {
          result.push(item[1] + "");
        }
      });

      return result.join(", ");
    },
    getLogPropTo(changes) {
      let result = [];
      let data = Object.entries(changes);
      data.forEach(item => {
        if (typeof item[1] === "object" && item[1] !== null) {
          result.push(item[1][1] + "");
        } else {
          result.push(item[1] + "");
        }
      });

      return result.join(", ");
    },
    undoBusinessLog(parameters) {
      return this.rollbackBusinessLog(parameters)
        .then(({ data }) => {
          this.getBusinessLogs(data.rollback_object_id);
        })
        .catch(e => {
          throw e;
        });
    },
    undoBrandLog(parameters) {
      return this.rollbackBrandLog(parameters)
        .then(({ data }) => {
          this.getBrandLogs(data.rollback_object_id);
        })
        .catch(e => {
          throw e;
        });
    },
    undoPersonLog(parameters) {
      return this.rollbackPersonLog(parameters)
        .then(({ data }) => {
          this.getPersonLogs(data.rollback_object_id);
        })
        .catch(e => {
          throw e;
        });
    },
    onUndoClick(auditable_type, associated_type, log_id) {
      let parameters = { log_id: log_id };
      let type = auditable_type;

      if (associated_type) {
        type = associated_type;
      }
      if (type == "Business") {
        return this.undoBusinessLog(parameters);
      }
      if (type == "Brand") {
        return this.undoBrandLog(parameters);
      }
      if (type == "Person") {
        return this.undoPersonLog(parameters);
      }
    },
    actionAlias(actionName) {
      let alias = this.actionsAliases[actionName];
      return alias == undefined ? actionName : alias;
    }
  }
};
