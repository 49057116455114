var render = function render(_c, _vm) {
  return _c(
    "li",
    [
      _c(
        "router-link",
        {
          class: [{ disabled: _vm.props.disabled }, _vm.props.additionalClass],
          attrs: { exact: _vm.props.exact, to: _vm.props.link }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }