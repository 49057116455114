<template>
  <div class="winbox-title">
    <h1 v-if="type === 'big'" class="title" :title="title">
      {{ title }}
    </h1>

    <h2 v-if="type === 'medium'" class="subtitle" :title="title">
      {{ title }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "WinboxTitle",
  props: {
    title: {
      required: true,
      type: String,
      note: "The text that should be titled"
    },
    type: {
      required: false,
      type: String,
      default: "big",
      note: 'Type of title. can be "big", "medium"'
    }
  }
};
</script>

<style lang="scss" scoped>
.title,
.subtitle {
  font-weight: 400;
}
.winbox-title {
  display: inline-block;
  width: fit-content;
}
</style>
