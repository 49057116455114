var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "public-notes-wrap" },
    [
      _c("winbox-textarea", {
        class: _vm.status(_vm.$v.entity.description),
        attrs: { placeholder: "Description" },
        model: {
          value: _vm.$v.entity.description.$model,
          callback: function($$v) {
            _vm.$set(
              _vm.$v.entity.description,
              "$model",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "$v.entity.description.$model"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "columns is-variable is-1" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c("winbox-input", {
              attrs: { readonly: "", label: "Author" },
              model: {
                value: _vm.entity.author,
                callback: function($$v) {
                  _vm.$set(_vm.entity, "author", $$v)
                },
                expression: "entity.author"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column" },
          [
            _c("winbox-input", {
              ref: "reminder-date",
              staticClass: "active-icon-bg",
              attrs: {
                "date-picker": true,
                label: "Reminder",
                "icon-right": "clock"
              },
              on: {
                datepickerShow: function($event) {
                  return _vm.setDisabledDate(
                    "reminder-date",
                    _vm.entity.expire_date,
                    "right"
                  )
                }
              },
              model: {
                value: _vm.entity.reminder_date,
                callback: function($$v) {
                  _vm.$set(_vm.entity, "reminder_date", $$v)
                },
                expression: "entity.reminder_date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column" },
          [
            _c("winbox-input", {
              ref: "expire-date",
              staticClass: "active-icon-bg",
              attrs: {
                "date-picker": true,
                label: "Expire",
                "icon-right": "hourglass-half"
              },
              on: {
                datepickerShow: function($event) {
                  return _vm.setDisabledDate(
                    "expire-date",
                    _vm.entity.reminder_date,
                    "left"
                  )
                }
              },
              model: {
                value: _vm.entity.expire_date,
                callback: function($$v) {
                  _vm.$set(_vm.entity, "expire_date", $$v)
                },
                expression: "entity.expire_date"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }