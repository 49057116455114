var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "winbox-select",
      class: [_vm.theme, { "has-label": !!_vm.formLabel }]
    },
    [
      _vm.formLabel
        ? _c("label", { staticClass: "label winbox-label" }, [
            _c("span", [_vm._v(_vm._s(_vm.formLabel))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-select",
        {
          attrs: {
            value: _vm.selected,
            options: _vm.options,
            disabled: _vm.disabled,
            clearable: _vm.clearable,
            searchable: _vm.searchable,
            multiple: _vm.multiple,
            placeholder: _vm.placeholder,
            transition: _vm.transition,
            label: _vm.label,
            taggable: _vm.taggable
          },
          on: { input: _vm.onInput, "search:focus": _vm.onFocus }
        },
        [
          _c("div", { attrs: { slot: "no-options" }, slot: "no-options" }, [
            _vm._v(_vm._s(_vm.emptyText))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }