<template functional>
  <ul class="winbox-tabs-list" :class="[props.theme]">
    <slot />
  </ul>
</template>

<script>
export default {
  name: "WinboxTabs",
  props: {
    theme: {
      type: String,
      default: "vertical-grey-theme",
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

.winbox-tabs-list {
  &.vertical-grey-theme {
    ::v-deep {
      li {
        a {
          color: $winbox-body-color;
          display: block;
          padding: 8px 12px;
          font-size: $winbox-font-size-base;
          transition: all 0.3s;

          &.router-link-active {
            color: $winbox-link-color;
            background: $winbox-body-color;
            font-weight: bold;
          }
        }
      }
    }
  }
  &.horizontal-theme {
    background: $winbox-nav-bg-color;
    border-radius: $winbox-border-radius $winbox-border-radius 0 0;

    ::v-deep {
      li {
        display: inline-block;

        &:first-child {
          a {
            border-left: none;
          }
        }

        a {
          color: $winbox-body-grey-bg;
          display: block;
          padding: 6px 12px;
          font-size: 13px;
          margin: 6px 0;
          transition: all 0.3s;
          border-left: 1px solid $winbox-body-grey-bg;

          &.router-link-active {
            color: $winbox-body-color;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
