export const EditModeMixin = {
  data() {
    return {
      isEditMode: false,
      selectedRowID: null,
      deleteModal: "deleteModal",
      isDeleteLoaded: true,
      oldStatus: null,
      statusFieldName: "status",
      statusSelectPrefix: "statusSelect",
      prevRow: null
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },
  watch: {},
  computed: {},
  methods: {
    isDisabledEdit(row) {
      return row.id != this.selectedRowID || !this.isEditMode;
    },
    onDelete(row) {
      this.selectedRowID = row.id;
      this.$refs[this.deleteModal].show();
    },
    onDeleteAccept() {
      this.deleteSelectedItem(this.selectedRowID);
    },
    onDeleteCancel() {
      this.notEditMode();
      this.isDeleteLoaded = true;
      this.$refs[this.deleteModal].hide();
    },
    onEdit(row) {
      if (this.isEditMode) {
        if (this.isChanged(this.prevRow) && confirm("You have unsaved changes. Do you want to proceed?")) {
          this.editCancel(this.prevRow);
          this.editMode(row);
        }
        else {
          this.editCancel(this.prevRow);
          this.editMode(row);
        }
      }
      else {
        this.editMode(row);
      }
    },
    notEditMode() {
      this.isEditMode = false;
      this.selectedRowID = null;
    },
    editMode(row) {
      this.isEditMode = true;
      this.selectedRowID = row.id;
      this.prevRow = row;
      this.oldStatus = row[this.statusFieldName];
    },
    editAccept(row) {
      this.updateStatus(row.id, this.getStatus(row));
      this.notEditMode();
    },
    editCancel(row) {
      row[this.statusFieldName] = this.oldStatus;
      this.$refs[this.statusSelectPrefix + row.id].selected = this.oldStatus;
      row.statusColor = this.getStatusIconColor(row[this.statusFieldName].value);
      this.notEditMode();
    },
    isChanged(row) {
      return row[this.statusFieldName] !== this.oldStatus;
    },
    updateStatus(id, status) { },
    preventNav(event) {
      if (!this.isEditMode) return;
      event.returnValue = "You have unsaved changes";
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isEditMode) {
      next();
    } else {
      let result = confirm("You have unsaved changes. Do you want to proceed?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
};
