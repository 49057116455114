var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card winbox-card" }, [
    _vm.hasHeaderSlot
      ? _c("div", { staticClass: "card-header" }, [
          _vm.hasHeaderLeftIconSlot
            ? _c(
                "div",
                { staticClass: "card-header-icon" },
                [_vm._t("headerLeftIcon")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-header-title" },
            [_vm._t("header")],
            2
          ),
          _vm._v(" "),
          _vm.hasHeaderIconSlot
            ? _c(
                "div",
                { staticClass: "card-header-icon" },
                [_vm._t("headerIcon")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.expandable
            ? _c("div", { staticClass: "card-header-icon _expand" }, [
                _c(
                  "button",
                  { staticClass: "button" },
                  [
                    _c("winbox-icon", {
                      attrs: {
                        icon: _vm.iconExpand,
                        color: _vm.Colors.system.primaryLight
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.toggleCard.apply(null, arguments)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isExpanded,
            expression: "isExpanded"
          }
        ],
        staticClass: "card-content"
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }