import { mapState } from "vuex";

import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";

import WinboxCreateLocation from "@/components/Organisms/WinboxCreateLocation";

import { Colors } from "@/Colors";
import { BusinessStatuses } from "@/constants/BusinessStatuses";
import { LocationTypes } from "@/constants/LocationTypes";
import * as Utils from "@/utils";

const tableProps = {
  name: "name",
  count: "_relatedContacts",
  type: "location_type",
  city: "city",
  status: "status"
};
const mainTableColumns = Object.values(tableProps);

export const EntityLocationsMixin = {
  components: {
    WinboxModal,
    WinboxSelect,
    WinboxLargeIndicator,
    WinboxCompanyHeader,
    WinboxDataTable,
    WinboxCreateLocation
  },
  filters: {
    humanLocationType(key) {
      return LocationTypes[key];
    }
  },
  data() {
    return {
      newLocationEntity: {
        name: "",
        type: "",
        address_1: "",
        city: "",
        zip: "",
        status: BusinessStatuses.active
      },
      Colors: Colors,
      isLoaded: true,
      isCreateNewLocationLoaded: true,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.count]: "Contacts",
          [tableProps.type]: "Type",
          [tableProps.city]: "City",
          [tableProps.status]: "Status"
        },
        sortable: [...mainTableColumns],
        filterable: [...mainTableColumns]
      },
      specificSlots: [tableProps.name, tableProps.type, tableProps.status],
      createModal: "createModal"
    };
  },
  computed: {
    ...mapState([
      'businessLocations',
      'businessProfileContacts',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Locations (${this.locationsData.length})`;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    locationsData() {
      let result = this.businessLocations;
      return this.modifyLocationsData(result);
    },
    contactsData() {
      return this.businessProfileContacts || [];
    }
  },
  methods: {
    openModal() {
      this.$refs[this.createModal].show();
    },
    onCancel() {
      this.$refs[this.createModal].hide();
    },
    onSave() {
      const createLocationVm = this.$refs.createLocation;
      const $v = createLocationVm.$v;
      createLocationVm.submitStatus = true;
      $v.$touch();

      if (!$v.$error) {
        this.isCreateNewLocationLoaded = false;
        const payload = {
          ...createLocationVm.entity,
          location_type: createLocationVm.entity.location_type.value,
          tv_region_id: createLocationVm.entity?.tv_region?.value
        };

        this.$store
          .dispatch("createBusinessLocation", {
            id: this.id,
            payload: payload
          })
          .then(() => {
            this.isCreateNewLocationLoaded = true;
            this.onCancel();
          });
      }
    },
    updateLocationStatus(data, status) {
      const payload = {
        location_type: data.location_type,
        status: status
      };

      this.$store.dispatch("updateBusinessLocation", {
        id: this.id,
        locationId: data.id,
        payload: payload
      });
    },
    modifyLocationsData(data) {
      return data.map(item => {
        return {
          ...item,
          [tableProps.count]: item.persons.length,
          [tableProps.status]: Utils.findStatusForSelect(
            item[tableProps.status],
            this.possibleStatuses
          )
        };
      });
    },
  }
};
