<template>
  <div class="winbox-data-table" :class="[theme]">
    <winbox-data-table-search
      v-if="enableCustomFiltering"
      :data="datasetBackup"
      @search="onSearch"
    />
    <v-server-table
      ref="vTableRef"
      :columns="columns"
      :url="url"
      :data="dataset"
      :options="tableOptions"
      @row-click="$emit('row-click', $event)"
      @filter="onFilter"
    >
      <template v-if="hasIdSlot" :slot="headerIdSlotName">
        <input
          type="checkbox"
          class="checkbox check-all-header"
          v-model="allMarked"
          @change="toggleAllCheckboxes"
        />
      </template>

      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

      <template :slot="slot" slot-scope="props" v-for="(slot, key) in slots">
        <div v-if="slot === idKey" :key="key">
          <input
            type="checkbox"
            id="checkbox"
            :value="props.row[idKey]"
            v-model="markedRows"
            @change="toggleCheckbox"
          />
        </div>
        <slot v-if="slot !== idKey" :name="slot" :row="props.row" />
      </template>
    </v-server-table>
  </div>
</template>

<script>
import WinboxDataTableSearch from "@/components/Molecules/WinboxDataTableSearch";

import { Colors } from "@/Colors";
import { ClientTablePerPageValues } from "@/constants/ClientTable";
import { Http } from "../../api/index";

export default {
  name: "WinboxServerDataTable",
  components: {
    WinboxDataTableSearch,
  },
  props: {
    slots: {
      type: Array,
    },
    idKey: {
      type: String,
      required: false,
      default: "id",
    },
    theme: {
      type: String,
      required: false,
      default: "winbox-theme",
    },
    columns: {
      required: true,
      type: Array,
    },
    url: {
      type: String,
      required: true,
    },
    options: {
      required: false,
      type: Object,
      default: () => {},
    },
    enableCustomFiltering: {
      required: false,
      default: false,
      type: Boolean,
    },
    perPage: {
      required: false,
      default: 25,
      type: Number,
    },
    responseFunction: Function,
  },
  data() {
    return {
      Colors: Colors,
      dataset: [],
      datasetBackup: [],
      filterTimeout: null,
      tableOptions: {
        perPage: this.perPage,
        perPageValues: this.getPerPageValues(),
        texts: {
          filterPlaceholder: "Filter (e.x. 'BMW')",
        },
        sortIcon: {
          base: "fas",
          is: "fa-sort",
          up: "fa-sort-up",
          down: "fa-sort-down",
        },
        pagination: {
          edge: true,
          chunk: 5,
          nav: 'fixed',
        },
        requestFunction(data) {
          this.$parent.$emit("updateQuery", data);
          return Http
            .get(this.url, {
              params: { ...data, ascending: data.ascending ? 1 : undefined},
            })
            .catch(function (e) {
              this.dispatch("error", e);
            });
        },
        responseAdapter: (response) => {
          this.count = response.data.count;
          let enhanceData = this.responseFunction(
            response.data.data,
            response.data.count
          );
          this.data = enhanceData;

          return { data: enhanceData, count: response.data.count };
        },
        resizableColumns: false,
        ...this.options,
      },
      allMarked: false,
      markedRows: [],
      count: 0,
      data: [],
    };
  },
  computed: {
    hasIdSlot() {
      return !!this.slots.find((item) => item === this.idKey);
    },
    headerIdSlotName() {
      return `h__${this.idKey}`;
    },
  },
  watch: {
    data: {
      handler() {
        this.focusSearchInput();
      },
      deep: true,
    },
  },
  methods: {
    onSearch(data) {},
    onFilter() {
      if (this.hasIdSlot) {
        this.resetCheckboxes();
      }

      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        this.$emit("filter", this.$refs.vTableRef.allFilteredData);
      }, 300);
    },
    focusSearchInput() {
      this.$nextTick(() => {
        const searchInput = document.querySelector('.VueTables__search__input.input.is-small');
        if (searchInput) {
          searchInput.focus();
        }
      });
    },
    toggleAllCheckboxes() {
      this.markedRows = this.allMarked ? this.getAllFilteredRowIds() : [];
      this.$emit("rowsChange", this.markedRows);
    },
    toggleCheckbox(e) {
      const state = e.target.checked;
      const rows = this.getAllFilteredRowIds();
      if (!state) {
        this.allMarked = false;
      } else if (rows.length === this.markedRows.length) {
        this.allMarked = true;
      }

      this.$emit("rowsChange", this.markedRows);
    },
    resetCheckboxes() {
      this.markedRows = [];
      this.allMarked = false;
    },
    getAllFilteredRowIds() {
      return this.data.map((row) => row[this.idKey]);
    },
    getPerPageValues() {
      return ClientTablePerPageValues;
    },
    refreshTable() {
      this.$refs.vTableRef.refresh();
    },
    getData() {
      return this.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-data-table {
  // All themes
  ::v-deep {
    #VueTables_th--id {
      width: 30px;
    }

    .table {
      th {
        background: $winbox-table-header;
        color: #fff;
        vertical-align: middle;
        @include break-word;
      }
      td {
        @include break-word;
        a {
          color: #3D589B;
        }
        a:hover {
          text-decoration: underline;
          color: #3D589B;
          font-weight: bold;
        }

        &:focus {
          outline: none;
        }
      }
    }
    .VueTables__sort-icon {
      font-size: 18px;
    }
    .VueTables__child-row-toggler {
      font-family: "Font Awesome 5 Free";
      cursor: pointer;
      font-weight: 900;
      font-size: 16px;
      line-height: 16px;
      display: block;
      margin: auto;
      text-align: center;
    }
    .VueTables__child-row-toggler--closed::before {
      content: "\f078";
    }
    .VueTables__child-row-toggler--open::before {
      content: "\f077";
    }
    // Native filters
    .VueTables--server {
      > .columns:first-child {
        background: $winbox-primary-active-bg-color;
        margin: 0;
        position: relative;
        overflow: hidden;

        > .column {
          padding-bottom: 0;
        }

        .VueTables__search {
          width: 90%;
          .VueTables__search-field {
            width: 100%;
          }
        }
        .VueTables__search-field {
          label {
            display: none;
          }
          input {
            @include datatable-search-input;
          }
        }
        .VueTables__limit-field {
          padding-right: 5px;

          label {
            display: none;

            + select {
              height: 34px;
            }
          }
        }
      }
    }
  }
  // Specific themes
  &.winbox-theme {
    ::v-deep {
      .table {
        th {
          border: 0;
        }
        td {
          border: 0;
        }
      }
    }
  }
  &.with-borders-theme {
    ::v-deep {
      .table {
        th {
          border-color: $winbox-table-header;
        }
        td {
          border-color: $winbox-secondary-title;
        }
      }
    }
  }
}
</style>
