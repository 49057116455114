import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxAddBrandContact from "@/components/Organisms/WinboxAddBrandContact";

import * as PeopleApi from "@/api/people/people";
import cloneDeep from "clone-deep";
import { Colors } from "@/Colors";

export const WinboxLinkContactHelperMixin = {
  components: {
    WinboxSelect,
    WinboxLargeIndicator,
    WinboxDataTable,
    WinboxCompanyHeader,
    WinboxAddBrandContact
  },
  data() {
    return {
        linkModal: "linkModal",
        linkEditModal: "linkEditModal",
        nextStepIsActive: false,
        rowOptions: this.prepareRowMenuOptions(),
        editEntity: null,
        linkConfig: {
            tableProps: {
                name: "name",
                email: "email",
                linked: "",
            },
            specificSlots: [""],
            tableOptions: {
                headings: {
                    name: "Name",
                    email: "Email",
                    linked: ""
                },
                sortable: [],
                filterable: ["name"]
            }
        },
    };
  },
  computed: {
    saveModalText() {
        return this.nextStepIsActive ? "Create" : "Add Selected";
    }
  },
  methods: {
    openLinkModal() {
        this.$refs[this.linkModal].show();
    },
    onCancelLinkModal() {
        this.$refs[this.linkModal].hide();
    },
    resetLinkNextStep() {
        this.nextStepIsActive = false;
    },
    onSaveLinkModal() {
        const vm = this.$refs.addBrandContact;
        if(vm) {
            const $v = vm.$v;

            if (!vm.entity) {
                return;
            }

            if (vm.multiselect) {
                if (typeof this.saveContactRelationship === "function") {
                    this.saveContactRelationship(vm.entity);
                }
                this.onCancelLinkModal();
            }
            else {
                $v.$touch();

                if (!$v.$error) {
                    if (typeof this.saveContactRelationship === "function") {
                        this.saveContactRelationship(vm.entity);
                    }
                    this.onCancelLinkModal();
                }
            }
        }
    },
    prepareRowMenuOptions() {
        return [
            {
                id: 1,
                title: "Edit",
                icon: "edit",
                iconColor: Colors.system.primaryLight,
                action: data => {
                    this.editRelationship(data);
                }
            },
            {
                id: 2,
                title: "Delete",
                icon: "trash",
                iconColor: Colors.system.error,
                action: data => {
                    this.removeRelationship(data);
                }
            }
        ];
    },
    removeRelationship(row) {
        let shouldBeConfirmed = row.id ? confirm("Are you sure?") : true;
        if (shouldBeConfirmed) {
          if (typeof this.deleteRelationship === "function") {
            this.deleteRelationship(row);
          }
        }
      },
  }
};
