import { MutationTypes } from "@/constants/MutationTypes";

export default {
  [MutationTypes.SET_FILTER_OPTIONS]: (state, payload) => {
    const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
    const isUk = isUKMetaTag && isUKMetaTag.getAttribute('content') == 'true';

    if (isUk) {
      payload = payload.filter((item) => item.name !== 'Location');
    }

    state.filterOptions = payload;
  },
  [MutationTypes.SET_LOCATION_FILTER_OPTIONS]: (state, payload) => {
    state.filterOptionItems = payload;
  },
  [MutationTypes.SET_POSTAL_CODES_FILTER_OPTIONS]: (state, payload) => {
    state.filterOptionItems = payload;
  },
  [MutationTypes.SET_INDUSTRIES_FILTER_OPTIONS]: (state, payload) => {
    state.filterOptionItems = payload;
  },
  [MutationTypes.SET_TITLE_FUNCTIONS_FILTER_OPTIONS]: (state, payload) => {
    state.filterOptionItems = payload;
  },
  [MutationTypes.SET_TITLE_RANKS_FILTER_OPTIONS]: (state, payload) => {
    state.filterOptionItems = payload;
  },
};
