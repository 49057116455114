var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-brand" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("winbox-input", {
          class: _vm.status(_vm.$v.entity.name),
          attrs: { placeholder: "Brand Name", field: "brandName" },
          model: {
            value: _vm.$v.entity.name.$model,
            callback: function($$v) {
              _vm.$set(
                _vm.$v.entity.name,
                "$model",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "$v.entity.name.$model"
          }
        }),
        _vm._v(" "),
        _c("winbox-input", {
          class: _vm.status(_vm.$v.entity.description),
          attrs: { placeholder: "Description" },
          model: {
            value: _vm.$v.entity.description.$model,
            callback: function($$v) {
              _vm.$set(
                _vm.$v.entity.description,
                "$model",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "$v.entity.description.$model"
          }
        }),
        _vm._v(" "),
        _c("winbox-select", {
          class: _vm.status(_vm.$v.entity.industry),
          attrs: {
            options: _vm.possibleIndustries,
            clearable: true,
            multiple: true,
            placeholder: "Industry"
          },
          model: {
            value: _vm.entity.industry,
            callback: function($$v) {
              _vm.$set(_vm.entity, "industry", $$v)
            },
            expression: "entity.industry"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }