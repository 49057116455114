var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-sepciality-lists" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c("winbox-card", { attrs: { expandable: true } }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("winbox-speciality-tags-list", {
                  attrs: {
                    "possible-tags": _vm.possibleTags,
                    "entity-tags": _vm.selectedTags,
                    "add-create-support": false,
                    disabled: !_vm.isEditMode
                  },
                  on: {
                    changed: function($event) {
                      return _vm.onTagsChanged($event, "name")
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }