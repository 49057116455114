<template>
  <div class="wrap-industries-block">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="industries">
      <div class="columns">
        <div class="column">
          <winbox-speciality-tags
            :possible-tags="possibleIndustryTags"
            :entity-tags="selectedIndustryTags"
            :placeholder-possible="placeholderPossible"
            :placeholder-selected="placeholderSelected"
            :is-industry="true"
            prop-selected-parent-id="industry.id"
            @changed="onTagsChanged($event, 'name')"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxSpecialityTags from "@/components/Organisms/WinboxSpecialityTags";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

export default {
  name: "WinboxIndustries",
  mixins: [WinboxSpecialityTagsHelperMixin],
  components: {
    WinboxLargeIndicator,
    WinboxSpecialityTags
  },
  props: {
    orgType: {
      type: String,
      required: true,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      Colors: Colors,
      isLoaded: true,
      placeholderPossible: "Add one or more options",
      placeholderSelected: "Related industries"
    };
  },
  computed: {
    ...mapState([
      'industries',
      'brandIndustries',
      'businessIndustries',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return "Industries";
    },
    possibleIndustryTags() {
      return Utils.sortAlphabetic(this.industries, "name");
    },
    selectedIndustryTags() {
      if (this.orgType == "brand") {
        return Utils.sortAlphabetic(
          this.brandIndustries.map(item => {
            return {
              ...item,
              name: item.industry.name
            };
          }),
          "name"
        );
      }
      return Utils.sortAlphabetic(
          this.businessIndustries.map(item => {
            return {
              ...item,
              name: item.industry.name
            };
          }),
          "name"
        );
    }
  },
  methods: {
    ...mapActions([
      'createIndustry',
      'assignBrandIndustries',
    ]),
    createNewTagEntity(newTags) {
      const payload = newTags[0];
      this.$store.dispatch("createIndustry", payload);
    },
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        industry_ids: selectedTags.map((selectedTag) => selectedTag.id),
      };

      this.$store.dispatch("assignBrandIndustries", {
        id: this.id,
        payload: payload
      });
    },
  }
};
</script>

<style scoped lang="scss">
.industries {
  padding-top: 0.5rem;
  ::v-deep {
    .wrap-speciality-widget {
      .card-content {
        height: 100%;
        max-height: 600px;
      }
    }
  }
}
.main-title {
  margin: 0 0 1rem;
}
</style>
