<template>
  <div class="wrap-sidebar-menu" :class="{ 'with-separator': isProfilePage }">
    <ul class="menu-list">
      <div v-if="isAdmin()">
        <li
          class="menu-list-item has-tooltip-right"
          v-for="(item, index) in items"
          :key="index"
          :data-tooltip="item.name"
        >
          <router-link
            :exact="item.path === '/'"
            class="menu-link-item"
            :to="{ path: item.path }"
          >
            <winbox-icon class="menu-link-icon" :icon="item.icon" />
          </router-link>
        </li>
      </div>
      <div v-else>
        <li
          class="menu-list-item has-tooltip-right"
          v-for="(item, index) in items"
          v-show="item.name!='Data Import'"
          :key="index"
          :data-tooltip="item.name"
        >
          <router-link
            :exact="item.path === '/'"
            class="menu-link-item"
            :to="{ path: item.path }"
          >
            <winbox-icon class="menu-link-icon" :icon="item.icon" />
          </router-link>
        </li>
      </div>
    </ul>
    <winbox-search-by-menu 
      :searchPage="searchPage" 
      :filterOptions="filterOptions" 
      @open-popup-filter="openPopupFilter"
    />
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxSearchByMenu from "@/components/Organisms/WinboxSearchByMenu";
import { MainWinboxMenuItems } from "@/constants/MainWinboxMenuItems";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

export default {
  name: "WinboxSidebarMenu",
  mixins: [
    RolesMixin,
  ],
  components: {
    WinboxIcon,
    WinboxSearchByMenu,
  },
  props: {
    searchPage: {
      required: true,
      type: String,
    },
    filterOptions: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      items: [...MainWinboxMenuItems],
    };
  },
  computed: {
    isProfilePage() {
      return this.$store.getters.IS_PROFILE_PAGE;
    },
  },
  methods: {
    openPopupFilter(code, topPosition) {
      this.$emit('open-popup-filter', code, topPosition);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.wrap-sidebar-menu {
  padding: 10px 0 0 0;

  &.with-separator {
    .menu-link-item {
      &.router-link-active {
        &:before {
          background: $winbox-sidebar-active-bg-color;
          height: $winbox-sidebar-menu-item-width;
          width: 22px;
          position: fixed;
          content: "";
          margin-left: 27px;
          margin-top: 0;
        }
      }
    }
  }
}
.menu-list {
  position: fixed;
  z-index: 5;
  padding-top: 60px;
  width: $winbox-nav-width;
}
.menu-list-item {
  margin: 0 0 0.5rem;

  .menu-link-item {
    margin: 0 auto;
    width: $winbox-sidebar-menu-item-width;
    height: $winbox-sidebar-menu-item-width;
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    border-radius: $winbox-border-radius;

    .menu-link-icon {
      opacity: 0.7;
    }

    &:hover,
    &.router-link-active {
      background: $winbox-sidebar-active-bg-color;
      .menu-link-icon {
        opacity: 1;
      }
    }
  }
}
</style>
