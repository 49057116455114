import { mapActions, mapState } from "vuex";
import cloneDeep from "clone-deep";

export const EditProfileMixin = {
  data() {
    return {
      initData: null,
      dataUpdate: {}
    };
  },
  watch: {
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'isEditMode',
      'isChanged',
      'isSaving',
      'isCanceling',
    ]),
  },
  methods: {
    ...mapActions([
      'edit',
      'change',
      'cancel',
      'save',
    ]),
    editEntity() {
      this.edit(true);
    },
    editCancel() {
      if (this.isChanged) {
        if (confirm("Are you sure you want to reset this changes?")) {
          this.entity = cloneDeep(this.initData);
          this.dataUpdate = {};
          setTimeout(() => {
            this.edit(false);
          }, 100);
        }
        else {
          this.cancel(false);
        }
      }
      else {
        this.edit(false);
      }
    },
    editAccept() {
      if (confirm("Are you sure you want to save this changes?")) {
        this.saveEntity();
      }
      else {
        this.save(false);
      }
    },
    changed() {
      for (const property in this.dataUpdate) {
        if (JSON.stringify(this.dataUpdate[property]) != JSON.stringify(this.initData[property])) {
          this.change(true);
          return;
        }
      }
      this.change(false);
    },
    addToDataUpdate(key, val) {
      if (key.indexOf(".") === -1) {
        this.dataUpdate[key] = val;
      } else {
        const props = key.split(".");
        if (!this.dataUpdate[props[0]]) {
          this.dataUpdate[props[0]] = {};
        }
        this.dataUpdate[props[0]][props[1]] = val;
      }

      if (val && this.$v && this.$v.entity && this.$v.entity[key]) {
        this.$v.entity[key].$reset();
      }
      this.changed();
    },
  },
};
