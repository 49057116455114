var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-title" }, [
    _vm.type === "big"
      ? _c("h1", { staticClass: "title", attrs: { title: _vm.title } }, [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "medium"
      ? _c("h2", { staticClass: "subtitle", attrs: { title: _vm.title } }, [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }