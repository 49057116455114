import * as EntitiesApi from "@/api/entities/entities";
import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
	getLogo({ commit }, { owner_id, owner_type }) {
	  return EntitiesApi.getLogo(owner_id, owner_type).then(({ data }) => {
      commit(MutationTypes.SET_ENTITY_LOGO, data.logo_url);
    });
	},

	updateLogo({ commit }, { logo_url, owner_type, owner_id }) {
	  return EntitiesApi.updateLogo(logo_url, owner_type, owner_id).then(({ data }) => {
      commit(MutationTypes.SET_ENTITY_LOGO, data.logo_url);
    });
	},
};
