import { render, staticRenderFns } from "./WinboxTabItem.vue?vue&type=template&id=650b3b0c&scoped=true&functional=true"
import script from "./WinboxTabItem.vue?vue&type=script&lang=js"
export * from "./WinboxTabItem.vue?vue&type=script&lang=js"
import style0 from "./WinboxTabItem.vue?vue&type=style&index=0&id=650b3b0c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "650b3b0c",
  null
  
)

export default component.exports