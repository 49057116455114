var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-task-completion" },
    [
      _c("winbox-card", { staticClass: "item-overview" }, [
        _c(
          "div",
          { staticClass: "item-title" },
          [
            _c("winbox-title", {
              attrs: { title: "My Completed Work", type: "medium" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item-chart" }, [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("winbox-progress-chart", {
                  attrs: { "completed-steps": _vm.value.progress }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column" }, [
              _c(
                "div",
                { staticClass: "item-status" },
                [
                  _c("winbox-icon", {
                    staticClass: "item-status-icon",
                    attrs: { icon: "award", color: _vm.Colors.draggableIcon }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "main-status" }, [
                    _vm._v(_vm._s(_vm.progressMessage(_vm.value.progress)))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "additional-status" }, [
                    _vm._v(_vm._s(_vm.currentStatus))
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }