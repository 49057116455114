import axios from "axios";
import vueCookie from "vue-cookie";
import { pick } from "lodash";
import { BaseUrl } from "@/constants/BaseUrl";
import { MutationTypes } from "@/constants/MutationTypes";

/**
 * API instance
 * @type {AxiosInstance}
 */
export const Http = axios.create({
  baseURL: BaseUrl.api
});

Http.interceptors.request.use(function(config) {
  if (window.winboxApp && window.winboxApp.$store) {
    const headers = window.winboxApp.$store.getters.AUTH;
    config.headers = headers;

    if (config && config.method !== "get") {
      window.winboxApp.$store.commit(MutationTypes.SHOW_LOADER);
    }
  }
  return config;
});

Http.interceptors.response.use(
  function(response) {
    if (response.headers["access-token"]) {
      saveAuthInfo(response);
    }

    if (window.winboxApp && window.winboxApp.$store) {
      window.winboxApp.$store.commit(MutationTypes.HIDE_LOADER);
      window.winboxApp.$store.commit(MutationTypes.BLUR_ACTIVE_ELEMENT);
    }
    return response;
  },
  function(error) {
    if (window.winboxApp && window.winboxApp.$store) {
      window.winboxApp.$store.commit(MutationTypes.HIDE_LOADER);
    }
    return Promise.reject(error);
  }
);

function saveAuthInfo(response) {
  const authHeaders = pick(response.headers, [
    "access-token",
    "client",
    "expiry",
    "uid",
    "token-type"
  ]);

  window.winboxApp.$store.commit(MutationTypes.SET_AUTH, authHeaders);
  let session = vueCookie.get("session");

  if (session) {
    session = JSON.parse(session);
    session["tokens"] = authHeaders;

    vueCookie.set("session", JSON.stringify(session), { expires: "1D" });
  }
}
