import { render, staticRenderFns } from "../../Pages/Generic/EntityTagsMixin.html?vue&type=template&id=913e638a&scoped=true&external"
import script from "./AgencyTags.vue?vue&type=script&lang=js"
export * from "./AgencyTags.vue?vue&type=script&lang=js"
import style0 from "../Generic/EntityTagsMixin.scss?vue&type=style&index=0&id=913e638a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "913e638a",
  null
  
)

export default component.exports