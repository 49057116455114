<template>
  <div class="winbox-progress-chart">
    <radial-progress-bar
      :diameter="diameter"
      :completed-steps="completedSteps"
      :total-steps="totalSteps"
      :start-color="startColor"
      :stop-color="stopColor"
      :inner-stroke-color="innerStrokeColor"
      :stroke-width="strokeWidth"
    ></radial-progress-bar>
    <div v-if="showLabels" class="winbox-progress-chart-labels">
      <div class="progress-value">{{ completedSteps }}%</div>
      <div class="progress-status">Completed</div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from "vue-radial-progress/dist/vue-radial-progress.min";
import { Colors } from "@/Colors";

export default {
  name: "WinboxProgressChart",
  components: { RadialProgressBar },
  data() {
    return {
      Colors: Colors
    };
  },
  props: {
    showLabels: {
      required: false,
      default: true
    },
    completedSteps: {
      required: true,
      type: Number
    },
    totalSteps: {
      required: false,
      default: 100,
      type: Number
    },
    diameter: {
      required: false,
      default: 220,
      type: Number
    },
    startColor: {
      required: false,
      default: Colors.progress,
      type: String
    },
    stopColor: {
      required: false,
      default: Colors.progress,
      type: String
    },
    innerStrokeColor: {
      required: false,
      default: Colors.progressBackground,
      type: String
    },
    strokeWidth: {
      required: false,
      default: 18,
      type: Number
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.winbox-progress-chart {
  position: relative;
}
.winbox-progress-chart-labels {
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  height: 220px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 21px;

  .progress-value {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 0.5rem;
  }
  .progress-status {
    font-size: 21px;
    color: $winbox-secondary-title;
  }
}
</style>
