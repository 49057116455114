import { Http } from "../index";

export function getGeneric(url) {
  return Http.get(url)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

export function getGenericFullResponse(url) {
  return Http.get(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}

export function postGeneric(url, data) {
  return Http.post(url, data)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

export function postGenericFullResponse(url, data) {
  return Http.post(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}

export function putGeneric(url, data) {
  return Http.put(url, data)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

export function putGenericFullResponse(url, data) {
  return Http.put(url, data)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}

export function deleteGenericFullResponse(url) {
  return Http.delete(url)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}
