<template>
  <div class="wrap-revenue-media">
    <div v-if="!entities.length">No items</div>
    <ul v-if="entities.length" class="revenue-list">
      <li v-for="item in entities" :key="item.id">
        <div class="columns is-variable is-1 is-vcentered">
          <div class="column is-2">
            <winbox-select
              v-model="item.year"
              :options="years"
              :disabled="disabled"
              @input="change(true)"
              placeholder="Year"
            />
          </div>
          <div class="column is-9">
            <winbox-input
              placeholder="Revenue Value"
              v-model.trim="item.revenues"
              v-currency="{}"
              :disabled="disabled"
              @input="change(true)"
            />
          </div>
          <div class="column column-remove-entity">
            <button
              :disabled="disabled"
              class="button button-delete icon-wrapper"
              v-if="!disabled"
            >
              <winbox-icon
                icon="trash"
                :color="Colors.system.error"
                @click.native="removeEntity(item)"
              />
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import cloneDeep from "clone-deep";

export default {
  name: "WinboxRevenue",
  components: {
    WinboxIcon,
    WinboxInput,
    WinboxSelect
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      Colors: Colors,
      entities: cloneDeep(this.items),
      currentYear: new Date().getFullYear(),
      entityCount: 0,
    };
  },
  watch: {
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'socials',
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
    years() {
      return Utils.prepareDefaultYearsFrom(this.currentYear);
    },
  },
  methods: {
    ...mapActions([
      'change',
    ]),
    removeEntity(item) {
      this.entities = this.entities.filter((entity) => entity.id != item.id);
      this.change(true);
    },
    addNewEntity() {
      this.entities = [...this.entities, {id: this.entityCount++, year: '', revenues: ''}];
    },
    editCancel() {
      this.entities = cloneDeep(this.items);
    },
    editAccept() {
      this.$emit("changed", this.entities);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.revenue-list {
  .icon-wrapper {
    @include icon-wrapper-base-style;
  }
  li {
    @include button-delete-universal-hover;
  }
}
.column-remove-entity {
  padding: $winbox-default-column-padding 0;
  text-align: center;
}
.button-delete {
  @include button-delete-universal;
}
</style>
