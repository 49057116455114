import { render, staticRenderFns } from "./WinboxDisciplinesList.vue?vue&type=template&id=162d7416&scoped=true"
import script from "./WinboxDisciplinesList.vue?vue&type=script&lang=js"
export * from "./WinboxDisciplinesList.vue?vue&type=script&lang=js"
import style0 from "./WinboxDisciplinesList.vue?vue&type=style&index=0&id=162d7416&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162d7416",
  null
  
)

export default component.exports