import { render, staticRenderFns } from "./WinboxNicknames.vue?vue&type=template&id=daf55f74&scoped=true"
import script from "./WinboxNicknames.vue?vue&type=script&lang=js"
export * from "./WinboxNicknames.vue?vue&type=script&lang=js"
import style0 from "./WinboxNicknames.vue?vue&type=style&index=0&id=daf55f74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daf55f74",
  null
  
)

export default component.exports