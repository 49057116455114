<template>
  <div class="brand-agencies-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="brand-agencies">
      <winbox-company-header
        :title="mainTitle"
        entity-icon="link"
        entity-label="Link Agencies"
        :enable-status-toggle="true"
        @toggle-status="updateVisibleStatus"
        @changed="openLinkModal"
      />
      <div class="columns">
        <div class="column">
          <winbox-data-table
            class="main-data-table"
            :data="visibleData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :data-tooltip="data.row.agency_id"
                :to="{ path: constructAgencyUrl(data.row.agency_id) }"
              >
                {{ data.row[tableProps.name] }}
              </router-link>
            </template>
            <template :slot="tableProps.services" slot-scope="data">
              {{ data.row.isAOR ? data.row[tableProps.services].length > 0  ? 'AOR,' : 'AOR' : '' }}
              {{
                data.row[tableProps.services]
                  .map(a => findServiceNameById(a.id))
                  .join(", ")
              }}
            </template>
            <template :slot="tableProps.relationshipProof" slot-scope="data">
              {{ data.row[tableProps.relationshipProof] }}
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <winbox-select
                :value="data.row[tableProps.status]"
                :options="possibleStatuses"
                :searchable="false"
                @input="onStatusChange(data.row, $event)"
              />
            </template>
            <template :slot="tableProps.options" slot-scope="data">
              <winbox-toggle-menu :options="rowOptions" :data="data.row" />
            </template>
          </winbox-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="linkModal"
      :name="linkModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      width="1000px"
      height="600px"
      class="winbox-modal-scrollable"
      title="Link Agencies"
      :save-text="saveModalText"
      @on-cancel="onCancelLinkModal"
      @on-save="onSaveLinkModal"
      @enter-press="onSaveLinkModal"
      @before-close="resetLinkNextStep"
    >
      <winbox-add-relationships-remote
        ref="addRelationships"
        :url="getAgencyExceptByBrandUrl()"
        :config="linkConfig"
        :responseFunction="responseFunction"
      />
    </winbox-modal>
    <winbox-modal
      :ref="linkEditModal"
      :name="linkEditModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      width="800px"
      height="300px"
      title="Edit"
      save-text="Edit"
      @on-cancel="onEditCancel"
      @on-save="onEditSave"
    >
      <winbox-relation-ship-items
        v-if="editEntity"
        :value="$v.editEntity"
        @change="onEditEntityChange"
      />
      <div v-if="editEntity" class="columns">
        <div class="column is-6">
          <winbox-select
            v-if="possibleLocations.length >= 1"
            placeholder="Location"
            label="name"
            v-model.trim="$v.editEntity.location.$model"
            :class="status($v.editEntity.location)"
            :options="possibleLocations"
            :disabled="possibleLocations.length == 1"
            :emptyText="emptyText"
          />
          <winbox-input
            v-else
            placeholder="Location"
            :readonly="true"
            :value="emptyText"
          />
        </div>
        <div class="column is-6">
          <button
            class="button action-button"
            :class="{ 'is-primary': $v.editEntity.is_aor.$model }"
            @click="toggleAOR"
          >
            <input
              type="checkbox"
              :checked="$v.editEntity.is_aor.$model"
            />
            <span>AOR</span>
          </button>
        </div>
      </div>
    </winbox-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { WinboxCreateBusinessEntityHelperMixin } from "@/components/Organisms/WinboxCreateBusinessEntityHelperMixin";
import { EntityRelationshipsRemoteMixin } from "@/components/Pages/Generic/EntityRelationshipsRemoteMixin";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { BusinessStatuses } from "@/constants/BusinessStatuses";

const tableProps = {
  name: "name",
  services: "services",
  relationshipProof: "relationshipProof",
  status: "status",
  options: "__options"
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "BrandAgencies",
  components: {
    WinboxInput,
    WinboxSelect,
  },
  mixins: [
    WinboxCreateBusinessEntityHelperMixin,
    EntityRelationshipsRemoteMixin
  ],
  data() {
    return {
      entity: null,
      visibileStatus: 'Active',
      Colors: Colors,
      isLoaded: true,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Agency",
          [tableProps.services]: "Service",
          [tableProps.relationshipProof]: "Last Proof",
          [tableProps.status]: "Status",
          [tableProps.options]: ""
        },
        sortable: [
          tableProps.name,
          tableProps.services,
          tableProps.status,
          tableProps.relationshipProof,
        ],
        filterable: [
          tableProps.name,
          tableProps.services,
          tableProps.status,
          tableProps.relationshipProof,
        ]
      },
      specificSlots: [
        tableProps.name,
        tableProps.services,
        tableProps.relationshipProof,
        tableProps.status,
        tableProps.options
      ]
    };
  },
  computed: {
    ...mapState([
      'brandBusinesses',
      'brandBusinessRelationships',
      'users',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Agencies (${this.visibleData.length})`;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    dataset() {
      return this.brandBusinessRelationships.map(item => {
          return {
            ...item,
            [tableProps.name]: item.agency && item.agency.name ? item.agency.name : "",
            [tableProps.status]: BusinessStatuses[item.status],
            isAOR: item.is_aor,
            [tableProps.relationshipProof]: `${this.getUserNameById(item.proofed_by)} - ${this.formatDate(item.proofed_at)}`
          };
        });
    },
    datasetRelationshipsMain() {
      return this.$store.getters.AGENCIES;
    },
    emptyText() {
      if (this.possibleLocations.length == 0) {
        return `Please make sure that ${this.editEntity.agency.name} has a location`;
      }
      return "Sorry, no matching options.";
    },
    activeData() {
      return this.dataset.filter((data) => data.status.value === 'active');
    },
    inactiveData() {
      return this.dataset.filter((data) => data.status.value === 'inactive');
    },
    visibleData() {
      if(this.visibileStatus === 'Active') {
        return this.activeData;
      }
      else {
        return this.inactiveData;
      }
    },
  },
  beforeMount() {
    this.linkConfig.tableOptions.headings.name = "Agency";
    this.getData();
  },
  methods: {
    ...mapActions([
      'getBrandBusinessRelationships',
      'getUsers'
    ]),
    async getData() {
      this.isLoaded = false;

      await this.getBrandBusinessRelationships(this.id);
      await this.getUsers();

      this.isLoaded = true;
    },
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    onStatusChange(row, e) {
      const item = {
        id: row.id,
        business_id: row.business_id
      };
      const payload = {
        status: e.value
      };
      this.callUpdateDispatch(item, payload);
    },
    saveNewRelationship(item) {
      const payload_data = {
        brand_ids: [this.id],
        agency_id: item.id,
        service_ids: item.services.map(a => a.id),
        media_type_ids: item.mediaTypes?.map(a => a.id),
        agency_location_id: item.location.id,
        status: item.status.value,
        is_aor: item.is_aor,
      };
      const payload = {
        business_relationship: payload_data
      };

      return this.$store.dispatch("createBrandBusinessRelationship", {
        id: this.id,
        businessId: this.brandBusinesses.id,
        payload: payload
      });
    },
    updateRelationship(item) {
      const payload = {
        business_relationship: {
          service_ids: item.services.map(a => a.id),
          media_type_ids: item.mediaTypes?.map(a => a.id),
          agency_location_id: item.location.id,
          is_aor: item.is_aor,
        }
      };

      this.callUpdateDispatch(item, payload);
    },
    callUpdateDispatch(item, payload) {
      return this.$store.dispatch("updateBrandBusinessRelationship", {
        id: this.id,
        businessId: item.business_id,
        relationshipId: item.id,
        payload
      });
    },
    constructAgencyUrl(id) {
      return Utils.getAgencyProfileUrl(id);
    },
    getAgencyExceptByBrandUrl() {
      return Utils.getAgencyExceptByBrandUrl(this.id);
    },
    responseFunction(data, count) {
      this.totalCount = count;
      return data.map((item) => {
        return {
          ...item,
          ["location"]: Utils.getBusinessLocationsTitle(item.location),
        };
      });
    },
    toggleAOR() {
      if(this.$v.editEntity.is_aor.$model === undefined) {
        this.$set(this.editEntity, 'is_aor', true);
      }
      else {
        this.$v.editEntity.is_aor.$model = !this.$v.editEntity.is_aor.$model;
      }
    },
    getUserNameById(userId) {
      const user = this.users.find(user => user.id === userId);
      return user ? `${user.first_name} ${user.last_name}` : '';
    },
    formatDate(timestamp) {
      if (!timestamp) return "";
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US');
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.brand-agencies {
  @include agency-brand-relationships;

  ::v-deep {
    .main-data-table {
      font-size: 13px;
      thead {
        th {
          /* Status */
          &:nth-child(5) {
            width: 150px;
          }
        }
      }
      tbody {
        td[colspan="6"] {
          text-align: left !important;
        }
      }
    }
  }
}
.action-button {
  @include profile-action-button;
}
</style>
