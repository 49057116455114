var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "toggleMenu",
      staticClass: "toggle-menu",
      class: { visible: _vm.isVisible }
    },
    [
      _c(
        "button",
        {
          staticClass: "button action-button",
          on: { click: _vm.toggleVisibility }
        },
        [
          _c("winbox-icon", {
            attrs: {
              icon: "ellipsis-v",
              color: _vm.isVisible
                ? _vm.Colors.system.primaryLight
                : _vm.mainIconColor
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isVisible
        ? _c("div", { staticClass: "toggle-menu-modal" }, [
            _c(
              "ul",
              {
                on: {
                  click: function($event) {
                    _vm.isVisible = false
                  }
                }
              },
              _vm._l(_vm.options, function(option) {
                return _c(
                  "li",
                  {
                    key: option.id,
                    style: [option.style],
                    attrs: {
                      title: option.title,
                      "data-tooltip": option.dataToltipTitle
                    },
                    on: {
                      click: function($event) {
                        return option.action.call(null, _vm.data)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "menu-item-icon" },
                      [
                        _c("winbox-icon", {
                          attrs: {
                            icon: option.icon,
                            color: option.iconColor
                              ? option.iconColor
                              : _vm.Colors.system.primaryLight
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "item-text",
                        style: { color: option.iconColor }
                      },
                      [_vm._v(_vm._s(option.title))]
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }