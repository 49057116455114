<template>
  <div
    class="control winbox-input"
    :class="{
      'has-icons-left': iconLeft,
      'has-icons-right': iconRight,
      'has-label': !!label
    }"
  >
    <label class="label winbox-label" v-if="label"
      ><span>{{ label }}</span></label
    >
    <input
      class="input"
      :type="type"
      v-model="editedText"
      :placeholder="placeholder"
      :title="title"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :min="min"
      ref="inputRef"
      @input="emit"
      @focus="onFocus"
      @blur="onBlur"
      v-mask="mask"
      autocomplete="nope"
    />
    <div v-if="maxLengthExceeded" class="max-length-message">
      Max character limit reached
    </div>
    <winbox-icon
      class="icon-box"
      v-if="iconLeft"
      :icon="iconLeft"
      :namespace="iconLeftNamespace"
      :color="Colors.inputIcon"
      container-class="is-small is-left"
    />
    <winbox-icon
      class="icon-box"
      v-if="iconRight"
      :icon="iconRight"
      :namespace="iconRightNamespace"
      :color="Colors.inputIcon"
      container-class="is-small is-right"
    />
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import Lightpick from "lightpick";
import moment from "moment";
import { Colors } from "@/Colors";
import { DateFormats } from "@/constants/DateFormats";

export default {
  name: "WinboxInput",
  components: { WinboxIcon },
  props: {
    type: {
      required: false,
      default: "text",
      type: String
    },
    datePicker: {
      required: false,
      default: false,
      type: Boolean
    },
    dateRange: {
      required: false,
      default: false,
      type: Boolean
    },
    datePickerFormat: {
      required: false,
      default: DateFormats.BASE,
      type: String
    },
    iconLeft: {
      required: false,
      type: String
    },
    iconLeftNamespace: {
      required: false,
      type: String
    },
    iconRight: {
      required: false,
      type: String
    },
    iconRightNamespace: {
      required: false,
      type: String
    },
    preText: {
      required: false,
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      required: false,
      type: String,
      default: ""
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    title: {
      required: false,
      type: String,
      default: null
    },
    value: {
      required: false,
      type: [String, Number],
      default: "",
      note: "v-model"
    },
    field: {
      required: false,
      type: [String],
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false
    },
    maxlength: {
      required: false,
      type: [Number, String],
      default: 999
    },
    min: {
      required: false,
      type: [Boolean, Number],
      default: null
    },
    maxDate: {
      required: false,
      type: String,
      default: null
    },
    mask: {
      required: false,
      type: String,
      default: null
    },
    orientation: {
      required: false,
      type: String,
      default: "auto"
    }
  },
  computed: {
    maxLengthExceeded() {
      if (this.field === 'website') {
        return this.value?.length > 40;
      }
      else if (this.field === 'brandWebsite') {
        return this.value?.length > 200;
      }
      else if (this.field === 'brandName') {
        return this.value?.length > 75;
      }
      else if (this.field === 'name') {
        return this.value?.length > 100;
      }
      else if (this.field === 'locationName') {
        return this.value?.length > 50;
      }
      else {
        return false;
      }
    }
  },
  data() {
    return {
      editedText: "",
      Colors: Colors,
      datePickerInstance: null
    };
  },
  watch: {
    preText: {
      handler: function() {
        this.editedText = this.preText;
      }
    },
    value() {
      this.editedText = this.value;
    }
  },
  created() {
    this.editedText = this.preText || this.value;
  },
  mounted() {
    if (this.datePicker) {
      this.goAsDatePicker();
    }
  },
  methods: {
    emit() {
      this.$emit("updated", this.editedText);
      this.$emit("input", this.editedText);
    },
    onFocus() {
      this.$emit("focus", this.editedText);
    },
    onBlur() {
      this.$emit("blur", this.editedText);
    },
    goAsDatePicker() {
      let lightpickParams = {};
      lightpickParams.singleDate = !this.dateRange;
      lightpickParams.field = this.$refs.inputRef;
      lightpickParams.format = this.datePickerFormat;
      lightpickParams.orientation = this.orientation;
      if (this.dateRange) {
        lightpickParams.onSelect = (startDate, endDate) => {
          const startStr = startDate.format(this.datePickerFormat);
          const endStr = (!!endDate ? endDate.format(this.datePickerFormat) : "");
          this.editedText = startStr + " - " + endStr;
          this.emit();
        };
      } else {
        lightpickParams.onSelect = date => {
          this.editedText = date.format(this.datePickerFormat);
          this.emit();
        };
      }
      lightpickParams.onOpen = date => {
        this.$emit(
          "datepickerShow",
          date ? date.format(this.datePickerFormat) : null
        );
      };

      if (this.maxDate) {
        lightpickParams.maxDate = moment(this.maxDate, this.datePickerFormat);
      }
      this.datePickerInstance = new Lightpick(lightpickParams);
      this.datePickerInstance.setDate(this.editedText);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-label {
  @include form-input-label;
}
.winbox-input {
  &.invalid,
  &.error {
    .input {
      border-color: $winbox-error;
      background: $winbox-icon-negative-background;
      &:focus {
        outline: $winbox-icon-negative-background;
      }
    }
    .winbox-label {
      span {
        background: $winbox-error;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
}
.input {
  font-size: 14px;
  &::placeholder {
    font-size: $winbox-font-size-small;
  }
}
.has-label {
  .input {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    box-shadow: none;
  }
}
.icon-box {
  height: 100% !important;
}
.max-length-message {
  color: red;
  font-size: 0.8rem;
}
</style>
