<template>
  <div class="contact-title-functions">
    <winbox-card>
      <div class="content">
        <winbox-contact-title-functions-list
          :possible-tags="possibleTags"
          :entity-tags="selectedTags"
          :placeholder-selected="placeholderSelected"
          prop-selected-parent-id="title_function_id"
          @changed="onTagsChanged($event, 'name')"
          :disabled="!isEditMode"
        />
      </div>
    </winbox-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxContactTitleFunctionsList from "@/components/Organisms/WinboxContactTitleFunctionsList";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";
import * as TitleFunctionsApi from "@/api/title-functions/title-functions";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

export default {
  name: "ContactTitleFunctions",
  mixins: [WinboxSpecialityTagsHelperMixin],
  components: {
    WinboxCard,
    WinboxContactTitleFunctionsList,
  },
  data() {
    return {
      entity: {},
      Colors: Colors,
      placeholderSelected: "Assigned Functions",
      organizationEdited: false,
    };
  },
  mounted() {
    this.$emit('mounted');
    this.$root.$on('changeContactOrganization', (data) => {
      this.organizationEdited = true;
    });

    this.$root.$on('changeContactOrganizationCompleted', (data) => {
      this.organizationEdited = false;
    })
  },
  computed: {
    ...mapState([
      'isEditMode',
      'titleFunctions',
      'personTitleFunctions',
    ]),
    id() {
      return +this.$route.params.id;
    },
    possibleTags() {
      return this.titleFunctions.map(item => {
          return { ...item, name: item.function };
        });
    },
    selectedTags() {
      if (this.organizationEdited) {
        return [];
      }

      return Utils.sortAlphabetic(
        this.personTitleFunctions.map(item => {
          return {
            ...item,
            name: this.findItemNameFromTags(item.title_function_id)
          };
        }),
        "name"
      );
    }
  },

  methods: {
    ...mapActions([
      'updatePersonTitleFunctionList',
    ]),
    findItemNameFromTags(tagId) {
      const found = this.possibleTags.find(item => item.id === tagId);
      return found ? found.function : "";
    },
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        title_function_ids: selectedTags.map((selectedTag) => selectedTag.title_function_id),
      };

      this.updatePersonTitleFunctionList({ id: this.id, payload });
    },
    updatePossibleTitleFunctions(orgType) {
      TitleFunctionsApi.getAll(orgType);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-title-functions {
  ::v-deep {
    .winbox-card {
      .card-header {
        background: $winbox-table-header;
      }
      .card-header-title {
        + .card-header-icon {
          display: none;
        }
      }
    }
  }
}
</style>
