<template>
  <div class="winbox-select" :class="[theme, { 'has-label': !!formLabel }]">
    <label class="label winbox-label" v-if="formLabel"
      ><span>{{ formLabel }}</span></label
    >
    <v-select
      :options="options"
      :filterable="false"
      :disabled="disabled"
      :clearable="clearable"
      :searchable="searchable"
      :multiple="multiple"
      :placeholder="placeholder"
      :transition="transition"
      :label="label"
      :taggable="taggable"
      :value="selected"
      @search="onSearch"
      @input="onInput"
    >
      <div slot="no-options">{{ emptyText }}</div>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "WinboxRemoteSelect",
  components: { vSelect },
  data() {
    return {
      selected: [],
      options: [],
    };
  },
  created() {
    this.selected = this.value;
  },
  mounted() {},
  props: {
    theme: {
      default: "default-theme",
    },
    value: {
      default: null,
    },
    formLabel: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    transition: {
      type: String,
      default: "fade",
    },
    label: {
      type: String,
      default: "label",
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    minSearchLength: {
      type: Number,
      default: 1,
    },
    dropdownShouldOpen: {
      type: Function,
      default({noDrop, open, mutableLoading}) {
      return noDrop ? false : open && !mutableLoading;
      }
    },
    emptyText: {
      type: String,
      default: "Search and choose a Company or Agency for this Contact.",
    },
    responseFunction: Function,
    url: String
  },
  methods: {
    onInput(e) {
      this.selected = e;
      this.$emit("input", this.selected);
    },
    onSearch(search, loading) {
      if (search.length >= this.minSearchLength) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      const encodedSearch = encodeURIComponent(search);
      fetch(`${vm.url}${encodedSearch}`, {
        headers: window.winboxApp.$store.getters.AUTH
      })
      .then((res) => {
        res.json().then((json) => (vm.options = vm.responseFunction(json)));
        loading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        loading(false);
      });
    }, 350),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-select {
  position: relative;

  .winbox-label {
    @include form-input-label;
  }

  &.has-label {
    ::v-deep {
      .vs__dropdown-toggle {
        padding: 5px 0 6px 0;
      }
    }
  }

  &.error {
    ::v-deep {
      .vs__dropdown-toggle {
        border-color: $winbox-error;
        background: $winbox-icon-negative-background;
      }
    }
    .winbox-label {
      span {
        background: $winbox-error;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
  ::v-deep {
    .vs__dropdown-menu {
      min-width: auto;
    }
    .vs__search {
      &::placeholder {
        font-size: $winbox-font-size-small;
        color: $winbox-input-placeholder;
      }
    }
    .vs__dropdown-option--highlight {
      background: $winbox-primary-active-bg-color;
    }
    .vs__dropdown-option {
      padding: 3px 15px;
    }
  }

  &.green-theme {
    ::v-deep {
      .vs__dropdown-toggle {
        background: $winbox-primary-active-bg-color;
        border-color: transparent;
        color: #fff;
      }
      .vs__search,
      .vs__search:focus,
      .vs__selected {
        color: #fff;
      }
      .vs__open-indicator {
        fill: #fff;
      }
    }
  }
}
</style>
