<template>
  <div class="company-agency-contacts-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="company-agency-contacts">
<!--       <winbox-company-header
        :title="mainTitle"
        @changed="openModal"
        entity-secondary-label="Link Contacts"
        entity-secondary-icon="link"
        @changedSecondary="openLinkModal"
      /> -->
      <winbox-company-header
        :title="mainTitle"
        :enable-status-toggle="true"
        @toggle-status="updateVisibleStatus"
      />
      <div class="columns">
        <div class="column">
          <winbox-data-table
            class="main-data-table"
            :data="visibleData"
            :columns="columns"
            :options="tableOptions"
            :enable-custom-filtering="false"
            :slots="specificSlots"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :to="{ path: constructContactProfileUrl(data.row.person.id) }"
              >
                {{ data.row.person.full_name }}
              </router-link>
            </template>
            <template :slot="tableProps.agency" slot-scope="data">
              <router-link
                :to="{ path: constructAgencyUrl(data.row.agency.id) }"
              >
                {{ data.row.agency.name }}
              </router-link>
            </template>
            <template :slot="tableProps.email" slot-scope="data">
              <a :href="constructEmailLink(data.row.person[tableProps.email])">
                {{ data.row.person[tableProps.email] }}
              </a>
            </template>
            <template :slot="tableProps.options" slot-scope="data">
              <button
                v-if="data.row.id != -1"
                class="button button-remove"
                @click="deleteRelationship(data.row)"
              >
                <winbox-icon icon="trash" :color="Colors.system.error" />
              </button>
            </template>
          </winbox-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="createModal"
      :name="createModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Create new contact"
      save-text="Create"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <winbox-create-contact
        ref="createContactRef"
        v-if="newContactEntity && isCreateNewContactLoaded"
        :data="newContactEntity"
        :backend-errors="backendErrors"
        :possible-orgs="possibleOrgs"
      />
      <winbox-large-indicator v-if="!isCreateNewContactLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="linkModal"
      :name="linkModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      class="winbox-modal-scrollable"
      width="1000px"
      height="600px"
      title="Link Contacts"
      :save-text="saveModalText"
      @on-cancel="onCancelLinkModal"
      @on-save="onSaveLinkModal"
      @enter-press="onSaveLinkModal"
      @before-close="resetLinkNextStep"
    >
      <winbox-add-brand-contact
        ref="addBrandContact"
        :data="datasetRelationshipsMain"
        :config="linkConfig"
      />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxCreateContact from "@/components/Organisms/WinboxCreateContact";
import { WinboxCreateContactHelperMixin } from "@/components/Organisms/WinboxCreateContactHelperMixin";
import { WinboxLinkContactHelperMixin } from "@/components/Organisms/WinboxLinkContactHelperMixin";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

import * as BusinessesApi from "@/api/businesses/businesses";
import * as CompaniesApi from "@/api/companies/companies";

const tableProps = {
  name: "name",
  agency: "agency",
  email: "email",
  options: "__options"
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "CompanyAgencyContacts",
  components: {
    WinboxLargeIndicator,
    WinboxDataTable,
    WinboxCompanyHeader,
    WinboxModal,
    WinboxCreateContact,
    WinboxIcon,
  },
  mixins: [WinboxCreateContactHelperMixin, WinboxLinkContactHelperMixin],
  data() {
    return {
      Colors: Colors,
      isLoaded: false,
      visibileStatus: 'Active',
      businessEntity: {},
      locationsData: [],
      agenciesData: [],
      columns: [...mainTableColumns],
      tableProps: tableProps,
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.agency]: "Agency",
          [tableProps.email]: "Email",
          [tableProps.options]: ""
        },
        sortable: [
          tableProps.name,
          tableProps.agency,
          tableProps.title,
          tableProps.email,
        ],
        filterable: [
          tableProps.name,
          tableProps.agency,
          tableProps.title,
          tableProps.email,
        ]
      },
      specificSlots: [tableProps.name, tableProps.agency, tableProps.email, tableProps.options],
      linkConfig: {
        tableProps: {
            name: "name",
            email: "email",
            linked: "linked"
        },
        specificSlots: ["linked"],
        tableOptions: {
            headings: {
                name: "Name",
                email: "Email",
                linked: ""
            },
            sortable: [],
            filterable: ["name", "title"]
        },
        multiselect: true,
      }
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapState([
      'businessAgencyContacts',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Agency Contacts (${this.visibleData.length})`;
    },
    dataset() {
      return this.businessAgencyContacts;
    },
    possibleLocations() {
      return this.locationsData.map(item => {
        return {
          id: item.id,
          label: item.city,
          value: item.id
        };
      });
    },
    possibleOrgs() {
      return this.agenciesData.map(item => {
        return {
          id: item.id,
          label: item.name,
          value: item.id
        };
      });
    },
    datasetRelationshipsMain() {
      let result = [];

      let companyAgencies = this.businessRelationships;
      if (companyAgencies && companyAgencies.length) {
        companyAgencies.forEach(agency => {
          if (agency.agency_id) {
            let agencyPersons = this.$store.getters.BUSINESS_PROFILE_CONTACTS_BY_ID(agency.agency_id);
            let unselectedContacts = agencyPersons.filter(contact => {
              return this.dataset.filter( item => item.id === contact.id ).length === 0
            })
            unselectedContacts.forEach((person) => {
              person["name"] = `${person.fname} ${person.lname}`;
              person.linked = false;
              result.push(person);
            });
          }
        });
      }

      return result;
    },
    activeData() {
      return this.dataset.filter((data) => data.person.published);
    },
    inactiveData() {
      return this.dataset.filter((data) => !data.person.published);
    },
    visibleData() {
      if(this.visibileStatus === 'Active') {
        return this.activeData;
      }
      else {
        return this.inactiveData;
      }
    },
  },
  methods: {
    ...mapActions([
      'getBrandAgencyContactsByCompany',
      'unassignBrandAgencyContactsByCompany'
    ]),
    async getData() {
      this.isLoaded = false;

      await this.getBrandAgencyContactsByCompany(this.id);

      this.isLoaded = true;
    },
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    getBusinessLocations(id) {
      return BusinessesApi.getBusinessLocations(id).then(({ data }) => {
        this.locationsData = data;
      });
    },
    constructContactProfileUrl(id) {
      return Utils.getContactProfileUrl(id);
    },
    constructAgencyUrl(id) {
      return Utils.getAgencyProfileUrl(id);
    },
    constructEmailLink(email) {
      return `mailto:${email}`;
    },
    saveContactRelationship(data) {
      const payload = {
        data: data.map(item => {
          return {
            person_id: item.id,
            agency_id: item.organization.id
          }
        }),
      };

      return this.$store.dispatch("assignBusinessAgencyPeople", {
        id: this.id,
        payload: payload
      });
    },
    deleteRelationship(item) {
      CompaniesApi.getBrandIdsByPersonAndCompany({ company_id: this.id })
      .then(({ data }) => {
        const brandIds = data.brand_ids;
        return this.unassignBrandAgencyContactsByCompany({
          id: item.person.id,
          business_id: this.id,
          brand_ids: brandIds
        });
      })
      .catch(error => {
        console.error("Failed to fetch brand_ids:", error);
      });
    },
    afterSave(data) {
      let payload = {
        data: [{
            person_id: data.id,
            agency_id: data.organization.id
        }]
        };

      this.$store.dispatch("assignBusinessAgencyPeople", {
        id: this.id,
        payload: payload
      }).then(() => {
        this.goToNewContact(data.id);
      });
    },
    saveFn(payload) {
      const createContactVm = this.$refs.createContactRef;
      const organization_id = createContactVm.entity.organization.id;
      const data = this.modifyCreateContactPayload(payload);
      return BusinessesApi.createBusinessContact(organization_id, data);
    },
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

.company-agency-contacts {
  padding-top: 0.5rem;
}

.main-data-table {
  ::v-deep {
    font-size: 13px;
    th {
      &:first-child {
        min-width: 150px;
      }
    }
  }
}
</style>
