var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-contact" }, [
    _vm.backendErrors
      ? _c("div", { staticClass: "columns error-block" }, [
          !_vm.backendErrors.id
            ? _c("div", { staticClass: "column" }, [
                _vm._v(_vm._s(_vm.backendErrors))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.backendErrors.id
            ? _c("div", { staticClass: "column" }, [
                _vm._v("\n      " + _vm._s(_vm.backendErrors.text) + " "),
                _c("a", { attrs: { href: _vm.contactUrl } }, [
                  _vm._v("View profile")
                ])
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-variable is-1" }, [
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.fname),
            attrs: { placeholder: "First Name" },
            model: {
              value: _vm.$v.entity.fname.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.fname,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.fname.$model"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.lname),
            attrs: { placeholder: "Last Name" },
            model: {
              value: _vm.$v.entity.lname.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.lname,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.lname.$model"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-variable is-1" }, [
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.entity.title),
            attrs: { placeholder: "Title" },
            model: {
              value: _vm.$v.entity.title.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.entity.title,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.entity.title.$model"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-select", {
            class: _vm.status(_vm.$v.entity.title_rank),
            attrs: {
              value: _vm.entity.title_rank,
              options: _vm.possibleRanks,
              placeholder: "Title Rank"
            },
            on: {
              input: function($event) {
                _vm.$v.entity.title_rank.$model = $event
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-variable is-1" }, [
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          !_vm.possibleOrgs
            ? _c("winbox-select", {
                attrs: {
                  disabled: true,
                  options: [_vm.entityOrgList],
                  value: _vm.entityOrg,
                  placeholder: "Organization"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.possibleOrgs
            ? _c("winbox-select", {
                attrs: {
                  options: _vm.possibleOrgs,
                  placeholder: "Organization"
                },
                on: { input: _vm.onOrgChanged }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _vm.possibleLocations
            ? _c("winbox-select", {
                class: _vm.status(_vm.$v.entity.location),
                attrs: {
                  placeholder: "Location",
                  options: _vm.possibleLocations,
                  disabled: _vm.possibleLocations.length <= 1,
                  emptyText: _vm.emptyText
                },
                model: {
                  value: _vm.entity.location,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.entity,
                      "location",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "entity.location"
                }
              })
            : _c("winbox-input", {
                attrs: {
                  placeholder: "Location",
                  value:
                    _vm.entity.location && _vm.entity.location.label
                      ? _vm.entity.location.label
                      : _vm.entity.location,
                  readonly: true,
                  emptyText: _vm.emptyText
                }
              })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-variable is-1" }, [
      _c(
        "div",
        { staticClass: "column is-12" },
        [
          _c("winbox-input", {
            staticClass: "active-icon-bg",
            class: _vm.status(_vm.$v.entity.email),
            attrs: {
              placeholder: "Email",
              "icon-right": "envelope",
              "icon-right-namespace": "far"
            },
            model: {
              value: _vm.entity.email,
              callback: function($$v) {
                _vm.$set(_vm.entity, "email", $$v)
              },
              expression: "entity.email"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }