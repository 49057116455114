<template>
  <div class="create-contact">
    <div v-if="backendErrors" class="columns error-block">
      <div class="column" v-if="!backendErrors.id">{{ backendErrors }}</div>
      <div class="column" v-if="backendErrors.id">
        {{ backendErrors.text }} <a :href="contactUrl">View profile</a>
      </div>
    </div>
    <div class="columns is-variable is-1">
      <div class="column is-6">
        <winbox-input
          placeholder="First Name"
          v-model.trim="$v.entity.fname.$model"
          :class="status($v.entity.fname)"
        />
      </div>
      <div class="column is-6">
        <winbox-input
          placeholder="Last Name"
          v-model.trim="$v.entity.lname.$model"
          :class="status($v.entity.lname)"
        />
      </div>
    </div>

    <div class="columns is-variable is-1">
      <div class="column is-6">
        <winbox-input
          placeholder="Title"
          v-model.trim="$v.entity.title.$model"
          :class="status($v.entity.title)"
        />
      </div>
      <div class="column is-6">
        <winbox-select
          :value="entity.title_rank"
          :options="possibleRanks"
          placeholder="Title Rank"
          :class="status($v.entity.title_rank)"
          @input="$v.entity.title_rank.$model = $event"
        />
      </div>
    </div>
    <div class="columns is-variable is-1">
      <div class="column is-6">
        <winbox-select
          v-if="!possibleOrgs"
          :disabled="true"
          :options="[entityOrgList]"
          :value="entityOrg"
          placeholder="Organization"
        />
        <winbox-select
          v-if="possibleOrgs"
          :options="possibleOrgs"
          placeholder="Organization"
          @input="onOrgChanged"
        />
      </div>
      <div class="column is-6">
        <winbox-select
          v-if="possibleLocations"
          placeholder="Location"
          v-model.trim="entity.location"
          :options="possibleLocations"
          :class="status($v.entity.location)"
          :disabled="possibleLocations.length <= 1"
          :emptyText="emptyText"
        />
        <winbox-input
          v-else
          placeholder="Location"
          :value="
            entity.location && entity.location.label
              ? entity.location.label
              : entity.location
          "
          :readonly="true"
          :emptyText="emptyText"
        />
      </div>
    </div>

    <div class="columns is-variable is-1">
      <div class="column is-12">
        <winbox-input
          placeholder="Email"
          v-model="entity.email"
          class="active-icon-bg"
          icon-right="envelope"
          icon-right-namespace="far"
          :class="status($v.entity.email)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import * as Utils from "@/utils";
import * as BusinessesApi from "@/api/businesses/businesses";

export default {
  name: "WinboxCreateContact",
  components: {
    WinboxInput,
    WinboxSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    possibleOrgs: {
      type: [Array, Boolean],
      required: false,
      default: null,
    },
    backendErrors: {
      required: false,
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      Colors: Colors,
      entity: {},
      locationsData: [],
      entityOrg: null,
    };
  },
  validations: {
    entity: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      title: {
        required,
      },
      title_rank: {
        required,
      },
      organization: {
        required: requiredIf(function (entity) {
          return this.possibleOrgs && !entity.organization;
        }),
      },
      location: {
        required: requiredIf(function (entity) {
          return this.possibleLocations && !entity.location;
        }),
      },
      email: {
        email,
      },
    },
  },
  computed: {
    possibleRanks() {
      return this.$store.getters.TITLE_RANKS.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.rank,
        };
      });
    },
    possibleLocations() {
      return this.locationsData.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
        };
      });
    },
    entityOrgList() {
      if (this.entityOrg) {
        return [this.entityOrg];
      }
      return [];
    },
    emptyText() {
      if (this.$v.entity.organization.$model) {
        return (
          "Please make sure that " +
          this.$v.entity.organization.$model.label +
          " has a location"
        );
      }
      return "Sorry, no matching options.";
    },
    contactUrl() {
      return Utils.getContactProfileUrl(this.backendErrors.id);
    },
  },
  beforeMount() {
    this.entity = cloneDeep(this.data);

    if (!this.$store.getters.TITLE_RANKS.length) {
      this.$store.dispatch("getTitleRanks");
    }

    if (this.entity.organization.id) {
      this.entityOrg = this.entity.organization;
      this.entityOrg["label"] = this.entity.organization.name;
      this.entityOrg["selected"] = true;
      this.getBusinessLocations(this.entity.organization.id);
    }
  },
  methods: {
    status(validation) {
      if(validation.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    },
    onOrgChanged(e) {
      this.$v.entity.organization.$model = e;
      this.getBusinessLocations(e.id);
    },
    getBusinessLocations(id) {
      this.locationsData = [];
      BusinessesApi.getBusinessLocations(id).then(({ data }) => {
        if (data) {
          data.forEach((location) => {
            this.locationsData.push(location);
          });

          if (this.locationsData.length == 1) {
            this.entity.location = this.locationsData[0];
            this.entity.location["label"] = this.entity.location.name;
            this.entity.location["selected"] = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-contact {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
.error-block {
  color: $winbox-error;
}
</style>
