var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-upload-file" }, [
    _c(
      "form",
      {
        ref: "uploadForm",
        attrs: { enctype: "multipart/form-data", novalidate: "" }
      },
      [
        _c("div", { staticClass: "file is-primary" }, [
          _c(
            "label",
            { staticClass: "file-label", class: _vm.disabledStyleObj },
            [
              _c("input", {
                staticClass: "file-input",
                attrs: {
                  type: "file",
                  name: _vm.uploadFile,
                  multiple: _vm.multiple,
                  accept: _vm.accept,
                  disabled: _vm.disabled
                },
                on: { change: _vm.filesChange }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "file-cta" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "file-label", class: _vm.disabledStyleObj },
                  [_vm._v("\n            Choose a File\n          ")]
                )
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "file-icon" }, [
      _c("i", { staticClass: "fas fa-upload" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }