import { ReportsUrl } from "@/constants/ReportsUrl";
import { getGeneric, postGenericFullResponse } from "@/api/_generic/_generic";

export function getReports() {
  const url = ReportsUrl.base;
  return getGeneric(url);
}

export function createReport(data) {
  const url = ReportsUrl.base;
  return postGenericFullResponse(url, data);
}
