var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length
    ? _c("nav", { staticClass: "breadcrumb is-small" }, [
        _c(
          "ul",
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "li",
              { key: index, class: { "is-active": item.isCurrent } },
              [
                _c(
                  "a",
                  {
                    attrs: { href: _vm.breadcrumbLink(item) },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.navigateTo(item)
                      }
                    }
                  },
                  [
                    item.icon
                      ? _c("winbox-icon", {
                          staticClass: "is-small",
                          attrs: {
                            icon: item.icon,
                            color: item.isCurrent
                              ? _vm.Colors.system.white
                              : _vm.Colors.system.primaryLight
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.title))])
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }