<template>
  <div class="profile-wrap" v-if="isLoaded">
    <div class="columns is-desktop">
      <div class="column">
        <winbox-card>
          <template v-slot:header> General </template>
          <template v-slot:headerIcon>
            <button
              class="button save-button"
              @click="save(true)"
              v-if="isEditMode"
              :disabled="!isChanged"
            >
              Save
            </button>
            <button
              class="button cancel-button"
              @click="cancel(true)"
              v-if="isEditMode"
            >
              Cancel
            </button>

            <button
              class="button edit-button"
              @click="edit(true)"
              v-if="!isEditMode"
            >
              Edit
            </button>
          </template>
          <div class="content">
            <winbox-input
              label="Brand Name"
              v-model.trim="$v.entity.name.$model"
              :class="status($v.entity.name)"
              @input="addToDataUpdate('name', entity.name)"
              :disabled="!isEditMode"
              field="brandName"
            />
            <winbox-textarea
              v-model.trim="$v.entity.description.$model"
              :class="status($v.entity.description)"
              form-label="Description"
              @input="addToDataUpdate('description', entity.description)"
              :disabled="!isEditMode"
            />

            <div class="columns">
              <div class="column is-3">
                <winbox-input
                  label="Founded"
                  maxlength="4"
                  v-model.trim="$v.entity.founded.$model"
                  :class="status($v.entity.founded)"
                  @input="addToDataUpdate('founded', entity.founded)"
                  :disabled="!isEditMode"
                />
              </div>
              <div class="column is-9">
                <winbox-input
                  v-if="isEditMode"
                  label="Web Address"
                  icon-left="globe-americas"
                  v-model.trim="$v.entity.website.$model"
                  @input="addToDataUpdate('website', entity.website)"
                  :class="status($v.entity.website)"
                  field="brandWebsite"
                />
                <fieldset
                  v-else
                  class="border"
                >
                  <legend class="float-none w-auto">Web Address</legend>
                  <div class="website-container">
                    <a
                      class="website-link"
                      :href="`http://${entity.website}`" target="_blank"
                    >
                      {{ decodeURIComponent(entity.website) }}
                    </a>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="columns">
              <div class="column is-4">
                <button
                  :disabled="!isEditMode"
                  class="button action-button"
                  :class="{ 'is-primary': entity.inhouse_media }"
                  @click="
                    () => {
                      entity.inhouse_media = !entity.inhouse_media;
                      addToDataUpdate('inhouse_media', entity.inhouse_media);
                    }
                  "
                >
                  <input
                    type="checkbox"
                    v-model="entity.inhouse_media"
                    :disabled="!isEditMode"
                  />
                  <span>In-House Media</span>
                </button>
              </div>
              <div class="column is-4">
                <button
                  :disabled="!isEditMode"
                  class="button action-button"
                  :class="{ 'is-primary': entity.inhouse_creative }"
                  @click="
                    () => {
                      entity.inhouse_creative = !entity.inhouse_creative;
                      addToDataUpdate(
                        'inhouse_creative',
                        entity.inhouse_creative
                      );
                    }
                  "
                >
                  <input
                    type="checkbox"
                    v-model="entity.inhouse_creative"
                    :disabled="!isEditMode"
                  />
                  <span>In-House Creative</span>
                </button>
              </div>
            </div>
          </div>
        </winbox-card>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <winbox-card :expandable="true">
          <template v-slot:header> Industries </template>
          <div class="content">
            <winbox-industries
              org-type="brand"
              @changed="onTagsChanged($event, 'name')"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <winbox-card :expandable="true">
          <template v-slot:header> Demographics </template>
          <div class="content">
            <winbox-demographics
              ref="demographicsComponent"
              :items="demographics"
            />
          </div>
        </winbox-card>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column">
        <winbox-card>
          <template v-slot:header> Social Media </template>
          <template v-slot:headerIcon>
            <winbox-icon
              icon="plus-circle"
              :color="Colors.system.primaryLight"
              @click.native="$refs.winboxSocialMediaRef.addNewSocialEntity"
              v-if="isEditMode"
            />
          </template>
          <div class="content">
            <winbox-social-media
              ref="winboxSocialMediaRef"
              :items="socialEntities"
              @changed="onSocialChanged"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
      <div class="column">
        <winbox-card>
          <template v-slot:header> Nicknames </template>
          <template v-slot:headerIcon>
            <winbox-icon
              icon="plus-circle"
              :color="Colors.system.primaryLight"
              @click.native="$refs.winboxNicknamesRef.addNewEntity"
              v-if="isEditMode"
            />
          </template>
          <div class="content">
            <winbox-nicknames
              ref="winboxNicknamesRef"
              :items="nicknames"
              @changed="onNicknamesChanged"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <winbox-card :expandable="true">
          <template v-slot:header> Speciality Lists </template>
          <div class="content">
            <winbox-speciality-tags-list
              :possible-tags="possibleTags"
              :entity-tags="selectedTags"
              org-type="brand"
              @changed="onTagsChanged($event, 'name')"
              :disabled="!isEditMode"
            />
          </div>
        </winbox-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { BusinessEntityProfileMixin } from "@/components/Pages/Generic/BusinessEntityProfileMixin";
import { NicknamesAndSocialsMixin } from "@/components/Pages/Generic/NicknamesAndSocialsMixin";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

import * as Utils from "@/utils";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "BrandProfile",
  mixins: [
    BusinessEntityProfileMixin,
    NicknamesAndSocialsMixin,
    WinboxSpecialityTagsHelperMixin,
  ],
  data() {
    return {
      demographics: {},
    };
  },
  validations: {
    entity: {
      name: {
        required,
        maxLength: maxLength(75),
      },
      description: {
        required,
      },
      founded: {
        winboxValidYear: Utils.winboxValidYear,
      },
      website: {
        winboxValidUrl: Utils.winboxValidUrl,
        maxLength: maxLength(200),
      },
    },
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState([
      'brandNicknames',
      'brandSocials',
      'brandSpecialities',
      'currentBrand',
      'brandNicknames',
      'brandSocials',
      'brandDemographics',
      'brandSpecialities',
      'isInvalid',
    ]),
    entitySpecialities() {
      return this.brandSpecialities;
    },
    id() {
      return +this.$route.params.id;
    },
  },
  methods: {
    ...mapActions([
      'updateBrand',
      'updateBrandSocialsList',
      'updateBrandSocial',
      'removeBrandSocial',
      'updateBrandNicknameList',
      'updateBrandNickname',
      'removeBrandNickname',
      'assignBrandSpeciality',
      'updateBrandSpeciality',
      'deleteBrandSpeciality',
      'getSocials',
      'getBrandSocials',
      'getSpecialities',
      'getBrandSpecialities',
      'getIndustries',
      'getBrandIndustries',
      'getBrandNicknames',
      'getBrandDemographics',
      'updateBrandDemographics',
      'edit',
      'reset',
      'save',
    ]),
    async getData() {
      this.isLoaded = false;

      this.entity = this.currentBrand;
      this.updateBreadcrumbsTitles(this.entity, "Brand");
      this.getSpecialities("brand");
      this.getBrandSpecialities(this.id);
      this.getIndustries();

      await Promise.all([
        this.getBrandIndustries(this.id),
        this.loadBrandDemographics(),
        this.loadBrandSocials(),
        this.loadBrandNicknames(),
      ]);

      this.isLoaded = true;
      this.$v.$touch();
    },
    async loadBrandDemographics() {
      await this.getBrandDemographics(this.id);
      return this.demographics = this.brandDemographics;
    },
    async loadBrandSocials() {
      this.getSocials();
      await this.getBrandSocials(this.id);
      return this.socialEntities = this.brandSocials;
    },
    async loadBrandNicknames() {
      await this.getBrandNicknames(this.id);
      return this.nicknames = this.brandNicknames;
    },
    saveEntity() {
      if (this.$v.entity.$error || this.$v.entity.$invalid || this.isInvalid) {
        this.save(false);
        return;
      }

      const demographicsPayload = this.$refs.demographicsComponent.prepareDemographicsPayload();

      if (Object.keys(this.dataUpdate).length > 0) {
        const payload = { ...this.dataUpdate };
        this.updateBrand({ id: this.id, payload });
      }

      if (Object.keys(demographicsPayload).length > 0) {
        this.updateBrandDemographics({ id: this.id, payload: demographicsPayload });
      }

      setTimeout(() => {
        this.edit(false);
      });

      this.afterSaveEntity();
    },
    /* @Override */
    updateBusinessSocialsList(id, payload) {
      this.updateBrandSocialsList({
        id: id,
        payload: {
          entity_socials: payload,
        },
      });
    },
    /* @Override */
    updateBusinessNicknameList(id, payload) {
      this.updateBrandNicknameList({
        id: id,
        payload: {
          entity_nicknames: payload,
        },
      });
    },
    /* @Override */
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        speciality_list_ids: selectedTags.map((selectedTag) => selectedTag.speciality_list_id),
      };

      return this.assignBrandSpeciality({ id: this.id, payload });
    },
    /* @Override */
    updateBusinessTagEntity(updatedTags) {
      // Only year on update
      const payload = {
        year: updatedTags[0].year,
      };

      return this.updateBrandSpeciality({ id: this.id, specialityId: updatedTags[0].id, payload });
    },
    /* @Override */
    removeBusinessTagEntity(removedTags) {
      const deleteItem = removedTags[0];

      return this.deleteBrandSpeciality({ id: this.id, specialityId: deleteItem.id });
    },
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.profile-wrap {
  ::v-deep {
    .card-content {
      @include profile-card-content-base-styles;
    }
  }
  .action-button {
    @include profile-action-button;
  }
  .save-button,
  .edit-button {
    background: green;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;
    margin-right: 16px;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .cancel-button {
    background: red;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .remove-button {
    display: none;
    background: red;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
    .website-container {
    background-color: whitesmoke;
    border-radius: 4px;
    height: 40px;
    position: relative;
    top: -7px;
    }
  .website-link {
    position: relative;
    left: 8.5px;
    top: 9px;
  }
  fieldset {
    position: relative;
    top: -8.5px;
    min-width: 0;
    margin: 0;
    border: 0;
    height: 48px;
  }
  .w-auto {
    width: auto!important;
  }
  .float-none {
    float: none!important;
  }
  .pb-0 {
    padding-bottom: 0;
  }
  legend {
    background-color: white;
    color: $winbox-body-grey-bg;
    font-size: 12px;
    height: 15px;
    line-height: inherit;
    padding: 0 2px;
    position: relative;
    left: 7px;
    top: 2px;
    z-index: 2;
  }
}
</style>
