<template>
  <div class="winbox-contacts-updated">
    <winbox-card class="item-overview">
      <div class="item-title">
        <winbox-title :title="value.title" type="medium" />
      </div>
      <div class="item-chart">
        <div class="columns">
          <div class="column is-6">
            <div class="item-count">
              {{ this.value.count | numeral(baseNumFormat) }}
            </div>
            <div class="item-status">
              {{ value.subtitle || value.title }} (Last
              {{ value.currentLastDays.value }} days)
            </div>
            <ul class="item-info-list">
              <li
                class="columns is-vcentered is-1 item-info-list-item"
                :class="{ negative: item.tendence === 'negative' }"
                v-for="(item, key) in value.infoStatuses"
                :key="key"
              >
                <div class="column is-narrow">
                  <div class="icon-block">
                    <winbox-icon
                      :icon="item.icon"
                      :color="
                        item.tendence === 'negative'
                          ? Colors.system.error
                          : Colors.system.primaryLight
                      "
                    />
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="item-info-text" v-html="item.status"></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="column is-6">
            <div class="item-graph">
              <winbox-multi-axes-chart
                v-if="value.chartComponent !== 'horizontal-bar'"
                :chartdata="chartData"
                :options="chartOptions"
              />
              <winbox-horizontal-bar-chart
                v-if="value.chartComponent === 'horizontal-bar'"
                :chartdata="chartData"
                :options="chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </winbox-card>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import WinboxMultiAxesChart from "@/components/Molecules/charts/WinboxMultiAxesChart";
import WinboxHorizontalBarChart from "@/components/Molecules/charts/WinboxHorizontalBarChart";

import { Colors } from "@/Colors";
import { NumFormats } from "@/constants/NumFormats";
import * as Utils from "@/utils";

export default {
  name: "WinboxFrontDashboardWidget",
  components: {
    WinboxCard,
    WinboxTitle,
    WinboxIcon,
    WinboxMultiAxesChart,
    WinboxHorizontalBarChart
  },
  props: {
    value: {
      required: true,
      type: Object,
      note: "Main data object"
    }
  },
  data() {
    return {
      Colors: Colors,
      chartData: this.value.chartData,
      chartOptions: Utils.getDefaultChartOptions(),
      baseNumFormat: NumFormats.BASE
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.item-draggable {
  @include card-draggable-icon;
}
.item-title {
  .winbox-title {
    margin: 0 0 1rem;
  }
}
.item-count {
  font-weight: bold;
  font-size: $winbox-font-size-huge;
}
.item-status {
  font-size: $winbox-font-size-average;
  margin: 0 0 1rem;
}
.item-info-list-item {
  &.negative {
    .icon-block {
      background: $winbox-icon-negative-background;
    }
  }
}
.item-info-text {
  ::v-deep {
    .positive {
      color: $winbox-positive;
      font-weight: 600;
    }
    .negative {
      color: $winbox-negative;
      font-weight: 600;
    }
  }
}
.icon-block {
  display: inline-block;
  background: $winbox-icon-background;
  border-radius: 4px;
  padding: 4px;
}
.item-graph {
  ::v-deep {
    canvas {
      height: 220px !important;
    }
  }
}
</style>
