var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brand-sponsorships-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "brand-sponsorships" },
            [
              _c("winbox-company-header", {
                attrs: {
                  title: _vm.mainTitle,
                  "entity-icon": "link",
                  "entity-label": "Add Sponsorship",
                  "enable-status-toggle": true
                },
                on: {
                  "toggle-status": _vm.updateVisibleStatus,
                  changed: _vm.openLinkModal
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-data-table", {
                      staticClass: "main-data-table",
                      attrs: {
                        data: _vm.visibleData,
                        columns: _vm.columns,
                        options: _vm.tableOptions,
                        slots: _vm.specificSlots
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: _vm.tableProps.brand_name,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      "data-tooltip": data.row.sponsor_id,
                                      to: {
                                        path: _vm.constructBrandUrl(
                                          data.row.sponsor_id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          data.row[_vm.tableProps.brand_name]
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.level,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(data.row[_vm.tableProps.level]) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.placement,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(data.row[_vm.tableProps.placement]) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.season,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(data.row[_vm.tableProps.season]) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.relationshipProof,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      data.row[_vm.tableProps.relationshipProof]
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.status,
                            fn: function(data) {
                              return [
                                _c("winbox-select", {
                                  attrs: {
                                    value: data.row[_vm.tableProps.status],
                                    options: _vm.possibleStatuses,
                                    searchable: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.onStatusChange(
                                        data.row,
                                        $event
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.options,
                            fn: function(data) {
                              return [
                                _c("winbox-toggle-menu", {
                                  attrs: {
                                    options: _vm.rowOptions,
                                    data: data.row
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.linkModal,
          staticClass: "winbox-modal-scrollable",
          attrs: {
            name: _vm.linkModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            width: "1000px",
            height: "475px",
            title: "Add Sponsorship",
            "save-text": _vm.saveModalText
          },
          on: {
            "on-cancel": _vm.onCancelLinkModal,
            "on-save": _vm.onSaveLinkModal,
            "enter-press": _vm.onSaveLinkModal,
            "before-close": _vm.resetLinkNextStep
          }
        },
        [
          _c("winbox-add-brand-sponsorships", {
            ref: "addSponsorships",
            attrs: {
              url: _vm.getBrandsExceptByAgencyUrl(),
              config: _vm.linkConfig,
              responseFunction: _vm.responseFunction
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.linkEditModal,
          attrs: {
            name: _vm.linkEditModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            width: "1000px",
            height: "475px",
            title: "Edit Sponsorship",
            "save-text": "Edit"
          },
          on: { "on-cancel": _vm.onEditCancel, "on-save": _vm.onEditSave }
        },
        [
          _vm.editEntity
            ? _c("div", [
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-4" },
                    [
                      _c("winbox-select", {
                        class: _vm.status(_vm.$v.editEntity.category),
                        attrs: {
                          "form-label": "Category",
                          options: _vm.possibleCategories,
                          label: "label"
                        },
                        model: {
                          value: _vm.$v.editEntity.category.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.category,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.editEntity.category.$model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-4" },
                    [
                      _c("winbox-select", {
                        class: _vm.status(_vm.$v.editEntity.affiliation),
                        attrs: {
                          "form-label": "Affiliation",
                          options: _vm.possibleAffiliations,
                          label: "label"
                        },
                        model: {
                          value: _vm.$v.editEntity.affiliation.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.affiliation,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.editEntity.affiliation.$model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-4" },
                    [
                      _c("winbox-select", {
                        class: _vm.status(_vm.$v.editEntity.level),
                        attrs: {
                          "form-label": "Level",
                          options: _vm.possibleLevels,
                          label: "label"
                        },
                        model: {
                          value: _vm.$v.editEntity.level.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.level,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.editEntity.level.$model"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-4" },
                    [
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.editEntity.deal_amount),
                        attrs: { label: "Deal Amount", type: "number" },
                        model: {
                          value: _vm.$v.editEntity.deal_amount.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.deal_amount,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.editEntity.deal_amount.$model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-4" },
                    [
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.editEntity.season_start_year),
                        attrs: { label: "Season Start Year" },
                        model: {
                          value: _vm.$v.editEntity.season_start_year.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.season_start_year,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.editEntity.season_start_year.$model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-4" },
                    [
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.editEntity.season_end_year),
                        attrs: { label: "Season End Year" },
                        model: {
                          value: _vm.$v.editEntity.season_end_year.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.season_end_year,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.editEntity.season_end_year.$model"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-6" },
                    [
                      _c("winbox-textarea", {
                        class: _vm.status(_vm.$v.editEntity.placement),
                        attrs: { "form-label": "Placement" },
                        model: {
                          value: _vm.$v.editEntity.placement.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.placement,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.editEntity.placement.$model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column is-6" },
                    [
                      _c("winbox-textarea", {
                        class: _vm.status(_vm.$v.editEntity.activation),
                        attrs: {
                          "form-label": "Description & Activation Details"
                        },
                        model: {
                          value: _vm.$v.editEntity.activation.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.editEntity.activation,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.editEntity.activation.$model"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    {
                      staticClass: "column is-4",
                      staticStyle: {
                        "margin-left": "auto",
                        display: "flex",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "min-width": "150px",
                            "flex-shrink": "0"
                          }
                        },
                        [
                          _c("winbox-select", {
                            class: _vm.status(_vm.$v.editEntity.status),
                            attrs: {
                              "form-label": "Status",
                              options: _vm.possibleStatuses,
                              label: "label"
                            },
                            model: {
                              value: _vm.$v.editEntity.status.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.editEntity.status,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.editEntity.status.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }