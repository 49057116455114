export const BusinessesUrl = {
  base: "/businesses",
  brands: "brands",
  agencies: "agencies",
  business_locations: "business_locations",
  people: "people",
  business_revenues: "business_revenues",
  business_speciality_lists: "business_speciality_lists",
  business_disciplines: "agency_disciplines",
  entity_socials: "entity_socials",
  business_nicknames: "entity_nicknames",
  tags: "tags",
  entity_tags: "entity_tags",
  entity_notes: "entity_notes",
  company_contacts: "company_contacts",
  entity_note_reminders: "entity_note_reminders",
  entity_audit_logs: "entity_audit_logs",
  entity_audit_logs_rollback: "entity_audit_logs_rollback",
  business_hierarchies: "business_hierarchies",
  business_industries: "business_industries",
  business_relationships: "business_relationships",
  bulk_update_agency_location: "bulk-update-agency-location",
  brand_agency_contacts: "brand_agency_contacts",
  business_agency_contacts: "business_agency_contacts",
};
