<template>
  <div class="create-agency">
    <div class="content">
      <winbox-input
        placeholder="Agency Name"
        v-model.trim="$v.entity.name.$model"
        :class="status($v.entity.name)"
        field="name"
      />
      <winbox-input
        placeholder="Short Description"
        v-model.trim="$v.entity.short_description.$model"
        :class="status($v.entity.short_description)"
      />
      <winbox-select
        v-model="entity.discipline"
        :options="possibleDiscipline"
        :clearable="true"
        :multiple="true"
        :class="status($v.entity.discipline)"
        placeholder="Discipline"
      />
      <winbox-input
        placeholder="URL"
        v-model.trim="$v.entity.website.$model"
        :class="status($v.entity.website)"
        field="website"
      />
    </div>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import { required, maxLength } from "vuelidate/lib/validators";
import * as Utils from "@/utils";

export default {
  name: "WinboxCreateAgency",
  components: {
    WinboxInput,
    WinboxSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Colors: Colors,
      entity: {},
    };
  },
  validations: {
    entity: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      short_description: {
      },
      discipline: {
        required
      },
      website: {
        required,
        winboxValidUrl: Utils.winboxValidUrl,
        maxLength: maxLength(40)
      }
    }
  },
  computed: {
    possibleDiscipline() {
      let result = this.$store.getters.DISCIPLINES || [];
      return result.map(item => {
        return {
          id: item.id,
          label: item.name,
          value: item.id
        };
      });
    }
  },
  beforeMount() {
    this.entity = cloneDeep(this.data);
  },
  methods: {
    status(validation) {
      if(validation.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-agency {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
</style>
