import * as BrandsApi from "@/api/brands/brands";
import * as BusinessesApi from "@/api/businesses/businesses";

import * as Utils from "@/utils";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getBrands({ commit }) {
    return BrandsApi.getAllBrands().then(({ data }) => {
      commit(MutationTypes.SET_BRANDS, data);
    });
  },

  getBrand({ commit }, id) {
    return BrandsApi.getBrandById(id).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_BRAND, data);
    });
  },

  getBrandCounts({ commit }, id) {
    return BrandsApi.getBrandCounts(id).then(({ data }) => {
      commit(MutationTypes.SET_COUNTS, data);
    });
  },

  updateBrand({ commit, getters }, { id, payload }) {
    return BrandsApi.updateBrandById(id, payload).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_BRAND, data);
    });
  },

  updateBrandFromCompany({ commit }, { id, payload }) {
    return BrandsApi.updateBrandById(id, payload).then(({ data }) => {
      commit(MutationTypes.UPDATE_BUSINESS_BRANDS, data);
    });
  },

  getBrandTags({ commit }, id) {
    return BrandsApi.getBrandTags(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_TAGS, {
        id,
        data
      });
    });
  },

  createBrandEntityTag({ state, commit, getters }, { id, payload }) {
    const allData = getters.BRAND_TAGS_BY_ID(id) || [];

    return BrandsApi.createBrandEntityTag(id, payload).then(({ data }) => {
      allData.push(data);
      commit(MutationTypes.SET_BRAND_TAGS, {
        id,
        data: allData
      });
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'tags_count',
        count: state.entityCounts.tags_count + 1,
      });
      return { data };
    });
  },

  removeBrandEntityTag({ state, commit, getters }, { id, tagId }) {
    let allData = getters.BRAND_TAGS_BY_ID(id) || [];

    return BusinessesApi.removeEntityTag(tagId).then(({ data }) => {
      allData = allData.filter(item => item.id !== tagId);
      commit(MutationTypes.SET_BRAND_TAGS, {
        id,
        data: allData
      });
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'tags_count',
        count: state.entityCounts.tags_count - 1,
      });
      return { data };
    });
  },

  getBrandSocials({ commit }, id) {
    return BrandsApi.getBrandSocials(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_SOCIALS, data);
    });
  },

  updateBrandSocialsList({ commit, getters }, { id, payload }) {
    return BrandsApi.updateBrandSocialsList(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_SOCIALS, data);
    });
  },

  getBrandNicknames({ commit }, id) {
    return BrandsApi.getBrandNicknames(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_NICKNAMES, data);
    });
  },

  updateBrandNicknameList({ commit, getters }, { id, payload }) {
    return BrandsApi.updateBrandNicknamesList(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_NICKNAMES, data);
    });
  },

  getBrandDemographics({ commit }, id) {
    return BrandsApi.getBrandDemographics(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_DEMOGRAPHICS, data);
    });
  },

  updateBrandDemographics({ commit, getters }, { id, payload }) {
    return BrandsApi.updateBrandDemographics(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_DEMOGRAPHICS, data);
    });
  },

  getBrandIndustries({ commit }, id) {
    return BrandsApi.getBrandIndustries(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_INDUSTRIES, data);
    });
  },

  assignBrandIndustries({ commit, getters }, { id, payload }) {
    return BrandsApi.updateBrandIndustryAssociation(id, payload).then(
      ({ data }) => {
        commit(MutationTypes.SET_BRAND_INDUSTRIES, data);
      }
    );
  },

  getBrandContacts({ commit }, id) {
    return BrandsApi.getBrandPeople(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_CONTACTS, data);
    });
  },

  getBrandNotes({ commit }, id) {
    return BrandsApi.getBrandNotes(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_NOTES, {
        id,
        data
      });
    });
  },

  createBrandEntityNote({ state, commit, getters }, { id, payload }) {
    const allData = getters.BRAND_NOTES_BY_ID(id) || [];

    return BrandsApi.createBrandNote(id, payload).then(({ data }) => {
      allData.push(data);
      commit(MutationTypes.SET_BRAND_NOTES, {
        id,
        data: allData
      });
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'note_count',
        count: state.entityCounts.note_count + 1,
      });
      return { data };
    });
  },

  updateBrandEntityNote({ commit, getters }, { id, noteId, payload }) {
    let allData = getters.BRAND_NOTES_BY_ID(id) || [];

    return BusinessesApi.updateEntityNote(noteId, payload).then(({ data }) => {
      allData = Utils.spliceArrayItem(allData, data);
      commit(MutationTypes.SET_BRAND_NOTES, {
        id,
        data: allData
      });
      return { data };
    });
  },

  removeBrandEntityNote({ state, commit, getters }, { id, noteId }) {
    let allData = getters.BRAND_NOTES_BY_ID(id) || [];

    return BusinessesApi.removeEntityNote(noteId).then(({ data }) => {
      allData = allData.filter(item => item.id !== noteId);
      commit(MutationTypes.SET_BRAND_NOTES, {
        id,
        data: allData
      });
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'note_count',
        count: state.entityCounts.note_count - 1,
      });
      return { data };
    });
  },

  getBrandLogs({ commit }, id) {
    return BrandsApi.getBrandLogs(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_LOGS, {
        id,
        data
      });
    });
  },

  rollbackBusinessLog({ commit }, payload) {
    return BusinessesApi.rollbackBusinessLog(payload).then(({ data }) => {
      return { data };
    });
  },

  getBrandSpecialities({ commit }, id) {
    return BrandsApi.getBrandSpecialities(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_SPECIALITIES, data);
    });
  },

  assignBrandSpeciality({ commit, getters }, { id, payload }) {
    return BrandsApi.assignBrandSpecialities(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_SPECIALITIES, data);
    });
  },

  updateBrandSpeciality({ commit, getters }, { id, specialityId, payload }) {
    return BrandsApi.updateBrandSpeciality(specialityId, payload).then(
      ({ data }) => {
        commit(MutationTypes.SET_BRAND_SPECIALITIES, data);
      }
    );
  },

  deleteBrandSpeciality({ commit, getters }, { id, specialityId }) {
    return BrandsApi.deleteBrandSpeciality(specialityId).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_SPECIALITIES, data);
    });
  },

  getBrandBusinessRelationships({ commit }, id) {
    return BrandsApi.getBrandAgencies(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_BUSINESS_RELATIONSHIPS, data);
    });
  },

  getBrandSponsorships({ commit }, id) {
    return BrandsApi.getBrandSponsorships(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_SPONSORSHIPS, data);
    });
  },

  createBrandSponsorship({ state, commit, getters }, { brandId, payload }) {
    if (payload.brand_sponsorship.hasOwnProperty('status')) {
      const createdStatus = payload.brand_sponsorship.status;
      const createdCountChange = (createdStatus === 'active') ? 1 : 0;

      if (createdCountChange !== 0) {
        commit(MutationTypes.UPDATE_COUNTS, {
          stateVariableName: 'entity_sponsorship_count',
          count: state.entityCounts.entity_sponsorship_count + createdCountChange
        });
      }
    }
    return BrandsApi.createBrandSponsorship(brandId, payload).then(({ data }) => {
      commit(MutationTypes.ADD_BRAND_SPONSORSHIPS, data);
    });
  },

  updateBrandSponsorship({ state, commit }, { brandId, sponsorshipId, payload }) {
    if (Object.keys(payload).length === 1 && payload.hasOwnProperty('status')) {
      const status = payload.status;
      const countChange = (status === 'inactive') ? -1 : (status === 'active') ? 1 : 0;

      if (countChange !== 0) {
        commit(MutationTypes.UPDATE_COUNTS, {
          stateVariableName: 'entity_sponsorship_count',
          count: state.entityCounts.entity_sponsorship_count + countChange
        });
      }
    }
    return BrandsApi.updateBrandSponsorship(brandId, sponsorshipId, payload).then(({ data }) => {
      commit(MutationTypes.UPDATE_BRAND_SPONSORSHIPS, data);
    });
  },

  createBrandBusinessRelationship({ state, commit, getters }, { id, businessId, payload }) {
    return BusinessesApi.createBusinessRelationship(businessId, payload).then(({ data }) => {
      commit(MutationTypes.ADD_BRAND_BUSINESS_RELATIONSHIPS, data);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'entity_agency_count',
        count: state.entityCounts.entity_agency_count + 1
      });
    });
  },

  updateBrandBusinessRelationship({ state, commit, getters }, { id, businessId, relationshipId, payload }) {
    return BusinessesApi.updateBusinessRelationship(businessId, relationshipId, payload).then(({ data }) => {
      commit(MutationTypes.UPDATE_BRAND_BUSINESS_RELATIONSHIPS, data);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'entity_agency_count',
        count: state.entityCounts.entity_agency_count - 1
      });
    });
  },

  getBrandBusiness({ commit }, id) {
    return BrandsApi.getBrandBusiness(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_BUSINESSES, data);
    });
  },

  assignBrandPeople({ state, commit, getters }, { id, payload }) {
    return BrandsApi.assignBrandPeople(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_CONTACTS, data);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'contacts_count',
        count: state.entityCounts.contacts_count + 1
      });
    });
  },

  unassignBrandPeople({ state, commit, getters }, { brand_id, person_id }) {
    return BrandsApi.unassignBrandPeople(brand_id, person_id).then(({ data }) => {
      commit(MutationTypes.REMOVE_BRAND_CONTACTS, person_id);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'contacts_count',
        count: state.entityCounts.contacts_count - 1
      });
    });
  },

  getBrandBusinessesContacts({ commit }, id) {
    return BrandsApi.getBrandBusinessesContacts(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_BUSINESSES_CONTACTS, data);
    });
  },

  getBrandAgenciesContacts({ commit }, id) {
    return BrandsApi.getBrandAgenciesContacts(id).then(({ data }) => {
      commit(MutationTypes.SET_BRAND_AGENCY_CONTACTS, data);
    });
  },

  assignBrandAgenciesContacts({ state, commit, getters }, { id, payload }) {
    return BrandsApi.assignBrandAgenciesContacts(id, payload).then(({ data }) => {
      commit(MutationTypes.UPDATE_BRAND_AGENCY_CONTACTS, data);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'entity_agency_contacts_count',
        count: state.entityCounts.entity_agency_contacts_count + 1,
      });
    });
  },

  unassignBrandAgenciesContacts({ state, commit, getters }, { brand_id, id }) {
    return BrandsApi.unassignBrandAgenciesContacts(id, brand_id).then(({ data }) => {
      commit(MutationTypes.REMOVE_BRAND_AGENCY_CONTACTS, id);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'entity_agency_contacts_count',
        count: state.entityCounts.entity_agency_contacts_count - 1,
      });
    });
  },

  getBrandHierarchies({ commit }, id) {
    return BrandsApi.getBrandHierarchies(id).then(({ data }) => {
      commit(MutationTypes.SET_ENTITY_PARENT_HIERARCHIES, data.parents);
      commit(MutationTypes.SET_ENTITY_CHILD_HIERARCHIES, data.children);
      commit(MutationTypes.SET_ENTITY_SIBLING_HIERARCHIES, data.siblings);
    });
  },

  createBrandHierarchy({ state, commit, getters }, { id, payload }) {
    return BrandsApi.createBrandHierarchy(id, payload).then(
      ({ data }) => {
        commit(MutationTypes.UPDATE_COUNTS, {
          stateVariableName: 'hierarchy_total_count',
          count: state.entityCounts.hierarchy_total_count + 1,
        });
        if(payload.hierarchy_type === 'parent') {
          commit(MutationTypes.UPDATE_ENTITY_PARENT_HIERARCHIES, data);
        }
        else {
          commit(MutationTypes.UPDATE_ENTITY_CHILD_HIERARCHIES, data);
        }
      }
    );
  },

  removeBrandHierarchy({ state, commit, getters }, { id, type }) {
    return BrandsApi.removeBrandHierarchy(id).then(() => {
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'hierarchy_total_count',
        count: state.entityCounts.hierarchy_total_count - 1,
      });
      if(type === 'parent') {
        commit(MutationTypes.REMOVE_ENTITY_PARENT_HIERARCHIES, id);
      }
      else {
        commit(MutationTypes.REMOVE_ENTITY_CHILD_HIERARCHIES, id);
      }
    });
  },
};
