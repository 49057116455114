<template>
  <div class="change-password">
    <form>
      <div class="columns is-variable is-1 is-vcentered">
        <div class="column is-4">
          <div class="field-label">Email</div>
        </div>
        <div class="column is-8">
          <winbox-input
            placeholder="Email"
            v-model.trim="$v.entity.email.$model"
            :readonly="true"
            :class="status($v.entity.email)"
          />
        </div>
      </div>

      <div class="columns is-variable is-1 is-vcentered">
        <div class="column is-4">
          <div class="field-label">New Password</div>
        </div>
        <div class="column is-8">
          <winbox-input
            placeholder="New Password"
            type="password"
            v-model.trim="$v.entity.newPassword.$model"
            :class="status($v.entity.newPassword)"
          />
        </div>
      </div>

      <div class="columns is-variable is-1 is-vcentered">
        <div class="column is-4">
          <div class="field-label">Repeat New Password</div>
        </div>
        <div class="column is-8">
          <winbox-input
            placeholder="Repeat New Password"
            type="password"
            v-model.trim="$v.entity.repeatNewPassword.$model"
            :class="status($v.entity.repeatNewPassword)"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import WinboxInput from "@/components/Atoms/WinboxInput";

import cloneDeep from "clone-deep";
import { required, email } from "vuelidate/lib/validators";
import * as Utils from "@/utils";

export default {
  name: "WinboxChangePassword",
  components: {
    WinboxInput
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      entity: {}
    };
  },
  validations: {
    entity: {
      email: {
        required,
        email
      },
      // oldPassword: {
      //   required
      // },
      newPassword: {
        required
      },
      repeatNewPassword: {
        required
      }
    }
  },
  computed: {},
  beforeMount() {
    this.entity = cloneDeep(this.data);
  },
  methods: {
    status(validation) {
      return Utils.validationStatus(validation);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.change-password {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
  .field-label {
    font-weight: bold;
    font-size: $winbox-font-size-less-than-main;
    color: $winbox-sidebar-active-bg-color;
  }
}
</style>
