import { DataImportUrl } from "@/constants/DataImportUrl";

import {
  getGeneric,
  postGenericFullResponse,
} from "@/api/_generic/_generic";

export function getDataImportTemplates() {
  const url = DataImportUrl.base;
  return getGeneric(url);
}

export function validateFileFormat(data) {
  const url = `${DataImportUrl.base}/${DataImportUrl.validateFileFormat}`;
  return postGenericFullResponse(url, data);
}

export function importData(data) {
  const url = `${DataImportUrl.base}/${DataImportUrl.dataImport}`;
  return postGenericFullResponse(url, data);
}

export function downloadTemplate(templateName) {
  const url = `/api/${DataImportUrl.base}/${DataImportUrl.downloadTemplate}?uploadOption=${encodeURIComponent(templateName)}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'text/csv',
    },
  });
}
