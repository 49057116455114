<template>
  <div class="contact-tags-wrap">
    <winbox-card>
      <template v-slot:header>
        Tags
      </template>
      <div class="content">
        <div v-if="isLoaded" class="contact-tags">
          <div class="columns">
            <div class="column">
              <winbox-speciality-tags
                :possible-tags="possibleTags"
                :entity-tags="selectedTags"
                prop-selected-parent-id="tag_id"
                placeholder-selected="Selected Tags"
                @changed="onTagsChanged($event, 'name')"
              />
            </div>
          </div>
        </div>
      </div>
    </winbox-card>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";

import WinboxSpecialityTags from "@/components/Organisms/WinboxSpecialityTags";
import { EntityTagsMixin } from "@/components/Pages/Generic/EntityTagsMixin";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

export default {
  name: "ContactTags",
  mixins: [EntityTagsMixin, WinboxSpecialityTagsHelperMixin],
  components: {
    WinboxCard,
    WinboxSpecialityTags
  },
  data() {
    return {
      isLoaded: true
    };
  },
  computed: {
    /* @Override */
    selectedTags() {
      let result = this.$store.getters.PERSON_TAGS_BY_ID(this.id);

      return this.modifySelectedTags(result);
    }
  },
  methods: {
    /* @Override */
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        tag_id: selectedTags[0].id
      };

      this.$store.dispatch("createPersonEntityTag", {
        id: this.id,
        payload: payload
      });
    },
    /* @Override */
    removeBusinessTagEntity(removedTags) {
      const deleteItem = removedTags[0];

      this.$store.dispatch("removePersonEntityTag", {
        id: this.id,
        tagId: deleteItem.id
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

.company-tags {
  padding-top: 0.5rem;
  ::v-deep {
    .wrap-speciality-widget {
      .card-content {
        height: 100%;
        max-height: 600px;
      }
    }
  }
}
</style>
