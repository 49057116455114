<template>
  <div class="contact-sepciality-lists">
    <winbox-large-indicator v-if="!isLoaded" />
    <winbox-card :expandable="true" v-if="isLoaded">
      <div class="content">
        <winbox-speciality-tags-list
          :possible-tags="possibleTags"
          :entity-tags="selectedTags"
          :add-create-support="false"
          @changed="onTagsChanged($event, 'name')"
          :disabled="!isEditMode"
        />
      </div>
    </winbox-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import * as Utils from "@/utils";
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxSpecialityTagsList from "@/components/Organisms/WinboxSpecialityTagsList";

import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

export default {
  name: "ContactSpecialityLists",
  mixins: [
    WinboxSpecialityTagsHelperMixin
  ],
  components: {
    WinboxSpecialityTagsList,
    WinboxLargeIndicator,
    WinboxCard,
  },
  data() {
    return {
      isLoaded: false
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapState([
      'isEditMode',
      'specialities',
      'personSpecialities',
    ]),
    id() {
      return +this.$route.params.id;
    },
    /* @Override */
    entitySpecialities() {
      if (this.personSpecialities.length == 0) {
        return [];
      }
      return this.personSpecialities;
    },
    possibleTags() {
      return Utils.sortAlphabetic(this.specialities, "name");
    },
    selectedTags() {
      return Utils.sortAlphabetic(
        this.entitySpecialities.map(item => {
          return {
            ...item,
            name: this.findTagEntityName(item.speciality_list_id)
          };
        }),
        "name"
      );
    }
  },
  methods: {
    ...mapActions([
      'edit',
      'reset',
      'save',
    ]),
    getData() {
      this.isLoaded = true;
    },
    /* @Override */
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        speciality_list_ids: selectedTags.map((selectedTag) => selectedTag.speciality_list_id),
      };

      return this.$store.dispatch("assignPersonSpeciality", {
        id: this.id,
        payload: payload
      });
    },
    findTagEntityName(id) {
      const foundItem = this.possibleTags.find(item => item.id === id);
      return foundItem ? foundItem.name : "";
    },
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>
