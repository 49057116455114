import { BusinessesUrl } from "@/constants/BusinessesUrl";
import { AgenciesUrl } from "@/constants/AgenciesUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse,
  putGenericFullResponse,
  deleteGenericFullResponse
} from "@/api/_generic/_generic";

export function getAgencies() {
  const url = AgenciesUrl.base;
  return getGenericFullResponse(url);
}

export function getAgencyCounts(id) {
  const url = `${AgenciesUrl.base}/${id}/${AgenciesUrl.counts}`;
  return getGenericFullResponse(url);
}

export function createAgency(data) {
  const url = AgenciesUrl.base;
  return postGenericFullResponse(url, data);
}

export function getAgency(id) {
  const url = `${AgenciesUrl.base}/${id}`;
  return getGenericFullResponse(url);
}

export function updateAgency(id, data) {
  const url = `${AgenciesUrl.base}/${id}`;
  return putGenericFullResponse(url, data);
}

export function getAgencyHierarchies(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_hierarchies}?type=agency`;
  return getGenericFullResponse(url);
}

export function createAgencyHierarchy(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_hierarchies}?type=agency`;
  return postGenericFullResponse(url, data);
}

export function removeAgencyHierarchy(id) {
  const url = `/${BusinessesUrl.business_hierarchies}/${id}?type=agency`;
  return deleteGenericFullResponse(url);
}

export function getAgencyRelationship(id) {
  const url = `${AgenciesUrl.base}/${id}/${AgenciesUrl.agency_relationship}`;
  return getGenericFullResponse(url);
}

export function getAgencyRelationshipForAgencyLocation(id) {
  const url = `${AgenciesUrl.base}/${id}/${AgenciesUrl.agency_relationship_for_agency_location}`;
  return getGenericFullResponse(url);
}

export function submitAgenciesProof(id) {
  const url = `/agencies-proof/${id}`;
  return putGenericFullResponse(url);
}
