<template>
  <div class="winbox-add-brand-contact">
    <div v-if="!showNextStep" class="step-one">
      <winbox-data-table
        class="winbox-add-relationships-table"
        :data="dataset"
        :columns="columns"
        :options="tableOptions"
        :slots="specificSlots"
        theme="with-borders-theme"
        @row-click="onRowClick"
      >
        <template slot="linked" slot-scope="data">
          <button
            class="button select-button"
            :class="{ active: data.row.linked }"
          >
            <winbox-icon
              icon="plus"
              :color="
                data.row.linked
                  ? Colors.system.lightGray
                  : Colors.system.primaryLight
              "
            />
          </button>
        </template>
      </winbox-data-table>
      <div class="pl px warning" v-if="!data.length">{{errorMessage}}</div>
    </div>
    <div v-if="showNextStep && entity" class="step-two">
      <div class="columns">
        <div class="column">
          <winbox-input
            placeholder="Name"
            v-model.trim="$v.entity.name.$model"
            :readonly="true"
            :class="status($v.entity.name)"
          />
        </div>
      </div>

      <winbox-relation-ship-items
        :value="$v.entity"
        @change="onChangeEntityFields"
      />

      <div class="columns">
        <div class="column is-6">
          <winbox-select
            :value="entity.status"
            :options="possibleStatuses"
            :searchable="false"
            placeholder="Status"
            :class="status($v.entity.status)"
            @input="$v.entity.status.$model = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";

import cloneDeep from "clone-deep";
import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { required } from "vuelidate/lib/validators";
import { BusinessStatuses } from "@/constants/BusinessStatuses";

export default {
  name: "WinboxAddBrandContact",
  components: {
    WinboxIcon,
    WinboxInput,
    WinboxSelect,
    WinboxDataTable
  },
  props: {
    data: {
      required: true,
      type: Array
    },
    config: {
      required: true,
      type: Object
    },
    errorMessage: {
      required: false,
      type: String,
    },
  },
  validations: {
    entity: {
      name: {
        required
      },
    }
  },
  data() {
    return {
      entity: null,
      showNextStep: false,
      Colors,
      tableProps: this.config.tableProps,
      dataset: cloneDeep(this.data),
      columns: Object.values(this.config.tableProps),
      tableOptions: this.config.tableOptions,
      specificSlots: this.config.specificSlots,
      multiselect: this.config.multiselect,
    };
  },
  computed: {
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    }
  },
  methods: {
    toggleLinked(id) {
      if (this.multiselect) {
        const found = this.dataset.find(item => item.id === id);
        if (found) {
          found.linked = !found.linked;
        }
        this.entity = this.dataset.filter(item => item.linked === true);
      }
      else {
        this.dataset.forEach(item => {
          item.linked = false;
        });
        const found = this.dataset.find(item => item.id === id);
        if (found) {
          found.linked = !found.linked;
          this.entity = {
            ...found
          };
        }
      }
    },
    getDeepCloneDataset() {
      return cloneDeep(this.dataset);
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    onChangeEntityFields(e) {
      this.entity = {
        ...this.entity,
        ...e
      };
    },
    onRowClick(e) {
      this.toggleLinked(e.row.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.winbox-add-relationships-table {
  ::v-deep {
    thead {
      th {
        vertical-align: middle;
        &:last-child {
          width: 130px;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
      td {
        border-left: none;
        border-right: none;

        &:first-child {
          border-left: 1px solid $winbox-secondary-title;
        }
        &:last-child {
          border-right: 1px solid $winbox-secondary-title;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
    .select-button {
      border-color: $winbox-primary-active-bg-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0;

      &.active {
        background: $winbox-primary-active-bg-color;
      }
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .winbox-icon {
        font-size: $winbox-font-size-small;
      }
    }
  }
}
.pl {
  padding-left: 10px;
}
.pt {
  padding-top: 10px;
}
.warning {
  color: red;
}
</style>
