<script>
import { HorizontalBar } from "vue-chartjs";

export default {
  extends: HorizontalBar,
  name: "WinboxHorizontalBarChart",
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartOptions: {
        defaultFontColor: "#fff",
        tooltips: {
          enabled: false
        },
        hover: { animationDuration: 0 },
        animation: {
          duration: 0,
          onComplete: function() {
            // render the value of the chart above the bar
            var ctx = this.chart.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontSize,
              "normal",
              Chart.defaults.global.defaultFontFamily
            );
            ctx.fillStyle = this.chart.config.options.defaultFontColor;
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";
            this.data.datasets.forEach(function(dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                var model =
                  dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                ctx.fillText(dataset.data[i], model.x - 20, model.y + 6);
              }
            });
          }
        },
        ...this.options
      }
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.chartOptions);
  }
};
</script>

<style scoped></style>
