import { BusinessStatuses } from "@/constants/BusinessStatuses";

export const BusinessEditHelperMixin = {
  data() {
    return {
      deleteTitle: "Delete martech",
      deleteModal: "deleteModal",
      isEditMode: false,
      selectedRowID: null,
      isDeleteLoaded: true,
      businessType: "COMPANY",
      prevRow: null
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },
  methods: {
    isDisabled(row) {
      return row.id != this.selectedRowID || !this.isEditMode;
    },
    onDeleteAccept() {
      this.deleteBusiness(this.selectedRowID);
    },
    onDeleteCancel() {
      this.notEditMode();
      this.isDeleteLoaded = true;
      this.$refs[this.deleteModal].hide();
    },
    notEditMode() {
      this.isEditMode = false;
      this.selectedRowID = null;
    },
    onEdit(row) {
      if (this.isEditMode) {
        if (this.isChanged(this.prevRow) && confirm("You have unsaved changes. Do you want to proceed?")) {
          this.editCancel(this.prevRow);
          this.editMode(row);
        }
        else {
          this.editCancel(this.prevRow);
          this.editMode(row);
        }
      }
      else {
        this.editMode(row);
      }
    },
    editMode(row) {
      this.isEditMode = true;
      this.prevRow = row;
      this.selectedRowID = row.id;
      this.oldStatus = row["status"];
    },
    isChanged(row) {
      return row['status'] !== this.oldStatus;
    },
    editCancel(row) {
      row["status"] = this.oldStatus;
      this.$refs["statusSelect" + row.id].selected = this.oldStatus;
      row.statusColor = this.getStatusIconColor(row["status"].value);
      this.notEditMode();
    },
    editBusiness(row) {
      this.updateBusinessStatus(row.id, this.getStatus(row));
      this.notEditMode();
    },
    updateBusinessStatus(id, status) {
      this.$store
        .dispatch("updateBusiness", {
          id: id,
          payload: { status: status },
          type: `ADD_UPDATE_${this.businessType}`,
        })
        .then(() => {
          this.$forceUpdate();
        })
        .catch((e) => { });
    },
    getStatus(item) {
      let statusValue = BusinessStatuses.inactive.value;
      if (item["status"]) {
        statusValue = item["status"].value;
      }
      return statusValue;
    },
    onChangeStatus(row, e) {
      row["status"] = e;
      row.statusColor = this.getStatusIconColor(e.value);
    },
    getStatusIconColor(status) {
      if (status === null) return this.Colors.system.gray;

      return status
        ? this.Colors.system.primaryLight
        : this.Colors.system.inactive;
    },
    refreshOnDelete() {
      this.$refs.mainTableRef.refreshTable();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isEditMode) {
      next();
    } else {
      let result = confirm("You have unsaved changes. Do you want to proceed?");
      if (result) {
        next();
      } else {
        next(false);
      }
    }
  },
};
