import { MutationTypes } from "@/constants/MutationTypes";

export default {
  [MutationTypes.SET_ORGANIZATIONS]: (state, payload) => {
    state.organizations = payload;
  },

  [MutationTypes.CREATE_BUSINESS_LOCATIONS]: (state, payload) => {
    state.businessLocations = [payload, ...state.businessLocations];
  },

  [MutationTypes.SET_BUSINESS_LOCATIONS]: (state, payload) => {
    state.businessLocations = payload;
  },

  [MutationTypes.UPDATE_BUSINESS_LOCATIONS]: (state, payload) => {
    let index = state.businessLocations.findIndex(businessLocation => businessLocation.id === payload.id);
    state.businessLocations[index].status = payload.status;
  },

  [MutationTypes.SET_BUSINESS_PROFILE_CONTACTS]: (state, payload) => {
    state.businessProfileContacts = payload;
  },

  [MutationTypes.UPDATE_BUSINESS_PROFILE_CONTACTS]: (state, payload) => {
    state.businessProfileContacts = [payload, ...state.businessProfileContacts];
  },

  [MutationTypes.REMOVE_BUSINESS_PROFILE_CONTACTS]: (state, businessProfileContactId) => {
    state.businessProfileContacts = [...state.businessProfileContacts.filter(
      (businessProfileContact) => businessProfileContact.id != businessProfileContactId)];
  },

  [MutationTypes.SET_BUSINESS_BRANDS]: (state, payload) => {
    state.businessBrands = payload;
  },

  [MutationTypes.UPDATE_BUSINESS_BRANDS]: (state, payload) => {
    state.businessBrands = [payload, ...state.businessBrands.filter(
      (businessBrand) => businessBrand.id != payload.id)];
  },

  [MutationTypes.SET_BUSINESS_NICKNAMES]: (state, payload) => {
    state.businessNicknames = {
      ...state.businessNicknames,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_BUSINESS_REVENUES]: (state, payload) => {
    state.businessRevenues = payload;
  },

  [MutationTypes.SET_BUSINESS_SOCIALS]: (state, payload) => {
    state.businessSocials = payload;
  },

  [MutationTypes.SET_BUSINESS_TAGS]: (state, payload) => {
    state.businessTags = {
      ...state.businessTags,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_BUSINESS_NOTES]: (state, payload) => {
    state.businessNotes = {
      ...state.businessNotes,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_BUSINESS_LOGS]: (state, payload) => {
    state.businessLogs = {
      ...state.businessLogs,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_ENTITY_PARENT_HIERARCHIES]: (state, payload) => {
    state.entityParentHierarchies = payload;
  },

  [MutationTypes.SET_ENTITY_CHILD_HIERARCHIES]: (state, payload) => {
    state.entityChildHierarchies = payload;
  },

  [MutationTypes.SET_ENTITY_SIBLING_HIERARCHIES]: (state, payload) => {
    state.entitySiblingHierarchies = payload;
  },

  [MutationTypes.UPDATE_ENTITY_PARENT_HIERARCHIES]: (state, payload) => {
    state.entityParentHierarchies = [payload, ...state.entityParentHierarchies];
  },

  [MutationTypes.UPDATE_ENTITY_CHILD_HIERARCHIES]: (state, payload) => {
    state.entityChildHierarchies = [payload, ...state.entityChildHierarchies];
  },

  [MutationTypes.REMOVE_ENTITY_PARENT_HIERARCHIES]: (state, hierarchyId) => {
    state.entityParentHierarchies = [...state.entityParentHierarchies.filter(
      (entityParentHierarchy) => entityParentHierarchy.id != hierarchyId)];
  },

  [MutationTypes.REMOVE_ENTITY_CHILD_HIERARCHIES]: (state, hierarchyId) => {
    state.entityChildHierarchies = [...state.entityChildHierarchies.filter(
      (entityChildHierarchy) => entityChildHierarchy.id != hierarchyId)];
  },

  [MutationTypes.SET_BUSINESS_INDUSTRIES]: (state, payload) => {
    state.businessIndustries = {
      ...state.businessIndustries,
      [payload.id]: payload.data
    };
  },

  [MutationTypes.SET_BUSINESS_DISCIPLINES]: (state, payload) => {
    state.businessDisciplines = payload;
  },

  [MutationTypes.ADD_BUSINESS_DISCIPLINES]: (state, payload) => {
    state.businessDisciplines = [payload, ...state.businessDisciplines];
  },

  [MutationTypes.REMOVE_BUSINESS_DISCIPLINES]: (state, id) => {
    state.businessDisciplines = [...state.businessDisciplines.filter((businessDiscipline) => businessDiscipline.id != id)];
  },

  [MutationTypes.SET_BUSINESS_SPECIALITIES]: (state, payload) => {
    state.businessSpecialities = payload;
  },

  [MutationTypes.SET_BUSINESS_RELATIONSHIPS]: (state, payload) => {
    state.businessRelationships = payload;
  },

  [MutationTypes.ADD_BUSINESS_RELATIONSHIPS]: (state, payload) => {
    state.businessRelationships = [payload, ...state.businessRelationships];
  },

  [MutationTypes.UPDATE_BUSINESS_RELATIONSHIPS]: (state, payload) => {
    state.businessRelationships = [payload, ...state.businessRelationships.filter(
      (businessRelationship) => businessRelationship.id != payload.id)];
  },

  [MutationTypes.SET_BUSINESS_AGENCY_CONTACTS]: (state, payload) => {
    state.businessAgencyContacts = payload;
  },

  [MutationTypes.REMOVE_BUSINESS_AGENCY_CONTACTS]: (state, businessAgencyContactId) => {
    state.businessAgencyContacts = [...state.businessAgencyContacts.filter(
      (businessAgencyContact) => businessAgencyContact.person.id != businessAgencyContactId)];
  },
};
