<template>
  <div class="company-brands-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="company-brands">
      <winbox-company-header
        :title="mainTitle"
        :enable-status-toggle="true"
        @toggle-status="updateVisibleStatus"
        @changed="openModal"
      />
      <div class="columns">
        <div class="column table">
          <winbox-data-table
            class="main-data-table"
            :data="visibleData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :data-tooltip="data.row.id"
                :to="{ path: constructProfileUrl(data.row.id) }"
              >
                {{ data.row[tableProps.name] }}
              </router-link>
            </template>
          </winbox-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="createModal"
      :name="createModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Create new"
      save-text="Create"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <winbox-create-brand
        v-if="isCreateNewLoaded"
        ref="createBrand"
        :data="newBrandEntity"
      />
      <winbox-large-indicator v-if="!isCreateNewLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxCreateBrand from "@/components/Organisms/WinboxCreateBrand";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import * as BusinessesApi from "@/api/businesses/businesses";
import { MutationTypes } from "@/constants/MutationTypes";

const tableProps = {
  name: "name",
  industry: "__industries",
  description: "description",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "CompanyBrands",
  components: {
    WinboxLargeIndicator,
    WinboxCompanyHeader,
    WinboxDataTable,
    WinboxModal,
    WinboxCreateBrand
  },
  data() {
    return {
      visibileStatus: true,
      newBrandEntity: {
        [tableProps.name]: "",
        [tableProps.description]: "",
        [tableProps.industry]: ""
      },
      isCreateNewLoaded: true,
      Colors: Colors,
      isLoaded: true,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.industry]: "Industry",
          [tableProps.description]: "Description",
        },
        sortable: [...mainTableColumns],
        filterable: [...mainTableColumns]
      },
      specificSlots: [tableProps.name],
      createModal: "createModal"
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapState([
      'currentCompany',
      'businessBrands',
    ]),
    id() {
      return +this.$route.params.id;
    },
    entity() {
      return this.currentCompany;
    },
    brandsData() {
      return this.businessBrands.map(item => {
        return {
          ...item,
          [tableProps.industry]: this.getHumanIndustries(item.industries),
        };
      });
    },
    mainTitle() {
      return `Brands (${this.visibleData.length})`;
    },
    activeData() {
      return this.brandsData.filter((data) => data.published);
    },
    inactiveData() {
      return this.brandsData.filter((data) => !data.published);
    },
    visibleData() {
      if(this.visibileStatus == true) {
        return this.activeData;
      }
      else {
        return this.inactiveData;
      }
    },
  },
  methods: {
    ...mapActions([
      'updateBrandFromCompany',
      'getBrandAgenciesByCompany',
      'getBrandAgencyContactsByCompany',
      'getBusinessBrands',
    ]),
    async getData() {
      this.isLoaded = false;

      await this.getBusinessBrands(this.id);

      this.isLoaded = true;
    },
    updateVisibleStatus(value) {
      if (value === "Past") {
        this.visibileStatus = false;
      }
      else if (value === "Active") {
        this.visibileStatus = true;
      }
      return this.visibileStatus;
    },
    constructProfileUrl(id) {
      return Utils.getBrandProfileUrl(id);
    },
    getHumanIndustries(industries) {
      return Utils.makeArrayToString(industries);
    },
    openModal() {
      this.$refs[this.createModal].show();
    },
    onCancel() {
      this.$refs[this.createModal].hide();
    },
    async onSave() {
      const createBrandVm = this.$refs.createBrand;
      const $v = createBrandVm.$v;

      $v.$touch();

      if (!$v.$error) {
        this.isCreateNewLoaded = false;
        const payload = {
          name: createBrandVm.entity.name,
          description: createBrandVm.entity.description,
          scope: "national"
        };

        const { data } = await BusinessesApi.createBrandAssignToBusiness(
          this.id,
          payload
        );

        this.$store.commit(MutationTypes.ADD_UPDATE_BRAND, data);
        const allIndustriesCreate = this.prepareIndustries(createBrandVm, data);

        await this.assignAllIndustries(allIndustriesCreate);

        this.afterSave(data.id);
        this.isCreateNewLoaded = true;
      }
    },
    prepareIndustries(createBrandVm, data) {
      return createBrandVm.entity.industry.map(item => {
        return {
          brandId: data.id,
          industryId: item.value
        };
      });
    },
    async assignAllIndustries(items) {
      let industryIds = items.map((item) => item.industryId);
      let brandId = items[0].brandId;
      await this.createBrandIndustryAssociation(brandId, industryIds);
    },
    async createBrandIndustryAssociation(brandId, industryIds) {
      const payload = {
        industry_ids: industryIds
      };

      return this.$store.dispatch("assignBrandIndustries", {
        id: brandId,
        payload: payload
      });
    },
    afterSave(id) {
      this.onCancel();
      this.goToNewCreatedEntity(id);
    },
    goToNewCreatedEntity(id) {
      let url = this.constructProfileUrl(id);
      this.$router.push(url);
    },
    async updateLocationStatus(data, status) {
      const payload = {
        status: status
      };
      await this.updateBrandFromCompany({ id: data.id, payload });
      this.getBrandAgencyContactsByCompany(this.id);
      this.getBrandAgenciesByCompany(this.id);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";

.company-brands {
  padding-top: 0.5rem;
  .main-data-table {
    ::v-deep {
      font-size: 13px;
      thead {
        th {
          width: 200px;
        }
      }
      .vs__dropdown-toggle {
        border-color: transparent;
      }
    }
  }
}
</style>
