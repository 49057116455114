var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-main" },
    [
      _c("div", { key: _vm.statusKey, staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c("div", [_vm._v("Entity")]),
            _vm._v(" "),
            _vm.currentTask && _vm.possibleTasks.length > 0
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      "data-tooltip": _vm.currentTask.entityable_id,
                      to: {
                        path: _vm.constructEntityUrl(
                          _vm.currentTask.entityable_id,
                          _vm.currentTask.entityable_type
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.addElipsis(_vm.currentTask.entityable_name)
                        ) +
                        "\n      "
                    )
                  ]
                )
              : _c("div", [_vm._v("All your current tasks are complete.")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "each-column" },
          [
            _c("div", [_vm._v("Task")]),
            _vm._v(" "),
            _vm.currentTask && _vm.possibleTasks.length > 0
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      "data-tooltip": _vm.currentTask.task.name,
                      to: {
                        path: _vm.constructEntityUrl(
                          _vm.currentTask.entityable_id,
                          _vm.currentTask.entityable_type
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.currentTask.task.name) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "each-column detail-column" },
          [
            _c("div", [_vm._v("Details")]),
            _vm._v(" "),
            _vm.currentTask && _vm.possibleTasks.length > 0
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      "data-tooltip": _vm.currentTask.details,
                      to: {
                        path: _vm.constructEntityUrl(
                          _vm.currentTask.entityable_id,
                          _vm.currentTask.entityable_type
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.addElipsis(_vm.currentTask.details)) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "each-column status-column",
            style: { paddingTop: 20 + "px" }
          },
          [
            _vm.currentTask && _vm.possibleTasks.length > 0
              ? _c("winbox-select", {
                  style: _vm.styleObj,
                  attrs: { options: _vm.possibleStatuses },
                  on: {
                    input: function($event) {
                      return _vm.onStatusChange()
                    }
                  },
                  model: {
                    value: _vm.currentTask.status,
                    callback: function($$v) {
                      _vm.$set(_vm.currentTask, "status", $$v)
                    },
                    expression: "currentTask.status"
                  }
                })
              : _c("winbox-select", {
                  style: _vm.styleObj,
                  attrs: { options: _vm.possibleStatuses, disabled: true }
                })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: "escalateTaskMainModal",
          attrs: {
            name: "escalateTaskMainModal",
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Escalate Task",
            "save-text": "Escalate"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _c("winbox-escalate-task", {
            ref: "escalateTaskMainRef",
            attrs: {
              "backend-errors": _vm.backendErrors,
              "default-admin-id": _vm.defaultAdminId
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }