var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "entry-wrap" }, [
    _c("div", { staticClass: "columns is-vcentered entry-top" }, [
      _c("div", { staticClass: "column" }, [_vm._t("entityName")], 2),
      _vm._v(" "),
      _vm.hasProofing && _vm.entity.proofed_by_name
        ? _c("div", { staticClass: "column is-narrow header-action-wrap" }, [
            _vm._v(
              "\n      Last Proof: " +
                _vm._s(_vm.entity.proofed_by_name) +
                " - " +
                _vm._s(new Date(_vm.entity.proofed_at).toLocaleDateString()) +
                "\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasProofing
        ? _c("div", { staticClass: "column is-narrow header-action-wrap" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.$emit("submit-proof")
                  }
                }
              },
              [_c("span", [_vm._v("Proof")])]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "column is-narrow header-action-wrap" }, [
        _vm.hasAssign && _vm.isAdmin()
          ? _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.$emit("on-assign")
                  }
                }
              },
              [
                _c("winbox-icon", {
                  staticClass: "add-entity-icon",
                  attrs: { color: _vm.Colors.system.lightGray, icon: "plus" }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("Assign")])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.enableSwitcher
        ? _c("div", { staticClass: "column is-narrow" }, [
            _c("div", { staticClass: "columns is-vcentered is-gapless" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-narrow" },
                [
                  _c("winbox-switch", {
                    model: {
                      value: _vm.entity.published,
                      callback: function($$v) {
                        _vm.$set(_vm.entity, "published", $$v)
                      },
                      expression: "entity.published"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns company-entry-content" }, [
      _c(
        "div",
        { staticClass: "column is-3" },
        [
          _c(
            "div",
            { staticClass: "entry-base-information" },
            [_vm._t("parentLink")],
            2
          ),
          _vm._v(" "),
          _vm.entity && _vm.entity.website
            ? _c(
                "div",
                { staticClass: "entry-base-information" },
                [_vm._t("baseInfo")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", [_vm._t("entityLogo")], 2),
          _vm._v(" "),
          _c(
            "winbox-tabs",
            _vm._l(_vm.menuItems, function(item, key) {
              return _c(
                "winbox-tab-item",
                {
                  key: key,
                  attrs: {
                    link: item.link,
                    "additional-class": _vm.getAdditionalActive(item)
                  }
                },
                [
                  _vm._v("\n          " + _vm._s(item.title) + "\n          "),
                  item.count
                    ? _c(
                        "span",
                        {
                          staticClass: "item-count",
                          attrs: { title: item.count }
                        },
                        [_vm._v(_vm._s(item.count))]
                      )
                    : _vm._e()
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.menuSubItems.length
            ? _c("div", { staticClass: "in-house-title" }, [
                _vm._v("\n        In - House\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "winbox-tabs",
            _vm._l(_vm.menuSubItems, function(item, key) {
              return _c(
                "winbox-tab-item",
                { key: key, attrs: { link: item.link } },
                [
                  _vm._v("\n          " + _vm._s(item.title) + "\n          "),
                  item.count
                    ? _c(
                        "span",
                        {
                          staticClass: "item-count",
                          attrs: { title: item.count }
                        },
                        [_vm._v(_vm._s(item.count))]
                      )
                    : _vm._e()
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-9 column-route-view" },
        [_vm.entity ? _c("router-view") : _vm._e()],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("span", { staticClass: "switch-label" }, [_vm._v("Active?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }