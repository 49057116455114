<template functional>
  <div class="winbox-global-progress">
    <progress
      class="progress"
      :class="props.progressClasses"
      :value="props.value"
      :max="props.max"
    />
  </div>
</template>

<script>
export default {
  name: "WinboxGlobalProgress",
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    max: {
      type: [Number, String],
      required: false,
      default: 100
    },
    progressClasses: {
      type: String,
      required: false,
      default: "is-small is-primary"
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-global-progress {
  position: fixed;
  z-index: 5;
  width: 100%;

  .progress {
    border-radius: 0;
    height: 5px;
  }
}
</style>
