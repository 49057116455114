export default {
  USER: state => {
    return state.user;
  },

  AUTH: state => {
    return state.auth;
  },

  USERS: state => {
    return state.users;
  },

  USER_ROLES: state => {
    return state.userRoles;
  }
};
