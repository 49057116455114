<template>
  <div class="business-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="business-wrap-content">
      <!-- Removing "Sync to Winmo" and "Export" until a later date
      entity-secondary-label="Sync to Winmo"
      entity-secondary-icon="sync"
      @changedSecondary="openSyncModal"
      entity-third-label="Export"
      @changedThird="onExportData" -->
      <winbox-company-header
        v-if="isAdmin()"
        :title="mainTitle"
        :entity-secondary-disabled="!markedRows.length"
        entity-fourth-label="Assign"
        @changed="openModal"
        @changedFourth="onAssignModal"
      />
      <!-- Hides "Export" button 
      entity-third-label="Export"
      changedThird="onExportData" -->
      <winbox-company-header
        v-else
        :title="mainTitle"
        entity-third-label="Export"
        @changed="openModal"
      />
      <div class="columns">
        <div class="column">
          <winbox-server-data-table
            ref="mainTableRef"
            class="main-data-table"
            :url="getCompaniesUrl()"
            :responseFunction="enhanceData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
            @rowsChange="onRowsChange"
            @filter="onFilter"
            @updateQuery="onUpdateQuery"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :data-tooltip="data.row.id"
                :to="{ path: constructProfileUrl(data.row.id) }"
              >
                {{ data.row[tableProps.name] }}
              </router-link>
            </template>
            <template :slot="tableProps.location" slot-scope="data">
              <router-link
                :data-tooltip="data.row[tableProps.location].location_id"
                :to="{
                  path: constructLocationUrl(
                    data.row.id,
                    getLocationId(data.row)
                  ),
                }"
              >
                {{ data.row[tableProps.location].name }}
              </router-link>
            </template>
            <template :slot="tableProps.type" slot-scope="data">
              {{ data.row[tableProps.type] | winboxHumanOrgType }}
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <div class="status-wrap">
                <winbox-icon :color="data.row.statusColor" :icon="data.row.statusIcon" />
              </div>
            </template>
          </winbox-server-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="createModal"
      :name="createModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Create new"
      save-text="Create"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <winbox-create-business-entity
        ref="createBusinessRef"
        v-if="newBusinessEntity && isCreateNewBusinessLoaded"
        :data="newBusinessEntity"
      />
      <winbox-large-indicator v-if="!isCreateNewBusinessLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="syncModal"
      :name="syncModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Sync to Winmo"
      save-text="Sync"
      @on-cancel="onSyncCancel"
      @on-save="onSyncSave"
    >
      <p v-if="isSyncModalLoaded">Are you sure?</p>
      <winbox-large-indicator v-if="!isSyncModalLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="deleteModal"
      :name="deleteModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      :title="deleteTitle"
      save-text="Ok"
      @on-cancel="onDeleteCancel"
      @on-save="onDeleteAccept"
    >
      <p v-if="isDeleteLoaded">Are you sure?</p>
      <winbox-large-indicator v-if="!isDeleteLoaded" />
    </winbox-modal>
    <winbox-modal
      :ref="assignModal"
      :name="assignModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Assign Company Tasks"
      save-text="Assign"
      @on-cancel="onAssignCancel"
      @on-save="onAssignSave"
    >
      <winbox-assign-entity-tasks
        ref="assignEntityTasksRef"
        v-if="isAssignNewEntityTasksLoaded"
      />
      <winbox-large-indicator v-if="!isAssignNewEntityTasksLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxServerDataTable from "@/components/Molecules/WinboxServerDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxCreateBusinessEntity from "@/components/Organisms/WinboxCreateBusinessEntity";
import { WinboxCreateBusinessEntityHelperMixin } from "@/components/Organisms/WinboxCreateBusinessEntityHelperMixin";
import WinboxAssignEntityTasks from "@/components/Organisms/WinboxAssignEntityTasks";
import { WinboxAssignEntityTasksHelperMixin } from "@/components/Organisms/WinboxAssignEntityTasksHelperMixin";
import { BusinessEntitiesHelperMixin } from "@/components/Pages/Generic/BusinessEntitiesHelperMixin";
import { BusinessEditHelperMixin } from "@/components/Pages/Generic/BusinessEditHelperMixin";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { BusinessTypes } from "@/constants/BusinessTypes";
import { MutationTypes } from "@/constants/MutationTypes";
import { BusinessStatuses } from "@/constants/BusinessStatuses";
import WinboxIcon from "@/components/Atoms/WinboxIcon";

import * as ExportApi from "@/api/export/export";

const tableProps = {
  id: "id",
  name: "name",
  location: "location",
  type: "org_type",
  status: "status",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "Companies",
  mixins: [
    WinboxCreateBusinessEntityHelperMixin,
    WinboxAssignEntityTasksHelperMixin,
    BusinessEntitiesHelperMixin,
    BusinessEditHelperMixin,
    RolesMixin
  ],
  components: {
    WinboxLargeIndicator,
    WinboxServerDataTable,
    WinboxCompanyHeader,
    WinboxModal,
    WinboxCreateBusinessEntity,
    WinboxAssignEntityTasks,
    WinboxIcon,
  },
  computed: {
    mainTitle() {
      return `Companies (${this.itemsCount})`;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
  },
  data() {
    return {
      isLoaded: false,
      markedRows: [],
      searchQuery: null,
      // Default values when Create new
      businessEntity: {
        type: BusinessTypes.company,
      },
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.location]: "Location",
          [tableProps.type]: "Type",
          [tableProps.status]: "Status",
        },
        sortable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
        filterable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
        filterAlgorithm: {
          name(row, query) {
            return row.name.includes(query.trim());
          },
          location(row, query) {
            return row.location && row.location.includes(query.trim());
          },
        },
      },
      specificSlots: [
        tableProps.id,
        tableProps.name,
        tableProps.location,
        tableProps.type,
        tableProps.status,
      ],
      Colors: Colors,
      deleteTitle: "Delete company",
      businessType: "COMPANY",
      businessTypeDelete: "COMPANY",
    };
  },
  beforeMount() {
    this.loadData();
    this.syncType = "companies";
  },
  watch: {
    '$route.query': {
      handler (oldUrl, newUrl) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    sortByName(prev, next) {
      return prev.name > next.name ? 1 : next.name > prev.name ? -1 : 0;
    },
    loadData() {
      // TODO need refactor
      this.isLoaded = false;
      this.isLoaded = true;
      this.afterLoadData();
    },
    afterLoadData() {
      if (typeof this.tryToSetGlobalFilters === "function") {
        this.$nextTick(() => {
          this.tryToSetGlobalFilters();
        });
      }
    },
    enhanceData(data, count) {
      this.itemsCount = count.toLocaleString();

      return data.map((item) => {
        return {
          ...item,
          [tableProps.location]: this.getCompanyLocation(
            item.company
          ),
          [tableProps.status]: Utils.findStatusForSelect(
            item[tableProps.status],
            this.possibleStatuses
          ),
          statusColor: this.getStatusIconColor(item.published),
          statusIcon: this.getStatusIcon(item.published),
        };
      });
    },
    getCompanyLocation(company) {
      let result = { name: "", location_id: "" };
      if (company && company.location) {
        result = {
          name: `${company.location.location_city}, ${company.location.location_state}`,
          location_id: company.location.location_id
        };
      }
      return result;
    },
    getStatusIconColor(status) {
      if (status === null) return this.Colors.system.gray;

      return status
        ? this.Colors.system.primaryLight
        : this.Colors.system.inactive;
    },
    getStatusIcon(status) {
      if (status === null) return "circle";

      return status
        ? "circle"
        : "times";
    },
    afterSave(data) {
      if (data && data.id) {
        this.goToNewCreatedEntity(data.id);
        this.$store.commit(MutationTypes.ADD_UPDATE_COMPANY, data);
      }
    },
    constructProfileUrl(id) {
      return Utils.getCompanyProfileUrl(id);
    },
    constructLocationUrl(companyId, locationId) {
      return Utils.getCompanyLocationDetailUrl(companyId, locationId);
    },
    getLocationId(row) {
      return row.location ? row.location.location_id : null;
    },
    updateStatus(row, status) {
      const payload = {
        business: {
          status: status,
        },
      };
      row.statusColor = this.getStatusIconColor(status);
      row.statusIcon = this.getStatusIcon(status);
      this.updateCompanyStatus(row.id, payload);
    },
    updateCompanyStatus(id, payload) {
      this.$store
        .dispatch("updateCompany", {
          id,
          payload,
        })
        .then(() => {
          this.$forceUpdate();
        })
        .catch((e) => {});
    },
    getCompaniesUrl() {
      const locations = this.$route.query.locations;
      return `${Utils.getCompaniesPageUrl()}${locations && locations.length !== 0 ? `?locations=${locations}` : ''}`;
    },
    onExportData() {
      const locations = this.$route.query.locations;
      const locationQuery = (locations && locations.length !== 0 ? `locations=${locations}` : '');

      const textQuery = (this.searchQuery && this.searchQuery.length !== 0 ? `query=${this.searchQuery}` : '');

      const query = [locationQuery, textQuery].filter((item) => item != '').join('&');

      ExportApi.exportCompanies({ ids: this.markedRows, query }).then((data) => {
        this.csvExport(data.data);
      });
    },
    onRowsChange(e) {
      this.markedRows = [...e];
    },
    onUpdateQuery(data) {
      this.searchQuery = data.query;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-data-table {
  ::v-deep {
    font-size: 13px;
    thead {
      th {
        &:nth-child(2),
        &:nth-child(3) {
          width: 300px;
        }
        &:nth-child(4) {
          width: 150px;
        }
        &:nth-child(5) {
          min-width: 150px;
        }
        &:nth-last-child(2) {
          min-width: 216px;
          width: 216px;
        }
        &:last-child {
          min-width: 100px;
          width: 100px;
        }
      }
    }
    .status-wrap {
      .icon {
        margin: 0 5px 0 0;
      }
      .winbox-select {
        display: inline-block;
        width: 110px;
      }
    }
  }
}
</style>
