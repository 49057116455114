<template src="../../Pages/Generic/EntityTagsMixin.html" />

<script>
import { mapActions } from "vuex";

import { EntityTagsMixin } from "@/components/Pages/Generic/EntityTagsMixin";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

export default {
  name: "AgencyTags",
  mixins: [EntityTagsMixin, WinboxSpecialityTagsHelperMixin],
  components: {},
  data() {
    return {
      isLoaded: true
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getTags',
      'getBusinessTags',
    ]),
    async getData() {
      this.isLoaded = false;

      this.getTags();
      await this.getBusinessTags(this.id);

      this.isLoaded = true;
    },
  },
};
</script>

<style scoped lang="scss" src="../Generic/EntityTagsMixin.scss" />
