import moment from "moment";
import { DateFormats } from "@/constants/DateFormats";

export const DatepickerHelperMixin = {
  methods: {
    setDisabledDate(elem, date, direction) {
      const elemRef = this.$refs[elem];
      const rangeAmountMax = 1000;

      if (elemRef) {
        if (!date) {
          elemRef.datePickerInstance.setDisableDates(null);
        } else {
          if (direction === "right") {
            elemRef.datePickerInstance.setDisableDates([
              [
                date,
                moment(date, DateFormats.BASE).add(rangeAmountMax, "years")
              ]
            ]);
          } else if (direction === "left") {
            elemRef.datePickerInstance.setDisableDates([
              [
                moment(date, DateFormats.BASE).subtract(
                  rangeAmountMax,
                  "years"
                ),
                date
              ]
            ]);
          }
        }
      }
    },
    validateDateFields(tableProps, row, key, e) {
      let result = true;
      const validateDates = [tableProps.start, tableProps.end];

      if (validateDates.includes(key)) {
        if (key === tableProps.start && row[tableProps.end]) {
          if (
            moment(e, DateFormats.BASE).isAfter(
              moment(row[tableProps.end], DateFormats.BASE)
            )
          ) {
            result = false;
          }
        } else if (key === tableProps.end && row[tableProps.start]) {
          if (
            moment(e, DateFormats.BASE).isBefore(
              moment(row[tableProps.start], DateFormats.BASE)
            )
          ) {
            result = false;
          }
        }
      }

      return result;
    }
  }
};
