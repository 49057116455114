<template>
  <div class="business-entity-profile-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded && entity" class="business-entity-profile">
      <div class="columns is-desktop">
        <div class="column">
          <winbox-card>
            <template v-slot:header>
              General
            </template>
            <template v-slot:headerIcon>
              <button
                class="button save-button"
                @click="save(true)"
                v-if="isEditMode"
                :disabled="!isChanged"
              >
                Save
              </button>
              <button
                class="button cancel-button"
                @click="cancel(true)"
                v-if="isEditMode"
              >
                Cancel
              </button>

              <button
                class="button edit-button"
                @click="edit(true)"
                v-if="!isEditMode"
              >
                Edit
              </button>
            </template>
            <div class="content">
              <winbox-input
                label="Company Name"
                v-model.trim="$v.entity.name.$model"
                :class="status($v.entity.name)"
                :disabled="!isEditMode"
                @input="addToDataUpdate('name', entity.name)"
                field="name"
              />
              <div class="columns" v-if="!isUk">
                <div class="column is-12">
                  <winbox-select
                    form-label="NAICS Code"
                    v-model="entity.naics"
                    :options="possibleNaics"
                    :disabled="!isEditMode"
                    :clearable="isEditMode"
                    @input="addToDataUpdate('naics', entity && entity.naics && entity.naics.value)"
                  />
                </div>
              </div>
              <div class="columns general-columns">
                <div class="column is-3">
                  <winbox-select
                    form-label="Type"
                    v-model="entity.org_type"
                    :class="status($v.entity.org_type)"
                    :options="entityTypes"
                    :disabled="!isEditMode"
                    :clearable="isEditMode"
                    @input="addToDataUpdate('org_type', entity && entity.org_type && entity.org_type.value)"
                  />
                </div>
                <div class="column is-2">
                  <winbox-select
                    form-label="Employee Number"
                    v-model="entity.employeeRange"
                    :options="possibleEmployeeRanges"
                    :disabled="!isEditMode"
                    :clearable="isEditMode"
                    @input="addToDataUpdate('employee_range_id', entity && entity.employeeRange && entity.employeeRange.value)"
                  />
                </div>
                <div class="column is-2">
                  <winbox-input
                    label="Founded"
                    maxlength="4"
                    v-model.trim="$v.entity.year_founded.$model"
                    :class="status($v.entity.year_founded)"
                    :disabled="!isEditMode"
                    @input="addToDataUpdate('year_founded', entity.year_founded)"
                  />
                </div>
                <div class="column is-3">
                  <winbox-select
                    form-label="Fiscal Close"
                    v-model="entity.fiscal_close"
                    :options="fiscalCloses"
                    :disabled="!isEditMode"
                    :clearable="isEditMode"
                    @input="addToDataUpdate('fiscal_close', entity.fiscal_close)"
                  />
                </div>
                <div class="column is-2">
                  <winbox-input
                    label="Stock Symbol"
                    v-model="entity.stock_symbol"
                    :disabled="!isEditMode"
                    @input="addToDataUpdate('stock_symbol', entity.stock_symbol)"
                  />
                </div>
              </div>
              <winbox-input
                label="Short Description"
                v-model.trim="$v.entity.short_description.$model"
                :class="status($v.entity.short_description)"
                :disabled="!isEditMode"
                @input="addToDataUpdate('short_description', entity.short_description)"
              />
              <winbox-input
                v-if="isEditMode"
                label="Web Address"
                icon-left="globe-americas"
                v-model.trim="$v.entity.website.$model"
                :class="status($v.entity.website)"
                @input="addToDataUpdate('website', entity.website)"
                field="website"
              />
              <fieldset
                v-else
                class="border"
              >
                <legend class="float-none w-auto">Web Address</legend>
                <div class="website-container">
                  <a
                    class="website-link"
                    :href="`http://${entity.website}`" target="_blank"
                  >
                  {{ decodeURIComponent(entity.website) }}
                  </a>
                </div>
              </fieldset>
              <winbox-textarea
                v-model.trim="$v.entity.long_description.$model"
                :class="status($v.entity.long_description)"
                form-label="Long Description"
                @input="addToDataUpdate('long_description', entity.long_description)"
                :disabled="!isEditMode"
              />
              <winbox-input
                label="Nickname"
                v-model.trim="$v.entity.nickname.$model"
                :class="status($v.entity.nickname)"
                :disabled="!isEditMode"
                @input="addToDataUpdate('nickname', entity.nickname)"
              />
            </div>
          </winbox-card>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column">
          <winbox-card>
            <template v-slot:header>
              Social Media
            </template>
            <template v-slot:headerIcon>
              <winbox-icon
                v-if="isEditMode"
                icon="plus-circle"
                :color="Colors.system.primaryLight"
                @click.native="$refs.winboxSocialMediaRef.addNewSocialEntity"
              />
            </template>
            <div class="content">
              <winbox-social-media
                ref="winboxSocialMediaRef"
                :items="socialEntities"
                @changed="onSocialChanged"
                :disabled="!isEditMode"
              />
            </div>
          </winbox-card>
        </div>
        <div class="column">
          <winbox-card>
            <template v-slot:header>
              Company Revenue
            </template>
            <template v-slot:headerIcon>
              <winbox-icon
                v-if="isEditMode"
                icon="plus-circle"
                :color="Colors.system.primaryLight"
                @click.native="$refs.winboxRevenueRef.addNewEntity"
              />
            </template>
            <div class="content">
              <winbox-revenue
                ref="winboxRevenueRef"
                :items="revenueItems"
                :disabled="!isEditMode"
                @changed="onRevenueChanged"
              />
            </div>
          </winbox-card>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <winbox-card :expandable="true">
            <template v-slot:header>
              Speciality Lists
            </template>
            <div class="content">
              <winbox-speciality-tags-list
                :possible-tags="possibleTags"
                :entity-tags="selectedTags"
                :org-type="modelName"
                @changed="onTagsChanged($event, 'name')"
                :disabled="!isEditMode"
              />
            </div>
          </winbox-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";
import { BusinessEntityProfileMixin } from "@/components/Pages/Generic/BusinessEntityProfileMixin";
import { NicknamesAndSocialsMixin } from "@/components/Pages/Generic/NicknamesAndSocialsMixin";

import * as Utils from "@/utils";

export default {
  name: "CompanyProfile",
  mixins: [
    BusinessEntityProfileMixin,
    NicknamesAndSocialsMixin,
    WinboxSpecialityTagsHelperMixin,
  ],
  components: {},
  data() {
    return {
      modelName: "company",
      isLoaded: false,
      prevRoute: null,
      dataUpdate: {},
      saveTimeout: null,
      saveAfterTime: 1500,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState([
      'currentCompany',
      'businessSocials',
      'businessNicknames',
      'isInvalid',
    ]),
    isUk() {
      const isUKMetaTag = document.querySelector('meta[name="is-uk"]');
      return isUKMetaTag && isUKMetaTag.getAttribute('content') == 'true';
    },
    id() {
      return +this.$route.params.id;
    },
  },
  methods: {
    ...mapActions([
      'updateCompany',
      'getNaics',
      'getEmployeeRanges',
      'getSocials',
      'getBusinessSocials',
      'getDisciplines',
      'getSpecialities',
      'getBusinessSpecialities',
      'getBusinessNicknames',
      'edit',
      'reset',
      'save',
    ]),
    async getData() {
      this.isLoaded = false;
      this.entity = this.currentCompany;

      this.getBusinessRevenues();
      this.getDisciplines();
      this.updateBreadcrumbsTitles(this.entity, 'Company');
      this.getSpecialities("company");
      this.getBusinessSpecialities(this.id);
      this.loadOrgType();
      this.loadBusinessSocials();
      await Promise.all([
        this.loadCompanyNaics(),
        this.loadEmployeeRangeData(),
        this.loadBusinessNicknames(),
      ]);
      this.isLoaded = true;
      this.$v.$touch();
    },
    async loadCompanyNaics() {
      await this.getNaics();
      if (this.entity?.naic_code?.id) {
        this.entity.naics = this.possibleNaics.find(
          item => item.value === this.entity.naic_code.id
        );
      };
    },
    async loadEmployeeRangeData() {
      await this.getEmployeeRanges('Company');
      if (this.entity?.employee_range_id) {
        this.entity.employeeRange = this.possibleEmployeeRanges.find(
          item => item.value === this.entity.employee_range_id
        );
      };
    },
    async loadBusinessSocials() {
      this.getSocials();
      await this.getBusinessSocials(this.id);
      return this.socialEntities = this.businessSocials;
    },
    async loadBusinessNicknames() {
      await this.getBusinessNicknames(this.id);
      return this.nicknames = this.businessNicknames;
    },
    loadOrgType() {
      if (this.entity?.org_type) {
        this.entity.org_type = this.entityTypes.find(
          item => item.value === this.entity.org_type
        );
      }
    },
    saveEntity() {
      if (this.$v.entity.$error || this.$v.entity.$invalid || this.isInvalid) {
        this.save(false);
        return;
      }

      if (Object.keys(this.dataUpdate).length > 0) {
        const payload = { ...this.dataUpdate };
        this.updateCompany({
          id: this.id,
          payload,
        });
      }

      setTimeout(() => {
        this.edit(false);
      });

      this.afterSaveEntity();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.prevRoute = from;
    next();
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style scoped lang="scss" src="../Generic/BusinessEntityProfileMixin.scss" />
