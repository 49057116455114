<template>
  <div class="wrap-nicknames-block">
    <div v-if="!entities.length">No items</div>
    <ul v-if="entities.length" class="nicknames-list">
      <li v-for="(item, key) in entities" :key="key">
        <div class="columns is-vcentered is-variable is-1">
          <div class="column is-11">
            <winbox-input
              placeholder="Nickname"
              v-model="item.name"
              @input="onNameChanged"
              :disabled="disabled"
            />
          </div>
          <div class="column column-remove-entity">
            <button class="button button-delete icon-wrapper" v-if="!disabled">
              <winbox-icon
                icon="trash"
                :color="Colors.system.error"
                @click.native="removeEntity(item)"
                :disabled="disabled"
              />
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import * as Utils from "@/utils";

export default {
  name: "WinboxNicknames",
  components: {
    WinboxIcon,
    WinboxInput
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      Colors: Colors,
      entities: this.prepareEntities(this.items),
      timeout: null
    };
  },
  watch: {
    items() {
      this.entities = this.prepareEntities(this.items);
    },
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
  },
  methods: {
    ...mapActions([
      'change',
    ]),
    prepareEntities(items) {
      return Utils.sortNumericByPropComplex(cloneDeep(items), "id", true);
    },
    removeEntity(item) {
      let shouldBeConfirmed = item.id ? confirm("Are you sure?") : true;
      if (shouldBeConfirmed) {
        this.entities = this.entities.filter((entity) => entity.id != item.id);
        this.change(true);
      }
    },
    addNewEntity() {
      this.entities = [...this.entities, { id: this.entityCount++, name: '' }];
    },
    onNameChanged() {
      this.change(true);
    },
    editCancel() {
      this.entities = this.prepareEntities(this.items);
    },
    editAccept() {
      this.$emit("changed", this.entities);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.wrap-nicknames-block {
  .icon-wrapper {
    @include icon-wrapper-base-style;
  }
}
.nicknames-list {
  li {
    @include button-delete-universal-hover;
  }
}
.column-remove-entity {
  padding: $winbox-default-column-padding 0;
}
.button-delete {
  @include button-delete-universal;
}
</style>
