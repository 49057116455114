<template>
  <div class="winbox-select" :class="[theme, { 'has-label': !!formLabel }]">
    <label class="label winbox-label" v-if="formLabel"
      ><span>{{ formLabel }}</span></label
    >
    <v-select
      :value="selected"
      :options="options"
      :disabled="disabled"
      :clearable="clearable"
      :searchable="searchable"
      :multiple="multiple"
      :placeholder="placeholder"
      :transition="transition"
      :label="label"
      :taggable="taggable"
      @input="onInput"
      @search:focus="onFocus"
    >
      <div slot="no-options">{{ emptyText }}</div>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "WinboxSelect",
  components: { vSelect },
  data() {
    return {
      selected: []
    };
  },
  watch: {
    value: {
      handler() {
        this.selected = this.value;
      }
    }
  },
  created() {
    this.selected = this.value;
  },
  mounted() {
    this.disableAutocomplete();
  },
  props: {
    theme: {
      default: "default-theme"
    },
    value: {
      default: null
    },
    formLabel: {
      type: String,
      required: false,
      default: ""
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    transition: {
      type: String,
      default: "fade"
    },
    label: {
      type: String,
      default: "label"
    },
    taggable: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: "Please search an entity."
    }
  },
  methods: {
    onInput(e) {
      this.selected = e;
      this.$emit("input", this.selected);
    },
    onFocus() {
      this.$emit("focus", this.selected);
    },
    disableAutocomplete() {
      let input = this.$el.querySelector('input');
      input.setAttribute('type', 'text');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.data-import-page {
  .winbox-select {
    .winbox-label {
      color: black;

      span {
        background-color: #F7F7F7;
      }
    }
  }
}

.winbox-select {
  position: relative;

  .winbox-label {
    @include form-input-label;
  }

  &.has-label {
    ::v-deep {
      .vs__dropdown-toggle {
        padding: 5px 0 6px 0;
      }
    }
  }

  &.invalid,
  &.error {
    ::v-deep {
      .vs__dropdown-toggle {
        border-color: $winbox-error;
        background: $winbox-icon-negative-background;
        &:focus {
        outline: $winbox-icon-negative-background;
        }
      }
    }
    .winbox-label {
      span {
        background: $winbox-error;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
  ::v-deep {
    .vs__dropdown-menu {
      min-width: auto;
    }
    .vs__search {
      &::placeholder {
        font-size: $winbox-font-size-small;
        color: $winbox-input-placeholder;
      }
    }
    .vs__dropdown-option--highlight {
      background: $winbox-primary-active-bg-color;
    }
    .vs__dropdown-option {
      padding: 3px 15px;
    }
  }

  &.green-theme {
    ::v-deep {
      .vs__dropdown-toggle {
        background: $winbox-primary-active-bg-color;
        border-color: transparent;
        color: #fff;
      }
      .vs__search,
      .vs__search:focus,
      .vs__selected {
        color: #fff;
      }
      .vs__open-indicator {
        fill: #fff;
      }
    }
  }
}
</style>
