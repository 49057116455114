import { MutationTypes } from "@/constants/MutationTypes";

export default {
  [MutationTypes.SET_USER]: (state, payload) => {
    state.user = payload;
  },

  [MutationTypes.SET_AUTH]: (state, payload) => {
    state.auth = payload;
  },

  [MutationTypes.SET_USERS]: (state, payload) => {
    state.users = payload;
  },

  [MutationTypes.SET_USER_ROLES]: (state, payload) => {
    state.userRoles = payload;
  }
};
