<template>
  <div class="contact-profile-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded && entity" class="contact-profile">
      <div class="columns">
        <div class="column">
          <winbox-card>
            <template v-slot:header> Contact Information </template>
            <template v-slot:headerIcon>
              <button
                class="button save-button"
                @click="save(true)"
                v-if="isEditMode"
                :disabled="!isChanged"
              >
                Save
              </button>
              <button
                class="button cancel-button"
                @click="cancel(true)"
                v-if="isEditMode"
              >
                Cancel
              </button>

              <button
                class="button edit-button"
                @click="edit(true)"
                v-if="!isEditMode"
              >
                Edit
              </button>
            </template>
            <div class="content">
              <p v-if="backendErrors" class="error-block">
                {{ backendErrors }}
              </p>
              <div class="columns is-gapless">
                <div class="column is-narrow">
                  <div class="avatar-wrap">
                    <div class="avatar-img-box">
                      <img
                        width="80"
                        height="80"
                        :src="logo_url"
                        class="avatar-img"
                      />
                    </div>
                    <button
                      class="button add-avatar-action"
                      @click="openAvatarModal"
                    >
                      <winbox-icon
                        icon="pen"
                        :color="Colors.system.primaryLight"
                      />
                    </button>
                  </div>
                </div>
                <div class="column">
                  <div class="columns is-variable is-1">
                    <div class="column is-3">
                      <winbox-input
                        label="First Name"
                        v-model.trim="$v.entity.fname.$model"
                        :class="status($v.entity.fname)"
                        @input="addToDataUpdate('fname', entity.fname)"
                        :disabled="!isEditMode"
                      />
                    </div>
                    <div class="column is-3">
                      <winbox-input
                        label="Last Name"
                        v-model.trim="$v.entity.lname.$model"
                        :class="status($v.entity.lname)"
                        @input="addToDataUpdate('lname', entity.lname)"
                        :disabled="!isEditMode"
                      />
                    </div>
                    <div class="column is-3">
                      <winbox-input
                        class="active-icon-bg"
                        label="Direct"
                        icon-right="phone-alt"
                        v-model.trim="$v.entity.phone.$model"
                        :class="status($v.entity.phone)"
                        @input="addToDataUpdate('phone', entity.phone)"
                        mask="(###) ###-####"
                        :disabled="!isEditMode"
                      />
                    </div>
                    <div class="column">
                      <winbox-input
                        label="Extension"
                        v-model.trim="entity.phone_ext"
                        @input="addToDataUpdate('phone_ext', normalizedPhoneExt)"
                        mask="XXXXXXXXXX"
                        :disabled="!isEditMode"
                      />
                    </div>
                  </div>

                  <div class="columns is-variable is-1">
                    <div class="column is-6">
                      <winbox-input
                        label="Title"
                        v-model.trim="$v.entity.title.$model"
                        :class="status($v.entity.title)"
                        @input="addToDataUpdate('title', entity.title)"
                        :disabled="!isEditMode"
                      />
                    </div>
                    <div class="column is-6">
                      <winbox-select
                        v-model="entity.title_rank"
                        :options="possibleRanks"
                        :class="status($v.entity.title_rank)"
                        :searchable="false"
                        form-label="Title Rank"
                        @input="addToDataUpdate('title_rank', entity && entity.title_rank)"
                        :disabled="!isEditMode"
                        :clearable="isEditMode"
                      />
                    </div>
                  </div>

                  <div class="columns is-variable is-1">
                    <div class="column is-7">
                      <winbox-remote-select
                        :value="entity.organization"
                        form-label="Organization"
                        :url="getOrganizationSearchUrl()"
                        :responseFunction="responseOrganization"
                        :class="status($v.entity.organization)"
                        :minSearchLength="3"
                        @input="onChangedOrg"
                        :disabled="!isEditMode"
                      />
                    </div>
                    <div class="column is-5">
                      <winbox-select
                        v-model="entity.location"
                        :options="possibleLocations"
                        :class="status($v.entity.location)"
                        form-label="Location"
                        :emptyText="emptyLocationText"
                        @input="onChangedLocation"
                        :disabled="!isEditMode"
                      />
                    </div>
                  </div>

                  <div class="columns is-variable is-1">
                    <div class="column is-7 pb-0">
                      <winbox-input
                        v-if="isEditMode"
                        label="Email"
                        v-model.trim="$v.entity.email.$model"
                        :class="status($v.entity.email)"
                        class="active-icon-bg"
                        icon-right="envelope"
                        icon-right-namespace="far"
                        @input="addToDataUpdate('email', entity.email)"
                      />
                      <fieldset
                        v-else
                        class="border"
                      >
                        <legend class="float-none w-auto">Email</legend>
                        <div class="email-container">
                          <a
                            class="email-link"
                            :href="constructEmailLink(entity.email)"
                          >
                            {{entity.email}}
                          </a>
                          <div class="icon-container">
                            <winbox-icon
                              class="icon-box"
                              icon="envelope"
                              namespace="far"
                              container-class="is-small is-right"
                              color="dbdbdb"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="column is-5">
                      <div class="columns is-variable is-1 is-vcentered">
                        <div class="column">
                          <button
                            class="button action-button"
                            :class="{ 'is-primary': entity.is_provided }"
                            @click="
                              () => {
                                entity.is_provided = !entity.is_provided;
                                addToDataUpdate(
                                  'is_provided',
                                  entity.is_provided
                                );
                              }
                            "
                            :disabled="!isEditMode"
                          >
                            <input
                              type="checkbox"
                              v-model="entity.is_provided"
                              :disabled="!isEditMode"
                            />
                            <span>Provided</span>
                          </button>
                        </div>
                        <div class="column">
                          <button
                            class="button action-button"
                            :class="{ 'is-primary': entity.is_verified }"
                            @click="
                              () => {
                                entity.is_verified = !entity.is_verified;
                                addToDataUpdate(
                                  'is_verified',
                                  entity.is_verified
                                );
                              }
                            "
                            :disabled="!isEditMode"
                          >
                            <input
                              type="checkbox"
                              v-model="entity.is_verified"
                              :disabled="!isEditMode"
                            />
                            <span>Verified</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </winbox-card>
        </div>
      </div>
      <div class="contact-profile-tabs">
        <winbox-tabs v-if="routeMatchWithTabs" theme="horizontal-theme">
          <winbox-tab-item
            v-for="(item, key) in profileTabs"
            :key="key"
            :link="item.link"
          >
            {{ item.title }}
          </winbox-tab-item>
        </winbox-tabs>
        <div class="columns profile-tab-content">
          <div class="column">
            <router-view ref="tabContent" @mounted="onTabContentMount" />
          </div>
        </div>
      </div>
      <winbox-modal
        :ref="uploadAvatar"
        :name="uploadAvatar"
        :enable-footer-buttons="true"
        title="Update Logo"
        save-text="Update"
        @on-cancel="onCancelLogo"
        @on-save="onSaveLogo"
      >
        <winbox-input label="URL" v-model="newLogoUrl" />
      </winbox-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxRemoteSelect from "@/components/Atoms/WinboxRemoteSelect";
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxTabItem from "@/components/Atoms/WinboxTabItem";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxTabs from "@/components/Molecules/WinboxTabs";
import WinboxModal from "@/components/Molecules/WinboxModal";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import cloneDeep from "clone-deep";
import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { MutationTypes } from "@/constants/MutationTypes";
import * as PeopleApi from "@/api/people/people";

import { required, email } from "vuelidate/lib/validators";
import * as BusinessesApi from "@/api/businesses/businesses";
import { AnyEntityEntryMixin } from "@/components/Pages/Generic/AnyEntityEntryMixin";
import { EditProfileMixin } from "@/components/Pages/Generic/EditProfileMixin";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

export default {
  name: "ContactProfile",
  mixins: [EditProfileMixin, AnyEntityEntryMixin, RolesMixin],
  components: {
    WinboxCard,
    WinboxInput,
    WinboxSelect,
    WinboxRemoteSelect,
    WinboxIcon,
    WinboxTabItem,
    WinboxLargeIndicator,
    WinboxTabs,
    WinboxModal,
  },
  data() {
    return {
      isLoaded: false,
      Colors: Colors,
      entity: null,
      locationsData: [],
      uploadAvatar: "uploadAvatar",
      newLogoUrl: null,
      mainLocationId: null,
      mainTitleId: null,
      prevOrg: null,
      prevTitle: null,
      backendErrors: "",
      possibleOrgs: [],
    };
  },
  validations: {
    entity: {
      phone: {
        winboxValidPhone: Utils.winboxValidPhone,
      },
      fname: {
        required,
      },
      lname: {
        required,
      },
      title: {
        required,
      },
      title_rank: {
      },
      organization: {
        required,
      },
      location: {
        required,
      },
      email: {
        email,
      },
    },
  },
  watch: {
    entity(newValue, oldValue) {
      if(newValue) {
        this.setPersonOrganization(newValue.organization);
      }
    },
  },
  computed: {
    ...mapState([
      'logo_url',
      'currentContact',
      'personOrganization',
      'isInvalid',
    ]),
    routeMatchWithTabs() {
      return this.profileTabs.find((tab) => tab.link === this.$route.path);
    },
    profileTabs() {
      return [
        { link: this.titleFunctionsUrl, title: "Title Functions" },
        { link: this.brandsUrl, title: "Brands" },
        { link: this.socialMediaUrl, title: "Social Media" },
        { link: this.workHistoryUrl, title: "Work History" },
        { link: this.notesUrl, title: "Notes" },
        { link: this.specialityListsUrl, title: "Speciality Lists" },
      ];
    },
    id() {
      return +this.$route.params.id;
    },
    baseUrl() {
      return `${WinboxRoutePaths.CONTACTS}/${this.id}/${WinboxRoutePaths.CONTACT_PROFILE}`;
    },
    titleFunctionsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE_TITLE_FUNCTIONS}`;
    },
    brandsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE_BRANDS}`;
    },
    socialMediaUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE_SOCIAL_MEDIA}`;
    },
    workHistoryUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE_WORK_HISTORY}`;
    },
    notesUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE_NOTES}`;
    },
    specialityListsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.CONTACT_PROFILE_SPECIALITY_LISTS}`;
    },
    possibleRanks() {
      return this.$store.getters.TITLE_RANKS.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.rank,
        };
      });
    },
    possibleOrganizations() {
      return this.$store.getters.ORGANIZATIONS.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
          type: item.type,
        };
      });
    },
    possibleLocations() {
      if (!this.entity.organization) {
        return [];
      }

      return this.locationsData.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    emptyLocationText() {
      return "Please add a location";
    },
    normalizedPhone() {
      return this.normalizePhone(this.entity.phone);
    },
    normalizedPhoneExt() {
      return this.normalizePhone(this.entity.phone_ext);
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getLogo',
      "getPersonLogs",
      "setPersonOrganization",
      "getSpecialities",
      'edit',
      'reset',
      'save',
      'updateLogo',
    ]),
    getData() {
      const data = this.currentContact;
      const entityResult = cloneDeep(data);

      this.getLogo({ owner_id: this.id, owner_type: 'Person' });
      this.prevTitle = entityResult.title;

      if (data.person_title_rank) {
        entityResult.title_rank = {
          label: data.person_title_rank.rank,
          value: data.person_title_rank.id,
        };
      }

      let org = this.personOrganization;
      let loc = this.$store.getters.PERSON_LOCATION_BY_ID(this.id);

      if(org.id) {
        entityResult.organization = {
          id: org.id,
          label: org.name,
          value: org.id,
          type: org.type,
        };

        entityResult.location = {
          id: loc.id,
          label: loc.name,
          value: loc.id,
        };

        this.entity = entityResult;
        this.initData = cloneDeep(this.entity);
        this.getBusinessLocations(this.entity.organization.id);
      }

      this.isLoaded = true;
      this.updateBreadcrumbsTitles(data);
    },
    requestOrganization(search) {
      return BusinessesApi.getAllBusinesses(search);
    },
    getOrganizationSearchUrl() {
      return Utils.getOrganizationsSearchUrl();
    },
    saveEntity() {
      this.$v.$touch();
      this.backendErrors = "";

      if (this.$v.entity.$error || this.$v.entity.$invalid || this.isInvalid) {
        this.save(false);
        return;
      }

      if (Object.keys(this.dataUpdate).length > 0) {
        if(this.dataUpdate.hasOwnProperty('title_rank')) {
          this.dataUpdate['title_rank_id'] = this.dataUpdate.title_rank ? this.dataUpdate.title_rank?.id : null;
          delete this.dataUpdate.title_rank;
        }

        const payload = { ...this.dataUpdate };

        if (
          this.prevOrg &&
          this.prevOrg.value &&
          this.entity.organization &&
          this.prevOrg.value !== this.entity.organization.value
        ) {
          this.createNewWorkHistoryRecord();
        }

        this.$store
          .dispatch("updatePerson", {
            id: this.id,
            payload,
            type: "ADD_UPDATE_PROFILE_CONTACT",
          })
          .then(() => {
            this.setPrevOrg();
            this.$store.dispatch("getPersonBrands", this.id);
            this.$store.dispatch("getPersonTitleFunctions", this.id),
              this.$root.$emit(
                "changeContactOrganizationCompleted",
                this.prevOrg
              );
          })
          .catch((e) => {
            this.afterProfileSaveFail(e.response);
            this.$root.$emit("changeContactOrganizationCompleted", this.prevOrg);
          });
      }

      setTimeout(() => {
        this.edit(false);
      });

      this.afterSaveEntity();
    },
    setPrevOrg() {
      this.prevOrg = this.entity.organization;
      this.prevTitle = this.entity.title;
    },
    createNewWorkHistoryRecord() {
      const payload = this.prepareWorkHistoryPayload();

      this.$store
        .dispatch("createPersonWorkHistory", {
          id: this.id,
          payload: payload,
        })
        .then(() => {
          this.$children.forEach((cmp) => {
            if (cmp && cmp.isWorkHistoryComponent) {
              cmp.getData();
            }
          });
        });
    },
    prepareWorkHistoryPayload() {
      return {
        business_name: this.prevOrg.label,
        business_id: this.prevOrg.value,
        title: this.prevTitle,
        from_date: null,
        to_date: Utils.getServerDateFormat(),
      };
    },
    afterProfileSaveFail(response) {
      if (response && response.data) {
        const errors = response.data.errors;
        if (errors && errors.base && errors.base.length) {
          this.backendErrors = Object.values(errors.base).join(", ");
        }
      }
    },
    openAvatarModal() {
      this.$refs[this.uploadAvatar].show();
    },
    onCancelLogo() {
      this.$refs[this.uploadAvatar].hide();
    },
    onSaveLogo() {
      this.updateLogo({
        logo_url: this.newLogoUrl,
        owner_type: 'Person',
        owner_id: this.id,
      });

      this.$refs[this.uploadAvatar].hide();
    },
    onChangedOrg(e) {
      this.eraseMainFields();
      this.prevOrg = this.entity.organization;
      this.entity.organization = e;
      this.setPersonOrganization(e);
      this.getSpecialities(e.type);
      this.$v.$touch();
      this.$root.$emit("changeContactOrganization", this.prevOrg);
      if (e && e.id) {
        this.getBusinessLocations(e.id);
      }
      this.addToDataUpdate(
        "business_people_attributes.business_id",
        e ? e.id : null
      );
      this.resetContactProfileTabItems();
    },
    resetContactProfileTabItems() {
      this.$store.dispatch("resetContactProfileTabItems", { id: this.id, type: this.entity.organization.type });

      this.updatePossibleTitleFunctions();
      this.updatePossibleBrands();
    },
    onChangedLocation(e) {
      if (this.mainLocationId) {
        this.addToDataUpdate(
          "person_location_attributes.id",
          this.mainLocationId
        );
      }
      this.addToDataUpdate(
        "business_location_id",
        e ? e.value : null
      );
    },
    eraseMainFields() {
      const eraseFields = [
        "location",
        "phone",
        "phone_ext",
        "title",
        "title_rank",
        "email",
        "is_provided",
        "is_verified",
      ];

      eraseFields.forEach((item) => {
        this.entity[item] = null;
        this.addToDataUpdate(item, null);
      });
    },
    getBusinessLocations(id) {
      if (!id) {
        return;
      }
      return BusinessesApi.getBusinessLocations(id)
        .then(({ data }) => {
          this.locationsData = data.filter(location => location.status === 'active');

          if (this.locationsData.length === 1) {
            let item = this.locationsData[0];
            if (this.entity && !this.entity.location) {
              this.entity.location = {
                label: item.city,
                value: item.id,
              };
              this.addToDataUpdate(
                "business_location_id",
                item.id
              );
            }
          }
        })
        .catch(() => {
          this.locationsData = [];
        });
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    normalizePhone(value) {
      return value.replaceAll("-", "");
    },
    updatePossibleTitleFunctions() {
      let updatePossibleTitleFunctions =
        this.$refs.tabContent.updatePossibleTitleFunctions;
      if (updatePossibleTitleFunctions) {
        updatePossibleTitleFunctions(this.entity.organization?.type);
      }
    },
    updatePossibleBrands() {
      let updatePossibleBrands =
        this.$refs.tabContent.updatePossibleBrands;
      if (updatePossibleBrands) {
        updatePossibleBrands(this.id);
      }
    },
    onTabContentMount() {
      this.updatePossibleTitleFunctions(this.entity.organization?.type);
      this.updatePossibleBrands(this.id);
    },
    updateBreadcrumbsTitles(entity) {
      let newTitle = `${entity.fname} ${entity.lname}`;
      window.breadcrumbs[window.breadcrumbs.length - 1].to.meta.breadcrumb = newTitle;
      Utils.EventBus.$emit("updateBreadcrumbs");
    },
    responseOrganization(data) {
      return data.map((item) => {
        return {
          id: item.id,
          label: item.name,
          value: item.id,
          type: item.type,
        };
      });
    },
    afterSaveEntity() {
      this.initData = cloneDeep(this.entity);
      this.dataUpdate = {};
    },
    constructEmailLink(email) {
      return `mailto:${email}`;
    },
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.contact-profile {
  .save-button,
  .edit-button {
    background: green;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;
    margin-right: 16px;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
  .cancel-button {
    background: red;
    border-color: transparent;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
.avatar-wrap {
  position: relative;

  &:hover {
    .add-avatar-action {
      opacity: 1;
    }
  }
}
.add-avatar-action {
  position: absolute;
  bottom: -23px;
  right: 9px;
  background: transparent;
  border-color: transparent;
  opacity: 0;
}
.avatar-img-box {
  border: 1px solid $winbox-secondary-title;
  border-radius: 50%;
  overflow: hidden;
  width: 82px;
  height: 82px;
  margin: 0 1.5rem 1.5rem 0;
  display: flex;
}
.action-button {
  @include profile-action-button;
}
.active-icon-bg {
  ::v-deep {
    @include input-icon-box;
  }
}
.profile-tab-content {
  min-height: 400px;
}
.error-block {
  color: $winbox-error;
  margin: 0 0 1.5rem;
}

.icon {
  float: right;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.pb-0 {
  padding-bottom: 0;
}

fieldset {
  position: relative;
  top: -8.5px;
  min-width: 0;
  margin: 0;
  border: 0;
  height: 48px;
}

.email-link {
  position: relative;
  left: 8.5px;
  top: 9px;
}

.email-container {
  background-color: whitesmoke;
  border-radius: 4px;
  height: 40px;
  position: relative;
  top: -7px;
}

.w-auto {
  width: auto!important;
}
.float-none {
  float: none!important;
}
legend {
  background-color: white;
  color: $winbox-body-grey-bg;
  font-size: 12px;
  height: 15px;
  line-height: inherit;
  padding: 0 2px;
  position: relative;
  left: 7px;
  top: 2px;
  z-index: 2;
}

.icon-container {
  float: right;
  height: 40px;
  position: relative;
  padding: 9px;
  background: #e9eaeb;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
</style>
