import * as TasksApi from "@/api/tasks/tasks";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getTasks({ commit }) {
    return TasksApi.getTasks().then((data) => {
      commit(MutationTypes.SET_TASKS, data.tasks);
      commit(MutationTypes.SET_USERS, data.users);
    });
  },
};
