<template>
  <div class="create-entity">
    <div class="columns">
      <div class="column">
        <winbox-select
          v-model.trim="task.user"
          form-label="Resarcher"
          :options="possibleUsers"
          :class="status($v.task.user)"
        />
      </div>
      <div class="column">
        <winbox-select
         v-model.trim="task.taskType"
          form-label="Task Type"
          :options="possibleTaskTypes"
          :class="status($v.task.taskType)"
        />
      </div>
      <div class="column">
        <winbox-input
          v-model="task.completionGoalTo"
          :date-picker="true"
          ref="completionGoalTo"
          label="Completion Goal"
          class="active-icon-bg"
          :class="status($v.task.completionGoalTo)"
          icon-right="clock"
          @datepickerShow="
            setDisabledDate('completionGoalTo', $v.task.completionGoalFrom, 'right')
          "
        />
      </div>
      <div class="column priority">
        <winbox-input
            v-model="task.priority"
            ref="priority"
            label="Priority"
            class="active-icon-bg"
            :class="status($v.task.priority)"
          />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <winbox-textarea
          v-model.trim="task.details"
          form-label="Details"
          :maxlength="255"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxTextarea from "@/components/Atoms/WinboxTextarea";
import { DatepickerHelperMixin } from "@/components/Pages/Generic/DatepickerHelperMixin";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import { required, numeric } from "vuelidate/lib/validators";
import * as Utils from "@/utils";

export default {
  name: "WinboxAssignEntityTasks",
  mixins: [DatepickerHelperMixin],
  components: {
    WinboxInput,
    WinboxSelect,
    WinboxTextarea,
  },
  data() {
    return {
      Colors: Colors,
      task: {},
    };
  },
  validations: {
    task: {
      user: {
        required
      },
      taskType: {
        required
      },
      completionGoalTo: {
        required
      },
      priority: {
        numeric
      }
    }
  },
  computed: {
    ...mapState([
      'tasks',
      'users',
    ]),
    possibleUsers() {
      return this.users.map((user) => {
        return {
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
        };
      });
    },
    possibleTaskTypes() {
      return this.tasks.map((task) => {
        return {
          value: task.id,
          label: task.name,
        };
      });
    },
  },
  methods: {
    status(validation) {
      if(validation.$dirty) {
        return Utils.validationStatus(validation);
      }
      else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.create-entity {
  ::v-deep {
    @include profile-card-content-base-styles;
  }
}
.priority {
  max-width: 85px;
}
</style>
