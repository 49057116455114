<template>
  <div class="admin-console-page">
    <winbox-large-indicator v-if="!isLoaded" />
    <page-generic-entry
      v-if="isLoaded"
      :entity="entity"
      :menu-items="menuItems"
      :menu-sub-items="subItems"
      :enable-switcher="false"
    >
      <template v-slot:entityName>
        <winbox-title title="Admin Console" />
      </template>
      <template v-slot:baseInfo />
    </page-generic-entry>
  </div>
</template>

<script>
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";
import WinboxTitle from "@/components/Atoms/WinboxTitle";

import PageGenericEntry from "@/components/Pages/PageGenericEntry";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { RoleTypes } from "@/constants/RoleTypes";

export default {
  name: "AdminConsole",
  components: {
    WinboxTitle,
    WinboxLargeIndicator,
    PageGenericEntry
  },
  validations: {},
  data() {
    return {
      entity: {
        name: "Admin Console"
      },
      isLoaded: true
    };
  },
  computed: {
    baseUrl() {
      return `${WinboxRoutePaths.SETTINGS}`;
    },
    profileUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.SETTINGS_PROFILE}`;
    },
    reportsUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.SETTINGS_REPORTS}`;
    },
    usersUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.SETTINGS_USERS}`;
    },
    tasksUrl() {
      return `${this.baseUrl}/${WinboxRoutePaths.SETTINGS_TASKS}`;
    },
    menuItems() {
      let items = [
        { link: this.profileUrl, title: "Profile" },
        { link: this.reportsUrl, title: "Reports" },
        { link: this.usersUrl, title: "Users" },
        { link: this.tasksUrl, title: "Task Management" }
      ];

      if(this.$store.getters.USER.role != RoleTypes.ADMIN) {
        items = items.filter((item) => item.title !== "Users" && item.title !== "Task Management");
      }
      return items;
    },
    subItems() {
      return [];
    }
  }
};
</script>
