import * as BusinessesApi from "@/api/businesses/businesses";

export const WinboxCreateBusinessEntityHelperMixin = {
  data() {
    return {
      isCreateNewBusinessLoaded: true,
      newBusinessEntity: null,
      createModal: "createModal"
    };
  },
  methods: {
    openModal() {
      this.newBusinessEntity = this.prepareNewBusinessEntity(
        this.businessEntity
      );
      this.$refs[this.createModal].show();
    },
    prepareNewBusinessEntity(data) {
      return {
        name: "",
        long_description: "",
        short_description: "",
        discipline: "",
        website: "",
        type: data.type,
        org_type: data.org_type,
        ownership: data.ownership
      };
    },
    goToNewCreatedEntity(id) {
      let url = this.constructProfileUrl(id);
      this.$router.push(url);
    },
    onCancel() {
      this.$refs[this.createModal].hide();
    },
    onSave() {
      const createBusinessVm = this.$refs.createBusinessRef;
      const $v = createBusinessVm.$v;

      $v.$touch();

      if (!$v.$error) {
        this.isCreateNewBusinessLoaded = false;
        const payload = { ...createBusinessVm.entity };

        BusinessesApi.createEntity(payload)
          .then(({ data }) => {
            if (typeof this.afterSave === "function") {
              this.afterSave(data, payload);
            }
          })
          .catch(e => {
            this.afterSaveFail(e.response, payload.name);
            throw e;
          })
          .finally(() => {
            this.isCreateNewBusinessLoaded = true;
          });
      }
    },
    afterSaveFail(response, name) {
      if (response && response.data) {
        const errors = response.data.errors;
        if (errors) {
          Object.entries(errors).forEach(([key, value]) => {
            value.forEach( error => { this.$notify({ type: 'error', text: key+' '+error, title: 'Error message', group: "main" });} );
          });
        }
      }
    }
  }
};
