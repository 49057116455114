var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "logo-wrap" }, [
        _c("div", { staticClass: "logo-img-box" }, [
          _c("img", { attrs: { width: "80", height: "80", src: _vm.logoUrl } })
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button add-logo-action",
            on: { click: _vm.openLogoModal }
          },
          [
            _c("winbox-icon", {
              attrs: { icon: "pen", color: _vm.Colors.system.primaryLight }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.logoModal,
          attrs: {
            name: _vm.logoModal,
            "enable-footer-buttons": true,
            title: "Update Logo",
            "save-text": "Update"
          },
          on: { "on-save": _vm.onSaveLogoUrl, "on-cancel": _vm.closeLogoModal }
        },
        [
          _c("winbox-input", {
            attrs: { label: "URL" },
            model: {
              value: _vm.newLogoUrl,
              callback: function($$v) {
                _vm.newLogoUrl = $$v
              },
              expression: "newLogoUrl"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }