export const WinboxSpecialityTagsHelperMixin = {
  methods: {
    onTagsChanged(e) {
      if (e) {
        if (e.newTags && e.newTags[0]) {
          if (typeof this.createNewTagEntity === "function") {
            this.createNewTagEntity(e.newTags);
          }
        }
        if (e.selectedTags) {
          if (typeof this.assignTagEntityToBusiness === "function") {
            this.assignTagEntityToBusiness(e.selectedTags);
          }
        }
      }
    }
  }
};
