import { UsersUrl } from "@/constants/UsersUrl";
import { getGeneric, postGeneric, putGeneric, deleteGenericFullResponse } from "@/api/_generic/_generic";

export function getUsers() {
  const url = UsersUrl.base;
  return getGeneric(url);
}

export function createUser(data) {
  const url = UsersUrl.base;
  return postGeneric(url, data);
}

export function updateUser(id, data) {
  const url = `${UsersUrl.base}/${id}`;
  return putGeneric(url, data);
}

export function deleteUser(id) {
  const url = `${UsersUrl.base}/${id}`;
  return deleteGenericFullResponse(url);
}
