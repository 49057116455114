<template>
  <div class="business-wrap">
    <div class="business-wrap-content">
      <winbox-company-header
        v-if="isAdmin()"
        :title="mainTitle"
        :enable-status-toggle="true"
        active-label="Unstarted"
        inactive-label="Completed"
        in-progress-label="In Progress"
        escalated-label="Escalated"
        @toggle-status="updateVisibleStatus"
      />
      <winbox-company-header
        v-else
        :title="mainTitle"
        :enable-status-toggle="true"
        active-label="Unstarted"
        inactive-label="Completed"
        in-progress-label="In Progress"
        @toggle-status="updateVisibleStatus"
      />
      <div class="columns" :key="statusKey">
        <div class="column">
          <winbox-data-table
            class="main-data-table"
            :data="visibleData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
          >
            <template :slot="tableProps.entity" slot-scope="data">
              <router-link
                :data-tooltip="data.row.id"
                :to="{ path: constructEntityUrl(data.row.entityable_id, data.row.entityable_type) }"
              >
                {{ data.row[tableProps.entity] }}
              </router-link>
            </template>
            <template :slot="tableProps.task" slot-scope="data">
              {{ data.row[tableProps.task].name }}
            </template>
            <template :slot="tableProps.details" slot-scope="data">
              {{ data.row[tableProps.details] }}
            </template>
            <template :slot="tableProps.completionGoal" slot-scope="data">
              {{ data.row[tableProps.completionGoal] }}
            </template>
            <template :slot="tableProps.priority" slot-scope="data">
              {{ data.row[tableProps.priority] }}
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <winbox-select
                :key="data.row.id"
                :value="data.row[tableProps.status]"
                :options="possibleStatuses"
                :searchable="false"
                @input="onStatusChange(data.row, $event)"
              />
            </template>
          </winbox-data-table>
          <winbox-modal
            :ref="escalateModalMyWorkRef"
            :name="escalateModalMyWorkRef"
            :enable-footer-buttons="true"
            :click-to-close="false"
            title="Escalate Task"
            save-text="Escalate"
            @on-cancel="onCancel"
            @on-save="onSave"
          >
            <winbox-escalate-task
              ref="escalateMyWorkTaskRef"
              :backend-errors="backendErrors"
              :default-admin-id="defaultAdminId"
            />
          </winbox-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import { UserTaskStatuses } from "@/constants/UserTaskStatuses";
import WinboxModal from "@/components/Molecules/WinboxModal";
import WinboxEscalateTask from "@/components/Molecules/WinboxEscalateTask";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

import { DateFormats } from "@/constants/DateFormats";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";

const tableProps = {
  id: "id",
  entity: "entityable_name",
  task: "task",
  details: "details",
  completionGoal: "completionGoal",
  priority: "priority",
  status: "status",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "WinboxUserTasks",
  mixins: [
    RolesMixin
  ],
  components: {
    WinboxSelect,
    WinboxCompanyHeader,
    WinboxDataTable,
    WinboxModal,
    WinboxEscalateTask,
  },
  data() {
    return {
      escalateModalMyWorkRef: "escalateMyWorkModal",
      escalateMyWorkTaskRef: "escalateMyWorkTaskRef",
      statusSelectRef: "statusSelectRef",
      backendErrors: null,
      currentTask: null,
      defaultAdminId: null,
      statusKey: 0,
      visibileStatus: 'Unstarted',
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.entity]: "Entity",
          [tableProps.task]: "Task",
          [tableProps.details]: "Details",
          [tableProps.completionGoal]: "Completion Goal",
          [tableProps.priority]: "Priority",
          [tableProps.status]: "Status"
        },
        sortable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
        filterable: [...mainTableColumns].filter(
          (item) => item !== tableProps.id
        ),
      },
      specificSlots: [
        tableProps.id,
        tableProps.entity,
        tableProps.task,
        tableProps.details,
        tableProps.completionGoal,
        tableProps.priority,
        tableProps.status
      ],
      Colors: Colors,
    };
  },
  computed: {
    ...mapState([
      'userTasks',
      'user'
    ]),
    mainTitle() {
      return 'My Work';
    },
    possibleStatuses() {
      return UserTaskStatuses.map((status) => {
        return {
          ...status,
        };
      });
    },
    enhanceData(data) {
      return this.userTasks.map((item) => {
        return {
          ...item,
          completionGoal: item.completion_goal ? (new Date(item.completion_goal).toLocaleDateString('en-US', {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })) : '',
        };
      });
    },
    uncompletedData() {
      return this.enhanceData.filter((data) => data.status === 'Unstarted');
    },
    completedData() {
      return this.enhanceData.filter((data) => data.status === 'Completed');
    },
    inProgressData() {
      return this.enhanceData.filter((data) => data.status === 'In Progress');
    },
    escalatedData() {
      return this.enhanceData.filter((data) => data.status === 'Escalated');
    },
    visibleData() {
      if(this.visibileStatus === 'Unstarted') {
        return this.uncompletedData;
      }
      else if(this.visibileStatus === 'In Progress') {
        return this.inProgressData;
      }
      else if(this.visibileStatus === 'Escalated') {
        return this.escalatedData;
      }
      else {
        return this.completedData;
      }
    },
  },
  methods: {
    ...mapActions([
      'updateUserTask',
      'updateUser',
    ]),
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    constructEntityUrl(entityId, entityType) {
      switch(entityType) {
        case 'Company':
          return Utils.getCompanyProfileUrl(entityId);
          break;
        case 'Agency':
          return Utils.getAgencyProfileUrl(entityId);
          break;
        case 'Brand':
          return Utils.getBrandProfileUrl(entityId);
          break;
        case 'Person':
          return Utils.getContactProfileUrl(entityId);
          break;
      }
    },
    getStatusIconColor(status) {
      if (status === null) return this.Colors.system.gray;

      return status
        ? this.Colors.system.primaryLight
        : this.Colors.system.inactive;
    },
    onStatusChange(row, e) {
      const newStatus = e.value;

      if (newStatus === "Escalated") {
        this.currentTask = row;
        this.defaultAdminId = this.user ? this.user.escalate_user_id : null;
        this.$refs[this.escalateModalMyWorkRef].show();
      } else {
        const item = { id: row.id };
        const payload = { status: newStatus };

        this.updateUserTask({ id: item.id, payload })
          .then(() => {
            this.$emit("task-completed");
          })
          .catch(error => {
            console.error('Error updating user task:', error);
          });
      }
    },
    onCancel() {
      this.statusKey += 1;
      this.$refs[this.escalateModalMyWorkRef].hide();
    },
    onSave() {
      const selectedAdmin = this.$refs[this.escalateMyWorkTaskRef].selectedAdmin;
      const escalationDetails = this.$refs[this.escalateMyWorkTaskRef].escalationDetails;

      if (selectedAdmin) {
        this.reassignTaskToAdmin(selectedAdmin, escalationDetails);
      } else {
        this.backendErrors = "Please select an admin.";
      }
    },
    reassignTaskToAdmin(selectedAdmin, details) {
      const item = { id: this.currentTask.id };
      const payload = {
        status: "Escalated",
        user_id: selectedAdmin.id,
        details: details,
      };
      this.updateUserTask({ id: item.id, payload })
      .then(() => {
        return this.updateUser({ id: this.currentTask.user_id, payload: { escalate_user_id: selectedAdmin.id } });
      })
      .then(() => {
        this.$refs[this.escalateModalMyWorkRef].hide();
        this.$emit("task-completed");
      })
      .catch((error) => {
        this.backendErrors = error.response.data.message;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-data-table {
  ::v-deep {
    font-size: 12px;
    thead {
      th {
        &:nth-child(2) {
          width: 200px;
        }
        &:nth-child(4) {
          width: 300px;
        }
        &:nth-child(6){
          width: 75px;
        }
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(7) {
          width: 150px;
        }
      }
    }
    .status-wrap {
      .icon {
        margin: 0 5px 0 0;
      }
      .winbox-select {
        display: inline-block;
        width: 110px;
      }
    }
  }
}
</style>
