var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-work-history" },
    [
      _c("div", { staticClass: "wrap-company-header" }, [
        _c("div", { staticClass: "columns is-vcentered" }, [
          _c(
            "div",
            { staticClass: "column" },
            [_c("winbox-title", { attrs: { title: _vm.mainTitle } })],
            1
          ),
          _vm._v(" "),
          _vm.isEditMode
            ? _c(
                "div",
                { staticClass: "column is-narrow header-action-wrap" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: { click: _vm.openCreateModal }
                    },
                    [
                      _c("winbox-icon", {
                        staticClass: "add-entity-icon",
                        attrs: {
                          color: _vm.Colors.system.lightGray,
                          icon: "plus"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Create")])
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "winbox-card",
        [
          _c(
            "winbox-data-table",
            {
              staticClass: "main-data-table",
              attrs: {
                data: _vm.dataset,
                columns: _vm.columns,
                options: _vm.tableOptions,
                slots: _vm.specificSlots
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: _vm.tableProps.title,
                    fn: function(data) {
                      return [
                        _vm._v(
                          "\n       " +
                            _vm._s(data.row[_vm.tableProps.title]) +
                            "\n      "
                        )
                      ]
                    }
                  },
                  {
                    key: _vm.tableProps.company,
                    fn: function(data) {
                      return [
                        _vm._v(
                          "\n       " +
                            _vm._s(data.row[_vm.tableProps.company].label) +
                            "\n      "
                        )
                      ]
                    }
                  },
                  {
                    key: _vm.tableProps.start,
                    fn: function(data) {
                      return [
                        _vm._v(
                          "\n       " +
                            _vm._s(data.row[_vm.tableProps.start]) +
                            "\n      "
                        )
                      ]
                    }
                  },
                  {
                    key: _vm.tableProps.end,
                    fn: function(data) {
                      return [
                        _vm._v(
                          "\n       " +
                            _vm._s(data.row[_vm.tableProps.end]) +
                            "\n      "
                        )
                      ]
                    }
                  },
                  {
                    key: _vm.tableProps.options,
                    fn: function(data) {
                      return [
                        _vm.isEditMode
                          ? _c(
                              "button",
                              { staticClass: "button button-edit" },
                              [
                                _c("winbox-icon", {
                                  attrs: {
                                    icon: "pen",
                                    color: _vm.Colors.system.primaryLight
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.onEdit(data.row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isEditMode
                          ? _c(
                              "button",
                              { staticClass: "button button-delete" },
                              [
                                _c("winbox-icon", {
                                  attrs: {
                                    icon: "trash",
                                    color: _vm.Colors.system.error
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.removeEntity(data.row.id)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _c(
                "tr",
                {
                  staticClass: "visible-thead-row",
                  attrs: { slot: "prependHead" },
                  slot: "prependHead"
                },
                [
                  _c("th", [_vm._v("Title")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Company")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Start")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("End")]),
                  _vm._v(" "),
                  _c("th")
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.workHistoryModal,
          staticClass: "notes-modal",
          attrs: {
            name: _vm.workHistoryModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            width: 800,
            title: _vm.modalTitle,
            "save-text": "Save"
          },
          on: { "on-cancel": _vm.onCancel, "on-save": _vm.onSave }
        },
        [
          _c("winbox-input", {
            class: _vm.status(_vm.$v.modalDataRow[_vm.tableProps.title]),
            attrs: { placeholder: "Title" },
            model: {
              value: _vm.$v.modalDataRow[_vm.tableProps.title].$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.modalDataRow[_vm.tableProps.title],
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.modalDataRow[tableProps.title].$model"
            }
          }),
          _vm._v(" "),
          _c("winbox-remote-select", {
            class: _vm.status(_vm.$v.modalDataRow[_vm.tableProps.company]),
            attrs: {
              multiple: false,
              taggable: true,
              url: _vm.getOrganizationSearchUrl(),
              responseFunction: _vm.responseOrganization,
              placeholder: "Company"
            },
            model: {
              value: _vm.modalDataRow[_vm.tableProps.company],
              callback: function($$v) {
                _vm.$set(
                  _vm.modalDataRow,
                  _vm.tableProps.company,
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "modalDataRow[tableProps.company]"
            }
          }),
          _vm._v(" "),
          _c("winbox-input", {
            ref: `date-start-${_vm.modalDataRow.id}`,
            class: _vm.status(_vm.$v.modalDataRow[_vm.tableProps.start]),
            attrs: {
              "date-picker": true,
              placeholder: "Start",
              "icon-left": "calendar-alt"
            },
            on: {
              datepickerShow: function($event) {
                return _vm.setDisabledDate(
                  `date-start-${_vm.modalDataRow.id}`,
                  _vm.modalDataRow[_vm.tableProps.end],
                  "right"
                )
              }
            },
            model: {
              value: _vm.$v.modalDataRow[_vm.tableProps.start].$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.modalDataRow[_vm.tableProps.start],
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.modalDataRow[tableProps.start].$model"
            }
          }),
          _vm._v(" "),
          _c("winbox-input", {
            ref: `date-end-${_vm.modalDataRow.id}`,
            class: _vm.status(_vm.$v.modalDataRow[_vm.tableProps.end]),
            attrs: {
              "date-picker": true,
              placeholder: "End",
              "icon-left": "calendar-alt"
            },
            on: {
              datepickerShow: function($event) {
                return _vm.setDisabledDate(
                  `date-end-${_vm.modalDataRow.id}`,
                  _vm.modalDataRow[_vm.tableProps.start],
                  "left"
                )
              }
            },
            model: {
              value: _vm.$v.modalDataRow[_vm.tableProps.end].$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.modalDataRow[_vm.tableProps.end],
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.modalDataRow[tableProps.end].$model"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }