<template>
  <div class="columns winbox-data-table-search-columns">
    <div class="column is-10">
      <input
        v-model="mainFilter"
        class="input main-search-input"
        type="text"
        :placeholder="placeholderText"
        @input="onInput"
      />
    </div>
    <div class="column is-1">
      <button
        class="button is-primary custom-filters-btn"
        @click="showFilterModal"
      >
        Custom Filter
      </button>
      <winbox-data-table-filter-modal
        class="filter-modal"
        v-show="filterModalIsVisible"
        @changed="onFiltersChange"
      />
    </div>
  </div>
</template>

<script>
import WinboxDataTableFilterModal from "@/components/Molecules/WinboxDataTableFilterModal";

import { Colors } from "@/Colors";

export default {
  name: "WinboxDataTableSearch",
  components: {
    WinboxDataTableFilterModal
  },
  props: {
    data: {
      required: true,
      type: Array
    },
    placeholderText: {
      required: false,
      type: String,
      default: "Filter (e.x. 'BMW')"
    }
  },
  data() {
    return {
      Colors: Colors,
      mainFilter: "",
      searchTimeout: null,
      datasetFiltered: [...this.data],
      filterModalIsVisible: false
    };
  },
  methods: {
    onInput(e) {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.search();
        this.emitState();
      }, 300);
    },
    search() {
      const searchVal = this.mainFilter.trim();
      if (!searchVal) {
        this.datasetFiltered = [...this.data];
      }
      this.datasetFiltered = this.filterByPattern(this.data, searchVal);
    },
    filterByPattern(data, searchVal) {
      const result = [];

      data.forEach(item => {
        for (let key in item) {
          if (typeof item[key] === "string") {
            const regex = new RegExp(searchVal, "i");
            if (regex.test(item[key])) {
              result.push(item);
              break;
            }
          }
        }
      });

      return result;
    },
    showFilterModal() {
      this.filterModalIsVisible = true;
      this.toggleMainBg();
    },
    hideFilterModal() {
      this.filterModalIsVisible = false;
      this.toggleMainBg();
    },
    toggleMainBg() {
      document.querySelector(".main-modal-bg").classList.toggle("active");
    },
    onFiltersChange(e) {
      this.hideFilterModal();
    },
    emitState() {
      this.$emit("search", this.datasetFiltered);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.winbox-data-table-search-columns {
  background: $winbox-primary-active-bg-color;
  margin: 0;
  position: relative;
}
.main-search-input {
  @include datatable-search-input;
}
.custom-filters-btn {
  background: $winbox-button-focus-color;
  height: auto;
  padding: 8px 13px;
  font-size: 12px;
}
.filter-modal {
  position: absolute;
  z-index: 2;
}
</style>
