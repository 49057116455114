<template src="../../Pages/Generic/EntityTagsMixin.html" />

<script>
import { mapActions } from "vuex";

import { EntityTagsMixin } from "@/components/Pages/Generic/EntityTagsMixin";
import { WinboxSpecialityTagsHelperMixin } from "@/components/Organisms/WinboxSpecialityTagsHelperMixin";

export default {
  name: "BrandTags",
  mixins: [EntityTagsMixin, WinboxSpecialityTagsHelperMixin],
  components: {},
  data() {
    return {
      isLoaded: true
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    /* @Override */
    selectedTags() {
      let result = this.$store.getters.BRAND_TAGS_BY_ID(this.id);

      return this.modifySelectedTags(result);
    }
  },
  methods: {
     ...mapActions([
      'getTags',
      'getBrandTags',
    ]),
    async getData() {
      this.isLoaded = false;

      this.getTags();
      await this.getBrandTags(this.id);

      this.isLoaded = true;
    },
    /* @Override */
    assignTagEntityToBusiness(selectedTags) {
      const payload = {
        tag_id: selectedTags[0].id
      };

      this.$store.dispatch("createBrandEntityTag", {
        id: this.id,
        payload: payload
      });
    },
    /* @Override */
    removeBusinessTagEntity(removedTags) {
      const deleteItem = removedTags[0];

      this.$store.dispatch("removeBrandEntityTag", {
        id: this.id,
        tagId: deleteItem.id
      });
    }
  }
};
</script>

<style scoped lang="scss" src="../Generic/EntityTagsMixin.scss" />
