var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "winbox-hierarchy-entity" },
    [
      _c(
        "winbox-card",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
              },
              proxy: true
            },
            {
              key: "headerIcon",
              fn: function() {
                return [
                  _vm.canAddEntity
                    ? _c("winbox-icon", {
                        attrs: {
                          icon: "plus-circle",
                          color: _vm.Colors.system.white
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.addNewTag.apply(null, arguments)
                          }
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            !_vm.selected.length
              ? _c("p", { staticClass: "no-data" }, [_vm._v("No items")])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "hierarchy-item-list" },
              _vm._l(_vm.selected, function(item) {
                return _c(
                  "li",
                  {
                    key: item[_vm.propId],
                    staticClass: "columns is-gapless hierarchy-item",
                    class: {
                      _inactive:
                        item.status === _vm.BusinessStatuses.inactive.value
                    }
                  },
                  [
                    item.status === _vm.BusinessStatuses.inactive.value
                      ? _c("div", { staticClass: "column is-narrow" }, [
                          _c("div", { staticClass: "item-indicator" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "hierarchy-item-columns columns is-gapless is-vcentered"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "column is-9" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    "data-tooltip": item[_vm.propId],
                                    to: { path: _vm.constructProfileUrl(item) }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item[_vm.propLabel]))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              !item.id
                                ? _c(
                                    "div",
                                    { staticClass: "new-tag-input-wrap" },
                                    [
                                      !item.type
                                        ? _c(
                                            "div",
                                            { staticClass: "item-type" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "please-select"
                                                },
                                                [_vm._v("Please select type:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "columns is-variable is-1 business-types-list"
                                                },
                                                _vm._l(
                                                  _vm.possibleBusinessTypes,
                                                  function(businessItem, key) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: key,
                                                        staticClass:
                                                          "column is-narrow"
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "#"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                item.type = businessItem
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                businessItem
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.type === "company" ||
                                      item.type === "agency"
                                        ? _c("winbox-remote-select", {
                                            attrs: {
                                              value: item,
                                              responseFunction:
                                                _vm.responseHierarchy,
                                              url: _vm.getHierarchyOrganizationsSearchUrl(
                                                item.type
                                              ),
                                              minSearchLength: 3,
                                              emptyText:
                                                "Please search an entity."
                                            },
                                            on: {
                                              input: function($event) {
                                                item._entity = $event
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.type === "brand"
                                        ? _c("winbox-remote-select", {
                                            attrs: {
                                              value: item,
                                              responseFunction:
                                                _vm.responseHierarchy,
                                              url: _vm.getHierarchyBrandsSearchUrl(),
                                              minSearchLength: 3,
                                              emptyText:
                                                "Please search an entity."
                                            },
                                            on: {
                                              input: function($event) {
                                                item._entity = $event
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "column is-3 action-right" },
                            [
                              !item.id && item.type
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button button-create",
                                      on: {
                                        click: function($event) {
                                          return _vm.createNewTag(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("winbox-icon", {
                                        attrs: {
                                          icon: "check",
                                          color: _vm.Colors.system.primaryLight
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.serviceName != "sibling"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button button-remove",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeTag(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("winbox-icon", {
                                        attrs: {
                                          icon: "trash",
                                          color: _vm.Colors.system.error
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("button", {
                                    staticClass: "button default",
                                    attrs: { disabled: "" }
                                  })
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }