export const UserTaskStatuses = [
  {
    label: "Unstarted",
    value: "Unstarted",
  },
  {
    label: "In Progress",
    value: "In Progress",
  },
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "Escalate",
    value: "Escalated",
  }
];
