export default {
  INDUSTRIES: state => {
    return state.industries;
  },

  INDUSTRY_BY_ID(state) {
    return id =>
      state.industries.find(item => {
        return item.id === id;
      });
  },

  TAGS: state => {
    return state.tags;
  },

  DISCIPLINES: state => {
    return state.disciplines;
  },

  SPECIALITIES: state => {
    return state.specialities;
  },

  TITLE_RANKS: state => {
    return state.titleRanks;
  },

  TITLE_FUNCTIONS: state => {
    return state.titleFunctions;
  },

  SERVICES: state => {
    return state.services;
  },

  MEDIA_TYPES: state => {
    return state.mediaTypes;
  },

  GLOBAL_LOADER_VISIBLE: state => {
    return state.globalLoaderVisible;
  },

  IS_PROFILE_PAGE: state => {
    return state.isProfilePage;
  },

  STATES: state => {
    return state.states;
  },

  TV_REGIONS: state => {
    return state.tvRegions;
  },

  NAICS: state => {
    return state.naics;
  },

  EMPLOYEE_RANGES: state => {
    return state.employeeRanges;
  },

  LOCATIONS: state => {
    return state.locations;
  },
};
