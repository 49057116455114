import { mapState } from "vuex";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxCreateContact from "@/components/Organisms/WinboxCreateContact";

import * as BusinessesApi from "@/api/businesses/businesses";

import * as Utils from "@/utils";
import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";

const tableProps = {
  name: "name",
  title: "title",
  business: "business",
  email: "email"
};
const mainTableColumns = Object.values(tableProps);

export const EntityContactsMixin = {
  components: {
    WinboxLargeIndicator,
    WinboxDataTable,
    WinboxCompanyHeader,
    WinboxModal,
    WinboxCreateContact
  },
  data() {
    return {
      Colors: Colors,
      isLoaded: true,
      visibileStatus: 'Active',
      entity: {},
      columns: [...mainTableColumns],
      tableProps: tableProps,
      tableOptions: {
        headings: {
          [tableProps.name]: "Name",
          [tableProps.title]: "Title",
          [tableProps.business]: "Business",
          [tableProps.email]: "Email"
        },
        sortable: [...mainTableColumns],
        filterable: [...mainTableColumns]
      },
      specificSlots: [tableProps.name, tableProps.email]
    };
  },
  computed: {
    ...mapState([
      'businessLocations',
      'businessProfileContacts',
    ]),
    id() {
      return +this.$route.params.id;
    },
    dataset() {
      return this.businessProfileContacts.map((businessProfileContact) => {
        return {
          ...businessProfileContact,
          business: this.businessName(),
          name: `${businessProfileContact.fname} ${businessProfileContact.lname}`,
        };
      });
    },
    mainTitle() {
      return `Contacts (${this.visibleData.length})`;
    },
    locationsData() {
      return this.businessLocations;
    },
    possibleLocations() {
      return this.locationsData.map(item => {
        return {
          id: item.id,
          label: item.city,
          value: item.id
        };
      });
    },
    activeData() {
      return this.dataset.filter((data) => data.published);
    },
    inactiveData() {
      return this.dataset.filter((data) => !data.published);
    },
    visibleData() {
      if(this.visibileStatus === 'Active') {
        return this.activeData;
      }
      else {
        return this.inactiveData;
      }
    },
  },
  beforeMount() {
    this.entity = cloneDeep(this.data);
  },
  methods: {
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    businessName() {
      return this.businessEntity.name;
    },
    constructContactProfileUrl(id) {
      return Utils.getContactProfileUrl(id);
    },
    constructEmailLink(email) {
      return `mailto:${email}`;
    },
    saveFn(payload) {
      return BusinessesApi.createBusinessContact(this.id, payload);
    }
  }
};
