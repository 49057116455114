// Contacts
import Contacts from "@/components/Pages/Contacts/Contacts";
import ContactEntry from "@/components/Pages/Contacts/ContactEntry";
import ContactProfile from "@/components/Pages/Contacts/ContactProfile";
import ContactTitleFunctions from "@/components/Organisms/ContactProfileTabs/ContactTitleFunctions";
import ContactBrands from "@/components/Organisms/ContactProfileTabs/ContactBrands";
import ContactSocialMedia from "@/components/Organisms/ContactProfileTabs/ContactSocialMedia";
import ContactWorkHistory from "@/components/Organisms/ContactProfileTabs/ContactWorkHistory";
import ContactNotes from "@/components/Organisms/ContactProfileTabs/ContactNotes";
import ContactSpecialityLists from "@/components/Organisms/ContactProfileTabs/ContactSpecialityLists";

import ContactTags from "@/components/Pages/Contacts/ContactTags";
import ContactInternalNotes from "@/components/Pages/Contacts/ContactInternalNotes";
import ContactAuditLog from "@/components/Pages/Contacts/ContactAuditLog";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";

export default [
  {
    path: WinboxRoutePaths.CONTACTS,
    name: "Contacts",
    component: Contacts,
    meta: {
      breadcrumb: "Contacts"
    }
  },
  {
    path: `${WinboxRoutePaths.CONTACTS}/:id`,
    component: ContactEntry,
    children: [
      {
        path: WinboxRoutePaths.CONTACT_PROFILE,
        component: ContactProfile,
        name: "ContactProfile",
        redirect: WinboxRoutePaths.CONTACT_PROFILE+"/"+WinboxRoutePaths.CONTACT_PROFILE_TITLE_FUNCTIONS,
        children: [
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_TITLE_FUNCTIONS,
            component: ContactTitleFunctions,
           name: "TitleFunctions",
            meta: {
              breadcrumb: "Contact Profile"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_BRANDS,
            component: ContactBrands,
            meta: {
              breadcrumb: "Brands"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_SOCIAL_MEDIA,
            component: ContactSocialMedia,
            meta: {
              breadcrumb: "Social Media"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_WORK_HISTORY,
            component: ContactWorkHistory,
            meta: {
              breadcrumb: "Work History"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_NOTES,
            component: ContactNotes,
            meta: {
              breadcrumb: "Notes"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_SPECIALITY_LISTS,
            component: ContactSpecialityLists,
            meta: {
              breadcrumb: "Speciality Lists"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_TAGS,
            component: ContactTags,
            name: "ContactTags",
            meta: {
              breadcrumb: "Tags"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_INTERNAL_NOTES,
            component: ContactInternalNotes,
            name: "ContactInternalNotes",
            meta: {
              breadcrumb: "Internal Notes"
            }
          },
          {
            path: WinboxRoutePaths.CONTACT_PROFILE_AUDIT_LOG,
            component: ContactAuditLog,
            name: "ContactAuditLog",
            meta: {
              breadcrumb: "Audit Log"
            }
          }
        ]
      }
    ]
  }
];
