var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "create-entity" }, [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-select", {
            class: _vm.status(_vm.$v.task.user),
            attrs: { "form-label": "Resarcher", options: _vm.possibleUsers },
            model: {
              value: _vm.task.user,
              callback: function($$v) {
                _vm.$set(
                  _vm.task,
                  "user",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "task.user"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-select", {
            class: _vm.status(_vm.$v.task.taskType),
            attrs: {
              "form-label": "Task Type",
              options: _vm.possibleTaskTypes
            },
            model: {
              value: _vm.task.taskType,
              callback: function($$v) {
                _vm.$set(
                  _vm.task,
                  "taskType",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "task.taskType"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-input", {
            ref: "completionGoalTo",
            staticClass: "active-icon-bg",
            class: _vm.status(_vm.$v.task.completionGoalTo),
            attrs: {
              "date-picker": true,
              label: "Completion Goal",
              "icon-right": "clock"
            },
            on: {
              datepickerShow: function($event) {
                return _vm.setDisabledDate(
                  "completionGoalTo",
                  _vm.$v.task.completionGoalFrom,
                  "right"
                )
              }
            },
            model: {
              value: _vm.task.completionGoalTo,
              callback: function($$v) {
                _vm.$set(_vm.task, "completionGoalTo", $$v)
              },
              expression: "task.completionGoalTo"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column priority" },
        [
          _c("winbox-input", {
            ref: "priority",
            staticClass: "active-icon-bg",
            class: _vm.status(_vm.$v.task.priority),
            attrs: { label: "Priority" },
            model: {
              value: _vm.task.priority,
              callback: function($$v) {
                _vm.$set(_vm.task, "priority", $$v)
              },
              expression: "task.priority"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("winbox-textarea", {
            attrs: { "form-label": "Details", maxlength: 255 },
            model: {
              value: _vm.task.details,
              callback: function($$v) {
                _vm.$set(
                  _vm.task,
                  "details",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "task.details"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }