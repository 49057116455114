import LoginPage from "@/components/Pages/LoginPage";
import LogoutPage from "@/components/Pages/LogoutPage";
import FrontDashboard from "@/components/Pages/FrontDashboard";
import DataImportPage from "@/components/Pages/DataImportPage";
// import DataLinkMediaPage from "@/components/Pages/DataLinkMediaPage";
import AdminConsole from "@/components/Pages/AdminConsole/AdminConsole";
import AdminProfile from "@/components/Pages/AdminConsole/AdminProfile";
import AdminReports from "@/components/Pages/AdminConsole/AdminReports";
import AdminUsers from "@/components/Pages/AdminConsole/AdminUsers";
import AdminTasks from "@/components/Pages/AdminConsole/AdminTasks";
import ResetPasswordPage from "@/components/Pages/ResetPasswordPage";

import * as AuthApi from "@/api/auth/auth";

// Companies
import CompanyRoutes from "@/routes/companies/companies";

// Agencies
import AgencyRoutes from "@/routes/agencies/agencies";

// Brands
import BrandRoutes from "@/routes/brands/brands";

// Contacts
import ContactRoutes from "@/routes/contacts/contacts";

import PageNotFound from "@/components/Pages/PageNotFound";
import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import { RoleTypes } from "@/constants/RoleTypes";

const getAuth = async () => {
  return await AuthApi.getCurrentAccount();
}

const isAuthorized = async (to, from, next) => {
  let userAuth, { authenticate } = to.meta;

  // if some page other than dashboard is called on the first try window.winboxApp will be undefined
  // so we need to make an API call to populate the userAuth
  if(window.winboxApp == undefined) {
    userAuth = await getAuth();
  }

  if(window.winboxApp?.$store.getters.USER.role == authenticate || userAuth?.role == authenticate) {
    return next();
  }

  return next("/");
};

const isActiveStorageRoute = (to, from, next) => {
  if (!to.fullPath.includes('rails/active_storage')) {
    next();
  }
};


export default [
  {
    path: WinboxRoutePaths.LOGIN,
    name: "Login",
    component: LoginPage
  },
  {
    path: WinboxRoutePaths.LOGOUT,
    name: "Logout",
    component: LogoutPage
  },
  {
    path: WinboxRoutePaths.RESET_PASSWORD,
    name: "Reset Password",
    component: ResetPasswordPage
  },
  {
    path: WinboxRoutePaths.FRONT_DASHBOARD,
    name: "FrontDashboard",
    component: FrontDashboard,
    meta: {
      breadcrumb: "Dashboard"
    }
  },
  {
    path: WinboxRoutePaths.SETTINGS,
    name: "AdminConsole",
    component: AdminConsole,
    redirect: { name: "AdminProfile" },
    meta: {
      breadcrumb: "Admin Console"
    },
    children: [
      {
        path: WinboxRoutePaths.SETTINGS_PROFILE,
        name: "AdminProfile",
        component: AdminProfile,
        meta: {
          breadcrumb: "Admin Profile"
        }
      },
      {
        path: WinboxRoutePaths.SETTINGS_REPORTS,
        name: "AdminReports",
        component: AdminReports,
        meta: {
          breadcrumb: "Admin Reports"
        }
      },
      {
        path: WinboxRoutePaths.SETTINGS_USERS,
        name: "AdminUsers",
        component: AdminUsers,
        beforeEnter: isAuthorized,
        meta: {
          authenticate: RoleTypes.ADMIN,
          breadcrumb: "Admin Users"
        }
      },
      {
        path: WinboxRoutePaths.SETTINGS_TASKS,
        name: "AdminTasks",
        component: AdminTasks,
        beforeEnter: isAuthorized,
        meta: {
          authenticate: RoleTypes.ADMIN,
          breadcrumb: "Admin Tasks"
        }
      },
    ]
  },
  {
    path: WinboxRoutePaths.DATA_IMPORT,
    name: "DataImport",
    component: DataImportPage,
    meta: {
      breadcrumb: "Data Import"
    }
  },
  ...CompanyRoutes,
  // {
  //   path: WinboxRoutePaths.COMPANIES_DATA_MAPPING,
  //   component: DataLinkMediaPage
  // },
  ...AgencyRoutes,
  ...BrandRoutes,
  ...ContactRoutes,
  { path: "*", component: PageNotFound, beforeEnter: isActiveStorageRoute }
];
