import getters from "./getters/getters";
import mutations from "./mutations/mutations";
import actions from "./actions/actions";

const store = {
  state: {
    user: null,
    auth: {},

    users: [],
    userRoles: [],

    reports: ["stale"],

    isEditMode: false,
    isChanged: false,
    isSaving: false,
    isCanceling: false,
    isInvalid: false,

    logo_url: null,

    // Main entities
    organizations: [],
    currentCompany: {},
    companies: [],
    currentBrand: {},
    brands: [],
    currentAgency: {},
    agencies: [],
    currentContact: {},
    profileContacts: [],

    // Business
    businessLocations: [],
    businessProfileContacts: [],
    businessBrands: {},
    businessRevenues: {},
    businessSocials: [],
    businessNicknames: [],
    businessTags: {},
    businessNotes: {},
    businessLogs: {},

    entityParentHierarchies: [],
    entityChildHierarchies: [],
    entitySiblingHierarchies: [],
    businessIndustries: [],
    businessDisciplines: [],
    businessSpecialities: [],
    businessRelationships: {},
    businessAgencyContacts: {},
    agencyRelationships: {},
    agencyHoldingCompany: {},

    // Brand
    brandSocials: [],
    brandNicknames: [],
    brandIndustries: [],
    brandDemographics: {},
    brandContacts: [],
    brandTags: {},
    brandNotes: {},
    brandLogs: {},
    brandSpecialities: [],
    brandSponsorships: {},
    brandBusinessRelationships: {},
    brandBusinesses: {},
    brandBusinessesContacts: [],
    brandAgencyContacts: [],

    // Person
    personSocials: {},
    personTags: {},
    personNotes: {},
    personLogs: {},
    personTitleFunctions: {},
    personWorkHistories: {},
    personSpecialities: {},
    personBrands: {},
    personLocation: {},
    personOrganization: {},

    // Helpers
    tasks: [],
    userTasks: [],
    industries: [],
    disciplines: [],
    specialities: [],
    tags: [],
    titleRanks: [],
    titleFunctions: [],
    services: [],
    mediaTypes: [],
    socials: [],
    dataImportTemplates: [],
    states: [],
    tvRegions: [],
    naics: [],
    holdingCompanies: [],
    employeeRanges: [],
    locations: [],
    location_people: {},
    isProfilePage: false,
    globalLoaderVisible: false,
    filterOptions: [],
    filterOptionItems: [],
    entityCounts: {},
  },

  getters,
  mutations,
  actions
};

export default store;
