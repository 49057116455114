import * as PeopleApi from "@/api/people/people";
import * as BusinessesApi from "@/api/businesses/businesses";
import * as Utils from "@/utils";

import { MutationTypes } from "@/constants/MutationTypes";

export default {
  /* Contacts */
  getAllPeople({ commit }) {
    return PeopleApi.getAllPeople().then(({ data }) => {
      commit(MutationTypes.SET_PROFILE_CONTACTS, data);
    });
  },

  getPerson({ commit }, id) {
    return PeopleApi.getPerson(id)
      .then(({ data }) => {
        commit(MutationTypes.ADD_UPDATE_PROFILE_CONTACT, data);
      })
      .catch(e => {
        // @TODO: remove (for testing only)
        const result = {
          ...Utils.defaultCompanyContacts().find(item => item.id === id)
        };
        commit(MutationTypes.ADD_UPDATE_PROFILE_CONTACT, result);
      });
  },

  updatePerson({ commit, getters }, { id, payload }) {
    return PeopleApi.updatePerson(id, payload);
  },

  getPersonSocials({ commit }, id) {
    return PeopleApi.getPersonSocials(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_SOCIALS, data);
    });
  },

  updatePersonSocialList({ commit, getters }, { id, payload }) {
    return PeopleApi.updatePersonSocialsList(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_SOCIALS, data);
    });
  },

  getPersonTags({ commit }, id) {
    return PeopleApi.getPersonTags(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_TAGS, {
        id,
        data
      });
    });
  },

  createPersonEntityTag({ commit, getters }, { id, payload }) {
    const allData = getters.PERSON_TAGS_BY_ID(id) || [];

    return PeopleApi.createPersonEntityTag(id, payload).then(({ data }) => {
      allData.push(data);
      commit(MutationTypes.SET_PERSON_TAGS, {
        id,
        data: allData
      });
      return { data };
    });
  },

  removePersonEntityTag({ commit, getters }, { id, tagId }) {
    let allData = getters.PERSON_TAGS_BY_ID(id) || [];

    return BusinessesApi.removeEntityTag(tagId).then(({ data }) => {
      allData = allData.filter(item => item.id !== tagId);
      commit(MutationTypes.SET_PERSON_TAGS, {
        id,
        data: allData
      });
      return { data };
    });
  },

  getPersonNotes({ commit }, id) {
    return PeopleApi.getPersonNotes(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_NOTES, data);
    });
  },

  createPersonEntityNote({ commit, getters }, { id, payload }) {
    return PeopleApi.createPersonNote(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_NOTES, data);
    });
  },

  updatePersonEntityNote({ commit, getters }, { id, noteId, payload }) {
    let allData = getters.PERSON_NOTES_BY_ID(id) || [];

    return BusinessesApi.updateEntityNote(noteId, payload).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_NOTES, data);
    });
  },

  removePersonEntityNote({ commit, getters }, { id, noteId }) {
    return BusinessesApi.removeEntityNote(noteId).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_NOTES, data);
    });
  },

  getPersonSpecialities({ commit }, id) {
    return PeopleApi.getPersonSpecialities(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_SPECIALITIES, data);
    });
  },

  assignPersonSpeciality({ commit, getters }, { id, payload }) {
    return PeopleApi.assignPersonSpecialities(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_SPECIALITIES, data);
    });
  },

  updatePersonSpeciality({ commit, getters }, { id, specialityId, payload }) {
    return PeopleApi.updatePersonSpeciality(specialityId, payload).then(
      ({ data }) => {
        commit(MutationTypes.SET_PERSON_SPECIALITIES, data);
      }
    );
  },

  deletePersonSpeciality({ commit, getters }, { id, specialityId }) {
    return PeopleApi.deletePersonSpeciality(specialityId).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_SPECIALITIES, data);
    });
  },

  getPersonLogs({ commit }, id) {
    return PeopleApi.getPersonLogs(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_LOGS, {
        id,
        data
      });
    });
  },

  rollbackPersonLog({ commit }, payload) {
    return BusinessesApi.rollbackBusinessLog(payload).then(({ data }) => {
      return { data };
    });
  },

  getPersonTitleFunctions({ commit }, id) {
    return PeopleApi.getPersonTitleFunctions(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_TITLE_FUNCTIONS, data);
    });
  },

  updatePersonTitleFunctionList({ commit, getters }, { id, payload }) {
    return PeopleApi.updatePersonTitleFunctionList(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_TITLE_FUNCTIONS, data);
    });
  },

  removePersonTitleFunction({ commit, getters }, { id, titleFunctionId }) {
    return PeopleApi.removePersonTitleFunction(titleFunctionId).then(
      ({ data }) => {
        commit(MutationTypes.SET_PERSON_TITLE_FUNCTIONS, data);
      }
    );
  },

  removeAllPersonTitleFunction({ commit, getters }, { id }) {
    PeopleApi.removeAllPersonTitleFunctions(id);
    return commit(MutationTypes.SET_PERSON_TITLE_FUNCTIONS, {
      id,
      data: [],
    });
  },

  getPersonWorkHistories({ commit }, id) {
    return PeopleApi.getPersonWorkHistories(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_WORK_HISTORIES, data);
    });
  },

  createPersonWorkHistory({ commit, getters }, { id, payload }) {
    return PeopleApi.createPersonWorkHistory(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_WORK_HISTORIES, data);
    });
  },

  updatePersonWorkHistory({ commit, getters }, { id, workHistoryId, payload }) {
    return PeopleApi.updatePersonWorkHistory(workHistoryId, payload).then(
      ({ data }) => {
        commit(MutationTypes.SET_PERSON_WORK_HISTORIES, data);
      }
    );
  },

  removePersonWorkHistory({ commit, getters }, { id, workHistoryId }) {
    let allData = getters.PERSON_WORK_HISTORIES_BY_ID(id) || [];

    return PeopleApi.removePersonWorkHistory(workHistoryId).then(
      ({ data }) => {
        commit(MutationTypes.SET_PERSON_WORK_HISTORIES, data);
      }
    );
  },

  getPersonBrands({ commit }, id) {
    return PeopleApi.getPersonBrands(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_BRANDS, data);
    });
  },

  removeAllPersonBrands({ commit }, id) {
    return PeopleApi.getPersonBrands(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_BRANDS, data);
    });
  },

  getPersonOrganizations({ commit }, id) {
    return PeopleApi.getPersonOrganizations(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_ORGANIZATION, data);
    });
  },

  setPersonOrganization({ commit }, data) {
    commit(MutationTypes.SET_PERSON_ORGANIZATION, data);
  },

  getPersonLocations({ commit }, id) {
    return PeopleApi.getPersonLocations(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_LOCATION, {
        id,
        data
      });
    });
  },

  getPersonAgencyBrands({ commit }, id) {
    return PeopleApi.getPersonAgencyBrands(id).then(({ data }) => {
      commit(MutationTypes.SET_PERSON_BRANDS, data);
    });
  },

  removeAllPersonAgencyBrands({ commit }, { id }) {
    PeopleApi.removeAllPersonAgencyBrands(id);
    return commit(MutationTypes.SET_PERSON_BRANDS, []);
  },

  resetContactProfileTabItems({ dispatch }, { id, type }) {
    dispatch('removeAllPersonTitleFunction', {
      id,
    });

    if(type === 'Agency') {
      dispatch('removeAllPersonAgencyBrands', { 
        id 
      });
    }
    else {
      dispatch('updatePerson', {
        id,
        payload: {
          person: {
            brand_ids: [],
          },
        },
      });
    }
  },
};
