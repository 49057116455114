<template>
  <div class="agency-brands-wrap">
    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="agency-brands">
      <winbox-company-header
        :title="mainTitle"
        entity-icon="link"
        entity-label="Link Brands"
        :enable-status-toggle="true"
        @toggle-status="updateVisibleStatus"
        @changed="openLinkModal"
      />
      <div class="columns">
        <div class="column">
          <winbox-data-table
            class="main-data-table"
            :data="visibleData"
            :columns="columns"
            :options="tableOptions"
            :slots="specificSlots"
          >
            <template :slot="tableProps.name" slot-scope="data">
              <router-link
                :data-tooltip="data.row.brand_id"
                :to="{ path: constructProfileUrl(data.row.brand_id) }"
              >
                {{ data.row[tableProps.name] }}
              </router-link>
            </template>
            <template :slot="tableProps.services" slot-scope="data">
              {{ data.row.isAOR ? data.row[tableProps.services].length > 0  ? 'AOR,' : 'AOR' : '' }}
              {{
                data.row[tableProps.services]
                  .map((a) => findServiceNameById(a.id))
                  .join(", ")
              }}
            </template>
            <template :slot="tableProps.relationshipProof" slot-scope="data">
              {{ data.row[tableProps.relationshipProof] }}
            </template>
            <template :slot="tableProps.status" slot-scope="data">
              <winbox-select
                :value="data.row[tableProps.status]"
                :options="possibleStatuses"
                :searchable="false"
                @input="onStatusChange(data.row, $event)"
              />
            </template>
            <template :slot="tableProps.options" slot-scope="data">
              <winbox-toggle-menu :options="rowOptions" :data="data.row" />
            </template>
          </winbox-data-table>
        </div>
      </div>
    </div>
    <winbox-modal
      :ref="linkModal"
      :name="linkModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      class="winbox-modal-scrollable"
      width="1000px"
      height="600px"
      title="Link Brands"
      :save-text="saveModalText"
      @on-cancel="onCancelLinkModal"
      @on-save="onSaveLinkModal"
      @enter-press="onSaveLinkModal"
      @before-close="resetLinkNextStep"
    >
      <winbox-add-multiple-relationships-remote
        ref="addRelationships"
        :url="getBrandsExceptByAgencyUrl()"
        :responseFunction="responseFunction"
        :config="linkConfig"
        :entity-location="possibleLocations.length == 1 ? possibleLocations[0] : null"
      />
    </winbox-modal>
    <winbox-modal
      :ref="linkEditModal"
      :name="linkEditModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      width="800px"
      height="300px"
      title="Edit"
      save-text="Edit"
      @on-cancel="onEditCancel"
      @on-save="onEditSave"
    >
      <winbox-relation-ship-items
        v-if="editEntity"
        :value="$v.editEntity"
        @change="onEditEntityChange"
      />
      <div v-if="editEntity" class="columns">
        <div class="column is-6">
          <winbox-select
            v-if="possibleLocations.length >= 1"
            placeholder="Location"
            label="name"
            v-model.trim="$v.editEntity.location.$model"
            :class="status($v.editEntity.location)"
            :options="possibleLocations"
            :disabled="possibleLocations.length == 1"
            :emptyText="emptyText"
          />
          <winbox-input
            v-else
            placeholder="Location"
            :readonly="true"
            :value="emptyText"
          />
        </div>
        <div class="column is-6">
          <button
            class="button action-button"
            :class="{ 'is-primary': $v.editEntity.is_aor.$model }"
            @click="toggleAOR"
          >
            <input
              type="checkbox"
              :checked="$v.editEntity.is_aor.$model"
            />
            <span>AOR</span>
          </button>
        </div>
      </div>
    </winbox-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxToggleMenu from "@/components/Molecules/WinboxToggleMenu";
import WinboxRelationShipItems from "@/components/Molecules/WinboxRelationshipItems";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxAddMultipleRelationshipsRemote from "@/components/Organisms/WinboxAddMultipleRelationshipsRemote";

import * as BusinessesApi from "@/api/businesses/businesses";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import { required } from "vuelidate/lib/validators";
import { BusinessStatuses } from "@/constants/BusinessStatuses";

const tableProps = {
  name: "name",
  services: "services",
  relationshipProof: "relationshipProof",
  status: "status",
  options: "__options",
};
const mainTableColumns = Object.values(tableProps);

export default {
  name: "AgencyBrands",
  components: {
    WinboxInput,
    WinboxModal,
    WinboxSelect,
    WinboxDataTable,
    WinboxToggleMenu,
    WinboxCompanyHeader,
    WinboxLargeIndicator,
    WinboxRelationShipItems,
    WinboxAddMultipleRelationshipsRemote
  },
  data() {
    return {
      entity: null,
      visibileStatus: 'Active',
      isCreateNewLoaded: true,
      Colors: Colors,
      isLoaded: true,
      nextStepIsActive: false,
      linkModal: "linkModal",
      linkEditModal: "linkEditModal",
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        headings: {
          [tableProps.name]: "Brand",
          [tableProps.services]: "Service",
          [tableProps.relationshipProof]: "Last Proof",
          [tableProps.status]: "Status",
          [tableProps.options]: "",
        },
        sortable: [
          tableProps.name,
          tableProps.services,
          tableProps.status,
          tableProps.relationshipProof,
        ],
        filterable: [
          tableProps.name,
          tableProps.services,
          tableProps.status,
          tableProps.relationshipProof,
        ],
      },
      specificSlots: [
        tableProps.name,
        tableProps.services,
        tableProps.relationshipProof,
        tableProps.status,
        tableProps.options,
      ],
      linkConfig: {
        tableProps: {
          name: "name",
          linked: "_linked"
        },
        specificSlots: ["_linked"],
        tableOptions: {
          headings: {
            name: "Name",
            _linked: ""
          },
          sortable: [],
          filterable: ["name"],
        },
        multiselect: true,
      },
      rowOptions: this.prepareRowMenuOptions(),
      editEntity: null,
    };
  },
  validations: {
    editEntity: {
      services: {
        required
      },
      mediaTypes: {
        required: false,
      },
      location: {
        required
      },
      is_aor: {
      },
    }
  },
  computed: {
    ...mapState([
      'currentAgency',
      'businessLocations',
      'agencyRelationships',
      'users',
    ]),
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Brands (${this.visibleData.length})`;
    },
    saveModalText() {
      return this.nextStepIsActive ? "Create" : "Add Selected";
    },
    dataset() {
      return this.agencyRelationships.map((item) => {
          return {
            ...item,
            [tableProps.name]:
              item.brand && item.brand.name ? item.brand.name : "",
            [tableProps.status]: BusinessStatuses[item.status],
            isAOR: item.is_aor,
            [tableProps.relationshipProof]: `${this.getUserNameById(item.proofed_by)} - ${this.formatDate(item.proofed_at)}`
          };
        });
    },
    possibleServices() {
      return this.$store.getters.SERVICES;
    },
    possibleMediaTypes() {
      return this.$store.getters.MEDIA_TYPES;
    },
    possibleStatuses() {
      return Object.values(BusinessStatuses);
    },
    possibleLocations() {
      return this.businessLocations.filter((businessLocation) => businessLocation.status === 'active');
    },
    emptyText() {
      if (this.possibleLocations.length == 0) {
        return `Please make sure that ${this.currentAgency.name} has a location`;
      }
      return "Sorry, no matching options.";
    },
    activeData() {
      return this.dataset.filter((data) => data.status.value === 'active');
    },
    inactiveData() {
      return this.dataset.filter((data) => data.status.value === 'inactive');
    },
    visibleData() {
      if(this.visibileStatus === 'Active') {
        return this.activeData;
      }
      else {
        return this.inactiveData;
      }
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'getBusinessLocations',
      'getAgencyRelationship',
      'createAgencyRelationship',
      'updateAgencyRelationship',
      'getUsers'
    ]),
    async getData() {
      this.isLoaded = false;

      this.getBusinessLocations(this.id);
      await this.getAgencyRelationship(this.id);
      await this.getUsers();

      this.isLoaded = true;
    },
    openLinkModal() {
      this.$refs[this.linkModal].show();
    },
    updateVisibleStatus(value) {
      this.visibileStatus = value;
    },
    status(validation) {
      return Utils.validationStatus(validation);
    },
    prepareRowMenuOptions() {
      return [
        {
          id: 1,
          title: "Edit",
          icon: "edit",
          iconColor: Colors.system.primaryLight,
          action: data => {
            this.editRelationship(data);
          }
        },
        {
          id: 2,
          title: "Proof",
          icon: "",
          iconColor: Colors.system.primaryLight,
          action: data => {
            this.proofRelationship(data);
          }
        },
      ];
    },
    async saveNewRelationship(item) {
      const payload_data = {
        brand_ids: item.brand_ids,
        agency_id: this.id,
        service_ids: item.services.map((service) => service.id),
        media_type_ids: item.mediaTypes?.map((mediaType) => mediaType.id),
        agency_location_id: item.location.id,
        status: item.status.value,
        is_aor: item.is_aor,
      };
      const payload = {
        business_relationship: payload_data,
      };

      await this.createAgencyRelationship({ id: this.id, payload });
      this.getAgencyRelationship(this.id);
    },
    updateRelationship(item) {
      const payload = {
        business_relationship: {
          service_ids: item.services.map((a) => a.id),
          media_type_ids: item.mediaTypes?.map((a) => a.id),
          agency_location_id: item.location.id,
          is_aor: item.is_aor,
        },
      };

      this.callUpdateDispatch(item, payload);
    },
    callUpdateDispatch(item, payload) {
      return this.updateAgencyRelationship({ id: this.id, relationshipId: item.id, payload });
    },
    constructProfileUrl(id) {
      return Utils.getBrandProfileUrl(id);
    },
    getBrandsExceptByAgencyUrl() {
      return Utils.getBrandsExceptByAgencyUrl(this.id);
    },
    responseFunction(data, count) {
      return data.map((item) => {
        return {
          ...item,
        };
      });
    },
    toggleAOR() {
      if(this.$v.editEntity.is_aor.$model === undefined) {
        this.$set(this.editEntity, 'is_aor', true);
      }
      else {
        this.$v.editEntity.is_aor.$model = !this.$v.editEntity.is_aor.$model;
      }
    },
    getUserNameById(userId) {
      const user = this.users.find(user => user.id === userId);
      return user ? `${user.first_name} ${user.last_name}` : '';
    },
    formatDate(timestamp) {
      if (!timestamp) return "";
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US');
    },
    async proofRelationship(row) {
      const response = await BusinessesApi.submitBusinessRelationshipsProof(row.id);
      this.$store.commit('UPDATE_AGENCY_RELATIONSHIP', response.data);
    },
    onSaveLinkModal() {
      const vm = this.$refs.addRelationships;
      const $v = vm.$v;

      if(this.businessLocations.length == 1) {
        vm.entity.location = this.businessLocations[0];
      }

      if(typeof(vm.entity.location) === "string") {
        delete vm.entity.location;
      }

      if (!vm.entity) {
        return;
      }

      if (!this.nextStepIsActive) {
        this.nextStepIsActive = true;
        vm.goToNextStep();
      } else {
        $v.$touch();

        if (!$v.$error) {
          let item = {
            brand_ids: vm.entity.map((entity) => entity.id),
            location: vm.location,
            status: vm.status,
            is_aor: !!vm.is_aor,
            services: vm.services,
            mediaTypes: vm.mediaTypes,
          }

          this.saveNewRelationship(item);
          this.onCancelLinkModal();
        }
      }
    },
    findServiceNameById(id, fullObject = false) {
      const found = this.possibleServices.find(item => item.id === id);
      if (found) {
        return fullObject ? found : found.name;
      }
    },
    findMediaTypeNameById(id, fullObject = false) {
      const found = this.possibleMediaTypes.find(item => item.id === id);
      if (found) {
        return fullObject ? found : found.name;
      }
    },
    findLocationById(id, fullObject = false) {
      if(this.possibleLocations.length == 1) {
        const found = this.possibleLocations[0];
        return fullObject ? found : found.name;
      }
      else {
        const found = this.possibleLocations.find(item => item.id === id);
        if (found) {
          return fullObject ? found : found.name;
        }
      }
    },
    resetLinkNextStep() {
      this.nextStepIsActive = false;
    },
    onCancelLinkModal() {
      this.$refs[this.linkModal].hide();
    },
    onEditCancel() {
      this.$refs[this.linkEditModal].hide();
      this.editEntity = null;
    },
    onEditSave() {
      if (!this.editEntity) {
        return;
      }

      this.$v.$touch();

      if (!this.$v.$error) {
        if (typeof this.updateRelationship === "function") {
          this.updateRelationship(this.editEntity);
        }
        this.onEditCancel();
      }
    },
    onEditEntityChange(e) {
      this.editEntity = {
        ...this.editEntity,
        ...e
      };
    },
    onStatusChange(row, e) {
      const item = {
        id: row.id
      };
      const payload = {
        status: e.value
      };
      this.callUpdateDispatch(item, payload);
    },
    async editRelationship(row) {
      this.editEntity = {
        id: row.id,
        business_id: row.business_id,
        services: row.services.map(a => this.findServiceNameById(a.id, true)),
        mediaTypes: row.media_types.map(a =>
          this.findMediaTypeNameById(a.id, true)
        ),
        location: this.findLocationById(row.agency_location_id, true),
        agency: row.agency,
        is_aor: row.is_aor,
      };
      this.openEditModal();
    },
    openEditModal() {
      this.$refs[this.linkEditModal].show();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.agency-brands {
  @include agency-brand-relationships;

  ::v-deep {
    .main-data-table {
      font-size: 13px;
      thead {
        th {
          &:nth-child(4) {
            min-width: 120px;
          }
          /* Status */
          &:nth-child(5) {
            min-width: 120px;
          }
        }
      }
      tbody {
        td[colspan="6"] {
          text-align: left !important;
        }
      }
    }
  }
}
.action-button {
  @include profile-action-button;
}
</style>
