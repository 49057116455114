export const NicknamesAndSocialsMixin = {
  data() {
    return {
      socialEntities: [],
      nicknames: [],
      socialsDataUpdate: [],
      socialsDataCreate: [],
      nicknamesDataUpdate: [],
      nicknamesDataCreate: []
    };
  },
  methods: {
    onSocialChanged(e) {
      this.tryToSaveSocials([...e]);
    },
    tryToSaveSocials(socials) {      
      this.socialsDataCreate = [];

      socials.forEach((item, index) => {
        this.socialsDataCreate.push(this.prepareSocialPayloadObject(item));
      });

      this.checkSocialSaveQueue(this.socialsDataCreate);
    },
    checkSocialSaveQueue(socials) {
      this.updateBusinessSocialsList(this.id, socials);
      this.socialEntities = socials;
    },
    updateBusinessSocialsList(id, payload) {
      this.$store
        .dispatch("updateBusinessSocialsList", {
          id: id,
          payload: {
            entity_socials: payload,
          },
        });
    },
    updateBusinessSocial(id, payload) {
      this.$store.dispatch("updateBusinessSocial", {
        id: this.id,
        socialId: id,
        payload: payload
      });
    },
    removeBusinessSocial(id) {
      this.$store.dispatch("removeBusinessSocial", {
        id: this.id,
        socialId: id
      });
    },
    onNicknamesChanged(e) {
      this.tryToSaveNicknames([...e]);
    },
    onNicknamesRemoved(e) {
      this.nicknames = this.nicknames.filter(item => item.id !== e.id);

      if (e.id) {
        this.removeNickname(e.id);
      }
    },
    tryToSaveNicknames(nicknames) {
      this.nicknamesDataCreate = [];

      nicknames.forEach((item, index) => {
        this.nicknamesDataCreate.push(this.prepareNicknamePayloadObject(item));
      });

      this.checkNicknamesSaveQueue(this.nicknamesDataCreate);
    },
    checkNicknamesSaveQueue(nicknames) {
      this.updateBusinessNicknameList(this.id, nicknames);
      this.nicknames = nicknames;
    },
    updateBusinessNicknameList(id, payload) {
      this.$store
        .dispatch("updateBusinessNicknameList", {
          id: id,
          payload: {
            entity_nicknames: payload,
          },
        })
    },
    prepareSocialPayloadObject(item) {
      return {
        id: item.id,
        social_id: item.social_id,
        website: item.website
      };
    },
    prepareNicknamePayloadObject(item) {
      return {
        id: item.id,
        name: item.name
      };
    }
  }
};
