<template>
  <div class="wrap-social-media">
    <div v-if="!entities.length">No items</div>
    <ul v-if="entities.length" class="social-entities-list">
      <li v-for="(item, key) in entities" :key="key">
        <div class="columns is-variable is-1 is-vcentered">
          <div class="column is-4">
            <winbox-select
              :value="item"
              :options="possibleSocials"
              :taggable="true"
              @input="onSocialChanged(item, $event)"
              placeholder="Social"
              :disabled="disabled"
            />
          </div>
          <div class="column is-7">
            <winbox-input
              v-if="!disabled"
              placeholder="Web Address"
              icon-left="globe-americas"
              v-model="$v.entities.$each.$iter[key].website.$model"
              :class="status($v.entities.$each.$iter[key].website)"
              @input="change(true)"
            />
              <fieldset
                v-else
                class="border"
              >
                <a
                  :href="item.website" target="_blank"
                >
                  {{ decodeURIComponent(item.website) }}
                </a>
              </fieldset>
          </div>
          <div class="column column-remove-entity">
            <button class="button button-delete icon-wrapper" v-if="!disabled">
              <winbox-icon
                icon="trash"
                :color="Colors.system.error"
                @click.native="removeEntity(item)"
              />
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxSelect from "@/components/Atoms/WinboxSelect";

import { Colors } from "@/Colors";
import cloneDeep from "clone-deep";
import * as Utils from "@/utils";

export default {
  name: "WinboxSocialMedia",
  components: {
    WinboxIcon,
    WinboxInput,
    WinboxSelect,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      Colors: Colors,
      entities: this.prepareEntities(this.items),
      entityCount: 0,
    };
  },
  validations: {
    entities: {
      $each: {
        website: {
          winboxValidSocialUrl: Utils.winboxValidSocialUrl
        },
      },
    },
  },
  watch: {
    isCanceling(newValue, oldValue) {
      if(newValue) {
        this.editCancel();
      }
    },
    isSaving(newValue, oldValue) {
      if(newValue) {
        this.editAccept();
      }
    },
  },
  computed: {
    ...mapState([
      'socials',
      'isEditMode',
      'isChanged',
      'isCanceling',
      'isSaving',
    ]),
    possibleSocials() {
      return this.socials.map((item) => {
        return { label: item.name, value: item.id };
      });
    },
  },
  methods: {
    ...mapActions([
      'change',
      'invalid',
    ]),
    prepareEntities(items) {
      return cloneDeep(items).map((item) => {
        item.value = item.social_id;
        item.label = item.social?.name;
        return item;
      });
    },
    removeEntity(item) {
      let shouldBeConfirmed = item.id ? confirm("Are you sure?") : true;
      if (shouldBeConfirmed) {
        this.entities = this.entities.filter((entity) => entity.id != item.id);
        this.change(true);
      }
    },
    addNewSocialEntity() {
      this.entities = [...this.entities, {id: this.entityCount++, website: '', social: null, social_id: null}];
    },
    onSocialChanged(item, e) {
      item.social_id = e.value;
      item.label = e.label;
      item.value = e.value;
      item.social = {};
      item.social.name = e.label;
      item.social.id = e.value;
      this.change(true);
    },
    editCancel() {
      this.entities = this.prepareEntities(this.items);
    },
    editAccept() {
      this.$emit("changed", this.entities);
    },
    status(validation) {
      const validationItems = Object.values(this.$v.entities.$each.$iter);
      const hasInvalids = validationItems.filter((validationItem) => validationItem.$invalid).length > 0;
      this.invalid(hasInvalids);
      return Utils.validationStatus(validation);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

.social-entities-list {
  .icon-wrapper {
    @include icon-wrapper-base-style;
  }
  li {
    @include button-delete-universal-hover;
  }
}
.column-remove-entity {
  padding: $winbox-default-column-padding 0;
  text-align: center;
}
.button-delete {
  @include button-delete-universal;
}
</style>
