<template>
  <div class="winbox-template-file">
    <div class="file is-primary">
      <label class="file-label" :class="disabledStyleObj">
        <span class="file-cta" @click="triggerDownloadTemplate" :class="disabledStyleObj">
          <span class="file-icon">
            <i class="fas fa-download" />
          </span>
          <span class="file-label">
            Download Template
          </span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinboxTemplateFile",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disabledStyleObj() {
      return {
        'disabled': this.disabled,
      };
    },
  },
  methods: {
    triggerDownloadTemplate() {
      this.$emit('download-template');
    },
  },
};
</script>

<style scoped>
.winbox-template-file {
  font-size: 18px;

  .file-label.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
