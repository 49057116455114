var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoaded
    ? _c("div", { staticClass: "profile-wrap" }, [
        _c("div", { staticClass: "columns is-desktop" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" General ")]
                        },
                        proxy: true
                      },
                      {
                        key: "headerIcon",
                        fn: function() {
                          return [
                            _vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button save-button",
                                    attrs: { disabled: !_vm.isChanged },
                                    on: {
                                      click: function($event) {
                                        return _vm.save(true)
                                      }
                                    }
                                  },
                                  [_vm._v("\n            Save\n          ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button cancel-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.cancel(true)
                                      }
                                    }
                                  },
                                  [_vm._v("\n            Cancel\n          ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button edit-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.edit(true)
                                      }
                                    }
                                  },
                                  [_vm._v("\n            Edit\n          ")]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3509522888
                  )
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.entity.name),
                        attrs: {
                          label: "Brand Name",
                          disabled: !_vm.isEditMode,
                          field: "brandName"
                        },
                        on: {
                          input: function($event) {
                            return _vm.addToDataUpdate("name", _vm.entity.name)
                          }
                        },
                        model: {
                          value: _vm.$v.entity.name.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.name,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.name.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c("winbox-textarea", {
                        class: _vm.status(_vm.$v.entity.description),
                        attrs: {
                          "form-label": "Description",
                          disabled: !_vm.isEditMode
                        },
                        on: {
                          input: function($event) {
                            return _vm.addToDataUpdate(
                              "description",
                              _vm.entity.description
                            )
                          }
                        },
                        model: {
                          value: _vm.$v.entity.description.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.description,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.description.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns" }, [
                        _c(
                          "div",
                          { staticClass: "column is-3" },
                          [
                            _c("winbox-input", {
                              class: _vm.status(_vm.$v.entity.founded),
                              attrs: {
                                label: "Founded",
                                maxlength: "4",
                                disabled: !_vm.isEditMode
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addToDataUpdate(
                                    "founded",
                                    _vm.entity.founded
                                  )
                                }
                              },
                              model: {
                                value: _vm.$v.entity.founded.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.entity.founded,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.entity.founded.$model"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-9" },
                          [
                            _vm.isEditMode
                              ? _c("winbox-input", {
                                  class: _vm.status(_vm.$v.entity.website),
                                  attrs: {
                                    label: "Web Address",
                                    "icon-left": "globe-americas",
                                    field: "brandWebsite"
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.addToDataUpdate(
                                        "website",
                                        _vm.entity.website
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.$v.entity.website.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.entity.website,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "$v.entity.website.$model"
                                  }
                                })
                              : _c("fieldset", { staticClass: "border" }, [
                                  _c(
                                    "legend",
                                    { staticClass: "float-none w-auto" },
                                    [_vm._v("Web Address")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "website-container" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "website-link",
                                          attrs: {
                                            href: `http://${_vm.entity.website}`,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                decodeURIComponent(
                                                  _vm.entity.website
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column is-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button action-button",
                              class: { "is-primary": _vm.entity.inhouse_media },
                              attrs: { disabled: !_vm.isEditMode },
                              on: {
                                click: () => {
                                  _vm.entity.inhouse_media = !_vm.entity
                                    .inhouse_media
                                  _vm.addToDataUpdate(
                                    "inhouse_media",
                                    _vm.entity.inhouse_media
                                  )
                                }
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.entity.inhouse_media,
                                    expression: "entity.inhouse_media"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: !_vm.isEditMode
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.entity.inhouse_media
                                  )
                                    ? _vm._i(_vm.entity.inhouse_media, null) >
                                      -1
                                    : _vm.entity.inhouse_media
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.entity.inhouse_media,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.entity,
                                            "inhouse_media",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.entity,
                                            "inhouse_media",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.entity, "inhouse_media", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("In-House Media")])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button action-button",
                              class: {
                                "is-primary": _vm.entity.inhouse_creative
                              },
                              attrs: { disabled: !_vm.isEditMode },
                              on: {
                                click: () => {
                                  _vm.entity.inhouse_creative = !_vm.entity
                                    .inhouse_creative
                                  _vm.addToDataUpdate(
                                    "inhouse_creative",
                                    _vm.entity.inhouse_creative
                                  )
                                }
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.entity.inhouse_creative,
                                    expression: "entity.inhouse_creative"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: !_vm.isEditMode
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.entity.inhouse_creative
                                  )
                                    ? _vm._i(
                                        _vm.entity.inhouse_creative,
                                        null
                                      ) > -1
                                    : _vm.entity.inhouse_creative
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.entity.inhouse_creative,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.entity,
                                            "inhouse_creative",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.entity,
                                            "inhouse_creative",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.entity,
                                        "inhouse_creative",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("In-House Creative")])
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  attrs: { expandable: true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Industries ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2288075198
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-industries", {
                        attrs: {
                          "org-type": "brand",
                          disabled: !_vm.isEditMode
                        },
                        on: {
                          changed: function($event) {
                            return _vm.onTagsChanged($event, "name")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  attrs: { expandable: true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Demographics ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    177075028
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-demographics", {
                        ref: "demographicsComponent",
                        attrs: { items: _vm.demographics }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns is-desktop" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Social Media ")]
                        },
                        proxy: true
                      },
                      {
                        key: "headerIcon",
                        fn: function() {
                          return [
                            _vm.isEditMode
                              ? _c("winbox-icon", {
                                  attrs: {
                                    icon: "plus-circle",
                                    color: _vm.Colors.system.primaryLight
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.$refs.winboxSocialMediaRef.addNewSocialEntity.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2820771390
                  )
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-social-media", {
                        ref: "winboxSocialMediaRef",
                        attrs: {
                          items: _vm.socialEntities,
                          disabled: !_vm.isEditMode
                        },
                        on: { changed: _vm.onSocialChanged }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Nicknames ")]
                        },
                        proxy: true
                      },
                      {
                        key: "headerIcon",
                        fn: function() {
                          return [
                            _vm.isEditMode
                              ? _c("winbox-icon", {
                                  attrs: {
                                    icon: "plus-circle",
                                    color: _vm.Colors.system.primaryLight
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.$refs.winboxNicknamesRef.addNewEntity.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1945430053
                  )
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-nicknames", {
                        ref: "winboxNicknamesRef",
                        attrs: {
                          items: _vm.nicknames,
                          disabled: !_vm.isEditMode
                        },
                        on: { changed: _vm.onNicknamesChanged }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  attrs: { expandable: true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Speciality Lists ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3565595510
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-speciality-tags-list", {
                        attrs: {
                          "possible-tags": _vm.possibleTags,
                          "entity-tags": _vm.selectedTags,
                          "org-type": "brand",
                          disabled: !_vm.isEditMode
                        },
                        on: {
                          changed: function($event) {
                            return _vm.onTagsChanged($event, "name")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }