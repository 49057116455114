import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import vueNumeralFilterInstaller from "vue-numeral-filter/dist/vue-numeral-filter.min";
import VueCurrencyInput from "vue-currency-input";
import { ClientTable, ServerTable } from "vue-tables-2";
import VueTruncate from "vue-truncate-filter";
import VModal from "vue-js-modal";
import Vuelidate from "vuelidate";
import Notifications from "vue-notification";
import vueDebounce from "vue-debounce";
import VueMask from 'v-mask';
import VuePapaParse from 'vue-papa-parse'

import { baseDateFilter, baseDateTimeFilter } from "@/filters/date-filters";
import { capitalize } from "@/filters/capitalize";
import { humanOrgTypeFilter } from "@/filters/org-types";
import { firstline, withoutFirstline } from "@/filters/firstline";
import { CurrencyOptions } from "@/constants/CurrencyOptions";

// Font Awesome 5
import "@fortawesome/fontawesome-free/css/all.min.css";
import "lightpick/css/lightpick.css";

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.use(ClientTable, {}, false, "bulma", "default");
Vue.use(ServerTable, {}, false, "bulma", "default");
Vue.use(VueTruncate);
Vue.use(VModal);
Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(vueDebounce);
Vue.use(VueMask);
Vue.use(VuePapaParse)

const pluginOptions = {
  /* see config reference */
  /* https://dm4t2.github.io/vue-currency-input/config/#directive-options */
  globalOptions: CurrencyOptions.base
};
Vue.use(VueCurrencyInput, pluginOptions);

// Custom global filters
Vue.filter("winboxDate", baseDateFilter);
Vue.filter("winboxDatetime", baseDateTimeFilter);
Vue.filter("winboxHumanOrgType", humanOrgTypeFilter);
Vue.filter("capitalize", capitalize);
Vue.filter("firstline", firstline);
Vue.filter("withoutFirstline", withoutFirstline);

import routes from "@/routes/routes";
import store from "@/store/index";
import App from "@/App";
import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";
import * as Utils from "@/utils";

const router = new VueRouter({
  mode: "history",
  fallback: false,
  routes
});

window.breadcrumbs = [];

router.beforeEach((to, from, next) => {
  let groupMatch = to.path.match(/\/(\w+)(\/\d+\/(\w+))?(\/([\w\-]+))?/);

  // If the breadcrumbs are empty then it is the first page load
  // If groupMatch is null then it is a page where breadcrumbs are not used like 'dashboard'
  if(window.breadcrumbs.length == 0 && groupMatch) {
    let { 1: entityType, 3: tab} = groupMatch;

    // Check if it is an entity page
    if(['companies', 'brands', 'agencies', 'contacts'].includes(entityType)) {
      // If tab == undefined then it is the main entity page
      // If the entityType is not a contact, then the profile page should end with 'profile'
      // If the entityType is a contact, then the profile page should end with 'title-functions'
      if((tab == undefined) ||
         (entityType != 'contacts' && tab.includes('profile')) ||
         (entityType == 'contacts' && groupMatch[5].includes('title-functions'))) {
        next();
      }
      // If it is the first page load, we only want to go to a profile page of a non-contact entity
      else if(entityType != 'contacts') {
        let arr = to.path.split('/');
        next(`${arr.slice(0, 3).join('/')}/profile`);
      }
      // If it is the first page load, we only want to go to a profile page of a contact
      else {
        let arr = to.path.split('/');
        next(`${arr.slice(0, 4).join('/')}/title-functions`);
      }
    }
  }

  next();
});

router.afterEach((to, from) => {
  if (Utils.ERASE_BREADCRUMBS_FOR.includes(to.path)) {
    window.breadcrumbs = [];
  } else {
    Utils.updateBreadcrumbs({
      to: to,
      isCurrent: false
    });
  }
});

// eslint-disable-next-line no-new
window.winboxApp = new Vue({
  render: h => h(App),
  components: { App },
  // eslint-disable-next-line import/no-named-as-default-member
  store: new Vuex.Store(store),
  router: router
}).$mount("#winbox");
