import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxDataTable from "@/components/Molecules/WinboxDataTable";
import WinboxCompanyHeader from "@/components/Molecules/CompanyProfile/WinboxCompanyHeader";
import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxPublicNotes from "@/components/Organisms/WinboxPublicNotes";
import * as NoteRemindersApi from "@/api/note-reminders/note-reminders";

import { DateFormats } from "@/constants/DateFormats";
import moment from "moment";
import { Colors } from "@/Colors";
import { NoteTypes } from "@/constants/NoteTypes";
import { EntityNoteTypes } from "@/constants/EntityNoteTypes";
import * as Utils from "@/utils";

const tableProps = {
  toggler: "__toggler",
  description: "description",
  published: "created_at",
  author: "author",
  options: "__options"
};
const mainTableColumns = Object.values(tableProps);

export const EntityNotesMixin = {
  components: {
    WinboxIcon,
    WinboxSelect,
    WinboxLargeIndicator,
    WinboxCompanyHeader,
    WinboxDataTable,
    WinboxModal,
    WinboxPublicNotes
  },
  data() {
    return {
      Colors,
      entity: null,
      noteReminders: [],
      maxSymbols: 58, // Agreed with Julia lol
      isLoaded: true,
      noteType: NoteTypes.public,
      entityNoteType: EntityNoteTypes.financial,
      tableProps: tableProps,
      columns: [...mainTableColumns],
      tableOptions: {
        showChildRowToggler: false,
        headings: {
          [tableProps.toggler]: "",
          [tableProps.description]: "Description",
          [tableProps.published]: "Published",
          [tableProps.author]: "Author",
          [tableProps.options]: ""
        },
        sortable: [],
        filterable: [...mainTableColumns]
      },
      specificSlots: [
        tableProps.toggler,
        tableProps.description,
        tableProps.published,
        tableProps.options,
        "child_row"
      ],
      reminderModal: "reminderModal",
      modalDataRow: null
    };
  },
  computed: {
    id() {
      return +this.$route.params.id;
    },
    mainTitle() {
      return `Notes (${this.notesData.length})`;
    },
    possibleTypes() {
      return ["Financial"];
    },
    noteModalTitle() {
      return this.modalDataRow
        ? !this.modalDataRow.id
          ? "Create Note"
          : "Edit Note"
        : "";
    },
    notesData() {
      return this.$store.getters
        .BUSINESS_NOTES_BY_ID(this.id)
        .filter(item => item.note_type === this.noteType)
        .map(item => this.extendNoteData(item));
    }
  },
  methods: {
    extendNoteData(item) {
      return {
        ...item,
        [tableProps.author]: Utils.findUserName(
          item.author_id,
          this.$store.getters.USERS
        )
      };
    },
    createNewNote(data) {
      const payload = this.getCreateNotePayload(data);

      return this.$store.dispatch("createBusinessEntityNote", {
        id: this.id,
        payload: payload
      });
    },
    getNoteReminders(id) {
      return NoteRemindersApi.getNoteReminders(id).then(({ data }) => {
        return data;
      });
    },
    createNoteReminders(noteId, date) {
      const payload = {
        reminder_date: date ? Utils.getServerDateFormat(date) : null
      };
      // No need for Vuex ?
      return NoteRemindersApi.createNoteReminders(noteId, payload);
    },
    updateNoteReminder(noteReminderId, date) {
      const payload = {
        reminder_date: date ? Utils.getServerDateFormat(date) : null
      };
      return NoteRemindersApi.updateNoteReminder(noteReminderId, payload);
    },
    updateNoteEntity(data) {
      const payload = this.getUpdateNotePayload(data);

      return this.$store.dispatch("updateBusinessEntityNote", {
        id: this.id,
        noteId: data.id,
        payload: payload
      });
    },
    removeEntity(item) {
      let shouldBeConfirmed = item.id ? confirm("Are you sure?") : true;
      if (shouldBeConfirmed) {
        this.$store.dispatch("removeBusinessEntityNote", {
          id: this.id,
          noteId: item.id
        });
      }
    },
    openCreateModal() {
      this.collapseAllActiveRows();

      this.modalDataRow = {
        id: null,
        [tableProps.description]: "",
        [tableProps.author]: Utils.findUserName(
          this.$store.getters.USER.id,
          this.$store.getters.USERS
        ),
        reminder: null,
        expire_date: null
      };
      this.$refs[this.reminderModal].show();
    },
    collapseAllActiveRows() {
      const vTable = this.getTableRef();
      if (vTable) {
        for (let row of vTable.filteredData) {
          this.collapseActiveChildRow(vTable, row);
        }
      }
    },
    collapseActiveChildRow(vTable, row) {
      if (vTable) {
        if (row._expanded) {
          vTable.toggleChildRow(row.id);
          row._expanded = false;
        }
      }
    },
    showFullDescription(description) {
      let result = false;

      if (
        description.length > this.maxSymbols ||
        this.$options.filters.withoutFirstline(description)
      ) {
        result = true;
      }
      return result;
    },
    async onEdit(row) {
      this.collapseAllActiveRows();

      this.noteReminders = await this.getNoteReminders(row.id);
      this.modalDataRow = {
        ...row,
        created: moment(row.created).format(DateFormats.BASE),
        [tableProps.author]: Utils.findUserName(
          row.author_id,
          this.$store.getters.USERS
        ),
        reminder: this.noteReminders.length
          ? this.noteReminders[0].reminder_date
          : null
      };
      this.$refs[this.reminderModal].show();
    },
    onCancel() {
      this.$refs[this.reminderModal].hide();
    },
    async onSave() {
      const vm = this.$refs.notesModalRef;
      const $v = vm.$v;

      $v.$touch();

      if ($v.$error) {
        return;
      }
      const payload = { ...vm.entity, author_id: this.$store.getters.USER.id };

      if (this.modalDataRow.id) {
        await this.updateNoteEntity(payload);
        if (this.noteReminders.length) {
          this.updateNoteReminder(this.noteReminders[0].id, payload.reminder);
        } else {
          this.createNoteReminders(payload.id, payload.reminder);
        }
      } else {
        this.createNewNote(payload).then(({ data }) => {
          this.createNoteReminders(data.id, payload.reminder);
        });
      }

      this.$refs[this.reminderModal].hide();
    },
    getCreateNotePayload(data) {
      return {
        description: data.description,
        expire_date: data.expire_date
          ? Utils.getServerDateFormat(data.expire_date)
          : null,
        note_type: this.noteType,
        type: this.entityNoteType,
        author_id: this.$store.getters.USER.id
      };
    },
    getUpdateNotePayload(data) {
      return {
        ...data,
        expire_date: data.expire_date
          ? Utils.getServerDateFormat(data.expire_date)
          : null
      };
    },
    /* Custom toggler logic */
    async onTogglerClick(data) {
      const vTable = this.getTableRef();

      if (data.row._expanded === undefined) {
        data.row._expanded = true;
        if (data.row.reminder === undefined) {
          const dateReminders = await this.getNoteReminders(data.row.id);
          data.row.reminder = dateReminders.length
            ? dateReminders[0].reminder_date
            : null;
        }
      } else {
        data.row._expanded = !data.row._expanded;
      }

      if (vTable) {
        vTable.toggleChildRow(data.row.id);
      }
    },
    getTableRef() {
      return this.$refs.winboxDataTable.$refs.vTableRef;
    }
  }
};
