export const ContactFilterOptions = [
  {
    id: 1,
    code: 'locations',
    name: 'Location',
  },
  {
    id: 2,
    code: 'jobs',
    name: 'Job Function',
  },
  {
    id: 3,
    code: 'ranks',
    name: 'Title Rank',
  },
];
