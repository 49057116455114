import * as BusinessesApi from "@/api/businesses/businesses";
import * as Utils from "@/utils";
import { BusinessTypes } from "@/constants/BusinessTypes";
import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getAllOrganizations({ commit }) {
    return BusinessesApi.getAllBusinesses().then(
      ({ data }) => {
        const dataWithMock = [...Utils.defaultTableDataset(), ...data];
        commit(MutationTypes.SET_ORGANIZATIONS, dataWithMock);
      }
    );
  },

  updateBusiness({ commit, getters }, { id, payload, type }) {
    return BusinessesApi.updateBusinessEntity(id, payload).then(({ data }) => {
      commit(type, data);
    });
  },

  getBusinessEntity({ commit }, { id, type }) {
    return BusinessesApi.getBusinessEntity(id).then(({ data }) => {
      commit(type, data);
    });
  },

  getBusinessLocations({ commit }, id) {
    return BusinessesApi.getBusinessLocations(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_LOCATIONS, data);
    });
  },

  createBusinessLocation({ state, commit, getters }, { id, payload }) {
    return BusinessesApi.createBusinessLocation(id, payload).then(({ data }) => {
      commit(MutationTypes.CREATE_BUSINESS_LOCATIONS, data);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'locations_count',
        count: state.entityCounts.locations_count + 1
      });
    });
  },

  updateBusinessLocation({ commit, getters }, { locationId, payload }) {
    return BusinessesApi.updateBusinessLocation(locationId, payload).then(({data}) => {
      commit(MutationTypes.UPDATE_BUSINESS_LOCATIONS, data);
    });
  },

  getBusinessContactsByBrand({ commit }, id) {
    return BusinessesApi.getBusinessContactsByBrand(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_PROFILE_CONTACTS, data);
    });
  },

  getBusinessContacts({ commit }, id) {
    return BusinessesApi.getBusinessContacts(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_PROFILE_CONTACTS, data);
    });
  },

  createBusinessContact({ state, commit, getters }, { id, payload }) {
    return BusinessesApi.createBusinessContact(id, payload).then(({ data }) => {
      commit(MutationTypes.UPDATE_BUSINESS_PROFILE_CONTACTS, data);
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'contacts_count',
        count: state.entityCounts.contacts_count + 1
      });
    });
  },

  getBusinessBrands({ commit }, id) {
    return BusinessesApi.getBusinessBrands(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_BRANDS, data);
    });
  },

  getBusinessRevenues({ commit, getters }, { id }) {
    return BusinessesApi.getBusinessRevenues(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_REVENUES, data);
    });
  },

  updateBusinessRevenuesList({ commit, getters }, { id, payload }) {
    return BusinessesApi.updateBusinessRevenuesList(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_REVENUES, data);
    });
  },

  getBusinessSocials({ commit }, id) {
    return BusinessesApi.getBusinessSocials(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_SOCIALS, data);
    });
  },

  updateBusinessSocialsList({ commit, getters }, { id, payload }) {
    return BusinessesApi.updateBusinessSocialsList(id, payload).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_SOCIALS, data);
    });
  },

  updateBusinessSocial({ commit, getters }, { id, socialId, payload }) {
    let allData = getters.BUSINESS_SOCIALS_BY_ID(id) || [];

    return BusinessesApi.updateSocial(socialId, payload).then(({ data }) => {
      allData = Utils.spliceArrayItem(allData, data);
      commit(MutationTypes.SET_BUSINESS_SOCIALS, {
        id,
        data: allData
      });
      return { data };
    });
  },

  removeBusinessSocial({ commit, getters }, { id, socialId }) {
    let allData = getters.BUSINESS_SOCIALS_BY_ID(id) || [];

    return BusinessesApi.removeSocial(socialId).then(({ data }) => {
      allData = allData.filter(item => item.id !== socialId);
      commit(MutationTypes.SET_BUSINESS_SOCIALS, {
        id,
        data: allData
      });
      return { data };
    });
  },

  getBusinessNicknames({ commit }, id) {
    return BusinessesApi.getBusinessNicknames(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_NICKNAMES, {
        id,
        data
      });
    });
  },

  updateBusinessNicknameList({ commit, getters }, { id, payload }) {
    const allData = getters.BUSINESS_NICKNAMES_BY_ID(id) || [];

    return BusinessesApi.updateBusinessNicknamesList(id, payload).then(
      ({ data }) => {
        allData.push(data);
        commit(MutationTypes.SET_BUSINESS_NICKNAMES, {
          id,
          data: allData
        });
        return { data };
      }
    );
  },

  updateBusinessNickname({ commit, getters }, { id, nicknameId, payload }) {
    let allData = getters.BUSINESS_NICKNAMES_BY_ID(id) || [];

    return BusinessesApi.updateNickname(nicknameId, payload).then(
      ({ data }) => {
        allData = Utils.spliceArrayItem(allData, data);
        commit(MutationTypes.SET_BUSINESS_NICKNAMES, {
          id,
          data: allData
        });
        return { data };
      }
    );
  },

  removeNickname({ commit, getters }, { id, nicknameId }) {
    let allData = getters.BUSINESS_NICKNAMES_BY_ID(id) || [];

    return BusinessesApi.removeNickname(nicknameId).then(({ data }) => {
      allData = allData.filter(item => item.id !== nicknameId);
      commit(MutationTypes.SET_BUSINESS_NICKNAMES, {
        id,
        data: allData
      });
      return { data };
    });
  },

  getBusinessTags({ commit }, id) {
    return BusinessesApi.getBusinessTags(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_TAGS, {
        id,
        data
      });
    });
  },

  createBusinessEntityTag({ state, commit, getters }, { id, payload }) {
    const allData = getters.BUSINESS_TAGS_BY_ID(id) || [];

    return BusinessesApi.createBusinessEntityTag(id, payload).then(
      ({ data }) => {
        allData.push(data);
        commit(MutationTypes.SET_BUSINESS_TAGS, {
          id,
          data: allData
        });
        commit(MutationTypes.UPDATE_COUNTS, {
          stateVariableName: 'tags_count',
          count: state.entityCounts.tags_count + 1
        });
        return { data };
      }
    );
  },

  removeBusinessEntityTag({ state, commit, getters }, { id, tagId }) {
    let allData = getters.BUSINESS_TAGS_BY_ID(id) || [];

    return BusinessesApi.removeEntityTag(tagId).then(({ data }) => {
      allData = allData.filter(item => item.id !== tagId);
      commit(MutationTypes.SET_BUSINESS_TAGS, {
        id,
        data: allData
      });
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'tags_count',
        count: state.entityCounts.tags_count - 1
      });
      return { data };
    });
  },

  getBusinessNotes({ commit }, id) {
    return BusinessesApi.getBusinessNotes(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_NOTES, {
        id,
        data
      });
    });
  },

  createBusinessEntityNote({ state, commit, getters }, { id, payload }) {
    const allData = getters.BUSINESS_NOTES_BY_ID(id) || [];

    return BusinessesApi.createBusinessNote(id, payload).then(({ data }) => {
      allData.push(data);
      commit(MutationTypes.SET_BUSINESS_NOTES, {
        id,
        data: allData
      });
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'note_count',
        count: state.entityCounts.note_count + 1,
      });
      return { data };
    });
  },

  updateBusinessEntityNote({ commit, getters }, { id, noteId, payload }) {
    let allData = getters.BUSINESS_NOTES_BY_ID(id) || [];

    return BusinessesApi.updateEntityNote(noteId, payload).then(({ data }) => {
      allData = Utils.spliceArrayItem(allData, data);
      commit(MutationTypes.SET_BUSINESS_NOTES, {
        id,
        data: allData
      });
      return { data };
    });
  },

  removeBusinessEntityNote({ state, commit, getters }, { id, noteId }) {
    let allData = getters.BUSINESS_NOTES_BY_ID(id) || [];

    return BusinessesApi.removeEntityNote(noteId).then(({ data }) => {
      allData = allData.filter(item => item.id !== noteId);
      commit(MutationTypes.SET_BUSINESS_NOTES, {
        id,
        data: allData
      });
      commit(MutationTypes.UPDATE_COUNTS, {
        stateVariableName: 'note_count',
        count: state.entityCounts.note_count - 1,
      });
      return { data };
    });
  },

  getBusinessLogs({ commit }, id) {
    return BusinessesApi.getBusinessLogs(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_LOGS, {
        id,
        data
      });
    });
  },

  rollbackBusinessLog({ commit }, payload) {
    return BusinessesApi.rollbackBusinessLog(payload).then(({ data }) => {
      return { data };
    });
  },

  getBusinessIndustries({ commit }, id) {
    return BusinessesApi.getBusinessIndustries(id);
  },

  assignBusinessIndustries({ commit, getters }, { id, payload }) {
    const allData = getters.BUSINESS_INDUSTRIES_BY_ID(id) || [];

    return BusinessesApi.createBusinessIndustryAssociation(id, payload).then(
      ({ data }) => {
        allData.push(data);
        commit(MutationTypes.SET_BUSINESS_INDUSTRIES, {
          id,
          data: allData
        });
        return { data };
      }
    );
  },

  deleteBusinessIndustry({ commit, getters }, { id, industryId }) {
    let allData = getters.BUSINESS_INDUSTRIES_BY_ID(id) || [];

    return BusinessesApi.deleteBusinessIndustry(industryId).then(({ data }) => {
      allData = allData.filter(item => item.id !== industryId);
      commit(MutationTypes.SET_BUSINESS_INDUSTRIES, {
        id,
        data: allData
      });
      return { data };
    });
  },

  getBusinessDisciplines({ commit }, id) {
    return BusinessesApi.getBusinessDisciplines(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_DISCIPLINES, data);
    });
  },

  updateBusinessDisciplines({ commit, getters }, { id, payload }) {
    return BusinessesApi.updateBusinessDisciplines(id, payload)
    .then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_DISCIPLINES, data);
    });
  },

  assignBusinessDisciplineFromAgencyCreate({ }, { id, payload }) {
    return BusinessesApi.updateBusinessDisciplines(id, payload);
  },

  deleteBusinessDiscipline({ commit, getters }, { id, disciplineId }) {
    return BusinessesApi.deleteBusinessDiscipline(disciplineId).then(({ data }) => {
      commit(MutationTypes.REMOVE_BUSINESS_DISCIPLINES, disciplineId);
    });
  },

  getBusinessSpecialities({ commit }, id) {
    return BusinessesApi.getBusinessSpecialities(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_SPECIALITIES, data);
    });
  },

  assignBusinessSpeciality({ commit, getters }, { id, payload }) {
    return BusinessesApi.assignBusinessSpecialities(id, payload).then(
      ({ data }) => {
        commit(MutationTypes.SET_BUSINESS_SPECIALITIES, data);
      }
    );
  },

  updateBusinessSpeciality({ commit, getters }, { id, specialityId, payload }) {
    return BusinessesApi.updateBusinessSpeciality(specialityId, payload).then(
      ({ data }) => {
        commit(MutationTypes.SET_BUSINESS_SPECIALITIES, data);
      }
    );
  },

  deleteBusinessSpeciality({ commit, getters }, { id, specialityId }) {
    return BusinessesApi.deleteBusinessSpeciality(specialityId).then(
      ({ data }) => {
        commit(MutationTypes.SET_BUSINESS_SPECIALITIES, data);
      }
    );
  },

  getBusinessRelationships({ commit }, id) {
    return BusinessesApi.getBusinessRelationships(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_RELATIONSHIPS, data);
    });
  },

  createBusinessRelationship({ commit, getters }, { id, payload }) {
    return BusinessesApi.createBusinessRelationship(id, payload).then(({ data }) => {
      commit(MutationTypes.ADD_BUSINESS_RELATIONSHIPS, data);
    });
  },

  updateBusinessRelationship({ commit, getters }, { id, relationshipId, payload }) {
    return BusinessesApi.updateBusinessRelationship(id, relationshipId, payload).then(({ data }) => {
      commit(MutationTypes.UPDATE_BUSINESS_RELATIONSHIPS, data);
    });
  },

  getBusinessAgencyPeople({ commit }, id) {
    return BusinessesApi.getAgencyPeople(id).then(({ data }) => {
      commit(MutationTypes.SET_BUSINESS_AGENCY_CONTACTS, {
        id,
        data
      });
    });
  },

  assignBusinessAgencyPeople({ commit, getters }, { id, payload }) {
    let allData = getters.BUSINESS_AGENCY_CONTACTS_BY_ID(id) || [];

    return BusinessesApi.assignAgencyPeople(id, payload).then(
      ({ data }) => {
        allData = allData.concat(data);
        commit(MutationTypes.SET_BUSINESS_AGENCY_CONTACTS, {
          id,
          data: allData
        });
        return { data };
      }
    );
  },
};
