export const SponsorAffiliations = [
  "WNBA", "NBA", "MLB", "NFL", "MLS", "NHL", "Premier League", "ATP Tennis",
  "WTA Tennis", "PGA", "NASCAR", "UFC", "WWE", "AEW", "Boxing", "NWSL", "CFL",
  "Wimbledon", "US Open", "NASCAR - Truck Series", "LPGA"
];

export const SponsorCategories = [
  "Sports", "Event Sponsor", "Charity/Nonprofit", "Educational Sponsor",
  "Award/Competition", "Technology Partner", "Influencer/Content Creator",
  "Cause Marketing", "Arts & Creative", "Media Sponsor", "Festival Sponsor"
];

export const SponsorLevels = [
  "Official/Partner", "Venue Naming Rights", "Media Rights", "Team Sponsor",
  "Event Sponsor", "Experiential Sponsor"
];
