import { BusinessesUrl } from "@/constants/BusinessesUrl";
import { BusinessTypes } from "@/constants/BusinessTypes";

import {
  getGenericFullResponse,
  postGenericFullResponse,
  putGenericFullResponse,
  deleteGenericFullResponse
} from "@/api/_generic/_generic";

export function getAllBusinesses(name = null) {
  let url = BusinessesUrl.base;
  if (name) {
    url += `?name=${name}`;
  }
  return getGenericFullResponse(url);
}

export function getAllBusinessesByType(type = BusinessTypes.company, name = null) {
  const url = `${BusinessesUrl.base}?type=${type}`;
  if (name) {
    url += `?name=${name}`;
  }
  return getGenericFullResponse(url);
}

export function createEntity(data) {
  const url = BusinessesUrl.base;
  return postGenericFullResponse(url, data);
}

export function getBusinessEntity(id) {
  const url = `${BusinessesUrl.base}/${id}`;
  return getGenericFullResponse(url);
}

export function updateBusinessEntity(id, data) {
  const url = `${BusinessesUrl.base}/${id}`;
  return putGenericFullResponse(url, data);
}

export function getBusinessBrands(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.brands}`;
  return getGenericFullResponse(url);
}

export function createBrandAssignToBusiness(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.brands}`;
  return postGenericFullResponse(url, data);
}

export function getBusinessLocations(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_locations}`;
  return getGenericFullResponse(url);
}

export function createBusinessLocation(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_locations}`;
  return postGenericFullResponse(url, data);
}

export function getBusinessLocation(id) {
  const url = `/${BusinessesUrl.business_locations}/${id}`;
  return getGenericFullResponse(url);
}

export function updateBusinessLocation(id, data) {
  const url = `/${BusinessesUrl.business_locations}/${id}`;
  return putGenericFullResponse(url, data);
}

export function bulkUpdateBusinessRelationships(data) {
  const url = BusinessesUrl.bulk_update_agency_location;
  return postGenericFullResponse(url, data);
}

export function getBusinessContactsByBrand(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.company_contacts}`;
  return getGenericFullResponse(url);
}

export function getBusinessContacts(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.people}`;
  return getGenericFullResponse(url);
}

export function createBusinessContact(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.people}`;
  return postGenericFullResponse(url, data);
}

export function getBusinessRevenues(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_revenues}`;
  return getGenericFullResponse(url);
}

export function updateBusinessRevenuesList(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_revenues}`;
  return putGenericFullResponse(url, data);
}

export function updateBusinessRevenueById(id, data) {
  const url = `/${BusinessesUrl.business_revenues}/${id}`;
  return putGenericFullResponse(url, data);
}

export function deleteBusinessRevenueById(id) {
  const url = `/${BusinessesUrl.business_revenues}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessSpecialities(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_speciality_lists}`;
  return getGenericFullResponse(url);
}

export function assignBusinessSpecialities(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_speciality_lists}`;
  return putGenericFullResponse(url, data);
}

export function updateBusinessSpeciality(id, data) {
  const url = `${BusinessesUrl.business_speciality_lists}/${id}`;
  return putGenericFullResponse(url, data);
}

export function deleteBusinessSpeciality(id) {
  const url = `${BusinessesUrl.business_speciality_lists}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessDisciplines(id) {
  const url = `/agencies/${id}/${BusinessesUrl.business_disciplines}`;
  return getGenericFullResponse(url);
}

export function updateBusinessDisciplines(id, data) {
  const url = `/agencies/${id}/${BusinessesUrl.business_disciplines}`;
  return putGenericFullResponse(url, data);
}

export function deleteBusinessDiscipline(id) {
  const url = `/${BusinessesUrl.business_disciplines}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessSocials(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.entity_socials}`;
  return getGenericFullResponse(url);
}

export function updateBusinessSocialsList(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.entity_socials}`;
  return putGenericFullResponse(url, data);
}

export function updateSocial(id, data) {
  const url = `/${BusinessesUrl.entity_socials}/${id}`;
  return putGenericFullResponse(url, data);
}

export function removeSocial(id) {
  const url = `/${BusinessesUrl.entity_socials}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessNicknames(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_nicknames}`;
  return getGenericFullResponse(url);
}

export function updateBusinessNicknamesList(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_nicknames}`;
  return putGenericFullResponse(url, data);
}

export function updateNickname(id, data) {
  const url = `/${BusinessesUrl.business_nicknames}/${id}`;
  return putGenericFullResponse(url, data);
}

export function removeNickname(id) {
  const url = `/${BusinessesUrl.business_nicknames}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessTags(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.entity_tags}`;
  return getGenericFullResponse(url);
}

export function createBusinessEntityTag(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.entity_tags}`;
  return postGenericFullResponse(url, data);
}

export function removeEntityTag(id) {
  const url = `/${BusinessesUrl.entity_tags}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessNotes(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.entity_notes}`;
  return getGenericFullResponse(url);
}

export function createBusinessNote(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.entity_notes}`;
  return postGenericFullResponse(url, data);
}

export function updateEntityNote(id, data) {
  const url = `/${BusinessesUrl.entity_notes}/${id}`;
  return putGenericFullResponse(url, data);
}

export function removeEntityNote(id) {
  const url = `/${BusinessesUrl.entity_notes}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessLogs(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.entity_audit_logs}`;
  return getGenericFullResponse(url);
}

export function rollbackBusinessLog(data) {
  const url = `${BusinessesUrl.base}/${BusinessesUrl.entity_audit_logs_rollback}`;
  return postGenericFullResponse(url, data);
}

export function getBusinessIndustries(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_industries}`;
  return getGenericFullResponse(url);
}

export function createBusinessIndustryAssociation(id, payload) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_industries}`;
  return postGenericFullResponse(url, payload);
}

export function deleteBusinessIndustry(id) {
  const url = `/${BusinessesUrl.business_industries}/${id}`;
  return deleteGenericFullResponse(url);
}

export function getBusinessRelationships(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_relationships}`;
  return getGenericFullResponse(url);
}

export function createBusinessRelationship(id, payload) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_relationships}`;
  return postGenericFullResponse(url, payload);
}

export function updateBusinessRelationship(id, relationshipId, payload) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_relationships}/${relationshipId}`;
  return putGenericFullResponse(url, payload);
}

export function submitBusinessRelationshipsProof(id) {
  const url = `/business-relationships-proof/${id}`;
  return putGenericFullResponse(url);
}

export function getAgencyPeople(id) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_agency_contacts}`;
  return getGenericFullResponse(url);
}

export function assignAgencyPeople(id, data) {
  const url = `${BusinessesUrl.base}/${id}/${BusinessesUrl.business_agency_contacts}`;
  return postGenericFullResponse(url, data);
}

export function unassignAgencyPeople(id, business_id) {
  const url = `${BusinessesUrl.base}/${business_id}/${BusinessesUrl.business_agency_contacts}/${id}`;
  return deleteGenericFullResponse(url);
}

export function submitBusinessLocationsProof(id) {
  const url = `/business-locations-proof/${id}`;
  return putGenericFullResponse(url);
}
