var render = function render(_c, _vm) {
  return _c(
    "ul",
    { staticClass: "winbox-tabs-list", class: [_vm.props.theme] },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }