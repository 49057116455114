<template>
  <div class="admin-profile">
    <winbox-card>
      <template v-slot:header>
        Profile
      </template>
      <div class="content">
        <div class="columns">
          <div class="column">
            <winbox-input label="Name" :value="entity.name" :readonly="true" />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <winbox-input
              label="Email"
              :value="entity.email"
              :readonly="true"
            />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <winbox-input label="Role" v-model="entity.role" :readonly="true" />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <button class="button is-primary" @click="openModal">
              Change Password
            </button>
          </div>
        </div>
      </div>
    </winbox-card>
    <winbox-modal
      :ref="passwordModal"
      :name="passwordModal"
      :enable-footer-buttons="true"
      :click-to-close="false"
      title="Change Password"
      save-text="Change"
      @on-cancel="onCancel"
      @on-save="onSave"
    >
      <div class="response-box success" v-if="passwordSuccessMessage">
        {{ passwordSuccessMessage }}
      </div>
      <div class="response-box error" v-if="passwordErrorMessage.length">
        <ul>
          <li v-for="(item, key) in passwordErrorMessage" :key="key">
            {{ item }}
          </li>
        </ul>
      </div>
      <winbox-change-password
        ref="changePasswordRef"
        v-if="newEntity && isCreateNewLoaded"
        :data="newEntity"
      />
      <winbox-large-indicator v-if="!isCreateNewLoaded" />
    </winbox-modal>
  </div>
</template>

<script>
import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxModal from "@/components/Molecules/WinboxModal";

import WinboxChangePassword from "@/components/Organisms/WinboxChangePassword";
import * as AuthApi from "@/api/auth/auth";

export default {
  name: "AdminProfile",
  components: {
    WinboxCard,
    WinboxInput,
    WinboxLargeIndicator,
    WinboxModal,
    WinboxChangePassword
  },
  validations: {},
  data() {
    return {
      passwordModal: "passwordModal",
      isCreateNewLoaded: true,
      newEntity: null,
      passwordSuccessMessage: null,
      passwordErrorMessage: []
    };
  },
  computed: {
    entity() {
      const item = this.$store.getters.USER;
      return {
        ...item,
        name: `${item.first_name} ${item.last_name}`,
        role: this.getRoleName(item)
      };
    }
  },
  methods: {
    getRoleName(item) {
      if (!item.role) {
        return "None";
      }

      if (item.role.role) {
        return item.role.role;
      }
      return item.role;
    },
    onCancel() {
      this.$refs[this.passwordModal].hide();
      this.clearErrors();
    },
    onSave() {
      const vm = this.$refs.changePasswordRef;
      const $v = vm.$v;

      $v.$touch();
      this.clearErrors();

      if (!$v.$error) {
        this.isCreateNewLoaded = false;
        const payload = {
          password: vm.entity.newPassword,
          password_confirmation: vm.entity.repeatNewPassword
        };

        AuthApi.changePassword(payload)
          .then(response => {
            if (response && response.message) {
              this.passwordSuccessMessage = response.message;
            }
            this.isCreateNewLoaded = true;
          })
          .catch(e => {
            const data = e.response.data;
            this.isCreateNewLoaded = true;

            if (data && data.errors) {
              if (data.errors && data.errors.full_messages) {
                this.passwordErrorMessage = data.errors.full_messages;
              }
            }
          });
      }
    },
    openModal() {
      this.newEntity = this.prepareNewEntity();
      this.$refs[this.passwordModal].show();
    },
    prepareNewEntity() {
      return {
        email: this.entity.email,
        // oldPassword: "",
        newPassword: "",
        repeatNewPassword: ""
      };
    },
    clearErrors() {
      this.passwordSuccessMessage = null;
      this.passwordErrorMessage = [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.response-box {
  margin: 0 0 1rem;

  &.success {
    color: $winbox-success;
  }
  &.error {
    color: $winbox-error;
  }
}
</style>
