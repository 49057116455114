<template>
  <div class="wrap-user-avatar">
    <div class="no-user-avatar">
      <winbox-icon icon="user-tie" />
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";

export default {
  name: "WinboxUserAvatar",
  components: { WinboxIcon },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.wrap-user-avatar {
  padding: 8px 0;
  display: flex;
  justify-content: center;
  font-size: 28px;
  align-items: center;
  background: $winbox-primary-bg-color;
  border-top: 1px solid $winbox-sidebar-active-bg-color;
}
</style>
