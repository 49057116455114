<template>
  <div class="winbox-relationship-items">
    <div class="columns">
      <div class="column is-6">
        <winbox-select
          :value="entity.services.$model"
          :options="possibleServices"
          :multiple="true"
          placeholder="Services"
          label="name"
          :class="status(entity.services)"
          @input="
            e => {
              entity.services.$model = e;
              emitState();
            }
          "
        />
      </div>
      <div class="column is-6">
        <winbox-select
          :value="entity.mediaTypes.$model"
          :options="possibleMediaTypes"
          :multiple="true"
          label="name"
          placeholder="Media Type"
          :class="status(entity.mediaTypes)"
          @input="
            e => {
              entity.mediaTypes.$model = e;
              emitState();
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import WinboxSelect from "@/components/Atoms/WinboxSelect";
import * as Utils from "@/utils";

export default {
  name: "WinboxRelationShipItems",
  components: {
    WinboxSelect,
  },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      entity: this.value
    };
  },
  computed: {
    possibleServices() {
      return this.$store.getters.SERVICES;
    },
    possibleMediaTypes() {
      return this.$store.getters.MEDIA_TYPES;
    },
  },
  methods: {
    status(validation) {
      return Utils.validationStatus(validation);
    },
    emitState() {
      this.$emit("input", this.entity.$model);
    }
  }
};
</script>

<style lang="scss" scoped>
.winbox-relationship-items {
  margin: 0 0 1rem;
}
</style>
