export const PeopleUrl = {
  base: "/people",
  person_title_functions: "person_title_functions",
  person_work_histories: "person_work_histories",
  person_speciality_lists: "person_speciality_lists",
  person_brands: "brands",
  person_locations: "person_business_locations",
  person_organizations: "person_organizations",
  person_brand_agencies: "person_brand_agencies",
  person_brand_companies: "person_brand_companies",
  person_brands_list: "brands-list",
  person_notes: "person_notes",
  person_business_agency_contacts: "business_agency_contacts",
};
