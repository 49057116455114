export const IndustriesFilterOptions = [
  { name: 'Advertising & Promotion' },
  { name: 'Agricultural Business' },
  { name: 'Apparel & Accessories' },
  { name: 'Associations & Organizations' },
  { name: 'Automotive' },
  { name: 'Beverages' },
  { name: 'Broadcasting' },
  { name: 'Commerce & Sales' },
  { name: 'Consumer Electronics' },
  { name: 'Content & Experience' },
  { name: 'Data' },
  { name: 'Digital Business Provider' },
  { name: 'Education' },
  { name: 'Energy, Gasoline & Oil' },
  { name: 'Entertainment' },
  { name: 'Financial Services' },
  { name: 'Food' },
  { name: 'Furniture' },
  { name: 'Government' },
  { name: 'Health Care' },
  { name: 'Home Related' },
  { name: 'Management' },
  { name: 'Manufacturing' },
  { name: 'Office Related' },
  { name: 'Packaged Goods' },
  { name: 'Publishing & Printed Media' },
  { name: 'Real Estate' },
  { name: 'Retail Stores & Chains' },
  { name: 'Service Businesses' },
  { name: 'Social & Relationships' },
  { name: 'Sports & Recreation' },
  { name: 'Technology' },
  { name: 'Telecommunications' },
  { name: 'Textiles' },
  { name: 'Transportation & Shipping' },
  { name: 'Travel & Hospitality' },
];
