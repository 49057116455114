var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoaded
    ? _c("div", { staticClass: "data-import-page" }, [
        _c(
          "div",
          {
            staticClass:
              "columns is-variable is-gapless is-vcentered data-import-header-title"
          },
          [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("winbox-title", {
                  staticClass: "main-title",
                  attrs: { title: "Import Data to Winbox" }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "main-descr" }, [
          _vm._v(
            "\n    Upload a CSV file and map fields to corresponding fields in Winbox\n  "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns is-variable is-1 is-vcentered" }, [
          _c(
            "div",
            { staticClass: "column is-2" },
            [
              _c("winbox-select", {
                attrs: {
                  "form-label": "Select a Template",
                  options: _vm.possibleTemplates
                },
                on: { input: _vm.selectTemplate },
                model: {
                  value: _vm.selectedTemplate,
                  callback: function($$v) {
                    _vm.selectedTemplate = $$v
                  },
                  expression: "selectedTemplate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-narrow column-upload-file" },
            [
              _c("winbox-upload-file", {
                ref: "fileUpload",
                attrs: {
                  "upload-file": _vm.uploadedFile,
                  disabled: !_vm.selectedTemplate
                },
                on: { changed: _vm.onFileUploadChange }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-narrow column-download-file" },
            [
              _c("winbox-template-file", {
                attrs: { disabled: !_vm.selectedTemplate },
                on: { "download-template": _vm.downloadTemplate }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.fileData
            ? _c("div", { staticClass: "column is-narrow" }, [
                _c("div", { staticClass: "upload-file-label" }, [
                  _vm._v("Uploaded File:")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "upload-file-wrap" }, [
                  _c("span", { attrs: { title: _vm.fileData.name } }, [
                    _vm._v(_vm._s(_vm.fileData.name))
                  ]),
                  _vm._v(" "),
                  _vm.isLoaded
                    ? _c(
                        "button",
                        { staticClass: "button button-delete" },
                        [
                          _c("winbox-icon", {
                            attrs: {
                              icon: "times",
                              color: _vm.Colors.system.error
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.removeFile.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.fileData
            ? _c("div", { staticClass: "column" }, [
                _vm.isLoaded
                  ? _c(
                      "button",
                      {
                        staticClass: "button",
                        on: { click: _vm.goToUploaData }
                      },
                      [_vm._v("\n        Upload\n      ")]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.isUploading
          ? _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "alert", class: _vm.alertStyleObj }, [
                _vm._v(_vm._s(_vm.message))
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }