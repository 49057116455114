var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "change-password" }, [
    _c("form", [
      _c("div", { staticClass: "columns is-variable is-1 is-vcentered" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column is-8" },
          [
            _c("winbox-input", {
              class: _vm.status(_vm.$v.entity.email),
              attrs: { placeholder: "Email", readonly: true },
              model: {
                value: _vm.$v.entity.email.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.entity.email,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.entity.email.$model"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns is-variable is-1 is-vcentered" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column is-8" },
          [
            _c("winbox-input", {
              class: _vm.status(_vm.$v.entity.newPassword),
              attrs: { placeholder: "New Password", type: "password" },
              model: {
                value: _vm.$v.entity.newPassword.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.entity.newPassword,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.entity.newPassword.$model"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns is-variable is-1 is-vcentered" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column is-8" },
          [
            _c("winbox-input", {
              class: _vm.status(_vm.$v.entity.repeatNewPassword),
              attrs: { placeholder: "Repeat New Password", type: "password" },
              model: {
                value: _vm.$v.entity.repeatNewPassword.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.entity.repeatNewPassword,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.entity.repeatNewPassword.$model"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-4" }, [
      _c("div", { staticClass: "field-label" }, [_vm._v("Email")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-4" }, [
      _c("div", { staticClass: "field-label" }, [_vm._v("New Password")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-4" }, [
      _c("div", { staticClass: "field-label" }, [_vm._v("Repeat New Password")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }