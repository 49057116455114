// Agencies
import Agencies from "@/components/Pages/Agencies/Agencies";
import AgencyEntry from "@/components/Pages/Agencies/AgencyEntry";
import AgencyProfile from "@/components/Pages/Agencies/AgencyProfile";
import AgencyLocations from "@/components/Pages/Agencies/AgencyLocations";
import AgencyLocationsDetail from "@/components/Pages/Agencies/AgencyLocationsDetail";
import AgencyContacts from "@/components/Pages/Agencies/AgencyContacts";
import AgencyBrands from "@/components/Pages/Agencies/AgencyBrands";
import AgencyHierarchy from "@/components/Pages/Agencies/AgencyHierarchy";
import AgencyNotes from "@/components/Pages/Agencies/AgencyNotes";
import AgencyTags from "@/components/Pages/Agencies/AgencyTags";
import AgencyInternalNotes from "@/components/Pages/Agencies/AgencyInternalNotes";
import AgencyAuditLog from "@/components/Pages/Agencies/AgencyAuditLog";

import { WinboxRoutePaths } from "@/constants/WinboxRoutePaths";

export default [
  {
    path: WinboxRoutePaths.AGENCIES,
    name: "Agencies",
    component: Agencies,
    meta: {
      breadcrumb: "Agencies"
    }
  },
  {
    path: `${WinboxRoutePaths.AGENCIES}/:id`,
    component: AgencyEntry,
    children: [
      {
        path: WinboxRoutePaths.AGENCY_PROFILE,
        component: AgencyProfile,
        meta: {
          breadcrumb: "Agency Profile"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_LOCATIONS,
        component: AgencyLocations,
        meta: {
          breadcrumb: "Locations"
        }
      },
      {
        path: `${WinboxRoutePaths.AGENCY_LOCATIONS}/:locationId`,
        component: AgencyLocationsDetail,
        meta: {
          breadcrumb: "Location Details"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_CONTACTS,
        component: AgencyContacts,
        meta: {
          breadcrumb: "Contacts"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_BRANDS,
        component: AgencyBrands,
        meta: {
          breadcrumb: "Brands"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_HIERARCHY,
        component: AgencyHierarchy,
        meta: {
          breadcrumb: "Hierarchy"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_NOTES,
        component: AgencyNotes,
        meta: {
          breadcrumb: "Notes"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_TAGS,
        component: AgencyTags,
        meta: {
          breadcrumb: "Tags"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_INTERNAL_NOTES,
        component: AgencyInternalNotes,
        meta: {
          breadcrumb: "Internal Notes"
        }
      },
      {
        path: WinboxRoutePaths.AGENCY_AUDIT_LOG,
        component: AgencyAuditLog,
        meta: {
          breadcrumb: "Audit Log"
        }
      }
    ]
  }
];
