<template>
  <aside class="search-options" v-if="isEntityPage">
    <div id="search-filters">
      <div>
        <button class="btn-filter-disabled">
            Search
          <span>
            <span>{{ searchPage }}</span>
          </span>
            by
        </button>
        <button 
          v-for="(filterOption) in filterOptions" 
          class="btn-filter" 
          :ref="filterOption.name"
          :id="filterOption.name" 
          :key="filterOption.id"
          @click="openPopupFilter(filterOption.name, filterOption.code)"
        >
          <i class="far fa-square" v-if="!filterOption.checked"></i>
          <i class="far fa-check-square" v-if="filterOption.checked"></i>
            {{ filterOption.name }}
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "WinboxSearchByMenu",
  props: {
    searchPage: {
      required: true,
      type: String,
    },
    filterOptions: {
      required: true,
      type: Array,
    },
  },
  computed: {
    isEntityPage() {
      return !this.$store.getters.IS_PROFILE_PAGE && ['Companies', 'Brands', 'Agencies', 'Contacts'].includes(this.searchPage);
    },
  },
  methods: {
    openPopupFilter(name, code) {
      let elem = this.$refs[name][0]
      let rect = elem.getBoundingClientRect();

      this.$store.dispatch(`get${name.split(' ').join('')}FilterOptions`);
      this.$emit('open-popup-filter', code, rect.top);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

aside.search-options {
  position: fixed;
  z-index: 2;
  height: auto;
  margin: -10px 0 0.5rem;
  min-height: 100%;
  background: #fff;
  width: 220px;
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 0 0 80px 0;
  color: #fff;
  overflow: visible !important;
  left: 76px;
  top: 70px;

  .filter-buttons-shared {
    background: none;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
  }

  .btn-filter-options {
    @extend .filter-buttons-shared;
    color: $winbox-search-filter-text-color;
    width: 100%;
    padding: 12px;
    border: 0;
    border-bottom: 1px solid #E6E9EE;
  }

  .btn-filter,
  .btn-filter-disabled {
    @extend .btn-filter-options;
    cursor: pointer;
  }

  .btn-filter {
    .far {
      margin-right: 5px;
      font-size: 15px;
      &.fa-check-square {
        color: $winbox-primary-active-bg-color;
      }
    }

    &:focus {
      background: none;
      color: $winbox-search-filter-text-color;
    }
    &:hover, &.active {
      background: $winbox-primary-active-bg-color;
      color: #FFF;
      .far {
        color: #FFF;
      }
    }
  }
}
</style>
