import * as PeopleApi from "@/api/people/people";
import cloneDeep from "clone-deep";

export const WinboxCreateContactHelperMixin = {
  data() {
    return {
      isCreateNewContactLoaded: true,
      newContactEntity: null,
      createModal: "createModal",
      backendErrors: "",
      oldData: null
    };
  },
  methods: {
    openModal() {
      this.newContactEntity = this.prepareNewContactEntity(this.businessEntity);
      this.$refs[this.createModal].show();
    },
    prepareNewContactEntity(data) {
      return {
        fname: "",
        lname: "",
        title: "",
        title_rank: "",
        organization: data ? data : "",
        location: data && data.location ? data.location : ""
      };
    },
    onCancel() {
      this.$refs[this.createModal].hide();
    },
    onSave() {
      const createContactVm = this.$refs.createContactRef;
      const $v = createContactVm.$v;

      $v.$touch();
      this.backendErrors = "";

      if (!$v.$error) {
        this.isCreateNewContactLoaded = false;
        this.oldData = cloneDeep(createContactVm.entity);

        if (typeof this.saveFn === "function") {
          this.saveFn(createContactVm.entity)
            .then(() => {
              this.beforeAfterSave();
            })
            .catch(e => {
              this.afterSaveFail(e.response);
            });
        } else {
          const payload = this.modifyCreateContactPayload(createContactVm.entity);
          PeopleApi.createPerson(payload)
            .then(({ data }) => {
              this.beforeAfterSave(data);
            })
            .catch(e => {
              this.afterSaveFail(e.response);
            });
        }
      }
    },
    modifyCreateContactPayload(payload) {
      let result = cloneDeep(payload);

      if (payload.location && payload.location.value) {
        result.business_location_id = payload.location.value
      }
      else if (payload.location && payload.location.id) {
        result.business_location_id = payload.location.id
      }

      if (payload.title_rank && payload.title_rank.value) {
        result.title_rank_id = payload.title_rank.value;
      }

      if (payload.brand && payload.brand.id) {
        result.brand_people_attributes = [payload.brand.id];
      }

      delete result.organization;
      delete result.location;
      delete result.title_rank;
      delete result.brand;

      return result;
    },
    beforeAfterSave(data) {
      this.isCreateNewContactLoaded = true;

      if (typeof this.afterSave === "function") {
        this.afterSave();
      } else {
        // Default: redirect
        if (data.id) {
          this.goToNewContact(data.id);
        }
      }
    },
    afterSaveFail(response) {
      this.isCreateNewContactLoaded = true;

      if (response && response.data) {
        const errors = response.data.errors;
        if (errors && errors.base && errors.base.length) {
          this.backendErrors = Object.values(errors.base).join(", ");
        }
        if (errors && errors.person && errors.person.length) {
          this.newContactEntity = this.oldData;
          this.backendErrors = errors.person[0];
        }
        if (errors && errors.email && errors.email.length) {
          this.newContactEntity = this.oldData;
          this.backendErrors = errors.email[0];
        }

      }
    },
    goToNewContact(id) {
      let url = this.constructContactProfileUrl(id);
      this.$router.push(url);
    },
  }
};
