var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "winbox-relationship-items" }, [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-select", {
            class: _vm.status(_vm.entity.services),
            attrs: {
              value: _vm.entity.services.$model,
              options: _vm.possibleServices,
              multiple: true,
              placeholder: "Services",
              label: "name"
            },
            on: {
              input: e => {
                _vm.entity.services.$model = e
                _vm.emitState()
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-6" },
        [
          _c("winbox-select", {
            class: _vm.status(_vm.entity.mediaTypes),
            attrs: {
              value: _vm.entity.mediaTypes.$model,
              options: _vm.possibleMediaTypes,
              multiple: true,
              label: "name",
              placeholder: "Media Type"
            },
            on: {
              input: e => {
                _vm.entity.mediaTypes.$model = e
                _vm.emitState()
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }