var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audit-log-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "business-wrap-content" },
            [
              _c("winbox-title", {
                staticClass: "main-title",
                attrs: { title: _vm.mainTitle }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-data-table", {
                      ref: "mainTableRef",
                      staticClass: "main-data-table",
                      attrs: {
                        data: _vm.dataset,
                        columns: _vm.columns,
                        options: _vm.tableOptions,
                        slots: _vm.specificSlots
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: _vm.tableProps.date,
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("winboxDatetime")(
                                        data.row[_vm.tableProps.date]
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.undo,
                            fn: function(data) {
                              return [
                                data.row.action ==
                                data.row.auditable_type +
                                  " " +
                                  _vm.AuditLogActions.update
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "button button-edit",
                                        attrs: {
                                          disabled: true,
                                          "data-tooltip": "Rollback"
                                        }
                                      },
                                      [
                                        _c("winbox-icon", {
                                          attrs: {
                                            icon: "undo",
                                            color:
                                              _vm.Colors.system.primaryLight
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.onUndoClick(
                                                data.row.auditable_type,
                                                data.row.associated_type,
                                                data.row.id
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }