import { RoleTypes } from "@/constants/RoleTypes";

export const RolesMixin = {
  data() {
    return {
    };
  },
  beforeMount() {
  },
  watch: {},
  computed: {
    user() {
      return this.$store.getters.USER;
    }
  },
  methods: {
    isAdmin() {
      return this.user?.role === RoleTypes.ADMIN;
    },
    deleteSupport() {
      return this.isAdmin();
    },
  }
};
