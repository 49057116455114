var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("winbox-title", {
        staticClass: "main-title",
        attrs: { title: "Winbox Login" }
      }),
      _vm._v(" "),
      !_vm.isForgotPasswordVisible
        ? _c("winbox-card", [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _vm.backendErrors
                  ? _c("p", { staticClass: "error-block" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.backendErrors) + "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-variable is-1" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.entity.login),
                        attrs: { placeholder: "user@example.com" },
                        model: {
                          value: _vm.$v.entity.login.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.login,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.login.$model"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-variable is-1" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.entity.password),
                        attrs: { placeholder: "password", type: "password" },
                        model: {
                          value: _vm.$v.entity.password.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.password,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.password.$model"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "forgot-password-block" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showForgot.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Forgot Password?")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-variable is-1" }, [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        attrs: { disabled: !_vm.isValidForm }
                      },
                      [_vm._v("\n            Submit\n          ")]
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isForgotPasswordVisible
        ? _c("winbox-card", [
            _vm.emailResetMessage
              ? _c("div", { staticClass: "forgot-box success" }, [
                  _vm._v("\n      " + _vm._s(_vm.emailResetMessage) + "\n    ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.emailResetErrorMessage
              ? _c("div", { staticClass: "forgot-box error" }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.emailResetErrorMessage) + "\n    "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.emailResetMessage
              ? _c("div", { staticClass: "reset-password-wrap" }, [
                  _c("div", { staticClass: "columns is-variable is-1" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _c("winbox-input", {
                          class: _vm.status(_vm.$v.forgotEntity.login),
                          attrs: { placeholder: "user@example.com" },
                          model: {
                            value: _vm.$v.forgotEntity.login.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.forgotEntity.login,
                                "$model",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.forgotEntity.login.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns is-variable is-1" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        !_vm.isForgotLoaderVisible
                          ? _c(
                              "button",
                              {
                                staticClass: "button is-primary",
                                attrs: { disabled: !_vm.isValidForgotForm },
                                on: { click: _vm.onForgotSubmit }
                              },
                              [
                                _vm._v(
                                  "\n            Send me a link to reset password\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isForgotLoaderVisible
                          ? _c("winbox-large-indicator")
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "back-to-login" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.backToLogin.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Back to Login")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }