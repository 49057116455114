var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brands-wrap" },
    [
      !_vm.isLoaded ? _c("winbox-large-indicator") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded
        ? _c(
            "div",
            { staticClass: "brands-wrap-content" },
            [
              _vm.isAdmin()
                ? _c("winbox-company-header", {
                    attrs: {
                      title: _vm.mainTitle,
                      "entity-secondary-disabled": !_vm.markedRows.length,
                      "entity-fourth-label": "Assign"
                    },
                    on: { changedFourth: _vm.onAssignModal }
                  })
                : _c("winbox-company-header", {
                    attrs: { title: _vm.mainTitle }
                  }),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("winbox-server-data-table", {
                      ref: "mainTableRef",
                      staticClass: "main-data-table",
                      attrs: {
                        url: _vm.getBrandsUrl(),
                        responseFunction: _vm.enhanceData,
                        columns: _vm.columns,
                        options: _vm.tableOptions,
                        slots: _vm.specificSlots
                      },
                      on: {
                        rowsChange: _vm.onRowsChange,
                        filter: _vm.onFilter,
                        updateQuery: _vm.onUpdateQuery
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: _vm.tableProps.name,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      "data-tooltip": data.row.id,
                                      to: {
                                        path: _vm.constructProfileUrl(
                                          data.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(data.row[_vm.tableProps.name]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.company,
                            fn: function(data) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      "data-tooltip": data.company,
                                      to: {
                                        path: _vm.constructCompanyProfileUrl(
                                          data.row.business
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          data.row.business
                                            ? data.row.business.name
                                            : ""
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.tableProps.status,
                            fn: function(data) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "status-wrap" },
                                  [
                                    _c("winbox-icon", {
                                      attrs: {
                                        color: data.row.statusColor,
                                        icon: data.row.statusIcon
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.syncModal,
          attrs: {
            name: _vm.syncModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Sync to Winmo",
            "save-text": "Sync"
          },
          on: { "on-cancel": _vm.onSyncCancel, "on-save": _vm.onSyncSave }
        },
        [
          _vm.isSyncModalLoaded ? _c("p", [_vm._v("Are you sure?")]) : _vm._e(),
          _vm._v(" "),
          !_vm.isSyncModalLoaded ? _c("winbox-large-indicator") : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.deleteModal,
          attrs: {
            name: _vm.deleteModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Delete brand",
            "save-text": "Ok"
          },
          on: { "on-cancel": _vm.onDeleteCancel, "on-save": _vm.onDeleteAccept }
        },
        [
          _vm.isDeleteLoaded ? _c("p", [_vm._v("Are you sure?")]) : _vm._e(),
          _vm._v(" "),
          !_vm.isDeleteLoaded ? _c("winbox-large-indicator") : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "winbox-modal",
        {
          ref: _vm.assignModal,
          attrs: {
            name: _vm.assignModal,
            "enable-footer-buttons": true,
            "click-to-close": false,
            title: "Assign Brand Tasks",
            "save-text": "Assign"
          },
          on: { "on-cancel": _vm.onAssignCancel, "on-save": _vm.onAssignSave }
        },
        [
          _vm.isAssignNewEntityTasksLoaded
            ? _c("winbox-assign-entity-tasks", { ref: "assignEntityTasksRef" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAssignNewEntityTasksLoaded
            ? _c("winbox-large-indicator")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }