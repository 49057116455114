<template>
  <div class="wrap-company-header">
    <div class="columns is-vcentered">
      <div class="column">
        <winbox-title :title="title" />
        <div v-if="enableStatusToggle" class="radio-status">
          <input type="radio" id="active" name="status" :value="activeLabel" @click="$emit('toggle-status', activeLabel)" checked />
          <label for="active" class="status">{{activeLabel}}</label><br>
          <span v-if="inProgressLabel">
            <input type="radio" id="in-progress" name="status" :value="inProgressLabel" @click="$emit('toggle-status', inProgressLabel)" />
            <label for="in-progress" class="status">{{inProgressLabel}}</label><br>
          </span>
          <input type="radio" id="past" name="status" :value="inactiveLabel" @click="$emit('toggle-status', inactiveLabel)" />
          <label for="past" class="status">{{inactiveLabel}}</label><br>
          <span v-if="escalatedLabel">
            <input type="radio" id="escalated" name="status" :value="escalatedLabel" @click="$emit('toggle-status', escalatedLabel)" />
            <label for="escalated" class="status">{{escalatedLabel}}</label><br>
          </span>
        </div>
        <div v-if="enableLocationContactStatusToggle" class="radio-status">
          <input type="radio" id="active" name="status" :value="locationContactActiveLabel" @click="$emit('toggle-status', locationContactActiveLabel)" checked />
          <label for="location-active" class="status">{{locationContactActiveLabel}}</label><br>
          <input type="radio" id="inactive" name="status" :value="locationContactInactiveLabel" @click="$emit('toggle-status', locationContactInactiveLabel)" />
          <label for="location-inactive" class="status">{{locationContactInactiveLabel}}</label><br>
        </div>
        <div v-if="enableBrandServiceStatusToggle" class="radio-status">
          <input type="radio" id="active" name="brand-status" :value="brandServiceActiveLabel" @click="$emit('toggle-status', brandServiceActiveLabel)" checked />
          <label for="brand-service-active" class="status">{{brandServiceActiveLabel}}</label><br>
          <input type="radio" id="inactive" name="brand-status" :value="brandServiceInactiveLabel" @click="$emit('toggle-status', brandServiceInactiveLabel)" />
          <label for="brand-service-inactive" class="status">{{brandServiceInactiveLabel}}</label><br>
        </div>
      </div>
      <div class="column is-narrow header-action-wrap">
        <button
          class="button"
          v-if="hasEventHandler"
          :disabled="!hasEventHandler"
          @click="$emit(mainEventName, {})"
        >
          <winbox-icon
            class="add-entity-icon"
            :color="Colors.system.lightGray"
            :icon="entityIcon"
          />
          <span>{{ entityLabel }}</span>
        </button>
      </div>
      <div
        v-if="hasEventSecondaryHandler"
        class="column is-narrow header-action-wrap"
      >
        <button
          :disabled="entitySecondaryDisabled"
          class="button"
          @click="$emit(secondaryEventName, {})"
        >
          <winbox-icon
            v-if="entitySecondaryIcon"
            class="add-entity-icon"
            :color="Colors.system.lightGray"
            :icon="entitySecondaryIcon"
          />
          <span v-if="entitySecondaryLabel">{{ entitySecondaryLabel }}</span>
        </button>
      </div>
      <div class="column is-narrow header-action-wrap">
        <button
          class="button"
          v-if="hasEventFourthHandler"
          :disabled="entityFourthDisabled"
          @click="$emit(fourthEventName, {})"
        >
          <winbox-icon
            class="add-entity-icon"
            :color="Colors.system.lightGray"
            :icon="entityIcon"
          />
          <span>{{ entityFourthLabel }}</span>
        </button>
      </div>
      <div
        v-if="hasEventThirdHandler"
        class="column is-narrow header-action-wrap"
      >
        <button
          :disabled="entityThirdDisabled"
          class="button"
          @click="$emit(thirdEventName, {})"
        >
          <winbox-icon
            v-if="entityThirdIcon"
            class="add-entity-icon"
            :color="Colors.system.lightGray"
            :icon="entityThirdIcon"
          />
          <span v-if="entityThirdLabel">{{ entityThirdLabel }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WinboxIcon from "@/components/Atoms/WinboxIcon";
import WinboxTitle from "@/components/Atoms/WinboxTitle";

import { Colors } from "@/Colors";

export default {
  name: "WinboxCompanyHeader",
  components: {
    WinboxIcon,
    WinboxTitle
  },
  props: {
    title: {
      type: String,
      required: true
    },
    entityIcon: {
      type: String,
      required: false,
      default: "plus"
    },
    entityLabel: {
      type: String,
      required: false,
      default: "Create"
    },
    entitySecondaryIcon: {
      type: String,
      required: false,
      default: null
    },
    entitySecondaryLabel: {
      type: String,
      required: false,
      default: null
    },
    entitySecondaryDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    entityThirdIcon: {
      type: String,
      required: false,
      default: null
    },
    entityThirdLabel: {
      type: String,
      required: false,
      default: null
    },
    entityThirdDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    enableStatusToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableLocationContactStatusToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableBrandServiceStatusToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    entityFourthLabel: {
      type: String,
      required: false,
      default: null,
    },
    entityFourthDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    activeLabel: {
      type: String,
      required: false,
      default: 'Active',
    },
    inactiveLabel: {
      type: String,
      required: false,
      default: 'Past',
    },
    locationContactActiveLabel: {
      type: String,
      required: false,
      default: 'Active',
    },
    locationContactInactiveLabel: {
      type: String,
      required: false,
      default: 'Inactive',
    },
    brandServiceActiveLabel: {
      type: String,
      required: false,
      default: 'Active',
    },
    brandServiceInactiveLabel: {
      type: String,
      required: false,
      default: 'Inactive',
    },
    inProgressLabel: {
      type: String,
      required: false,
      default: null,
    },
    escalatedLabel: {
      type: String,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      Colors: Colors,
      mainEventName: "changed",
      secondaryEventName: "changedSecondary",
      thirdEventName: "changedThird",
      fourthEventName: "changedFourth",
    };
  },
  computed: {
    hasEventHandler() {
      return this.$listeners && this.$listeners[this.mainEventName];
    },
    hasEventSecondaryHandler() {
      return this.$listeners && this.$listeners[this.secondaryEventName];
    },
    hasEventThirdHandler() {
      return this.$listeners && this.$listeners[this.thirdEventName];
    },
    hasEventFourthHandler() {
      return this.$listeners && this.$listeners[this.fourthEventName];
    },
  },
  method: {

  }
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";

.header-action-wrap {
  text-align: right;

  .button {
    background: $winbox-primary-active-bg-color;
    border-color: transparent;
    color: #585656;
    transition: all 0.3s;

    &:hover {
      background: $winbox-button-focus-color;
    }
  }
}
.add-entity-icon {
  cursor: pointer;
  font-size: $winbox-font-size-average;
  margin: 0 5px 0 0 !important;
  width: auto;
  height: auto;
}
.radio-status {
  display: inline-block;
  position: relative;
  top: 7px;
  left: 10px;
}
.status {
  color: $winbox-primary-active-bg-color;
  font-weight: bold;
}
</style>
