import { BusinessesUrl } from "@/constants/BusinessesUrl";

import {
  getGenericFullResponse,
  postGenericFullResponse,
  putGenericFullResponse
} from "@/api/_generic/_generic";

export function getNoteReminders(id) {
  const url = `/${BusinessesUrl.entity_notes}/${id}/${BusinessesUrl.entity_note_reminders}`;
  return getGenericFullResponse(url);
}

export function createNoteReminders(id, data) {
  const url = `/${BusinessesUrl.entity_notes}/${id}/${BusinessesUrl.entity_note_reminders}`;
  return postGenericFullResponse(url, data);
}

export function updateNoteReminder(id, data) {
  const url = `/${BusinessesUrl.entity_note_reminders}/${id}`;
  return putGenericFullResponse(url, data);
}
